import Dom from './dom'
import Tex from './tex'
import FlattenFilter from './filters/flatten-filter'
import DocViewFilter from './filters/doc-view-filter'
import RectFilter from './filters/rect-filter'
import OvalFilter from './filters/oval-filter'
import StrokeFilter from './filters/stroke-filter'
import BlendFilter from './filters/blend-filter'
import MoveFilter from './filters/move-filter'
import ClipFilter from './filters/clip-filter'
import DistortFilter from './filters/distort-filter'
import ShowTextureFilter from './filters/show-texture-filter'
import FillFilter from './filters/fill-filter'
import FlipFilter from './filters/flip-filter'

import HorizontalPresenceFilter from './filters/presence-filter/horizontal-presence-filter'
import VerticalPresenceFilter from './filters/presence-filter/vertical-presence-filter'
import CornerPresenceFilter from './filters/presence-filter/corner-presence-filter'

import LinearizeHorizontalFilter from './filters/boundary-filter/linearize-horizontal'
import VerticalBoundaryFilter from './filters/boundary-filter/vertical-boundary'

import LinearizeVerticalFilter from './filters/boundary-filter/linearize-vertical'
import HorizontalBoundaryFilter from './filters/boundary-filter/horizontal-boundary'

import { generateId } from './id'

const showTextureFilter = new ShowTextureFilter()

export class Wgl {
  constructor () {
    const canvas = Dom.canvas()
    this.canvas = canvas
    this.gl = canvas.getContext('webgl2')
    this.programs = {}
    this.init()
    this.activeTextureKeys = []
    this.bindedTextures = {}
    this.tempFrameBuffer = this.gl.createFramebuffer()
  }

  init () {
    const { gl } = this
    gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1)
    // gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true)
    const positionBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer)
    const top = 1, left = -1, right = 1, bottom = -1
    const vertexArray = new Float32Array([ left, top, left, bottom, right, top, right, bottom ])
    gl.bufferData(gl.ARRAY_BUFFER, vertexArray, gl.STATIC_DRAW)
  }

  deleteTexture ({ texture }) {
    const { gl } = this
    gl.deleteTexture(texture)
  }

  resizeCanvas ({ width, height }) {
    width = parseInt(width)
    height = parseInt(height)
    if (this.canvas.width !== width) { this.canvas.width = width }
    if (this.canvas.height !== height) { this.canvas.height = height }
  }

  resizeViewport ({ width, height }) {
    width = parseInt(width)
    height = parseInt(height)
    const { gl } = this
    gl.viewport(0, 0, width, height)
  }

  clear () {
    const { gl } = this
    gl.clearColor(0.0, 0.0, 0.0, 0.0)
    gl.clear(gl.COLOR_BUFFER_BIT)
  }

  clearTexture ({ texture, width, height }) {
    const { gl } = this
    this.bindTexture({ textureNumber: 0, texture })
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0,
      gl.RGBA, gl.UNSIGNED_BYTE, null)
    this.unbindTextures()
  }

  showTexture ({ texture, width, height, desTexture }) {
    if (desTexture === undefined) { desTexture = null }
    this.applyFilter({
      filter: showTextureFilter,
      data: {
        src: { texture, width, height },
      },
      des: { texture: desTexture, width, height }
    })
  }

  readPixels ({ texture, x, y, width, height }) {
    const { gl } = this
    const frameBuffer = this.tempFrameBuffer
    gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer)
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0)
    const data = new Uint8Array(width * height * 4)
    gl.readPixels(x, y, width, height, gl.RGBA, gl.UNSIGNED_BYTE, data)
    return data
  }

  printToTexture ({ src, des }) {
    this.applyFilter({
      filter: showTextureFilter,
      data: { src },
      des
    })
  }

  printToCanvas ({ texture, width, height, canvas }) {
    const { gl } = this
    let data = new Uint8Array(width * height * 4)
    // use applyFilter instead of readPixels to handle texture resizing
    this.applyFilter({
      filter: showTextureFilter,
      data: {
        src: { texture, width, height },
      },
      des: { texture: 'temp', width, height },
      afterRender: () => {
        gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, data)
      }
    })
    const context = canvas.getContext('2d')
    const imageData = context.createImageData(width, height)
    imageData.data.set(data)
    context.putImageData(imageData, 0, 0)
  }

  unbindTextures () {
    const { gl } = this
    for (const textureKey of this.activeTextureKeys) {
      gl.activeTexture(gl[textureKey])
      gl.bindTexture(gl.TEXTURE_2D, null)
      this.bindedTextures[textureKey] = false
    }
    this.activeTextureKeys = []
  }

  bindTexture ({ textureNumber, texture }) {
    const { gl } = this
    const textureKey = 'TEXTURE' + textureNumber
    gl.activeTexture(gl[textureKey])
    gl.bindTexture(gl.TEXTURE_2D, texture)
    this.activeTextureKeys.push(textureKey)
    this.bindedTextures[textureKey] = true
  }

  createTexture ({ textureNumber, width, height }) {
    width = parseInt(width)
    height = parseInt(height)
    const { gl } = this
    if (textureNumber === undefined) { textureNumber = 0 }

    const texture = gl.createTexture()
    texture.id = generateId()
    this.bindTexture({ textureNumber, texture })
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)

    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0,
      gl.RGBA, gl.UNSIGNED_BYTE, null)

    this.unbindTextures()

    return texture
  }

  loadImageData ({ texture, width, height, imageData, minFilter, magFilter }) {
    if (minFilter === undefined) { minFilter = 'LINEAR' }
    if (magFilter === undefined) { magFilter = 'LINEAR' }
    width = parseInt(width)
    height = parseInt(height)
    const { gl } = this
    this.bindTexture({ textureNumber: 0, texture })
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl[minFilter])
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl[magFilter])
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, imageData)
    this.unbindTextures()
  }

  loadImage ({ texture, width, height, image, minFilter, magFilter }) {
    if (minFilter === undefined) { minFilter = 'LINEAR' }
    if (magFilter === undefined) { magFilter = 'LINEAR' }
    width = parseInt(width)
    height = parseInt(height)
    const { gl } = this
    this.bindTexture({ textureNumber: 0, texture })
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl[minFilter])
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl[magFilter])
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image)
    this.unbindTextures()
  }

  setTextureMagFilter ({ texture, filter }) {
    const { gl } = this
    this.bindTexture({ texture, textureNumber: 0 })
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl[filter])
    this.unbindTextures()
  }

  setTextureMinFilter ({ texture, filter }) {
    const { gl } = this
    this.bindTexture({ texture, textureNumber: 0 })
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl[filter])
    this.unbindTextures()
  }

  setTextureParameters ({ texture, minFilter, magFilter }) {
    const { gl } = this
    this.bindTexture({ texture, textureNumber: 0 })
    if (minFilter) { gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl[minFilter]) }
    if (magFilter) { gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl[magFilter]) }
    this.unbindTextures()
  }

  createProgram ({ vertexShader, fragmentShader }) {
    const { gl } = this
    const glVertexShader = gl.createShader(gl.VERTEX_SHADER)
    gl.shaderSource(glVertexShader, vertexShader)
    gl.compileShader(glVertexShader)
    if (!gl.getShaderParameter(glVertexShader, gl.COMPILE_STATUS)) {
      throw new Error(gl.getShaderInfoLog(glVertexShader))
    }

    const glFragmentShader = gl.createShader(gl.FRAGMENT_SHADER)
    gl.shaderSource(glFragmentShader, fragmentShader)
    gl.compileShader(glFragmentShader)
    if (!gl.getShaderParameter(glFragmentShader, gl.COMPILE_STATUS)) {
      throw new Error(gl.getShaderInfoLog(glFragmentShader))
    }

    const program = gl.createProgram()
    gl.attachShader(program, glVertexShader)
    gl.attachShader(program, glFragmentShader)
    gl.linkProgram(program)

    const vertexAttribLocation = gl.getAttribLocation(program, 'vertex')
    gl.enableVertexAttribArray(vertexAttribLocation)
    gl.vertexAttribPointer(vertexAttribLocation, 2, gl.FLOAT, false, 0, 0)

    return program
  }

  loadFilter ({ filter }) {
    const { id, vertexShader, fragmentShader } = filter
    if (this.programs[id] !== undefined) { return  }
    const program = this.createProgram({ vertexShader, fragmentShader })
    this.programs[id] = program
  }

  setProgramUniform ({  program, name, value  }) {
    const { gl } = this
    const location = gl.getUniformLocation(program, name)
    if (location === null) { return }
    if (typeof value === 'boolean') {
      const boolIntvalue = value === true ? 1 : 0
      gl.uniform1f(location, boolIntvalue)
    } else if (Array.isArray(value)) {
      if (Array.isArray(value[0])) {
        let flattenedValue = _.flatten(value)
        switch (value[0].length) {
          case 1: gl.uniform1fv(location, new Float32Array(flattenedValue)); break
          case 2: gl.uniform2fv(location, new Float32Array(flattenedValue)); break
          case 3: gl.uniform3fv(location, new Float32Array(flattenedValue)); break
          case 4: gl.uniform4fv(location, new Float32Array(flattenedValue)); break
        }
      } else {
        switch (value.length) {
          case 0: break
          case 1: gl.uniform1fv(location, new Float32Array(value)); break
          case 2: gl.uniform2fv(location, new Float32Array(value)); break
          case 3: gl.uniform3fv(location, new Float32Array(value)); break
          case 4: gl.uniform4fv(location, new Float32Array(value)); break
          case 9: gl.uniformMatrix3fv(location, false, new Float32Array(value)); break
          case 16: gl.uniformMatrix4fv(location, false, new Float32Array(value)); break
          default: throw new Error('Unloadable length: "' + name + '" has an loadable length of ' + value.length)
        }
      }
    } else if (!isNaN(value)) {
      gl.uniform1f(location, value)
    } else {
      throw new Error('Invalid uniform value: "' + name + '" has an invalid value of ' + (value || 'undefined').toString())
    }
  }

  setProgramUniforms ({ program, uniforms }) {
    for (const name in uniforms) {
      const value = uniforms[name]
      // if (value === undefined) { continue }
      this.setProgramUniform({ program, name, value })
    }
  }

  setProgramTexture ({ program, textureItem, textureName, textureNumber }) {
    const { gl } = this
    const textureKey = 'TEXTURE' + textureNumber
    this.bindTexture({ textureNumber, texture: textureItem.texture })
    const textureNameLoc = gl.getUniformLocation(program, textureName)
    gl.uniform1i(textureNameLoc, textureNumber)
    const width = parseInt(textureItem.width)
    const height = parseInt(textureItem.height)
    const uniforms = {
      [textureName + 'Width']: width,
      [textureName + 'Height']: height,
      [textureName + 'Size']: [width, height],
    }
    this.setProgramUniforms({ program, uniforms })
  }

  clearProgramTextures ({ program, textureData }) {
    const { gl } = this
    let textureNumber = 0
    for (const textureName in textureData) {
      const textureNameLoc = gl.getUniformLocation(program, textureName)
      gl.uniform1i(textureNameLoc, 0)
    }
  }

  setProgramTextures ({ program, textureData }) {
    let textureNumber = 0
    for (const textureName in textureData) {
      const textureItem = textureData[textureName]
      this.setProgramTexture({ program, textureItem, textureName, textureNumber })
      textureNumber++
    }
  }

  splitProgramData ({ program, data }) {
    const uniforms = {}
    const textureData = {}
    for (const key in data) {
      const item = data[key]
      if (item === undefined) { continue; }
      if (item.texture !== undefined) {
        textureData[key] = item
      } else {
        uniforms[key] = item
      }
    }
    return { uniforms, textureData }
  }

  render () {
    const { gl } = this
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4)
  }

  initTempRenderedTex () {
    if (this.tempRenderedTex === undefined) { this.tempRenderedTex = new Tex() }
  }

  applyFilter ({ filter, des, data, afterRender }) {
    const { gl } = this
    this.loadFilter({ filter })
    const program = this.programs[filter.id]
    gl.useProgram(program)

    let frameBuffer
    if (des === undefined || des.texture === undefined) { throw new Error('Invalid des') }

    data.desWidth = parseInt(des.width)
    data.desHeight = parseInt(des.height)
    data.desSize = [data.desWidth, data.desHeight]
    const { uniforms, textureData } = this.splitProgramData({ program, data })

    let createNewTexture = false
    if (des.texture === null) {
      gl.bindFramebuffer(gl.FRAMEBUFFER, null)
      this.resizeCanvas({ width: des.width, height: des.height })
      this.resizeViewport({ width: des.width, height: des.height })
    } else {
      if (des.texture === 'new') {
        createNewTexture = true
        des.texture = this.createTexture({ width: des.width, height: des.height })
      }
      if (des.texture === 'temp') {
        this.initTempRenderedTex()
        this.tempRenderedTex.resize({ width: des.width, height: des.height })
        des.texture = this.tempRenderedTex.texture
      }
      frameBuffer = this.tempFrameBuffer
      gl.bindFramebuffer(gl.FRAMEBUFFER, frameBuffer)
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, des.texture, 0)
      this.bindTexture({ textureNumber: Object.values(textureData).length + 1, texture: des.texture })
      this.resizeViewport({ width: des.width, height: des.height })
    }
    for (const textureName in textureData) {
      if (textureData[textureName].texture === des.texture) {
        throw new Error('Source texture for "' + textureName + '" cannot be the same as the destination texture')
      }
    }

    this.setProgramTextures({ program, textureData })
    this.setProgramUniforms({ program, uniforms })

    this.render()
    if (des.touch !== undefined) { des.touch() }
    if (afterRender !== undefined) { afterRender() }

    this.clearProgramTextures({ program, textureData })
    this.unbindTextures()

    if (createNewTexture) {
      return new Tex({ width: des.width, height: des.height, texture: des.texture })
    }
  }

  preloadCoreFilters () {
    const filterClasses = [
      FlattenFilter, RectFilter, OvalFilter,
      StrokeFilter, BlendFilter, ShowTextureFilter,
      HorizontalPresenceFilter, VerticalPresenceFilter, CornerPresenceFilter,
      LinearizeHorizontalFilter, VerticalBoundaryFilter,
      LinearizeVerticalFilter, HorizontalBoundaryFilter,
      MoveFilter, ClipFilter, DistortFilter, FillFilter,
      FlipFilter, DocViewFilter
    ]
    for (const filterClass of filterClasses) {
      this.loadFilter({ filter: new filterClass() })
    }
  }
}

export const wgl = new Wgl()
