import Version from './versions/version'
import JSZip from 'jszip'
import { processAsyncTasks } from './utils'
import { getGlobalFactory } from './factory-references'

export function exportDoc ({ doc }, callback) {
  const zip = new JSZip()
  const version = new Version({ name: 'Export' })
  version.processItem({ item: doc })
  version.storedData.docId = doc.id
  zip.file('data.json', JSON.stringify(version.storedData))
  zip.file('map.json', JSON.stringify(version.childIdMap))

  const resourceData = []
  for (const parentId in version.storedResources) {
    for (const childKey in version.storedResources[parentId]) {
      const resource = version.storedResources[parentId][childKey]
      resourceData.push({
        parentId,
        childKey,
        resourceInfo: resource.serialize({ forStorage: false })
      })
    }
  }
  zip.file('resources.json', JSON.stringify(resourceData))

  const resourceObjects = version.getResourceArray()
  processAsyncTasks({
    items: resourceObjects,
    task: ({ item, runNext }) => {
      item.exportToZip(zip, () => runNext())
    },
    onComplete: () => {
      zip.generateAsync({ type: 'blob' }).then((blob) => {
        callback({ blob })
      })
    }
  })
}

export function saveDoc ({ doc }) {
  exportDoc({ doc }, ({ blob }) => {
    saveAs(blob, doc.name + '.pxl')
  })
}

export function readDataFileFromZip ({ file }, callback) {
  JSZip.loadAsync(file).then((zip) => {
    zip.file('data.json').async('string').then((data) => {
      const storedData = JSON.parse(data)
      callback({ storedData })
    })
  })
}

export function readZip ({ file }, callback) {
  let storedData, childIdMap, resourceData
  JSZip.loadAsync(file).then((zip) => {
    zip.file('data.json').async('string').then((data) => {
      storedData = JSON.parse(data)
      zip.file('map.json').async('string').then((data) => {
        childIdMap = JSON.parse(data)
        zip.file('resources.json').async('string').then((data) => {
          resourceData = JSON.parse(data)
          callback({ storedData, childIdMap, resourceData, zip })
        })
      })
    })
  })
}

function restoreVersionResources ({ version, resourceData, zip }, callback) {
  processAsyncTasks({
    items: resourceData,
    task: ({ item, runNext }) => {
      const { parentId, childKey, resourceInfo } = item
      version.storedResources[parentId] = {}
      const factory = getGlobalFactory(resourceInfo.data.factoryKey)
      const resource = factory()
      version.storedResources[parentId][childKey] = resource
      resource.restore(resourceInfo)
      resource.importFromZip(zip, () => runNext())
    },
    onComplete: () => callback()
  })
}

export function openDoc ({ doc, file }, callback) {
  const initialDocId = doc.id
  const version = new Version()
  readZip({ file }, ({ storedData, childIdMap, resourceData, zip }) => {
    version.storedData = storedData
    version.childIdMap = childIdMap
    restoreVersionResources({ version, resourceData, zip }, () => {
      version.restoreItem({ item: doc, id: storedData.docId })
      doc.id = initialDocId
      callback()
    })
  })
}
