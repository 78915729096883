import { observable, action } from 'mobx'
import React from 'react'
import BasePackage from '../../core/base-package'
import ContextOptionsView from './context-options-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'context-options'
  }

  activate () {
    const contextOptionsView = React.createElement(ContextOptionsView)
    this.packageFor('layouts').addLayoutItem('top-1',
      { viewComponent: contextOptionsView,
        width: 'fill',
        row: 50,
        zIndexOverride: 900 })
    this.contextOptionsView = contextOptionsView
  }
}
