const osName = bowser.osname
// export const osName = 'Windows'
// export const metaName = osName === 'macOS' ? '\u2318' : 'Ctrl'
// export const shiftName = osName === 'macOS' ? '\u21E7' : 'Shift'
// export const altName = osName === 'macOS' ? '\u2325' : 'Alt'
// export const backspaceName = osName === 'macOS' ? '\u232b' : 'Backspace'
export const metaName = osName === 'macOS' ? 'Command' : 'Ctrl'
export const shiftName = osName === 'macOS' ? 'Shift' : 'Shift'
export const altName = osName === 'macOS' ? 'Alt' : 'Alt'
export const backspaceName = osName === 'macOS' ? 'Backspace' : 'Backspace'

export function parseShortcut (shortcut) {
  return shortcut.replace('Meta+', metaName + '+')
}

export function beautifyShortcut (shortcutLabel) {
  let parts = shortcutLabel.split('+')
  parts = parts.map((part) => {
    if (part === 'meta') { return metaName }
    if (part === 'shift') { return shiftName }
    if (part === 'alt') { return altName }
    if (part === 'backspace') { return backspaceName }
    if (part === '=') { return '+' }
    return _.capitalize(part)
  })
  const joiningString = osName === 'macOS' ? '+' : '+'
  return parts.join(joiningString)
}
