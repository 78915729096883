export function fileMenu () {
  const name = 'File'
  return [
    {
      path: [name, 'New'],
      action: 'doc-creator:show-doc-creator-dialog'
    },
    {
      path: [name, 'Open'],
      action: 'doc-opener:show-doc-opener-dialog'
    },
    {
      path: [name, 'Save'],
      action: 'saver:show-saver-dialog'
    },
    {
      path: [name, 'Quick Save'],
      action: 'docs:save-doc'
    },
    {
      path: [name, 'Quick Export'],
      action: 'docs:save-doc'
    },
    {
      path: [name, 'DIVIDER']
    },
    {
      path: [name, 'Next Document'],
      action: 'docs:show-next'
    },
    {
      path: [name, 'Previous Document'],
      action: 'docs:show-previous'
    },
    {
      path: [name, 'DIVIDER']
    },
    {
      path: [name, 'Close'],
      action: 'docs:close'
    },
    {
      path: [name, 'Close All'],
      action: 'docs:close-all'
    }
  ]
}
