import Strokable from '../strokable/strokable'
import BrushOptionsView from './brush-options-view'

export default class extends Strokable {
  constructor (options = {}) {
    super({ ...options, id: 'brush', name: 'Brush', settingsId: 'brush' })
    this.icon = 'wi-brush'
    this.info = 'Brush (B)'
  }

  getOptionsViewClass () { return BrushOptionsView }
  getOptionsViewClassName () { return 'brush-options-view' }
}
