import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import './strokable-options-view.scss'

const PERSISTABLE_STATE = {
  opacityPercentage: 100,
  softnessPercentage: 0,
  size: 30,
  stabilization: 3,
  speedSensitivity: 10
}

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.initSettings({ settingsId: props.settingsId, persistableState: PERSISTABLE_STATE })
    this.state = this.retrieveState(props.settingsId, PERSISTABLE_STATE)
    this.state.sliderSize = this.getSliderSize(this.state.size)
    props.setOpacityPercentage(this.state.opacityPercentage)
    props.setSoftnessPercentage(this.state.softnessPercentage)
    props.setSize(this.state.size)
    props.setStabilization(this.state.stabilization)
    props.setOption('speedSensitivity', this.state.speedSensitivity)
    props.takeUpdateViewSize(this.updateViewSize)
  }

  updateOpacityPercentage = (value) => {
    this.setState({ opacityPercentage: value })
    this.props.setOpacityPercentage(value)
  }

  updateSoftnessPercentage = (value) => {
    this.setState({ softnessPercentage: value })
    this.props.setSoftnessPercentage(value)
  }

  updateStabilization = (value) => {
    this.setState({ stabilization: value })
    this.props.setStabilization(value)
  }

  updateSpeedSensitivity = (value) => {
    this.setState({ speedSensitivity: value })
    this.props.setOption('speedSensitivity', value)
  }

  getSliderSize = (size) => {
    let sliderSize = 0
    if (size <= 0) {
      sliderSize = 0
    } else if (size <= 110) {
      sliderSize = size / 2.0
    } else if (size <= 1000) {
      sliderSize = (size - 110) / 900.0 * 20.0 + 55
    } else if (size <= 5000) {
      sliderSize = (size - 1000) / 4000.0 * 25.0 + 75
    } else {
      sliderSize = 100
    }
    return parseInt(sliderSize)
  }

  getSize = (sliderSize) => {
    let transformedSize = 0
    if (sliderSize <= 55) {
      // (0 - 55) => (0 - 110)
      transformedSize = sliderSize * 2.0
    } else if (sliderSize <= 75) {
      // (56 - 75) => (111 - 1000)
      transformedSize = 110 + ((sliderSize - (55)) / (75.0 - 55.0)) * 900
    } else {
      // (76 - 100) => (1001 - 5000)
      transformedSize = 1000 + ((sliderSize - 75) / (100.0 - 75.0)) * 4000
    }
    if (transformedSize < 1) { transformedSize = 1 }
    return parseInt(transformedSize)
  }

  updateViewSize = (size) => {
    this.setState({
      size,
      sliderSize: this.getSliderSize(size)
    })
  }

  updateSize = (size) => {
    this.setState({
      size,
      sliderSize: this.getSliderSize(size)
    })
    this.props.setSize(size)
  }

  updateSliderSize = (sliderSize) => {
    const size = this.getSize(sliderSize)
    this.setState({ sliderSize, size })
    this.props.setSize(size)
  }

  render () {
    const { props, state } = this
    const SlidableInput = this.packageFor('sliders').SlidableInput
    return (
      <div className={ `strokable-options-view align-items-center ${props.className}` }>
        <div className="tool-options-label mr-s">Size:</div>
        <SlidableInput sliderValue={ state.sliderSize }
          inputValue={ state.size }
          skipRounding={ true }
          className="mr-l strokable-options-view__slidable-input"
          align="left"
          onInputChange={ this.updateSize }
          onSliderChange={ this.updateSliderSize } />

        <div className="tool-options-label mr-s">Opacity:</div>
        <SlidableInput value={ state.opacityPercentage }
          className="mr-l"
          align="left"
          min={ 1 }
          onChange={ this.updateOpacityPercentage } />

        <div className="tool-options-label mr-s softness-label">Softness:</div>
        <SlidableInput value={ state.softnessPercentage }
          className="mr-l softness-slider"
          align="left"
          onChange={ this.updateSoftnessPercentage } />

        <div className="tool-options-label mr-s">Stabilization:</div>
        <SlidableInput value={ state.stabilization }
          className="mr-l"
          align="left"
          min={ 1 }
          max={ 20 }
          onChange={ this.updateStabilization } />
      </div>
    )
  }
}
