import el from '../../lib/el'

function processDimensions (dimensions, op) {
  const processed = {}
  for (const key in dimensions) {
    processed[key] = op(dimensions[key])
  }
  return processed
}

function ceilDimensions (dimensions) { return processDimensions(dimensions, Math.ceil) }

function roundDimensions (dimensions) { return processDimensions(dimensions, Math.round) }

function getRelativePosition (element, container) {
  const elementOffset = el(element).offset()
  const containerOffset = el(container).offset()

  return {
    x: elementOffset.left - containerOffset.left,
    y: elementOffset.top - containerOffset.top
  }
}

export function getRelativeViewportDimensions ({ viewport, viewportContainer, padding }) {
  const viewportPosition = getRelativePosition(viewport, viewportContainer)
  const viewportSize = roundDimensions(el(viewport).innerSize())
  padding = padding || 0
  return {
    x: viewportPosition.x - padding,
    y: viewportPosition.y - padding,
    width: viewportSize.width + padding * 2,
    height: viewportSize.height + padding * 2
  }
}

export function matchSrcToViewport ({ doc, srcBoard, desBoard, desBoardContainer,
  viewportContainer, viewport }) {
  const desBoardContainerSize = ceilDimensions(el(desBoardContainer).innerSize())
  desBoard.resize(desBoardContainerSize)
  const des = getRelativeViewportDimensions({ viewport, viewportContainer })

  desBoard.pasteBoard(srcBoard, {
    des,
    contextOptions: {
      imageSmoothingEnabled: doc.zoom <= 1,
      globalCompositeOperation: 'copy'
    }
  })
  return des
}
