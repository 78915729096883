import { inject, observer } from 'mobx-react'
import React from 'react'
import { ensureValidCss } from '../../core/style-helpers'
import DocVersionView from './doc-version-view'
import BaseComponent from '../../core/base-component'

import './doc-history-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  rootStyle (visible) {
    const style = { display: visible }
    return ensureValidCss(style)
  }
  render () {
    const { doc, visible } = this.props
    return (
      <div className="doc-history-view fill-absolute"
        ref="root"
        style={ { ...this.rootStyle(visible) } }>
        { doc.versions().map((version, { isActive }) =>
          <DocVersionView version={ version }
            key={ version.id }
            isActive={ isActive }
            doc={ doc } />
        )}
      </div>
    )
  }
}
