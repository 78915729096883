import Item from './item'
import EventList from './event-list'
import Point from './point'
import ev from '../lib/ev'

export default class extends Item {
  constructor (options) {
    super(options)
    this.events = new EventList()
    this.windowEvents = new EventList()
    this.moveCount = 0
    this.clickStartedElement = undefined
    this.resetDragOffset()
    this.setup()
  }

  resetDragOffset () {
    this.dragOffset = new Point({ x: 0, y: 0 })
  }

  initializableAttributes () {
    return ['mouseDownEvent', 'targetElement', 'onBegin', 'onContinue',
      'onEnd', 'onClick', 'onMouseDown', 'minMoveDistance', 'ignorableClasses',
      'absorbMouseEvents', 'shouldBeginDrag']
  }

  defaultAttributes () {
    return { minMoveDistance: 0 }
  }

  setup () {
    const { mouseDownEvent, targetElement, onInitialMouseDown } = this
    if (mouseDownEvent) {
      this.events.addEvent(mouseDownEvent, onInitialMouseDown)
    }
    if (targetElement) {
      this.events.addDomEvent(targetElement, 'mousedown', onInitialMouseDown)
    }
  }

  setupWindowEvents = () => {
    if (this.windowEvents.length > 0) { return }
    this.windowEvents.addDomEvent(window, 'mousemove', this.continueDrag)
    this.windowEvents.addDomEvent(window, 'mouseup', this.endDrag)
  }

  onInitialMouseDown = (...args) => {
    const e = args[0]
    this.onMouseDown(...args)
    if (this.absorbMouseEvents) { e.stopPropagation() }
    if (this.ignorableClasses !== undefined) {
      for (const ignorableClass of this.ignorableClasses) {
        if (e.target.classList.contains(ignorableClass)) {
          return
        }
      }
    }
    if (this.shouldBeginDrag && this.shouldBeginDrag(e) === false) { return }
    this.setupWindowEvents()
    this.clickStartedElement = e.target
    this.initialPoint = ev(e).point()
    this.resetDragOffset()
    if (this.minMoveDistance === 0) { this.beginDrag(...args) }
  }

  beginDrag = (...args) => {
    this.dragStarted = true
    this.onBegin(...args)
  }

  continueDrag = (e) => {
    const { initialPoint, minMoveDistance } = this
    const currPoint = ev(e).point()
    const distance = initialPoint.distanceTo(currPoint)
    this.dragOffset = currPoint.subtract(initialPoint)
    if (e.buttons !== 1) { return this.endDrag() }
    if (this.dragStarted) { return this.onContinue(e, this) }
    if (distance > minMoveDistance) { this.beginDrag(e) }
  }

  endDrag = (e) => {
    try {
      if (e) {
        if (this.dragStarted) { this.onEnd(e, this) }
        if (this.clickStartedElement === e.target && !this.dragStarted) {
          this.onClick(e)
        }
      }
    }
    finally {
      this.windowEvents.dispose()
      this.moveCount = 0
      this.dragStarted = false
      this.clickStartedElement = undefined
    }
  }

  dispose () {
    super.dispose()
    this.events.dispose()
    this.windowEvents.dispose()
  }

  // placeholder methods
  onBegin () {}
  onContinue () {}
  onEnd () {}
  onClick () {}
  onMouseDown () {}
}
