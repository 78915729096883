import './globals'
import { render } from 'react-dom'
import { Provider } from 'mobx-react'
import React from 'react'
import { configure } from 'mobx'

import App from './app'
import * as packages from './packages/package-list'
// import MockWorkspace from './mock-workspace'
// import Scratchpad from './scratchpad/scratchpad'
import { wgl } from './core/wgl'

import 'javascript-detect-element-resize/detect-element-resize.js'
import './styles/style.scss'

configure({ enforceActions: 'strict' })

class Program {
  loadWorkspace (app) {
    const rootElement = document.getElementById('workspace-container')
    const workspaceView = app.packageFor('workspace').defaultWorkspaceView
    render(
      <Provider app={app}>
        { workspaceView }
      </Provider>,
      rootElement
    )
  }

  loadPackages (app) {
    this.packageItems = []
    for (const key in packages) {
      const packageItem = new packages[key]({ app })
      app.registerPackage(packageItem)
      this.packageItems.push(packageItem)
    }
  }

  activatePackages () {
    for (const packageItem of this.packageItems) {
      packageItem.activate()
    }
  }

  addMockData (app) {
    // const mockWorkspace = new MockWorkspace()
    // mockWorkspace.mockSimpleLayers(app)
    // mockWorkspace.mockTextLayers(app)
    // mockWorkspace.mockLayerGroup(app)
  }

  processPendingCommand (app) {
    if (window.pendingCommand !== undefined) {
      app.executeCommand(window.pendingCommand)
      window.pendingCommand = undefined
    }
  }

  start () {
    const app = new App()
    this.loadPackages(app)
    this.activatePackages()
    this.loadWorkspace(app)
    // this.addMockData(app)
    this.processPendingCommand(app)
    window.app = app
    requestAnimationFrame(() => {
      wgl.initTempRenderedTex()
      wgl.preloadCoreFilters()
      // add a doc to warm the webgl canvas
      const docsPackage = app.packageFor('docs')
      const doc = docsPackage.addDoc({ width: 500, height: 500, name: 'Untitled 1' })
      docsPackage.docs.setActiveId(doc.id)
      setTimeout(() => {
        docsPackage.close(doc)
        docsPackage.setLoading(false)
        docsPackage.setPreloaded(true)
        requestAnimationFrame(() => {
          const loaderPage = document.getElementById('loader-page')
          loaderPage.style.opacity = 0
          setTimeout(() => {
            loaderPage.style.display = 'none'
          }, 300)
        })
      }, 300)
    })
  }

  startScratchpad () {
    // const rootElement = document.getElementById('workspace-container')
    // const scratchpad = new Scratchpad()
    // scratchpad.init(rootElement)
  }
}

window.program = new Program()
// program.startScratchpad()

window.onbeforeunload = function() {
  if (window.app.hasUnsavedChanges()) {
    return 'You have unsaved changes.'
  }
}
