import { action } from 'mobx'
import Item from './item'
import SimpleQuad, { CORNERS, QUAD_CORNERS, OPPOSITE_CORNERS } from './simple-quad'
import EventList from './event-list'
import Rect from './vectors/rect'
import { globals } from '../globals'

const CTRL_PT_SIZE = 6
const CTRL_PT_BUFFER = 10

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.events = new EventList()
  }

  getCursorType ({ doc, mousePositionInDoc }) {
    const transformedQuad = this.getTransformedQuad({ doc })
    if (transformedQuad === undefined) { return 'move' }

    const maxDistance = CTRL_PT_BUFFER / doc.zoom
    const nearestPoint = transformedQuad.nearestPoint(mousePositionInDoc, maxDistance)
    if (nearestPoint !== undefined) {
      return 'grab'
    }
    return 'move'
  }

  getTransformable ({ doc }) {
    if (doc === undefined) { return }
    const layer = doc.activeLayer
    if (layer === undefined) { return }
    const { transformable } = layer
    if (transformable.initialized === undefined) { return }
    return transformable
  }

  getTransformedQuad ({ doc }) {
    const transformable = this.getTransformable({ doc })
    if (transformable === undefined) { return }
    return transformable.transformedQuad
  }

  getControlPoints ({ doc }) {
    const transformedQuad = this.getTransformedQuad({ doc })
    if (transformedQuad === undefined) { return }

    const points = transformedQuad.points

    return points.map((p) => {
      return {
        center: [p.x, p.y],
        size: [CTRL_PT_SIZE, CTRL_PT_SIZE],
        sizeIsZoomDependent: false,
        thickness: 2
      }
    })
  }

  getControlLines ({ doc }) {
    const transformedQuad = this.getTransformedQuad({ doc })
    if (transformedQuad === undefined) { return }

    const { topLeft, topRight, bottomLeft, bottomRight } = transformedQuad
    const thickness = 1
    return [
      {
        start: [topLeft.x, topLeft.y],
        end: [topRight.x, topRight.y],
        thickness
      },
      {
        start: [topRight.x, topRight.y],
        end: [bottomRight.x, bottomRight.y],
        thickness
      },
      {
        start: [bottomRight.x, bottomRight.y],
        end: [bottomLeft.x, bottomLeft.y],
        thickness
      },
      {
        start: [bottomLeft.x, bottomLeft.y],
        end: [topLeft.x, topLeft.y],
        thickness
      }
    ]
  }

  getControlCrosshairs ({ doc }) {
    const transformedQuad = this.getTransformedQuad({ doc })
    if (transformedQuad === undefined) { return }
    return [{
      center: [transformedQuad.center.x, transformedQuad.center.y],
      size: 12,
      thickness: 2
    }]
  }

  shouldReInitialize ({ layer, mask }) {
    const { transformable } = layer
    if (transformable.initialized === false ||
        transformable.updatedAt < layer.getUpdatedAt() ||
        transformable.updatedAt < mask.updatedAt) {
      return true
    }
    return false
  }

  init ({ doc, p }) {
    if (doc === undefined) { return }

    const layer = doc.activeLayer
    const mask = doc.selectionTex

    const shouldInit = this.shouldReInitialize({ layer, mask })
    if (shouldInit === false) { return }

    const boundaryContainer = mask.hasData ? mask : layer
    const boundary = boundaryContainer.calcBoundary()
    layer.transformable.initialize({ layer, mask, boundary, initialPoint: p })
  }

  beginMovement ({ doc, p, mode }) {
    this.init({ doc, p })
    const transformable = this.getTransformable({ doc })
    transformable.beginMovement({ doc, p, mode, ctrlPtBuffer: CTRL_PT_BUFFER })
  }

  continueMovement ({ doc, e, mode, movementDimensions, p }) {
    const transformable = this.getTransformable({ doc })
    const layer = doc.activeLayer
    transformable.transform({ doc, e, layer, mode, movementDimensions, p })
    transformable.touch()
  }

  endMovement ({ doc }) {
    const transformable = this.getTransformable({ doc })
    transformable.endMovement({ doc })
  }
}
