import React from 'react'
import BasePackage from '../../core/base-package'
import CanvasResizerView from './canvas-resizer-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'canvas-resizer'
  }

  activate = () => {
    this.initCanvasResizerDialog()
  }

  resizeCanvas = (options) => {
    const { width, height, anchor } = options
    const doc = this.getActiveDoc()
    if (width !== doc.width || height !== doc.height) {
      doc.resize({ width, height }, { anchor })
      doc.recordVersion({ name: 'Canvas Size' })
      this.queueDocRender({ mode: 'layersUpdated' })
    }
  }

  initCanvasResizerDialog = () => {
    const sharedOptions = {
      onConfirm: (options) => {
        this.hideCanvasResizerDialog()
        this.resizeCanvas(options)
      },
      onCancel: this.hideCanvasResizerDialog
    }
    const canvasResizer = React.createElement(CanvasResizerView, {
      ...sharedOptions,
      getDoc: this.getActiveDoc
    })
    this.canvasResizerDialog = this.createDialog(canvasResizer, {
      name: 'Canvas Size',
      dialogSettingsId: 'canvas-resizer:canvas-resizer-dialog',
      onCancel: sharedOptions.onCancel
    })
  }

  showCanvasResizerDialog = () => { this.canvasResizerDialog.show() }

  hideCanvasResizerDialog = () => {
    this.canvasResizerDialog.hide()
  }
}
