import Filter from '../../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'vertical-presence'
  }

  getFragmentShader () {
    return `
      const float _batchSize = 50.0;
      uniform sampler2D _src;
      uniform vec2 _srcSize; // des.height * _batchSize == _src.height, des.width == _src.width

      bool currentRangeHasData () {
        float x = coordX();
        float y = coordY();
        float startY = floor(y) * _batchSize + 0.5;
        float endY = startY + _batchSize;
        for (float j = startY; j < endY; j += 1.0) {
          vec4 color = texColorAt(_src, _srcSize, vec2(x, j));
          if (color.a > 0.0) { return true; }
        }
        return false;
      }

      void main () {
        if (!currentRangeHasData()) {
          outputColor = EMPTY_COLOR;
          return;
        }
        outputColor = vec4(0.0, 0.0, 0.0, 1.0);
      }
    `
  }
}
