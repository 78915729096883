import { observer } from 'mobx-react'
import BaseComponent from './base-component'

@observer
export default class extends BaseComponent {
  render () {
    const { definition } = this.props
    return definition.getTemplate()
  }
}
