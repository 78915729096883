import { pool } from './gl-canvas-pool'
import { wgl } from './wgl'
import Tex from './tex'
import Fabric from './fabric'

import FillFilter from './filters/fill-filter'
import BlendFilter from './filters/blend-filter'

const fillFilter = new FillFilter()
const blendFilter = new BlendFilter()

function addNextLine(newY, isNext, downwards, minX, maxX, ranges, hasContent, paint, r) {
  var rMinX = minX;
  var inRange = false;
  for(var x=minX; x<=maxX; x++) {
    // skip testing, if testing previous line within previous range
    var empty = (isNext || (x<r[0] || x>r[1])) && hasContent(x, newY);
    if(!inRange && empty) {
      rMinX = x;
      inRange = true;
    }
    else if(inRange && !empty) {
      ranges.push([rMinX, x-1, newY, downwards, rMinX==minX, false]);
      inRange = false;
    }
    if(inRange) {
      paint(x, newY);
    }
    // skip
    if(!isNext && x==r[0]) {
      x = r[1];
    }
  }
  if(inRange) {
    ranges.push([rMinX, x-1, newY, downwards, rMinX==minX, true]);
  }
}

export function floodFillScanline(x, y, width, height, hasContent, paint) {
  // xMin, xMax, y, down[true] / up[false], extendLeft, extendRight
  var ranges = [[x, x, y, null, true, true]];
  paint(x, y);

  while(ranges.length) {
    var r = ranges.pop();
    var down = r[3] === true;
    var up =   r[3] === false;

    // extendLeft
    var minX = r[0];
    var y = r[2];
    if(r[4]) {
      while(minX>0 && hasContent(minX-1, y)) {
        minX--;
        paint(minX, y);
      }
    }
    var maxX = r[1];
    // extendRight
    if(r[5]) {
      while(maxX<width-1 && hasContent(maxX+1, y)) {
        maxX++;
        paint(maxX, y);
      }
    }

    // extend range ignored from previous line
    r[0]--;
    r[1]++;

    if(y<height) {
      addNextLine(y+1, !up, true, minX, maxX, ranges, hasContent, paint, r);
    }
    if(y>0) {
      addNextLine(y-1, !down, false, minX, maxX, ranges, hasContent, paint, r);
    }
  }
}

export function fillTex ({ refTex, baseTex, des, seedColor, mask, point, tolerance, fillStyle, contiguous, compositeMode }) {
  const { width, height } = refTex.size()
  fillFilter.fill({
    wgl,
    refTex,
    mask,
    useMask: mask !== undefined && mask.hasData,
    seedColor: seedColor.forShader(),
    fillStyle: fillStyle === undefined ? undefined : fillStyle.forShader(),
    tolerance,
    desTexture: 'temp'
  })

  if (contiguous === false) {
    blendFilter.blend({
      wgl,
      des,
      _src1: baseTex,
      _src2: wgl.tempRenderedTex,
      _compositeMode: compositeMode
    })
    return
  }

  const pixels = wgl.tempRenderedTex.readPixels()
  const imageData = new ImageData(width, height)

  const hasContent = (x, y) => {
    const alphaIndex = (y) * (width * 4) + (x) * 4 + 3
    return pixels[alphaIndex] > 0
  }

  const paint = (x, y) => {
    const index = y * (width * 4) + x * 4
    const alphaIndex = (y) * (width * 4) + (x) * 4 + 3
    const alpha = pixels[alphaIndex]
    pixels[alphaIndex] = 0
    if (fillStyle !== undefined) {
      imageData.data[index] = fillStyle.r
      imageData.data[index + 1] = fillStyle.g
      imageData.data[index + 2] = fillStyle.b
    }
    imageData.data[index + 3] = alpha
  }

  floodFillScanline(point.x, point.y, width, height, hasContent, paint)

  const filledTex = new Tex({ width, height })
  filledTex.loadImageData({ imageData, width, height })

  blendFilter.blend({
    wgl,
    des,
    _src1: baseTex,
    _src2: filledTex,
    _compositeMode: compositeMode
  })
}

export function fillBoard({ colorBoard, refBoard, point, tolerance, fillStyle, contiguous, mask }) {
  colorBoard.fabric.fill({ contextOptions: { fillStyle } })
  const width = refBoard.getWidth()
  const height = refBoard.getHeight()
  const initialColor = refBoard.colorAtPoint(point).fractionalColor()

  if (mask === undefined) {
    mask = new Fabric({ width, height })
  }

  const glCanvas = pool.requestGlCanvas()
  glCanvas.resize({ width, height })
  glCanvas.applyFilter(fillFilter, {
    initialColor: [initialColor.r, initialColor.g, initialColor.b, initialColor.a],
    tolerance,
    useMask: mask.hasData ? 1.0 : 0.0,
    srcTexture: refBoard.fabric.asTexture(),
    srcAlphaTexture: refBoard.alphaFabric.asTexture(),
    mask: mask.asTexture()
  })

  if (contiguous) {
    const { gl } = glCanvas
    const pixels = new Uint8Array(width * height * 4)
    gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, pixels)

    const imageData = new ImageData(width, height)

    const hasContent = (x, y) => {
      const alphaIndex = (height - y - 1) * (width * 4) + (x) * 4 + 3
      return pixels[alphaIndex] > 0
    }

    const paint = (x, y) => {
      const index = y * (width * 4) + x * 4
      const alphaIndex = (height - y - 1) * (width * 4) + (x) * 4 + 3
      const alpha = pixels[alphaIndex]
      pixels[alphaIndex] = 0
      imageData.data[index] = 255
      imageData.data[index + 3] = alpha
    }

    floodFillScanline(point.x, point.y, colorBoard.getWidth(), colorBoard.getHeight(), hasContent, paint)

    colorBoard.alphaFabric.getContext().putImageData(imageData, 0, 0)
  } else {
    colorBoard.alphaFabric.drawImage(glCanvas.canvas)
  }

  pool.releaseGlCanvas(glCanvas)
}
