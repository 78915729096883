import Item from './item'
import ev from '../lib/ev'
import Point from './point'

export default class extends Item {
  constructor (options) {
    super(options)
    this.name = 'Tool'
    this.app = options.app
    this.previewCanvases = {}
    this.validLayerTypes = undefined
  }

  packageFor = (id) => {
    return this.app.packageFor(id)
  }

  getTool = (toolId) => {
    return this.packageFor('tools').tools.find(toolId)
  }

  activate () {}

  beforeBeginMovement ({ doc, element, e }) {}
  beginMovement ({ doc, element, e }) {}
  afterBeginMovement ({ doc, element, e }) {}

  beforeContinueMovement ({ doc, element, e }) {}
  continueMovement ({ doc, element, e }) {}
  afterContinueMovement ({ doc, element, e }) {}

  beforeEndMovement ({ doc, element, e }) {}
  endMovement ({ doc, element, e }) {}
  afterEndMovement ({ doc, element, e }) {}

  skipMovement ({ doc }) {
    const { validLayerTypes, movementStarted } = this
    return (validLayerTypes !== undefined && !validLayerTypes[doc.activeLayer.type()]) || movementStarted !== true
  }

  getRelativeCoordinates (element, e) {
    return ev(e).relativeCoordinates(element)
  }

  getEventCoordinatesInDoc ({ doc, element, e }) {
    const p = this.getRelativeCoordinates(element, e)
    p.x *= (1.0 / doc.zoom)
    p.y *= (1.0 / doc.zoom)
    return new Point(p)
  }

  activeForegroundColor = () => {
    return this.packageFor('tool-colors').foregroundColor
  }

  getActiveDoc = () => {
    return this.packageFor('docs').fetchActiveDoc()
  }

  getCursorType () {}
  getCustomCursors () {}
  getAltCursorType () {}

  getControlPoints () {}
  getControlLines () {}
  getControlCrosshairs () {}

  onSelect () {}
  onDeselect () {}

  handleKey () {}

  queueDocRender = (options = {}) => {
    this.packageFor('docs').queueDocRender(options)
  }
}
