import React from 'react'
import BaseComponent from '../../core/base-component'

import './text-layer-view.scss'

export default class extends BaseComponent {
  render () {
    return (
      <div className="text-layer-view fill-absolute center-all" ref="root">
        <div className="text-layer-view__text-icon wi-text">
        </div>
      </div>
    )
  }
}
