import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import DocViewport from './doc-viewport'
import DocLayerViewList from './doc-layer-view-list'
import { ensureValidCss } from '../../core/style-helpers'
import Event from '../../core/event'
import EventList from '../../core/event-list'
import el from '../../lib/el'
import { getRelativeViewportDimensions } from './doc-layer-view-helpers'

import './doc-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.state = { root: undefined }
    this.onViewportRectUpdate = new Event()
    this.transformationViewWasUpdated = new Event()
    this.viewMounted = new Event()
    this.events = new EventList()
  }

  docViewStyle (visible) {
    const style = { display: visible }
    return ensureValidCss(style)
  }

  viewportRectUpdated = () => {
    if (this.props.visible === false) { return }
    const { doc } = this.props
    const meta = doc.getMeta('doc-view')
    meta.scrollbars = el(this.viewportRoot).hasScrollbars()
    const viewportRectContainer = this.viewportRoot
    const viewportRect = this.viewportRect
    meta.viewport = getRelativeViewportDimensions({
      viewportContainer: viewportRectContainer,
      viewport: viewportRect
    })
    meta.viewportContainer = viewportRectContainer
    meta.viewportRectElement = this.viewportRect
    const docsPackage = this.packageFor('docs')
    docsPackage.queueDocRender({ mode: 'viewportUpdated' })
    this.onViewportRectUpdate.trigger()
  }

  componentDidMount = () => {
    this.setState({ root: this.refs.root, viewportRect: this.viewportRect })
    const { doc, docViewMounted } = this.props
    if (docViewMounted) { docViewMounted.trigger(doc.id) }
    this.viewMounted.trigger(this.refs.root)
    this.events.addDomEvent(this.refs.root, 'wheel', (e) => {
      if (doc.isScrollLocked !== true) {
        this.viewportRoot.scrollTop += e.deltaY
        this.viewportRoot.scrollLeft += e.deltaX
      }
      e.preventDefault()
    }, { passive: false })
  }

  viewportRootRefCallback = (viewportRoot) => {
    this.viewportRoot = viewportRoot
  }

  render () {
    const { doc, visible, onMouseDown } = this.props
    const sharedProps = {
      doc,
      viewportRect: this.viewportRect,
      viewportRectContainer: this.state.root,
      onViewportRectUpdate: this.onViewportRectUpdate
    }

    return (
      <div className="doc-view fill-absolute"
        onMouseDown={ (e) => onMouseDown(this.viewportRect, e) }
        style={ { ...this.docViewStyle(visible) } }
        ref="root">
        <DocLayerViewList layers={ doc.getLayers() }
          { ...sharedProps } />
        <DocViewport doc={ doc }
          rectRef={ (rect) => { this.viewportRect = rect } }
          scrollContainerMounted={ this.viewMounted }
          visible={ visible }
          rootRef={ this.viewportRootRefCallback }
          onViewportRectUpdate={ this.viewportRectUpdated }/>
      </div>
    )
  }
}
