import { observer, inject } from 'mobx-react'
import React from 'react'
import classNames from 'classnames'
import BaseComponent from '../../core/base-component'
import EventList from '../../core/event-list'
import { getFittingDimensions } from '../../core/calc'
import Dom from '../../core/dom'
import LayerableView from './layerable-view'
import { nestedPaddingStyle } from './layerable-helpers'

import './layer-info.scss'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.state = {
      editNameEnabled: false
    }
  }

  layerViewContainerStyle = (layer) => {
    const maxWidth = Dom.remToPx(2.6)
    const maxHeight = maxWidth
    const { width, height } = getFittingDimensions({
      width: layer.getWidth(),
      height: layer.getHeight()
    }, { width: maxWidth, height: maxHeight })
    const style = { width: width + 'px', height: height + 'px' }
    return style
  }

  componentDidMount () {
    this.events = new EventList()
    this.events.addDomEvent(this.refs.visibilityToggle, 'mousedown', (e) => {
      e.stopPropagation()
      const { layerable } = this.props
      layerable.setVisibility(!layerable.visible)
      this.queueDocRender({ mode: 'layersUpdated' })
    })
  }

  toggleEditNameMode () {
    this.setState({
      editNameEnabled: !this.state.editNameEnabled
    })
    this.refs.nameInput.select()
  }

  editName (e) {
    const { value } = e.currentTarget
    this.props.layerable.setName(value)
  }

  componentWillUnmount () {
    this.events.dispose()
  }

  onNameInputBlurred () {
    this.setState({ editNameEnabled: false })
  }

  rootStyle = () => {
    return nestedPaddingStyle(this.props.level)
  }

  render () {
    const { doc, layerables, layerable, draggableTree } = this.props
    const LayerView = this.packageFor('layers').LayerView
    return (
      <LayerableView layerable={ layerable } layerables={ layerables }
        draggableTree={ draggableTree } doc={ doc }>
        <div className={ classNames('layer-info activeable selectable',
          { active: layerable.isActive }, { selected: layerable.isSelected }) }
          style={ { ...this.rootStyle() } }>
          <div className="layer-visibility-toggle" ref="visibilityToggle">
            { layerable.visible &&
              <div className="fa fa-eye layer-visibility-toggle-icon"></div>
            }
          </div>
          <div className="layer-view-container" style={ { ...this.layerViewContainerStyle(layerable) } }>
            <LayerView layer={ layerable } doc={ doc } />
          </div>
          <div className="layer-name" onDoubleClick={ () => this.toggleEditNameMode() }>
            <input type="text"
                   ref="nameInput"
                   className={ classNames("layer-name-input blur-on-submit", { 'disabled' : !this.state.editNameEnabled }) }
                   onBlur={ () => this.onNameInputBlurred() }
                   value={ layerable.name }
                   onChange={ (e) => this.editName(e) } />
          </div>
        </div>
      </LayerableView>
    )
  }
}
