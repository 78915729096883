import { observer } from 'mobx-react'
import BaseComponent from './base-component'

@observer
export default class extends BaseComponent {
  render () {
    const { vector, forHitTest } = this.props
    const options = {}
    if (forHitTest) {
      options.propOverrides = vector.getHitTestPropOverrides()
    }
    return vector.getTemplate(options)
  }
}
