import React from 'react'
import BasePackage from '../../core/base-package'
import WorkspaceView from './workspace-view'
import Event from '../../core/event'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'workspace'
    this.onFileDrop = new Event()
    this.onFileDragEnter = new Event()
    this.onFileDragLeave = new Event()
    this.defaultWorkspaceView = this.newWorkspaceView()
  }

  newWorkspaceView = () => {
    const view = React.createElement(WorkspaceView, {
      onFileDrop: (...args) => { this.onFileDrop.trigger(...args) },
      onFileDragEnter: (...args) => { this.onFileDragEnter.trigger(...args) },
      onFileDragLeave: (...args) => { this.onFileDragLeave.trigger(...args) }
    })

    return view
  }
}
