import { observable, action } from 'mobx'
import React from 'react'
import BasePackage from '../../core/base-package'
import ToolOptionsView from './tool-options-view'

export default class extends BasePackage {
  @observable toolOptionsWrapper = { toolOptions: null }

  constructor (options) {
    super(options)
    this.id = 'tool-options'
    const { toolOptionsWrapper } = this
    const toolOptionsView = React.createElement(ToolOptionsView, { toolOptionsWrapper })
    this.toolOptionsView = toolOptionsView
  }

  @action setToolOptions (view) {
    this.toolOptionsWrapper.toolOptions = view
  }
}
