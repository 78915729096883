import Filter from '../../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'linearize-horizontal'
  }

  // this method creates a single vertical strip of values
  // each value on the strip represents whether that horizontal row has data
  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform vec2 _srcSize;

      bool currentRangeHasData () {
        float y = coordY();
        for (float x = 0.0; x < _srcSize.x; x += 1.0) {
          vec4 color = texColorAt(_src, _srcSize, vec2(x + 0.5, y));
          if (color.a > 0.0) { return true; }
        }

        return false;
      }

      void main () {
        float x = coordX();

        if (x != 0.5) { return; }

        if (!currentRangeHasData()) {
          clearOutputColor();
          return;
        }

        outputColor = BLACK;
      }
    `
  }
}
