import React from 'react'
import BaseComponent from '../../core/base-component'
import ColorPickerInfo from './color-picker-info'
import ShadePicker from './shade-picker'
import HuePicker from './hue-picker'
import Color from '../../core/color'
import EventList from '../../core/event-list'
import el from '../../lib/el'

import './color-picker-view.scss'

export default class extends BaseComponent {
  componentWillMount () {
    const { newColor } = this.props
    this.events = new EventList()
    this.hueColor = new Color()
    this.trackingColor = new Color()
    this.hueColor.setHsv({ h: newColor.h, s: 100, v: 100 })
    this.trackingColor.setHsv(newColor.hsv)
    this.initEvents()
  }

  initEvents () {
    const { newColor } = this.props
    this.events.addObserver(newColor, 'h', () => {
      this.hueColor.setHsv({ h: newColor.h, s: 100, v: 100 })
    })
    this.events.addObserver(newColor, 'hsv', () => {
      this.trackingColor.setHsv(newColor.hsv)
    })
  }

  componentDidMount () {
    const { shadePickerContainer } = this.refs
    const height = el(shadePickerContainer).innerHeight()
    shadePickerContainer.style.width = height + 'px'
  }

  updateNewColor = () => {
    const { viewOptions, currentColor, newColor } = this.props
    if (viewOptions.dynamicUpdate === true) {
      this.props.updateColorRef(this.trackingColor.hsv)
    }
    newColor.setHsv(this.trackingColor.hsv)
  }

  onHueUpdated = (hue) => {
    this.trackingColor.setHsv({ h: hue })
    this.updateNewColor()
  }

  onShadeUpdated = (saturation, value) => {
    this.trackingColor.setHsv({ s: saturation, v: value })
    this.updateNewColor()
  }

  componentWillUnmount () {
    this.events.dispose()
  }

  render () {
    const { currentColor, newColor, onCancel, onConfirm } = this.props
    return (
      <div className="color-picker-view">
        <div className="color-picker-view__shade-picker-container border-1"
          ref="shadePickerContainer">
          <ShadePicker hueColor={ this.hueColor }
            newColor={ newColor }
            onShadeUpdated={ this.onShadeUpdated } />
        </div>
        <div className="color-picker-view__hue-picker-container border-1">
          <HuePicker newColor={ newColor }
            onHueUpdated={ this.onHueUpdated } />
        </div>
        <ColorPickerInfo onCancel={ onCancel }
          onConfirm={ onConfirm }
          newColor={ newColor }
          currentColor={ currentColor } />
      </div>
    )
  }
}
