import $ from 'jquery'

let scrollBarSize
let mainMouseButtonActive = false

$(document).mousedown((e) => {
  if (e.button === 0) { mainMouseButtonActive = true }
})

$(document).mouseup((e) => {
  if (e.button === 0) { mainMouseButtonActive = false }
})

export default class {
  static getDocument () {
    return window.document
  }

  static createAndAppend (elementType, options = {}) {
    const element = this.getDocument().createElement(elementType)

    for (const key in options) {
      if (key === 'parent') { continue }
      element[key] = options[key]
    }
    if (options.parent) {
      options.parent.appendChild(element)
    }

    return element
  }

  static canvas (options) {
    return this.createAndAppend('canvas', options)
  }

  static image (options) {
    return this.createAndAppend('img', options)
  }

  static div (options) {
    return this.createAndAppend('div', options)
  }

  static getScrollBarSize () {
    if (scrollBarSize) { return scrollBarSize }
    var div = document.createElement('div')
    div.style.overflow = 'scroll'
    div.style.visibility = 'hidden'
    div.style.position = 'absolute'
    div.style.width = '100px'
    div.style.height = '100px'
    document.body.appendChild(div)
    scrollBarSize = {
      width: Math.max(div.offsetWidth - div.clientWidth, 7),
      height: Math.max(div.offsetHeight - div.clientHeight, 7)
    }
    document.body.removeChild(div)

    return scrollBarSize
  }

  static remToPx (remValue) {
    const fontSize = parseInt(this.getDocumentFontSize())
    return remValue * fontSize
  }

  static getDocumentFontSize () {
    return $('html').css('font-size')
  }

  static onMouseMove (callback) {
    $(document).mousemove(callback)
  }

  static onMouseDown (callback) {
    $(document).mousedown(callback)
  }

  static getElementById (elementId) {
    return this.getDocument().getElementById(elementId)
  }

  static devicePixelRatio () {
    return Math.round(window.devicePixelRatio)
  }

  static getMainMouseButtonActive () {
    return mainMouseButtonActive
  }

  static elementAtPoint (p) {
    return document.elementFromPoint(p.x, p.y)
  }

  static findById (id) {
    return document.getElementById(id)
  }
}
