import Filter from '../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'flip'
  }

  getFragmentShader () {
    return `
      const float HORIZONTAL_FLIP = 1.0;
      const float VERTICAL_FLIP = 2.0;
      uniform sampler2D _src;
      uniform vec2 _srcSize;
      uniform float _direction;

      void main () {
        vec2 position = currPosition();
        if (_direction == HORIZONTAL_FLIP) {
          position.x = _srcSize.x - position.x;
        }
        if (_direction == VERTICAL_FLIP) {
          position.y = _srcSize.y - position.y;
        }
        outputColor = texColorAt(_src, _srcSize, position);
      }
    `
  }
}
