import { action, observable } from 'mobx'
import { generateId } from './id'
import hsh from '../lib/hsh'
import Event from './event'

export default class Item {
  @observable collectionIndex = undefined
  @observable isActive = false
  @observable isSelected = false

  constructor (options = {}) {
    if (options.id) {
      this.id = options.id
    } else {
      this.id = generateId()
    }
    this.meta = {}

    this.assignInitializableAttributes(options)
    this.createdAt = Date.now()
    this.updatedAt = Date.now()
    this.initEvents()
  }

  getMeta (key) {
    if (this.meta[key] === undefined) {
      this.meta[key] = {}
    }
    return this.meta[key]
  }

  @action set (key, value) {
    this[key] = value
  }

  @action setCollectionIndex (index) {
    this.collectionIndex = index
  }

  @action initEvents = () => {
    this.becameActive = new Event()
    this.becameActive.subscribe(() => this.setIsActive(true))

    this.becameInactive = new Event()
    this.becameInactive.subscribe(() => this.setIsActive(false))

    this.wasSelected = new Event()
    this.wasSelected.subscribe(() => this.setIsSelected(true))

    this.wasUnselected = new Event()
    this.wasUnselected.subscribe(() => {
      this.setIsSelected(false)
    })

    this.wasUpdated = new Event()
  }

  @action setIsActive = (isActive) => {
    this.isActive = isActive
  }

  @action setIsSelected = (isSelected) => {
    this.isSelected = isSelected
  }

  touch () {
    this.updatedAt = Date.now()
    this.wasUpdated.trigger()
  }

  shortId () { return this.id.substr(0, 8) }

  defaultAttributes () { return {} }

  initializableAttributes () { return [] }

  @action updateAttribute (key, value) {
    this[key] = value
  }

  @action assignInitializableAttributes (options = {}) {
    const keys = this.initializableAttributes()
    const defaults = this.defaultAttributes()
    for (const key of keys) {
      if (options[key] === undefined) {
        options[key] = defaults[key]
      }
    }
    hsh(this).assignValues(options, keys)
  }

  getFactoryKey () {}

  serialize () {
    return {
      data: {
        id: this.id,
        factoryKey: this.getFactoryKey(),
        createdAt: this.createdAt,
        updatedAt: this.updatedAt
      },
      resources: undefined,
      childItems: undefined
    }
  }

  restore ({ data, resources, serializedChildItems, version }) {
    this.id = data.id
    this.createdAt = data.createdAt
    this.updatedAt = data.updatedAt
  }

  afterRestore () {}

  restoreChildItems ({ version, idMap }) {
    for (const childKey in idMap) {
      const childId = idMap[childKey]
      const childInfo = version.retrieve({ id: childId })
      this[childKey].restore(childInfo)
    }
  }

  dispose () {}

  getReusablePrevState (options) {
    const { forceStore, updatedItemIds, prevVersion } = options
    if (forceStore || updatedItemIds.includes(this.id)) {
      return
    }
    const prevState = prevVersion !== undefined
      ? prevVersion.retrieveState(this.id) : undefined
    return prevState
  }
}
