import Dom from './dom'
import Item from './item'
import Color from './color'
import { wgl } from './wgl'
import { texHasData } from './tex-helpers/tex-presence-helpers'
import { getTexBoundary } from './tex-helpers/tex-boundary-helpers'
import { registerGlobalFactory } from './factory-references'
import RectFilter from './filters/rect-filter'
import MoveFilter from './filters/move-filter'

const rectFilter = new RectFilter()
const moveFilter = new MoveFilter()

let count = 0

export default class Tex extends Item {
  constructor (options = {}) {
    super(options)
    if (options.width === undefined) { options.width = 1 }
    if (options.height === undefined) { options.height = 1 }

    this.texture = options.texture
    this.initTexture(options)
    this.width = options.width
    this.height = options.height
    this.hasData = false
    this.minFilter = 'LINEAR'
    this.magFilter = 'LINEAR'
  }

  getFactoryKey () { return 'Tex' }

  setHasData (value) {
    this.hasData = value
  }

  calcHasData () {
    this.hasData = texHasData(this)
  }

  calcBoundary () {
    const boundary = getTexBoundary(this)
    this.boundary = boundary
    const { min, max } = boundary
    if (min.x === 0 && max.x === 0 && min.y === 0 && max.y === 0) {
      this.hasData = false
    } else {
      this.hasData = true
    }
    return boundary
  }

  fill (options = {}) {
    this.fillRect(options)
  }

  fillRect (options = {}) {
    if (options.start === undefined) { options.start = {} }
    if (options.start.x === undefined) { options.start.x = 0 }
    if (options.start.y === undefined) { options.start.y = 0 }
    if (options.end === undefined) { options.end = {} }
    if (options.end.x === undefined) { options.end.x = this.width }
    if (options.end.y === undefined) { options.end.y = this.height }

    rectFilter.fillRect({
      wgl,
      _hasSrc: false,
      _start: options.start,
      _end: options.end,
      _fillStyle: options.fillStyle,
      des: this,
      _alpha: options.alpha,
      _compositeMode: options.compositeMode
    })
  }

  printToTex (tex) {
    wgl.printToTexture({
      src: this,
      des: tex
    })
  }

  deepClone () {
    const { width, height } = this
    const clone = new Tex({ width, height })
    clone.hasData = this.hasData
    clone.boundary = this.boundary
    clone.updatedAt = this.updatedAt
    this.printToTex(clone)
    return clone
  }

  getTexture () { return this.texture }
  getWidth () { return this.width }
  getHeight () { return this.height }

  initTexture ({ width, height }) {
    if (this.texture !== undefined) { return }
    if (width === undefined) { width === 1 }
    if (height === undefined) { height === 1 }
    this.texture = wgl.createTexture({ width, height })
    count++
  }

  loadImage ({ image, width, height }) {
    this.resize({ width, height })
    wgl.loadImage({ texture: this.texture, width, height, image })
    this.touch()
  }

  loadImageData ({ imageData, width, height }) {
    this.resize({ width, height })
    wgl.loadImageData({ texture: this.texture, width, height, imageData })
    this.touch()
  }

  colorAtPoint (p) {
    const data = wgl.readPixels({ texture: this.texture, x: p.x, y: p.y, width: 1, height: 1 })
    return new Color({ r: data[0], g: data[1], b: data[2], a: data[3] / 255.0 })
  }

  readPixels (options = {}) {
    let { x, y, width, height } = options
    if (x === undefined) { x = 0 }
    if (y === undefined) { y = 0 }
    if (width === undefined) { width = this.width }
    if (height === undefined) { height = this.height }
    const data = wgl.readPixels({ texture: this.texture, x, y, width, height })
    return data
  }

  swapTexture (tex) {
    const tempTexture = tex.texture
    const tempWidth = tex.width
    const tempHeight = tex.height
    tex.texture = this.texture
    tex.width = this.width
    tex.height = this.height
    tex.touch()

    this.texture = tempTexture
    this.width = tempWidth
    this.height = tempHeight
    this.touch()
  }

  size () {
    return { width: this.width, height: this.height }
  }

  resize ({ width, height }, options = {}) {
    const prevTexture = this.texture
    const prevWidth = this.width
    const prevHeight = this.height
    width = parseInt(width)
    height = parseInt(height)

    if (this.width === width && this.height === height) { return }

    this.texture = wgl.createTexture({ width, height })
    this.width = width
    this.height = height

    if (options.resizeContent) {
      wgl.printToTexture({
        des: this,
        src: {
          texture: prevTexture,
          width: prevWidth,
          height: prevHeight
        }
      })
    }

    if (options.crop) {
      moveFilter.move({
        wgl,
        des: this,
        _src: {
          texture: prevTexture,
          width: prevWidth,
          height: prevHeight
        },
        _offset: [-options.crop.x, -options.crop.y]
      })
    }

    if (options.anchor) {
      const { anchor } = options
      const offset = [
        (this.width - prevWidth) / 2.0 * anchor.x,
        (this.height - prevHeight) / 2.0 * anchor.y
      ]
      moveFilter.move({
        wgl,
        des: this,
        _src: {
          texture: prevTexture,
          width: prevWidth,
          height: prevHeight
        },
        _offset: offset
      })
    }

    wgl.deleteTexture({ texture: prevTexture })
    this.touch()
  }

  printToCanvas ({ width, height, canvas }) {
    wgl.printToCanvas({ texture: this.texture, width, height, canvas })
  }

  toBlob (options = {}, callback) {
    if (options.type === undefined) { options.type = 'png' }
    if (options.quality === undefined) { options.quality = 1 }
    const { width, height } = this
    const canvas = Dom.canvas()
    canvas.width = this.width
    canvas.height = this.height
    this.printToCanvas({ width, height, canvas })
    canvas.toBlob((blob) => {
      const url = URL.createObjectURL(blob)
      canvas.width = 0
      canvas.height = 0
      callback({ url, blob })
    }, 'image/' + options.type, options.quality)
  }

  forShader () {
    return {
      width: this.width,
      height: this.height,
      texture: this.texture
    }
  }

  setMagFilter (filter) {
    wgl.setTextureMagFilter({ texture: this.texture, filter })
    this.magFilter = filter
  }

  setMinFilter (filter) {
    wgl.setTextureMinFilter({ texture: this.texture, filter })
    this.minFilter = filter
  }

  setTextureParameters ({ minFilter, magFilter }) {
    wgl.setTextureParameters({ texture: this.texture, minFilter, magFilter })
  }

  saveContext () {
    this.savedContext = {
      minFilter: this.minFilter,
      magFilter: this.magFilter
    }
  }

  restoreContext () {
    const { savedContext } = this
    this.setMinFilter(savedContext.minFilter)
    this.setMagFilter(savedContext.magFilter)
  }

  clear () {
    wgl.clearTexture({ texture: this.texture, width: this.width, height: this.height })
    this.hasData = false
    this.touch()
  }

  dispose () {
    count--
    wgl.deleteTexture({ texture: this.texture })
    this.texture = undefined
  }

  getTexForVersion ({ prevVersion }) {
    if (prevVersion !== undefined) {
      const prevInfo = prevVersion.retrieve({ id: this.id })
      const prevData = prevInfo.data
      if (prevData.updatedAt === this.updatedAt) {
        return prevInfo.resources.tex
      }
    }
    return this.deepClone()
  }

  exportToZip (zip, callback) {
    const { width, height } = this
    const canvas = Dom.canvas()
    canvas.width = this.width
    canvas.height = this.height
    this.printToCanvas({ width, height, canvas })
    canvas.toBlob((blob) => {
      zip.file(this.id + '.png', blob)
      canvas.width = 0
      canvas.height = 0
      callback()
    })
  }

  importFromZip (zip, callback) {
    zip.file(this.id + '.png').async('base64').then((data) => {
      const imageSrc = "data:image/png;base64," + data
      const image = new Image()
      image.onload = () => {
        this.loadImage({ image, width: this.width, height: this.height })
        image.src = ''
        callback()
      }
      image.src = imageSrc
    })
  }

  serialize ({ prevVersion, forStorage }) {
    const info = super.serialize()
    return {
      data: {
        ...info.data,
        width: this.width,
        height: this.height,
        hasData: this.hasData,
        boundary: this.boundary
      },
      resources: {
        tex: forStorage === true ? this.getTexForVersion({ prevVersion }) : undefined
      }
    }
  }

  restore ({ data, resources }) {
    const shouldRestoreTex = this.updatedAt !== data.updatedAt
    super.restore({ data })

    const { width, height, hasData, boundary } = data
    this.resize({ width, height })
    this.hasData = hasData
    this.boundary = boundary

    if (shouldRestoreTex && resources !== undefined && resources.tex !== undefined) {
      resources.tex.printToTex(this)
      this.wasUpdated.trigger()
    }
  }
}

registerGlobalFactory('Tex', Tex)
