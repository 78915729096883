import React from 'react'
import BaseComponent from '../../core/base-component'
import RasterLayerView from './raster-layer-view'
import SvgLayerView from './svg-layer-view'
import TextLayerView from './text-layer-view'

export default class extends BaseComponent {
  getLayerComponentClass (layer) {
    if (layer.isRasterType()) { return RasterLayerView }
    if (layer.isSvgType()) { return SvgLayerView }
    if (layer.isTextType()) { return TextLayerView }
    throw new Error('Invalid layer type')
  }
  render () {
    const { layer } = this.props
    const Component = this.getLayerComponentClass(layer)
    return (
      <div className="layer-view fill-absolute">
        <Component { ...this.props } />
      </div>
    )
  }
}
