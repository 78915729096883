export class Nbr {
  constructor (number) {
    this.number = number
  }

  withinRange (min, max) {
    const value = this.number
    if (value < min) { return min }
    if (value > max) { return max }
    return value
  }
}

export default function nbr (number) {
  return (number instanceof Nbr) ? number : new Nbr(number)
}
