import Point from './point'

export function getCorners (point1, point2) {
  const topLeft = { x: point1.x, y: point1.y }
  const bottomRight = { x: point2.x, y: point2.y }
  if (topLeft.x > bottomRight.x) {
    const x = topLeft.x
    topLeft.x = bottomRight.x
    bottomRight.x = x
  }
  if (topLeft.y > bottomRight.y) {
    const y = topLeft.y
    topLeft.y = bottomRight.y
    bottomRight.y = y
  }
  return { topLeft: new Point(topLeft), bottomRight: new Point(bottomRight) }
}
