import BlurFilter from './blur-filter'
import { wgl } from '../../core/wgl'

const blurFilter = new BlurFilter()

export function applyBlurFilter ({ startTex, tempTex, blurAmount }) {
  const iterations = Math.min(blurAmount, 20)
  for (let i = 0; i < iterations; i++) {
    const sTex = i === 0 ? startTex : tempTex
    const radius = (blurAmount - i) * 0.1
    applyBlurFilterIteration({ startTex: sTex, tempTex, radius })
  }
}

function applyBlurFilterIteration ({ startTex, tempTex, radius }) {
  startTex.saveContext()
  tempTex.saveContext()
  wgl.tempRenderedTex.saveContext()
  startTex.setTextureParameters({ minFilter: 'NEAREST', magFilter: 'NEAREST' })
  tempTex.setTextureParameters({ minFilter: 'NEAREST', magFilter: 'NEAREST' })
  wgl.tempRenderedTex.setTextureParameters({ minFilter: 'NEAREST', magFilter: 'NEAREST' })
  wgl.applyFilter({
    filter: blurFilter,
    data: {
      direction: [radius, 0],
      src: startTex
    },
    des: {
      texture: 'temp',
      width: startTex.width,
      height: startTex.height
    }
  })
  tempTex.swapTexture(wgl.tempRenderedTex)
  wgl.applyFilter({
    filter: blurFilter,
    data: {
      direction: [0, radius],
      src: tempTex
    },
    des: {
      texture: 'temp',
      width: tempTex.width,
      height: tempTex.height
    }
  })
  tempTex.swapTexture(wgl.tempRenderedTex)
  startTex.restoreContext()
  tempTex.restoreContext()
  wgl.tempRenderedTex.restoreContext()
}
