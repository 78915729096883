import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.state = { brightness: 0, contrast: 0 }
  }

  updateOption = (key) => {
    return (value) => {
      this.setState({ [key]: value })
      this.props.setFilterOption(key, value / 100.0)
    }
  }

  render () {
    const { onCancel, onConfirm } = this.props
    const InputSliderRow = this.packageFor('sliders').InputSliderRow
    return (
      <div className="brightness-and-contrast-view">
        <form className="row" onSubmit={ onConfirm }>
          <div className="brightness-and-contrast-view__inputs filter-inputs">
            <InputSliderRow label="Brightness:"
              className="mb-s"
              min={ -100 }
              max={ 100 }
              autoFocus="true"
              value={ this.state.brightness }
              onChange={ this.updateOption('brightness') } />
            <InputSliderRow label="Contrast:"
              min={ -100 }
              max={ 100 }
              value={ this.state.contrast }
              onChange={ this.updateOption('contrast') } />
          </div>
          <div className="brightness-and-contrast-view__buttons ml-xl filter-buttons">
            <button type="submit"
              className="border-button stretch mb-m">
              Ok
            </button>
            <button type="button"
              onClick={ onCancel }
              className="border-button stretch">
              Cancel
            </button>
          </div>
        </form>
      </div>
    )
  }
}
