const degree = '\u00B0'

export function editMenu () {
  const name = 'Edit'
  return [
    {
      path: [name, 'Undo'],
      action: 'docs:undo'
    },
    {
      path: [name, 'Redo'],
      action: 'docs:redo'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Cut'],
      action: 'clipboard:cut'
    },
    {
      path: [name, 'Copy'],
      action: 'clipboard:copy'
    },
    {
      path: [name, 'Paste'],
      action: 'clipboard:paste'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Foreground Fill'],
      action: 'tools.marquee:foreground-fill'
    },
    {
      path: [name, 'Background Fill'],
      action: 'tools.marquee:background-fill'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Flip Horizontal'],
      action: 'tools.raster-transform:flip-horizontal'
    },
    {
      path: [name, 'Flip Vertical'],
      action: 'tools.raster-transform:flip-vertical'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Rotate 90' + degree + ' Clockwise'],
      action: 'tools.raster-transform:rotate-clockwise'
    },
    {
      path: [name, 'Rotate 90' + degree + ' Counter Clockwise'],
      action: 'tools.raster-transform:rotate-counter-clockwise'
    },
    {
      path: [name, 'Rotate 180' + degree],
      action: 'tools.raster-transform:rotate-180'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Scale'],
      action: 'tools:set-scale-mode'
    },
    {
      path: [name, 'Rotate'],
      action: 'tools:set-rotate-mode'
    },
    {
      path: [name, 'Distort'],
      action: 'tools:set-distort-mode'
    }
  ]
}
