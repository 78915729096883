import _ from 'lodash'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import GroupableCollection from '../../core/groupable-collection'
import LayerGroup from '../../core/layer-group'
import LayerGroupView from './layer-group-view'
import DraggableTree from '../../core/draggable-tree'
import { ensureValidCss } from '../../core/style-helpers'
import el from '../../lib/el'
import ary from '../../lib/ary'
import ev from '../../lib/ev'

import './layer-manager.scss'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    const { layerables } = props
    this.state = { ghostLayerablesBaseLevel: 0 }
    this.ghostLayerables = new GroupableCollection({ root: new LayerGroup() })
    this.draggableTree = new DraggableTree({
      dragMarkerClass: 'layer-manager__drag-marker',
      dragAxis: 'y',
      groupableCollection: layerables,
      onBegin: this.beginDrag,
      onEnd: () => this.endDrag(),
      getSiblingNodes: this.getSiblingNodes,
      onContinue: this.continueDrag,
      updateGhostElementPosition: this.updateGhostElementPosition
    })
  }

  validateTreeCycle = (newParent) => {
    const { layerables } = this.props
    const selectedItems = layerables.getSelectedItems()
    const parentsOfNewParent = layerables.parentsFor(newParent)
    const hasCycle = selectedItems.some((item) => {
      if (!item.isGroupType()) { return false }
      if (item.id === newParent.id) { return true }
      for (const parent of parentsOfNewParent) {
        if (parent.id === item.id) { return true }
      }
      return false
    })
    return !hasCycle
  }

  getNewParent = ({ markerIndex }) => {
    const { layerables } = this.props
    if (markerIndex === 0) {
      return layerables.root
    }
    const visibleItems = layerables.getVisibleItems()
    const hoveredItem = ary(visibleItems).closest(markerIndex - 1)
    const belowHoveredItem = ary(visibleItems).closest(markerIndex)
    const hoveredItemHasChildItems = hoveredItem.isGroupType()
    if (hoveredItemHasChildItems) {
      return hoveredItem
    }
    if ((hoveredItemHasChildItems ||
        layerables.parentFor(belowHoveredItem) !== layerables.parentFor(hoveredItem)) &&
        (!hoveredItemHasChildItems || !hoveredItem.isExpanded)) {
      const { draggableTree } = this
      const anchorOffset = draggableTree.getAnchorOffset()
      const { mainLayerablesRef } = this.refs
      const targetSelector = this.layerableSelector(hoveredItem.id)
      const hoveredElement = el(mainLayerablesRef).find(targetSelector)
      const hoveredElementOffset = hoveredElement.offsetTop + el(hoveredElement).outerHeight() / 2
      if (anchorOffset > hoveredElementOffset) {
        return layerables.closestGroupFor(belowHoveredItem)
      }
    }
    return layerables.closestGroupFor(hoveredItem)
  }

  updateHoveredItem = ({ markerIndex }) => {
    this.newParent = this.getNewParent({ markerIndex })
  }

  validateReorder = ({ markerIndex, initialIndex }) => {
    const { draggableTree, newParent } = this
    const { layerables } = this.props
    const { currDragEvent } = draggableTree
    const anchorItem = layerables.find(currDragEvent.id)
    if (markerIndex === initialIndex || markerIndex === initialIndex + 1) {
      if (layerables.parentFor(anchorItem) === newParent) { return false }
    }
    return this.validateTreeCycle(newParent)
  }

  updateBaseLevel = ({ markerIndex }) => {
    const { draggableTree, isValidReorder } = this
    const { layerables } = this.props
    const { newParent } = this
    if (isValidReorder) {
      this.setState({ ghostLayerablesBaseLevel: layerables.parentsFor(newParent).length })
      return
    }
    const { currDragEvent } = draggableTree
    const anchorItem = layerables.find(currDragEvent.id)
    const anchorItemParents = layerables.parentsFor(anchorItem)
    let referenceItem = anchorItem
    for (const parent of anchorItemParents) {
      if (parent.isSelected) { referenceItem = parent }
    }
    this.setState({ ghostLayerablesBaseLevel: layerables.parentsFor(referenceItem).length - 1 })
  }

  resetMarkerStyle (marker, secondaryMarker) {
    marker.style.marginTop = '0'
    marker.style.height = '0.1rem'
    marker.style.display = 'block'
    secondaryMarker.style.display = 'none'
  }

  updateDragMarker = ({ marker, secondaryMarker }) => {
    const baseOpacity = 0.8
    const mutedOpacity = 0.2
    const { isValidReorder } = this
    if (!isValidReorder) {
      marker.style.opacity = mutedOpacity
      secondaryMarker.style.opacity = mutedOpacity
      this.resetMarkerStyle(marker, secondaryMarker)
      return
    }
    marker.style.opacity = baseOpacity
    secondaryMarker.style.opacity = baseOpacity - 0.2
    const { newParent } = this
    if (newParent.isExpanded) {
      this.resetMarkerStyle(marker, secondaryMarker)
      return
    }
    const { mainLayerablesRef } = this.refs
    const newParentElementSelector = this.layerableSelector(newParent.id)
    const newParentElement = el(mainLayerablesRef).find(newParentElementSelector)
    const newParentHeight = el(newParentElement).outerHeight()
    secondaryMarker.style.height = (newParentHeight) + 'px'
    secondaryMarker.style.marginTop = (-newParentHeight + 2) + 'px'
    secondaryMarker.style.display = 'block'
    marker.style.display = 'none'
  }

  updateIsValidReorder = (data) => {
    this.isValidReorder = this.validateReorder(data)
  }

  continueDrag = () => {
    const { marker, secondaryMarker, markerIndex, initialIndex } = this.draggableTree
    const data = { marker, secondaryMarker, markerIndex, initialIndex }
    this.updateHoveredItem(data)
    this.updateIsValidReorder(data)
    this.updateBaseLevel(data)
    this.updateDragMarker(data)
  }

  getSiblingNodes = () => {
    const { layerables } = this.props
    const { mainLayerablesRef } = this.refs
    const siblingNodes = layerables.map((layerable) => {
      const selector = this.layerableSelector(layerable.id)
      return el(mainLayerablesRef).find(selector)
    })
    return _.compact(siblingNodes)
  }

  layerableSelector = (layerableId) => {
    const { layerables } = this.props
    const layerable = layerables.find(layerableId)
    let selector = '.layerable-view-' + layerable.id
    if (layerable.isGroupType()) { selector += ' > .layer-group-view > .layer-group-view__label' }
    return selector
  }

  componentDidMount () {
    const { draggableTree } = this
    const { ghostLayerablesRef, mainLayerablesRef } = this.refs
    draggableTree.setContainer(mainLayerablesRef)
    draggableTree.setAnchorContainer(ghostLayerablesRef)
    draggableTree.setGhostElement(ghostLayerablesRef)
    draggableTree.setScrollContainer(mainLayerablesRef)
  }

  @action constructGhostLayerables () {
    const { layerables } = this.props
    const { ghostLayerables } = this
    const selectedItems = layerables.getUniqueSelectedItems()
    for (const item of selectedItems) {
      ghostLayerables.add(item, { parent: ghostLayerables.root, skipItemOrderingUpdate: true })
    }
    ghostLayerables.updateItemOrderingToMatchGrouping()
  }

  getAnchorElement () {
    const { anchorId } = this
    const { ghostLayerablesRef } = this.refs
    const targetSelector = this.layerableSelector(anchorId)
    return el(ghostLayerablesRef).find(targetSelector)
  }

  updateGhostElementPosition = (e) => {
    const { mainLayerablesRef, ghostLayerablesRef } = this.refs
    const cursorPosition = ev(e).relativeCoordinates(mainLayerablesRef)
    const top = cursorPosition.y + this.initialOffset
    ghostLayerablesRef.style.top = top + 'px'
  }

  storeInitialOffset = (e) => {
    const { anchorId } = this
    const { mainLayerablesRef } = this.refs
    const targetSelector = this.layerableSelector(anchorId)
    const clickedElement = el(mainLayerablesRef).find(targetSelector)
    const cursorPosition = ev(e).relativeCoordinates(clickedElement)
    const anchorElement = this.getAnchorElement()
    this.initialOffset = -(anchorElement.offsetTop + cursorPosition.y)
  }

  beginDrag = (e) => {
    const { draggableTree, ghostLayerables } = this
    const { layerables } = this.props
    if (layerables.pendingSelectionItems !== undefined) {
      let wasCleared = false
      for (const item of layerables.pendingSelectionItems) {
        if (!item.isSelected) {
          if (!wasCleared) {
            layerables.unselectAll()
            layerables.setActiveId(item.id)
          }
          layerables.selectId(item.id)
          wasCleared = true
        }
      }
    }
    ghostLayerables.clear()
    this.constructGhostLayerables()
    const anchorId = draggableTree.currDragEvent.id
    const layerableIds = layerables.getVisibleItems().map((item) => item.id)
    const initialIndex = layerableIds.indexOf(anchorId)
    this.anchorId = anchorId
    this.storeInitialOffset(e)
    requestAnimationFrame(() => {
      this.updateGhostElementPosition(e)
      const anchorElement = this.getAnchorElement(anchorId)
      draggableTree.setAnchorElement(anchorElement)
      draggableTree.setInitialIndex(initialIndex)
      draggableTree.addMarker()
      draggableTree.updateMarkerIndex()
      draggableTree.updateMarkerPosition()
    })
  }

  getClosestUnselectedSiblingIndex (visibleItems, index) {
    const { newParent } = this
    const { layerables } = this.props
    let topSiblingIndex, bottomSiblingIndex
    for (let i = visibleItems.length - 1; i >= 0; i--) {
      const item = visibleItems[i]
      if (!item.isSelected && layerables.parentFor(item).id === newParent.id) {
        topSiblingIndex = i
        break
      }
    }
    for (let i = 0; i < visibleItems.length; i++) {
      const item = visibleItems[i]
      if (!item.isSelected && layerables.parentFor(item).id === newParent.id) {
        bottomSiblingIndex = i
        break
      }
    }
    if (topSiblingIndex === undefined && bottomSiblingIndex === undefined) { return }
    if (topSiblingIndex === undefined) { return bottomSiblingIndex }
    if (bottomSiblingIndex === undefined) { return topSiblingIndex }
    const topSiblingIndexDiff = topSiblingIndex - index
    const bottomSiblingIndexDiff = bottomSiblingIndex - index
    return topSiblingIndexDiff < bottomSiblingIndexDiff ? topSiblingIndex : bottomSiblingIndex
  }

  @action endDrag () {
    this.ghostLayerables.clear()
    const { isValidReorder, newParent, draggableTree } = this
    if (!isValidReorder) { return }
    const { markerIndex } = draggableTree
    const { layerables } = this.props
    const visibleItems = layerables.getVisibleItems()
    const itemIsVisible = {}
    visibleItems.forEach(item => itemIsVisible[item.id] = true)
    const hiddenItems = layerables.filter(item => !itemIsVisible[item.id])
    const selectedItems = layerables.getUniqueSelectedItems()
    const firstSelectedItemId = selectedItems[0].id

    let markerIndexAdjustment = 0
    for (const item of visibleItems) {
      if (item.isGroupType() && !item.isExpanded) {
        const itemIndex = visibleItems.indexOf(item)
        if (itemIndex >= markerIndex) { break }
        const hiddenChildItems = []
        layerables.flattenGroup(hiddenChildItems, item)
        markerIndexAdjustment += (hiddenChildItems.length - 1)
      }
    }

    const adjustedMarkerIndex = markerIndex + markerIndexAdjustment
    let insertionAdjustment = 0
    for (const item of selectedItems) {
      const itemIndex = visibleItems.indexOf(item)
      if (itemIndex < markerIndex) {
        const childItems = []
        layerables.flattenGroup(childItems, item)
        insertionAdjustment += childItems.length
      }
    }
    layerables.storeSelectionState()
    layerables.removeItems(selectedItems)
    const insertionIndex = markerIndex + markerIndexAdjustment - insertionAdjustment + 1

    let count = 0
    for (const item of selectedItems) {
      if (newParent.isExpanded) {
        layerables.insertAtIndex(item, insertionIndex + count, {
          parent: newParent,
          skipItemOrderingUpdate: true
        })
        count++
      } else {
        layerables.push(item, { parent: newParent, skipItemOrderingUpdate: true })
      }
    }
    layerables.updateItemOrderingToMatchGrouping()
    layerables.restoreSelectionState()
    this.queueDocRender({ mode: 'layersUpdated' })
    this.props.doc.recordVersion({ name: 'Reorder Layers' })
    requestAnimationFrame(() => {
      const firstSelectedElementSelector = this.layerableSelector(firstSelectedItemId)
      const { mainLayerablesRef } = this.refs
      const firstSelectedElement = el(mainLayerablesRef).find(firstSelectedElementSelector)
      el(firstSelectedElement).scrollIntoView()
    })
  }

  layerManagerStyle (visible) {
    const style = { display: visible }
    return ensureValidCss(style)
  }

  componentWillUnmount () {
    this.draggableTree.dispose()
  }

  render () {
    const { draggableTree } = this
    const { doc, layerables, visible } = this.props
    return (
      <div className="layer-manager fill-absolute"
        id={ `layer-manager-${doc.id}` }
        style={ { ...this.layerManagerStyle(visible) } }
        ref="root">
        <div className="fill-absolute layer-manager__main-layerables" ref="mainLayerablesRef">
          <LayerGroupView layerable={ layerables.root }
            level={ 0 }
            doc={ doc }
            draggableTree={ draggableTree }
            layerables={ layerables } />
        </div>
        <div className="fill-absolute layer-manager__ghost-layerables" ref="ghostLayerablesRef">
          <LayerGroupView layerable={ this.ghostLayerables.root }
            doc={ doc }
            level={ this.state.ghostLayerablesBaseLevel }
            layerables={ this.ghostLayerables } />
        </div>
      </div>
    )
  }
}
