import React from 'react'
import hsh from '../lib/hsh'

export default class extends React.Component {
  constructor (props) {
    super(props)
    this.app = props.app
  }

  initSettings ({ settingsId, persistableState }) {
    this.settingsId = settingsId
    this.persistableState = persistableState
  }

  packageFor = (id) => {
    if (this.app === undefined) {
      throw new Error(`this.app is not defined, app must first be defined or injected.`)
    }
    return this.app.packageFor(id)
  }

  getSettingsId () {
    return this.settingsId
  }

  retrieveState () {
    const settings = this.packageFor('settings')
    return settings.retrieveState(this.getSettingsId(), this.persistableState)
  }

  storeState (state) {
    const settings = this.packageFor('settings')
    settings.storeState(this.getSettingsId(), state, this.persistableState)
  }

  componentDidUpdate (prevProps, prevState) {
    if ([prevState, this.getSettingsId(), this.persistableState].includes(undefined)) { return }
    if (hsh(this.state).isEqualTo(prevState, Object.keys(this.persistableState))) {
      return
    }
    this.storeState(this.state)
  }

  getActiveDoc = () => {
    return this.packageFor('docs').fetchActiveDoc()
  }

  getTool = (toolId) => {
    return this.packageFor('tools').tools.find(toolId)
  }

  queueDocRender = (options = {}) => {
    this.packageFor('docs').queueDocRender(options)
  }
}
