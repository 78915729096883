export const BLEND_MODE = {
  NORMAL: 1,
  MULTIPLY: 2,
  SCREEN: 3,
  OVERLAY: 4,

  DARKEN: 5,
  LIGHTEN: 6,

  COLOR_DODGE: 7,
  LINEAR_DODGE: 8,
  COLOR_BURN: 9,
  LINEAR_BURN: 10,

  VIVID_LIGHT: 11,
  LINEAR_LIGHT: 12,
  HARD_LIGHT: 13,
  SOFT_LIGHT: 14,

  DIVIDE: 15,
  ADDITION: 16,
  SUBTRACT: 17,
  DIFFERENCE: 18
}

export const BLEND_MODE_LABELS = [
  'NORMAL',
  'MULTIPLY',
  'SCREEN',
  'OVERLAY',

  'DARKEN',
  'LIGHTEN',

  'COLOR_DODGE',
  'LINEAR_DODGE',
  'COLOR_BURN',
  'LINEAR_BURN',

  'VIVID_LIGHT',
  'LINEAR_LIGHT',
  'HARD_LIGHT',
  'SOFT_LIGHT',

  'DIVIDE',
  'ADDITION',
  'SUBTRACT',
  'DIFFERENCE'
]
