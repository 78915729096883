import { observer } from 'mobx-react'
import React from 'react'
import classNames from 'classnames'
import BaseComponent from '../../core/base-component'
import LayerableView from './layerable-view'
import LayerInfo from './layer-info'
import { nestedPaddingStyle } from './layerable-helpers'

import './layer-group-view.scss'

@observer
export default class LayerGroupView extends BaseComponent {
  getChildItemTemplate (childItem, doc, layerables, level, draggableTree) {
    if (childItem.isGroupType()) {
      return <LayerGroupView key={ childItem.id }
                doc={ doc }
                level={ level + 1}
                layerable={ childItem }
                layerables={ layerables }
                draggableTree={ draggableTree }/>
    }
    return <LayerInfo key={ childItem.id }
              doc={ doc }
              level={ level + 1}
              layerable={ childItem }
              layerables={ layerables }
              draggableTree={ draggableTree }/>
  }

  layerGroupLabelStyle = () => {
    return nestedPaddingStyle(this.props.level)
  }

  mouseDownOnLayerGroupIcon (e, layerable) {
    layerable.setIsExpanded(!layerable.isExpanded)
  }

  layerGroupIconClassNames (layerable) {
    let classes = 'fa layer-group-view__icon clickable '
    classes += layerable.isExpanded ? 'fa-folder-open' : 'fa-folder'
    return classes
  }

  render () {
    const { doc, layerable, layerables, level, draggableTree } = this.props
    return (
      <LayerableView layerable={ layerable } layerables={ layerables }
        draggableTree={ draggableTree }>
        <div className={ 'layer-group-view level-' + level } ref="groupView">
          { layerable !== layerables.root &&
            <div className={ classNames('layer-group-view__label activeable selectable',
                 { active: layerable.isActive }, { selected: layerable.isSelected }) }
                 style={ { ...this.layerGroupLabelStyle() } } >
              <div className={ this.layerGroupIconClassNames(layerable) }
                onClick={ (e) => this.mouseDownOnLayerGroupIcon(e, layerable) }></div>
              Group { layerable.shortId() }
            </div>
          }
          <div>
            { layerable.isExpanded && layerables.childItemsFor(layerable).map((childItem) =>
                this.getChildItemTemplate(childItem, doc, layerables, level, draggableTree)
            )}
          </div>
        </div>
      </LayerableView>
    )
  }
}
