import { inject, observer } from 'mobx-react'
import classNames from 'classnames'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './text-options-view.scss'

const FONT_FAMILIES = [
  'Arial',
  'Arial Black',
  'Brush Script MT',
  'Courier New',
  'Georgia',
  'Lucida Bright',
  'Lucida Sans Typewriter',
  'Palatino',
  'Papyrus',
  'Times New Roman',
  'Trebuchet',
  'Verdana'
]

const TEXT_ALIGNMENT_OPTIONS = ['left', 'center', 'right']

const SETTINGS_ID = 'text'
const PERSISTABLE_STATE = {
  fontFamily: 'Arial',
  fontSize: 25,
  color: '000000',
  textAlignment: 'left'
}

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.initSettings({ settingsId: SETTINGS_ID, persistableState: PERSISTABLE_STATE })
    this.state = this.retrieveState(SETTINGS_ID, PERSISTABLE_STATE)
    this.state.textWidth = 0
    props.setOption('fontFamily', this.state.fontFamily)
    props.setOption('fontSize', this.state.fontSize)
    props.setOption('color', this.state.color)
    props.setOption('textAlignment', this.state.textAlignment)
    props.colorObj.setHex(this.state.color)
    props.takeSetState((newState) => {
      this.setState(newState)
    })
  }

  updateFontFamily = (e) => {
    const { value } = e.currentTarget
    this.setState({ fontFamily: value })
    this.props.setOption('fontFamily', value)
  }

  updateFontSize = (value) => {
    this.setState({ fontSize: value })
    this.props.setOption('fontSize', value)
  }

  updateTextAlignment = (value) => {
    this.setState({ textAlignment: value })
    this.props.setOption('textAlignment', value)
  }

  updateWidth = (e) => {
    const { value } = e.currentTarget
    this.setState({ textWidth: value })
    this.props.setOption('textWidth', value)
  }

  render () {
    const { SlidableInput } = this.packageFor('sliders')
    const { ColorView } = this.packageFor('colors')
    const { state, props } = this
    return (
      <div className="text-options-view align-items-center">
        <select value={ state.fontFamily }
                onChange={ this.updateFontFamily }
                className="mr-l">
          { FONT_FAMILIES.map((fontFamily) => <option key={ fontFamily }>{ fontFamily }</option>) }
        </select>
        <SlidableInput value={ state.fontSize }
          className="mr-l"
          align="left"
          min={ 1 }
          max={ 100 }
          onChange={ this.updateFontSize } />
        <div className="inline-block mr-l text-options-view__alignment-options">
          { TEXT_ALIGNMENT_OPTIONS.map((alignment) => {
              const classes = classNames(
                'tool-options-button',
                'text-options-view__alignment-option',
                `text-options-view__align-${alignment}`,
                { active: alignment === state.textAlignment }
              )
              return (
                <button className={ classes }
                        key={ alignment }
                        onClick={ () => this.updateTextAlignment(alignment) }>
                  <span className={ `fa fa-align-${alignment}` }></span>
                </button>
              )
            })
          }
        </div>
        <ColorView color={ props.colorObj }
                  onClick={ props.colorClicked }
                  className="mr-l" />

                <div className="tool-options-label mr-s">Text Width:</div>
        <input className="mr-l blur-on-submit"
          type="number"
          value={ state.textWidth }
          onChange={ this.updateWidth } />
      </div>
    )
  }
}
