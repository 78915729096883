import Item from '../../core/item'

export default class extends Item {
  constructor (options = {}) {
    super(options)
    const { row, col, width, height, zIndexOverride } = options
    this.row = row
    this.col = col
    this.width = width
    this.height = height
    this.zIndexOverride = zIndexOverride
  }
}
