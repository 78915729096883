import $ from 'jquery'
import _ from 'lodash'
import React from 'react'
import BasePackage from '../../core/base-package'
import Collection from '../../core/collection'
import MenuBarView from './menu-bar-view'
import MenuItem from './menu-item'
import Command from '../../core/command'
import EventList from '../../core/event-list'
import { fileMenu } from './menus/file'
import { editMenu } from './menus/edit'
import { imageMenu } from './menus/image'
import { adjustmentsMenu } from './menus/adjustments'
import { layersMenu } from './menus/layers'
import { selectionMenu } from './menus/selection'
import { viewMenu } from './menus/view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'menu-bar'
    this.menuItems = new Collection()
    this.events = new EventList()
  }

  initMenuItems () {
    const items = _.flatten([
      fileMenu(), editMenu(), imageMenu(), adjustmentsMenu(),
      layersMenu(), selectionMenu(), viewMenu()
    ])
    for (const item of items) {
      const { path, action } = item
      const command = new Command({ commandString: action })
      this.insertMenuItem(this.menuItems, path, command, 0)
    }
  }

  insertMenuItem = (collection, pathArr, command, level) => {
    let id = pathArr.shift()
    let type = undefined
    if (id === 'DIVIDER') {
      id = undefined
      type = 'DIVIDER'
    }
    let shortcutLabel
    if (pathArr.length === 0) {
      const shortcuts = this.packageFor('shortcuts')
      shortcutLabel = shortcuts.shortcutReference[command.commandString]
    }

    const menuItem = collection.findOrAdd(id, () => new MenuItem({ id, level, type, shortcutLabel }))
    menuItem.app = this.app
    if (pathArr.length === 0) {
      menuItem.command = command
      return
    }
    this.insertMenuItem(menuItem.subItems, pathArr, command, level + 1)
  }

  activate () {
    this.initMenuItems()
    const menuBarView = React.createElement(MenuBarView, {
      menuItems: this.menuItems
    })
    this.packageFor('layouts').addLayoutItem('top-2',
      { viewComponent: menuBarView,
        width: 'fill',
        row: 50,
        zIndexOverride: 1000 })
    this.events.addDomEvent(window, 'click', () => {
      $('.menu-item-view').removeClass('active')
    })
    this.app.addMenuSelector('.menu-item-view')
    const shortcuts = this.packageFor('shortcuts')
    shortcuts.onEsc.subscribe(() => {
      $('.menu-item-view').removeClass('active')
    })
  }

  dispose () {
    super.dispose()
    this.events.dispose()
  }
}
