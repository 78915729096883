import Dom from './dom'
import el from '../lib/el'

function sanitizeHtml (html) {
  html = html.replace(/&nbsp;/g, '&#160;')
  html = html.replace(/<br>/g, '&#160;')
  html = html.replace(/<p>/g, '<p style="margin: 0; word-break: break-all;">')
  return html
}

function getContainerStyle ({ layer, forSizing }) {
  let style = [
    `overflow: visible`,
    `font-family: ${layer.style.fontFamily}`,
    `font-size: ${layer.style.fontSize}px`,
    `color: #${layer.style.color}`,
    `text-align: ${layer.style.textAlignment}`,
    `width: ${layer.textWidth}px`
  ]
  if (forSizing) {
    style = style.concat([
      `position: absolute`
    ])
  } else {
    style = style.concat([
      `margin-left: ${layer.x}px`,
      `margin-top: ${layer.y}px`
    ])
  }
  return style.join('; ')
}

function getContainerHtml ({ html, layer, forSizing, containerId }) {
  html = sanitizeHtml(html)
  const style = getContainerStyle({ layer, forSizing })
  if (containerId !== undefined) {
    return `<div id=${containerId} style="${style}">${html}</div>`
  }
  return `<div style="${style}">${html}</div>`
}

export function getTextSize ({ html, layer }) {
  const root = Dom.findById('text-size-checkers-container')
  const parentId = 'text-layer-size-check:' + layer.id
  const containerId = 'text-layer-size-check-container:' + layer.id
  let parent = Dom.findById(parentId)
  if (parent === null) { parent = Dom.div({ id: parentId, parent: root }) }
  parent.style.width = layer.textWidth + 'px'
  const containerHtml = getContainerHtml({ html, layer, forSizing: true, containerId })
  parent.innerHTML = containerHtml
  const container = Dom.findById(containerId)
  return el(container).innerSize()
}

export function loadText ({ html, pic, layer }) {
  html = sanitizeHtml(html)
  const width = layer.width
  const height = layer.height
  const containerHtml = getContainerHtml({ html, layer, forSizing: false })
  const data = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      <foreignObject width="100%" height="100%">
        <div xmlns="http://www.w3.org/1999/xhtml">
          ${containerHtml}
        </div>
      </foreignObject>
    </svg>
  `
  const src = 'data:image/svg+xml;base64,' + btoa(data)

  pic.loadSrc(src)
}
