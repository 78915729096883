import Filter from '../../core/filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'blur'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform vec2 direction;

      vec4 blur(sampler2D image, vec2 uv, vec2 resolution, vec2 direction) {
        vec2 off1 = vec2(1.3846153846) * direction;
        vec2 off2 = vec2(3.2307692308) * direction;
        float multipliers[5];
        multipliers[0] = 0.2270270270;
        multipliers[1] = 0.3162162162;
        multipliers[2] = 0.3162162162;
        multipliers[3] = 0.0702702703;
        multipliers[4] = 0.0702702703;

        vec4 colors[5];
        colors[0] = texture(image, uv);
        colors[1] = texture(image, uv + (off1 / resolution));
        colors[2] = texture(image, uv - (off1 / resolution));
        colors[3] = texture(image, uv + (off2 / resolution));
        colors[4] = texture(image, uv - (off2 / resolution));

        vec4 averageColor = EMPTY_COLOR;
        float totalMultiplied = 0.0;
        bool hasEmptyColor = false;
        for (int i = 0; i < 5; i++) {
          vec4 color = colors[i];
          if (color.a > 0.0) {
            averageColor += color * multipliers[i];
            totalMultiplied += multipliers[i];
          } else {
            hasEmptyColor = true;
          }
        }

        vec4 finalColor = averageColor;

        if (hasEmptyColor) {
          finalColor = EMPTY_COLOR;
          averageColor = averageColor / totalMultiplied;

          for (int i = 0; i < 5; i++) {
            vec4 color = colors[i];
            if (color.a == 0.0) {
              color.rgb = averageColor.rgb;
            }
            finalColor += color * multipliers[i];
          }
        }

        return finalColor;
      }

      void main () {
        vec2 uv = vec2(currPosition().x / desSize.x, currPosition().y / desSize.y);
        outputColor = blur(_src, uv, desSize, direction);
      }
    `
  }
}
