import { computed, action } from 'mobx'
import VectorPoint from './vector-point'
import hsh from '../../lib/hsh'

const POSITION_KEYS = ['x', 'y', 'c1x', 'c1y', 'c2x', 'c2y']

export default class CubicPoint extends VectorPoint {
  constructor (options = {}) {
    super(hsh(options).exclude(POSITION_KEYS))
    this.c1 = new VectorPoint()
    this.c2 = new VectorPoint()
    this.setPosition(options)
  }

  @action setPosition (options = {}) {
    hsh(this).assignValues(options, POSITION_KEYS)
  }

  @computed get c1x () { return this.c1.x }
  @computed get c1y () { return this.c1.y }
  @computed get c2x () { return this.c2.x }
  @computed get c2y () { return this.c2.y }

  set c1x (value) { this.c1.x = value }
  set c1y (value) { this.c1.y = value }
  set c2x (value) { this.c2.x = value }
  set c2y (value) { this.c2.y = value }

  type () { return 'cubic' }

  normalized () {
    const { x, y, c1x, c1y, c2x, c2y } = this
    return { x, y, c1x, c1y, c2x, c2y }
  }

  clone () {
    return new CubicPoint(this.normalized())
  }

  @action transform (...args) {
    super.transform(...args)
    this.c1.transform(...args)
    this.c2.transform(...args)
  }
}
