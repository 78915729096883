import Item from './item'
import Collection from './collection'
import Point from './point'

// https://github.com/opentoonz/opentoonz/pull/432/files
function getStabilizedPoint (points) {
  const stabilizedPoint = new Point({ x: 0, y: 0 })
  points.each((point) => {
    stabilizedPoint.$add(point)
  })
  const divisor = parseFloat(points.count())
  return stabilizedPoint.divideBy(divisor)
}

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.pointLimit = options.stabilization
    this.previousPoints = new Collection({ collectionLimit: this.pointLimit })
  }

  stabilize ({ x, y }) {
    const point = new Point({ x, y })
    this.previousPoints.unshift(point)
    return getStabilizedPoint(this.previousPoints)
  }

  dispose () {
    this.previousPoints.dispose()
  }
}
