import { wgl } from '../wgl'
import Tex from '../tex'

import HorizontalPresenceFilter from '../filters/presence-filter/horizontal-presence-filter'
import LinearizeHorizontalFilter from '../filters/boundary-filter/linearize-horizontal'
import VerticalBoundaryFilter from '../filters/boundary-filter/vertical-boundary'

import VerticalPresenceFilter from '../filters/presence-filter/vertical-presence-filter'
import LinearizeVerticalFilter from '../filters/boundary-filter/linearize-vertical'
import HorizontalBoundaryFilter from '../filters/boundary-filter/horizontal-boundary'

const horizontalPresenceFilter = new HorizontalPresenceFilter()
const linearizeHorizontalFilter = new LinearizeHorizontalFilter()
const linearizeVerticalFilter = new LinearizeVerticalFilter()

const verticalPresenceFilter = new VerticalPresenceFilter()
const verticalBoundaryFilter = new VerticalBoundaryFilter()
const horizontalBoundaryFilter = new HorizontalBoundaryFilter()

const BATCH_SIZE = 50.0

function colorToIndex ({ r, g, b }) {
  return r * (256 * 256) + g * 256 + b
}

function readBoundaryValue (tex) {
  const pixels = tex.readPixels({ x: 0, y: 0, width: 2, height: 1 })

  // values are encoded as colors
  const minColor = {
    r: pixels[0],
    g: pixels[1],
    b: pixels[2]
  }
  const maxColor = {
    r: pixels[4],
    g: pixels[5],
    b: pixels[6]
  }
  return {
    min: colorToIndex(minColor),
    max: colorToIndex(maxColor)
  }
}

function applyBoundaryFilters (options) {
  const { tex, tempTex, getHorizontalBoundary, getVerticalBoundary } = options
  const { presenceFilter, linearizeFilter, boundaryFilter } = options

  const { width, height } = tex
  const compressedWidth = Math.ceil(width / BATCH_SIZE)
  const compressedHeight = Math.ceil(height / BATCH_SIZE)

  const presenceSize = {
    width: getVerticalBoundary ? compressedWidth : width,
    height: getHorizontalBoundary ? compressedHeight : height
  }

  wgl.applyFilter({
    filter: presenceFilter,
    des: {
      texture: 'temp',
      width: presenceSize.width,
      height: presenceSize.height
    },
    data: {
      _src: tex
    }
  })

  tempTex.swapTexture(wgl.tempRenderedTex)

  const linearizeSize = {
    width: getVerticalBoundary ? 1 : width,
    height: getHorizontalBoundary ? 1 : height,
  }

  wgl.applyFilter({
    filter: linearizeFilter,
    des: {
      texture: 'temp',
      width: linearizeSize.width,
      height: linearizeSize.height
    },
    data: {
      _src: tempTex
    }
  })

  tempTex.swapTexture(wgl.tempRenderedTex)

  wgl.applyFilter({
    filter: boundaryFilter,
    des: {
      texture: 'temp',
      width: 2,
      height: 1
    },
    data: {
      _src: tempTex
    }
  })

  return readBoundaryValue(wgl.tempRenderedTex)
}

export function getTexBoundary (tex) {
  const tempTex = new Tex()

  const verticalBoundary = applyBoundaryFilters({
    tex,
    tempTex,
    getHorizontalBoundary: false,
    getVerticalBoundary: true,
    presenceFilter: horizontalPresenceFilter,
    linearizeFilter: linearizeHorizontalFilter,
    boundaryFilter: verticalBoundaryFilter
  })

  const horizontalBoundary = applyBoundaryFilters({
    tex,
    tempTex,
    getHorizontalBoundary: true,
    getVerticalBoundary: false,
    presenceFilter: verticalPresenceFilter,
    linearizeFilter: linearizeVerticalFilter,
    boundaryFilter: horizontalBoundaryFilter
  })

  tempTex.dispose()

  const result = {
    width: horizontalBoundary.max - horizontalBoundary.min,
    height: verticalBoundary.max - verticalBoundary.min,
    min: {
      x: horizontalBoundary.min,
      y: verticalBoundary.min
    },
    max: {
      x: horizontalBoundary.max,
      y: verticalBoundary.max
    }
  }

  return result
}
