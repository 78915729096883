import ImagePattern from './image-pattern'

export default class extends ImagePattern {
  defaultAttributes () {
    return {
      template: 'ImagePattern',
      units: 'userSpaceOnUse',
      width: 8,
      height: 8,
      transform: 'rotate(-45)',
      image: {
        href: '/img/marquee-pattern.gif',
        width: 8,
        height: 8
      }
    }
  }
}
