import { observable, action } from 'mobx'
import Pic from './pic'
import Layer from './layer'
import Collection from './collection'
import { registerGlobalFactory } from './factory-references'
import { globals } from '../globals'
import './svg-reference-container'

export default class Svg extends Layer {
  @observable width = 0
  @observable height = 0

  constructor (options = {}) {
    super(options)
    this.vectors = new Collection({ syncItemIndexes: true })
    this.fixedVectors = new Collection()
    this.definitions = new Collection()
    this.shouldGeneratePic = options.shouldGeneratePic
    this.shouldCreateReference = options.shouldCreateReference
    this.shouldGenerateHitTestPic = options.shouldGenerateHitTestPic
    if (this.shouldGeneratePic) { this.pic = new Pic() }
    if (this.shouldGenerateHitTestPic) { this.hitTestPic = new Pic() }
    const { width, height } = options
    this.resize({ width, height })
    this.origin = observable({ x: 0, y: 0 })
    if (this.shouldCreateReference) { globals.svgs.add(this) }

    if (this.shouldGenerateHitTestPic) {
      this.vectors.itemIndexesWereUpdated.subscribe(() => this.updateHitTestColors(this.vectors))
      this.fixedVectors.itemIndexesWereUpdated.subscribe(() => this.updateHitTestColors(this.fixedVectors))
    }
  }

  updateHitTestColors (vectors) {
    vectors.each((vector, { index }) => {
      let color = (index).toString(16)
      while (color.length < 6) { color = '0' + color }
      vector.hitTestColor = '#' + color
    })
  }

  hasReferenceView () { return this.shouldCreateReference }

  @action resize ({ width, height }) {
    if (width === undefined) { width = 0 }
    if (height === undefined) { height = 0 }
    this.width = width
    this.height = height
  }

  addDefinition (definition) {
    return this.definitions.add(definition)
  }

  addVector (vector) {
    return this.vectors.add(vector)
  }

  addFixedVector (vector) {
    return this.vectors.add(vector)
  }

  findOrAddFixedVector (vectorId, callback) {
    return this.fixedVectors.findOrAdd(vectorId, callback, true)
  }

  findOrAddVector (vectorId, callback) {
    return this.vectors.findOrAdd(vectorId, callback)
  }

  findVector (vectorId) {
    return this.vectors.find(vectorId)
  }

  type () { return 'svg' }

  getFactoryKey () { return 'Svg' }

  colorAtPoint (p) {
    const c = this.pic.colorAtPoint(p)
    c.setAlpha(c.a * this.opacity)
    return c
  }

  hitTestColorAtPoint (p) {
    return this.hitTestPic.colorAtPoint(p)
  }

  vectorAtPoint (p) {
    const color = this.hitTestColorAtPoint(p)
    if (color.a !== 1) { return }
    const index = parseInt(color.hex, 16)
    return this.vectors.itemAtIndex(index)
  }

  dispose () {
    super.dispose()
    if (this.pic) { this.pic.dispose() }
    if (this.hitTestPic) { this.hitTestPic.dispose() }
    globals.svgs.disposeItem(this)
  }
}

registerGlobalFactory('Svg', Svg)
