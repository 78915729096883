import Filter from '../filter'
import { parseForShader } from '../color'
import { withDefault } from '../utils'
import { COMPOSITE_MODE } from '../../constants/composite-modes'
import { getSoftValueSnippet } from './snippets/soft-value'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'oval'
  }

  fillOval ({ wgl, des, _src, _center, _size, _fillStyle, _alpha, _compositeMode  }) {
    const _a2 = Math.pow(_size.width / 2.0, 2)
    const _b2 = Math.pow(_size.height / 2.0, 2)
    return wgl.applyFilter({
      filter: this,
      des,
      data: {
        _src,
        _a2,
        _b2,
        _size: [_size.width, _size.height],
        _center: [_center.x, _center.y],
        _alpha: withDefault(_alpha, 1),
        _compositeMode: withDefault(_compositeMode, COMPOSITE_MODE.NORMAL),
        _fillStyle: parseForShader(withDefault(_fillStyle, 'black'))
      }
    })
  }

  getFragmentShader () {
    return getSoftValueSnippet() + `
      uniform sampler2D _src;
      uniform vec2 _srcSize;
      uniform vec2 _center;
      uniform vec2 _size;
      uniform float _a2; // oval.width ^ 2
      uniform float _b2; // oval.height ^ 2
      uniform vec4 _fillStyle;
      uniform float _alpha;
      uniform float _compositeMode;

      float getOvalBoundaryDistance (float x, float y) {
        if (y == 0.0) { return _size.x / 2.0; }
        if (x == 0.0) { return _size.y / 2.0; }
        float m = y / x;
        float m2 = m * m;
        float x1 = sqrt((_b2 * _a2) / (_a2 * m2 + _b2));
        float x2 = x1 * x1;
        float y1 = sqrt(_b2 - _b2 * x2 / _a2);
        return getLength(x1, y1);
      }

      float calcAlpha (float distance, float ovalDistance) {
        float dRatio = distance / ovalDistance;
        float blurWidth = 2.0;
        if (ovalDistance < 3.0) {
          blurWidth = 1.0;
        }
        float solidCutoff = 1.0 - blurWidth / ovalDistance;
        return softValue(dRatio, solidCutoff);
      }

      void main () {
        float x = abs(coordX() - _center.x);
        float y = abs(coordY() - _center.y);
        vec4 color = texColorFor(_src, _srcSize);
        float ovalDistance = getOvalBoundaryDistance(x, y);
        float currDistance = getLength(x, y);
        vec4 fillColor = EMPTY_COLOR;
        if (currDistance < ovalDistance) {
          float alpha = calcAlpha(currDistance, ovalDistance);
          fillColor = vec4(_fillStyle.rgb, alpha);
        }
        outputColor = drawColorOnTop(fillColor, color, _alpha, _compositeMode);
      }
    `
  }
}
