import Item from './item'

export default class extends Item {
  constructor (options) {
    super(options)
    this.task = undefined
    this.hasPendingTask = false
    this.active = true
  }

  setTask = (callback) => {
    this.task = callback
    // we need to enqueue the task if there is no requestAnimationFrame pending
    if (!this.hasPendingTask) { this.processTask() }
  }

  processTask = () => {
    this.hasPendingTask = true
    window.requestAnimationFrame(() => {
      if (this.task && this.active) { this.task() }
      this.task = undefined
      this.hasPendingTask = false
    })
  }

  dispose () {
    super.dispose()
    this.active = false
  }
}
