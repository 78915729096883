export function withDefault (value, defaultValue) {
  return value !== undefined ? value : defaultValue
}

export function processAsyncTasks ({ items, task, index, onComplete }) {
  if (index === undefined) { index = 0 }
  if (index >= items.length) { return onComplete() }
  const item = items[index]
  task({
    item,
    index,
    runNext: () => {
      processAsyncTasks({ items, task, index: index + 1, onComplete })
    }
  })
}
