import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import LayoutContainerView from './layout-container-view'
import GridLayoutView from './grid-layout-view'

import './layout-container-view-list.scss'

@observer
export default class extends BaseComponent {
  layoutContainerGridTemplate = (layoutContainer, column) => {
    return (
      <LayoutContainerView
        column={ column }
        layoutContainer={ layoutContainer }
        key={ layoutContainer.id} />
    )
  }

  render () {
    const { layoutContainers, hiddenItemsContainer, freeformItemsContainer } = this.props
    return (
      <div className='layout-container-view-list fill-absolute'>
        <div className="layout-containers fill-absolute">
          <GridLayoutView layouts={ layoutContainers }
            template={ this.layoutContainerGridTemplate } />
        </div>
        <div className='hidden-items-container hidden-from-view'>
          <LayoutContainerView layoutContainer={ hiddenItemsContainer } />
        </div>
        <div className='freeform-items-container'>
          <LayoutContainerView layoutContainer={ freeformItemsContainer } />
        </div>
      </div>
    )
  }
}
