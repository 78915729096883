export function adjustmentsMenu () {
  const name = 'Adjustments'
  return [
    {
      path: [name, 'Brightness / Contrast'],
      action: 'filters:show-filter-in-dialog(brightness-and-contrast)'
    },
    {
      path: [name, 'Hue / Saturation'],
      action: 'filters:show-filter-in-dialog(hue-saturation)'
    },
    {
      path: [name, 'Color Balance'],
      action: 'filters:show-filter-in-dialog(color-balance)'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Invert'],
      action: 'filters:apply-filter(invert)'
    },
    {
      path: [name, 'Grayscale'],
      action: 'filters:apply-filter(grayscale)'
    },
    {
      path: [name, 'Black / White'],
      action: 'filters:show-filter-in-dialog(black-white)'
    },
    {
      path: [name, 'Alpha'],
      action: 'filters:apply-filter(alpha)'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Blur'],
      action: 'filters:show-filter-in-dialog(blur)'
    },
    {
      path: [name, 'Sharpen'],
      action: 'filters:show-filter-in-dialog(sharpen)'
    }
  ]
}
