import ToolPackage from '../../core/tool-package'
import MagicWand from './magic-wand'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'magic-wand'
    this.tools = [MagicWand]
  }
}
