import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './layout-item-view.scss'

@observer
export default class extends BaseComponent {
  layoutItemStyle (layoutItem, collectionLength) {
    const { zIndex } = this.props
    const style = {}
    if (zIndex !== undefined) {
      style.zIndex = zIndex
      const row = collectionLength - zIndex
      style.gridRow = `${row} / ${row}`
    }
    if (layoutItem.zIndexOverride !== undefined) {
      style.zIndex = layoutItem.zIndexOverride
    }
    return style
  }

  render () {
    const { layoutItem, collectionLength } = this.props
    const { viewComponent } = layoutItem
    return (
      <div className="layout-item-view" style={ { ...this.layoutItemStyle(layoutItem, collectionLength) } }>
        { viewComponent }
      </div>
    )
  }
}
