import { observable, action } from 'mobx'
import React from 'react'
import BasePackage from '../../core/base-package'
import Collection from '../../core/collection'
import ToolViewList from './tool-view-list'
import DragEvent from '../../core/drag-event'
import EventList from '../../core/event-list'
import MovementRect from '../../core/movement-rect'
import Point from '../../core/point'
import Dom from '../../core/dom'
import ev from '../../lib/ev'
import el from '../../lib/el'
import { globals } from '../../globals'
import * as toolPackageList from '../../tool-packages/tool-package-list'

export default class extends BasePackage {
  @observable showVectorPoints = false
  @observable showTransformationView = false

  constructor (options) {
    super(options)
    this.id = 'tools'
    this.toolPackages = new Collection()
    this.tools = new Collection()
    this.movementInfo = undefined
    this.movementRect = new MovementRect({ useIntegers: true })
    this.colorPickerMode = false
    this.events = new EventList()
    this.closeableSelectors = {}
    this.events.addObserver(this.tools, 'activeId', this.onToolSelected)
  }

  onToolSelected = () => {
    const docsPackage = this.packageFor('docs')
    const doc = this.getActiveDoc()
    const activeTool = this.tools.getActiveItem()
    const { prevActiveTool } = this
    if (prevActiveTool && activeTool.id !== prevActiveTool.id) {
      prevActiveTool.onDeselect({ doc })
    }
    activeTool.onSelect({ doc })
    this.updateToolOptions()

    this.prevActiveTool = activeTool
    this.queueDocRender({ mode: 'cursorUpdated' })
  }

  shouldShowScrollbarCursor ({ doc, p, containerSize }) {
    if (doc === undefined || doc.meta['doc-view'] === undefined) {
      return false
    }
    const { scrollbars } = doc.meta['doc-view']
    if (scrollbars === undefined) { return false }
    const scrollBarSize = Dom.getScrollBarSize()
    if (scrollbars.vertical && p.x > (containerSize.width - scrollBarSize.width)) {
      return true
    }
    if (scrollbars.horizontal && p.y > (containerSize.height - scrollBarSize.height)) {
      return true
    }
    return false
  }

  updateToolOptions = () => {
    const toolOptions = this.packageFor('tool-options')
    const tool = this.tools.getActiveItem()
    toolOptions.setToolOptions(tool.optionsView)
  }

  activate = () => {
    this.initTools()
    this.initListeners()
    this.initToolViewList()
    this.onToolSelected()
  }

  subPackage = (subPackageId) => {
    return this.tools.find(subPackageId)
  }

  setTransformationDirection = (direction) => {
    this.transformationDirection = direction
  }

  initListeners = () => {
    const docsPackage = this.packageFor('docs')
    this.dragEvent = new DragEvent({
      mouseDownEvent: docsPackage.mouseDownInDocView,
      onBegin: this.beginMovement,
      onContinue: this.continueMovement,
      onEnd: (...args) => {
        this.endMovement(...args)
        this.endMovementCleanup()
      }
    })
  }

  initTools () {
    const { app, tools, toolPackages } = this
    for (const key in toolPackageList) {
      const toolPackage = new toolPackageList[key]({ app })
      for (const ToolClass of toolPackage.tools) {
        const tool = new ToolClass({ app })
        tools.add(tool)
        toolPackage.toolObjects.push(tool)
      }
      toolPackages.add(toolPackage)
    }
    toolPackages.each((toolPackage) => {
      toolPackage.activate()
    })
    tools.each((tool) => tool.activate())
    tools.setActiveId(this.defaultTool())
  }

  defaultTool () {
    return 'brush'
  }

  initToolViewList () {
    const toolViewList = React.createElement(ToolViewList, { tools: this.tools })
    this.packageFor('layouts').addLayoutItem('left-1',
      { viewComponent: toolViewList,
        row: 50 })
  }

  beginMovement = (doc, element, e) => {
    this.activeDoc = doc
    const p = this.getEventCoordinatesInDoc({ doc, element, e })
    this.currentCursorPositionInDoc = p.clone()
    this.movementRect.setStart(p)

    this.movementInfo = { doc: doc, element: element }
    const toolParams = this.getToolParams({ e, p })
    if (this.colorPickerMode) {
      this.tools.find('pipette').pickForegroundColorFromDoc(toolParams)
      return
    }
    if (this.skipMovement()) { return }
    const { transformationDirection } = this
    const activeTool = this.tools.getActiveItem()
    if (activeTool && activeTool.allowTransformation && transformationDirection) {
      this.beginTransform(toolParams)
      return
    }
    if (activeTool && activeTool.allowSelectionCursor) {
      const selectionChanged = this.performSelection(toolParams)
      if (selectionChanged && doc.selectedVectors.count() > 0) { return }
    }
    if (activeTool) {
      activeTool.movementStarted = true
    }
    if (activeTool && !activeTool.skipMovement(toolParams)) {
      activeTool.beforeBeginMovement(toolParams)
      activeTool.beginMovement(toolParams)
      activeTool.afterBeginMovement(toolParams)
    }
  }

  fetchActiveTool () {
    return this.tools.getActiveItem()
  }

  performSelection (...args) {
    const selectionTool = this.tools.find('selection')
    selectionTool.beginMovement(...args)
    return selectionTool.selectionChanged
  }

  beginTransform (...args) {
    const transformationTool = this.tools.find('transformation')
    transformationTool.beginMovement(...args)
  }

  continueTransform (...args) {
    const transformationTool = this.tools.find('transformation')
    transformationTool.continueMovement(...args)
  }

  endTransform (...args) {
    const transformationTool = this.tools.find('transformation')
    transformationTool.endMovement(...args)
  }

  getToolParams = ({ e, p }) => {
    const { transformationDirection, movementRect } = this
    const { currentCursorPositionInDoc, cursorPositionDiff } = this
    const { doc, element } = this.movementInfo
    const activeTool = this.tools.getActiveItem()
    const transformationModeIsOn = !!activeTool.allowTransformation
    const movementDimensions = movementRect.getDimensions()
    return { doc, element, e, p, transformationDirection,
             movementRect, movementDimensions, currentCursorPositionInDoc,
             transformationModeIsOn, cursorPositionDiff }
  }

  continueMovement = (e) => {
    if (this.movementInfo === undefined) { return }
    const { doc, element } = this.movementInfo

    const p = this.getEventCoordinatesInDoc({ doc, element, e })
    this.cursorPositionDiff = p.subtract(this.currentCursorPositionInDoc)
    this.currentCursorPositionInDoc = p
    this.movementRect.setEnd(p)

    if (this.skipMovement()) { return }

    const toolParams = this.getToolParams({ e, p })

    if (this.colorPickerMode && e.buttons === 1) {
      this.tools.find('pipette').pickForegroundColorFromDoc(toolParams)
      return
    }
    const activeTool = this.tools.getActiveItem()
    const { transformationDirection } = this
    if (activeTool && activeTool.allowTransformation && transformationDirection) {
      this.continueTransform(toolParams)
      return
    }
    if (activeTool && !activeTool.skipMovement(toolParams)) {
      activeTool.beforeContinueMovement(toolParams)
      activeTool.continueMovement(toolParams)
      activeTool.afterContinueMovement(toolParams)
    }
  }

  endMovement = (e) => {
    if (this.movementInfo === undefined) { return }
    const { doc, element } = this.movementInfo

    const p = this.getEventCoordinatesInDoc({ doc, element, e })
    this.currentCursorPositionInDoc = p
    this.movementRect.setEnd(p)

    const toolParams = this.getToolParams({ e, p })

    const activeTool = this.tools.getActiveItem()
    if (this.skipMovement()) { return }
    const { transformationDirection } = this
    if (activeTool && activeTool.allowTransformation && transformationDirection) {
      this.endTransform(toolParams)
      return
    }
    if (activeTool && !activeTool.skipMovement(toolParams)) {
      activeTool.beforeEndMovement(toolParams)
      activeTool.endMovement(toolParams)
      activeTool.afterEndMovement(toolParams)
    }
  }

  endMovementCleanup = () => {
    const activeTool = this.tools.getActiveItem()
    if (activeTool) { activeTool.movementStarted = false }
    this.movementInfo = undefined
    this.setTransformationDirection(undefined)
    this.movementRect.clear()
  }

  skipMovement = () => {
    return this.app.hasOpenedMenu()
  }

  selectTool = (toolId) => {
    this.tools.setActiveId(toolId)
  }

  rotateTool = (toolPackageId) => {
    const toolPackage = this.toolPackages.find(toolPackageId)
    const { toolObjects } = toolPackage
    if (toolObjects.length === 1) {
      this.tools.setActiveId(toolObjects[0].id)
      return
    }
    const toolIds = toolObjects.map((tool) => tool.id)
    let nextIndex = (toolIds.indexOf(this.tools.activeId) + 1) % toolIds.length

    this.tools.setActiveId(toolIds[nextIndex])
  }

  getRelativeCoordinates (element, e) {
    return ev(e).relativeCoordinates(element)
  }

  getEventCoordinatesInDoc ({ doc, element, e }) {
    const p = this.getRelativeCoordinates(element, e)
    p.x *= (1.0 / doc.zoom)
    p.y *= (1.0 / doc.zoom)
    return new Point(p)
  }

  setTransformMode (mode) {
    this.selectTool('raster-transform')
    const rasterTransform = this.getTool('raster-transform')
    rasterTransform.setMode(mode)
  }

  setScaleMode () { this.setTransformMode('scale') }
  setRotateMode () { this.setTransformMode('rotate') }
  setDistortMode () { this.setTransformMode('distort') }

  dispose = () => {
    this.dragEvent.dispose()
    this.events.dispose()
  }

  handleKey = ({ key, shift }) => {
    const doc = this.getActiveDoc()
    const activeTool = this.tools.getActiveItem()
    if (activeTool) { activeTool.handleKey({ doc, key, shift }) }
  }

  upKey = () => { this.handleKey({ key: 'up' }) }
  downKey = () => { this.handleKey({ key: 'down' }) }
  leftKey = () => { this.handleKey({ key: 'left' }) }
  rightKey = () => { this.handleKey({ key: 'right' }) }

  shiftUpKey = () => { this.handleKey({ key: 'up', shift: true }) }
  shiftDownKey = () => { this.handleKey({ key: 'down', shift: true }) }
  shiftLeftKey = () => { this.handleKey({ key: 'left', shift: true }) }
  shiftRightKey = () => { this.handleKey({ key: 'right', shift: true }) }
}
