import ToolPackage from '../../core/tool-package'
import Move from './move'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'move'
    this.tools = [Move]
  }
}
