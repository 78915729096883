import ToolPackage from '../../core/tool-package'
import BucketFill from './bucket-fill'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'fill'
    this.tools = [BucketFill]
  }
}
