import classNames from 'classnames'
import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './color-view.scss'

@observer
export default class extends BaseComponent {
  styleForColorView (color) {
    return { background: color.styleString }
  }
  render () {
    const { color, className, onClick } = this.props
    return (
      <div className={ classNames('color-view border-1', className) }
        style={ { ...this.styleForColorView(color) } }
        onClick={ onClick }>
      </div>
    )
  }
}
