import BaseLayout from './base-layout'

export default class extends BaseLayout {
  constructor (options = {}) {
    super(options)
    this.viewComponent = options.viewComponent
    this.name = options.name
  }

  getPriority = () => {
    return this.row
  }
}
