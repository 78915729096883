import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from './base-component'
import VectorView from './vector-view'
import DefinitionView from './definition-view'
import EventList from './event-list'

import './svg-reference-view.scss'

@observer
export default class extends BaseComponent {
  componentDidMount () {
    const { svg, forHitTest } = this.props
    this.events = new EventList()
    if (svg.shouldGeneratePic && forHitTest !== true) {
      this.events.addEvent(svg.wasUpdated, this.queueUpdatePic)
      this.queueUpdatePic()
    }
    if (svg.shouldGenerateHitTestPic && forHitTest === true) {
      this.events.addEvent(svg.wasUpdated, this.queueUpdateHitTestPic)
      this.queueUpdateHitTestPic()
    }
  }

  queueUpdatePic = () => {
    this.events.setTask('update-pic', this.updatePic)
  }

  queueUpdateHitTestPic = () => {
    this.events.setTask('update-hit-test-pic', this.updateHitTestPic)
  }

  updatePic = () => {
    const { svg } = this.props
    svg.pic.loadSvg(this.refs.svgRef)
  }

  updateHitTestPic = () => {
    const { svg } = this.props
    svg.hitTestPic.loadSvg(this.refs.svgRef)
  }

  componentWillUnmount () {
    this.events.dispose()
  }

  rootClassNames = () => {
    const { forHitTest } = this.props
    return 'svg-reference-view ' + (forHitTest ? 'for-hit-test' : 'not-for-hit-test')
  }

  render () {
    const { svg, forHitTest } = this.props
    const { vectors, definitions } = svg
    return (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          id={ svg.id }
          width={ svg.getWidth() }
          height={ svg.getHeight() }
          className={ this.rootClassNames() }
          ref="svgRef">
          <defs>
            { definitions.map((definition) =>
              <DefinitionView
                key={ definition.id }
                definition={ definition }
              />)
            }
          </defs>
          <g>
            { vectors.map((vector) =>
              <VectorView
                key={ vector.id }
                vector={ vector }
                forHitTest={ forHitTest }
              />)
            }
          </g>
        </svg>
      </div>
    )
  }
}
