import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import LayoutItemView from './layout-item-view'

import './layout-container-view.scss'

@observer
export default class extends BaseComponent {
  layoutContainerStyle ({ width, height, layoutItems }, column) {
    let rowStyleStr = ''
    layoutItems.reverseEach((layoutItem) => {
      let rowHeight = undefined
      if (layoutItem.height === 'fill') {
        rowHeight = 'fill'
      }
      rowStyleStr += rowHeight === 'fill' ? '1fr ' : 'min-content '
    })
    return {
      gridTemplateRows: rowStyleStr,
      gridColumn: `${column} / ${column}`
    }
  }

  render () {
    const { layoutContainer } = this.props
    const { layoutItems } = layoutContainer
    const collectionLength = layoutItems.count()
    const column = this.props.column === undefined ? 1 : this.props.column
    return (
      <div className={`layout-container-view ${layoutContainer.id}`}
        style={ { ...this.layoutContainerStyle(layoutContainer, column) } }>
        { layoutItems.reverseMap((layoutItem) =>
          <LayoutItemView key={ layoutItem.id }
            layoutItem={ layoutItem }
            collectionLength={ collectionLength }
            zIndex={ layoutItem.collectionIndex } />
        )}
      </div>
    )
  }
}
