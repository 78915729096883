import Strokable from '../strokable/strokable'
import CloneStampOptionsView from './clone-stamp-options-view'
import Tex from '../../core/tex'
import BlendFilter from '../../core/filters/blend-filter'
import { MID_GRAY } from '../../constants/colors'
import { COMPOSITE_MODE } from '../../constants/composite-modes'
import { CURSOR } from '../../constants/cursors'
import { wgl } from '../../core/wgl'

const blendFilter = new BlendFilter()

export default class extends Strokable {
  constructor (options = {}) {
    super({ ...options, id: 'clone-stamp', name: 'Clone Stamp', settingsId: 'clone-stamp' })
    this.icon = 'wi-clone-stamp'
    this.clonePoint = undefined
    this.cloneVector = { x: 0, y: 0 }
    this.patternedStrokeTex = new Tex()
    this.info = 'Clone Stamp (S)'
  }

  getOptionsViewClass () { return CloneStampOptionsView }
  getOptionsViewClassName () { return 'clone-stamp-options-view' }

  hasCloneSrc () { return true }

  altBeginMovement ({ doc, p }) {
    this.clonePoint = p.clone()
  }

  getPatternedStrokeTex () {
    return this.patternedStrokeTex
  }

  patternizeStrokeTex () {
    const { strokeTex, cloneVector } = this
    blendFilter.blend({
      wgl,
      des: this.patternedStrokeTex,
      _src1: this.originalLayerTex,
      _src2: strokeTex,
      _offset1: [Math.round(cloneVector.x), Math.round(cloneVector.y)],
      _alpha: 1,
      _compositeMode: COMPOSITE_MODE.DESTINATION_IN
    })
  }

  getCustomCursors = () => {
    const cursors = super.getCustomCursors()
    if (this.showClonePoint !== true) {
      return cursors
    }
    return cursors.concat([
      {
        type: CURSOR.CROSSHAIR,
        size: 10,
        sizeIsZoomDependent: false,
        offset: [-this.cloneVector.x, -this.cloneVector.y],
        thickness: 1
      }
    ])
  }

  beginMovement ({ doc, p, e }) {
    if (!e.altKey && this.clonePoint === undefined) {
      alert("Hold the 'alt' key and click on a point in your image to select the clone start point")
      return
    }
    if (!e.altKey) {
      this.cloneVector.x = p.x - this.clonePoint.x
      this.cloneVector.y = p.y - this.clonePoint.y
    }
    super.beginMovement({ doc, p, e })
    const layer = doc.activeLayer
    this.patternedStrokeTex.resize(layer.size())
    if (this.stroking) { this.showClonePoint = true }
  }

  endMovement ({ doc }) {
    super.endMovement({ doc })
    this.showClonePoint = false
    this.packageFor('docs').queueDocRender()
  }

  dispose () {
    super.dispose()
    this.patternedStrokeTex.dispose()
  }
}
