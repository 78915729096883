import { inject, observer } from 'mobx-react'
import React from 'react'
import CanvasResizerAnchorSelector from './canvas-resizer-anchor-selector'
import { generateId } from '../../core/id'
import BaseComponent from '../../core/base-component'

import './canvas-resizer-view.scss'

const SETTINGS_ID = 'canvas-resizer'
const PERSISTABLE_STATE = { relative: false }

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.id = generateId()
    this.initSettings({ settingsId: SETTINGS_ID, persistableState: PERSISTABLE_STATE })
    this.state = {
      unitType: 'pixels',
      width: 0,
      height: 0,
      anchor: { x: 1, y: 1 },
      ...this.retrieveState()
    }
  }

  componentWillMount () {
    const doc = this.props.getDoc()
    let { width, height } = doc
    if (this.state.relative) {
      width = 0
      height = 0
    }
    this.setState({ width, height })
    this.originalSize = doc.size()
  }

  updateWidth = (e) => {
    const width = e.currentTarget.value
    this.setState({ width })
  }

  updateHeight = (e) => {
    const height = e.currentTarget.value
    this.setState({ height })
  }

  updateUnitType = (e) => {
    const { originalSize } = this
    const unitType = e.currentTarget.value
    const updatedState = { unitType }
    if (unitType === 'pixels') {
      updatedState.width = originalSize.width
      updatedState.height = originalSize.height
    }
    if (unitType === 'percent') {
      updatedState.width = 100
      updatedState.height = 100
    }
    if (this.state.relative) {
      updatedState.width = 0
      updatedState.height = 0
    }
    this.setState(updatedState)
  }

  unitSelectorTemplate = () => {
    const { state } = this
    return (
      <select value={ state.unitType } onChange={ this.updateUnitType }>
        <option value="pixels">Pixels</option>
        <option value="percent">Percent</option>
      </select>
    )
  }

  updateRelative = (e) => {
    const value = e.currentTarget.checked
    const { originalSize } = this
    const updatedState = {
      relative: value,
      width: value ? 0 : originalSize.width,
      height: value ? 0 : originalSize.height
    }
    this.setState(updatedState)
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { state, originalSize } = this
    const { onConfirm } = this.props
    let width = parseInt(state.width)
    let height = parseInt(state.height)
    if (state.relative && state.unitType === 'percent') {
      width += 100
      height += 100
    }
    if (state.relative && state.unitType === 'pixels') {
      width += originalSize.width
      height += originalSize.height
    }
    if (state.unitType === 'percent') {
      width = width / 100.0 * originalSize.width
      height = height / 100.0 * originalSize.height
    }
    onConfirm({ width, height, anchor: state.anchor })
  }

  updateAnchor = (anchor) => {
    this.setState({ anchor })
  }

  render () {
    const { state, unitSelectorTemplate } = this
    const { onCancel } = this.props
    return (
      <div className="canvas-resizer-view">
        <form className="row" onSubmit={ this.onSubmit }>
          <div className="canvas-resizer-view__inputs form-fields">
            <div className="form-fields">
              <div className="form-row">
                <label className="form-left">Width:</label>
                <div className="form-right">
                  <input type="text"
                    autoFocus="true"
                    className="char-5 mr-m"
                    value={ state.width }
                    onChange={ this.updateWidth } />
                  { unitSelectorTemplate() }
                </div>
              </div>
              <div className="form-row">
                <label className="form-left">Height:</label>
                <div className="form-right">
                  <input type="text"
                    className="char-5 mr-m"
                    value={ state.height }
                    onChange={ this.updateHeight } />
                  { unitSelectorTemplate() }
                </div>
              </div>
              <div className="form-row canvas-resizer-view__relative-checkbox-row">
                <div className="form-left">
                  <input type="checkbox"
                    id={ 'relative-checkbox-input-' + this.id }
                    checked={ state.relative }
                    onChange={ this.updateRelative } />
                </div>
                <label className="form-right"
                  htmlFor={ 'relative-checkbox-input-' + this.id }>Relative</label>
              </div>
              <div className="form-row">
                <label className="form-left align-top">Anchor:</label>
                <div className="form-right">
                  <CanvasResizerAnchorSelector anchor={ state.anchor }
                    originalSize={ this.originalSize }
                    newSize={ state }
                    onAnchorChange={ this.updateAnchor } />
                </div>
              </div>
            </div>
          </div>
          <div className="canvas-resizer-view__border-buttons">
            <button type="submit"
              className="border-button stretch mb-m">
              Ok
            </button>
            <button type="button"
              onClick={ onCancel }
              className="border-button stretch">
              Cancel
            </button>
          </div>
        </form>
      </div>
    )
  }
}
