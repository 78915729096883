import React from 'react'
import { observable, computed, action } from 'mobx'
import Item from '../item'
import Collection from '../collection'
import { computePathStr } from './vector-helpers'
import CornerPoint from './corner-point'
import CubicPoint from './cubic-point'
import { getMinMaxOfPoints } from '../calc'

export default class extends Item {
  @observable closedPath = true
  @observable stroke = undefined
  @observable strokeWidth = undefined
  @observable fill = undefined
  @observable visible = true
  @observable hitTestColor = '#0'
  @observable style = {}
  @observable cursor = 'default'

  constructor (options) {
    super(options)
    this.points = new Collection()
  }

  @computed get pathStr () {
    const str = computePathStr(this.points, this.closedPath)
    return str
  }

  @action setVisible (value) {
    if (this.visible === value) { return }
    this.visible = value
  }

  defaultAttributes () {
    return { fill: 'none', strokeWidth: 1 }
  }

  templateAttributes () {
    return ['stroke', 'strokeWidth', 'fill', 'style']
  }

  initializableAttributes () {
    return this.templateAttributes().concat(['closedPath', 'cursor'])
  }

  @action setClosedPath (value) {
    this.closedPath = value
  }

  pointAtIndex (index) {
    return this.points.itemAtIndex(index)
  }

  @action setCornerPoints (pointDataArr) {
    const pointArr = []
    for (let i = 0; i < pointDataArr.length; i++) {
      const cornerPoint = new CornerPoint()
      cornerPoint.setPosition(pointDataArr[i])
      pointArr.push(cornerPoint)
    }
    this.points.disposeAll()
    this.points.setItems(pointArr)
  }

  @action setCubicPoints (pointDataArr) {
    const pointArr = []
    for (let i = 0; i < pointDataArr.length; i++) {
      const cubicPoint = new CubicPoint()
      cubicPoint.setPosition(pointDataArr[i])
      pointArr.push(cubicPoint)
    }
    this.points.disposeAll()
    this.points.setItems(pointArr)
  }

  chamferPoint (index, radius) {
    const { points } = this
    const point = points.itemAtIndex(index)
    const prevIndex = index === 0 ? points.length - 1 : index - 1
    const nextIndex = index === points.length - 1 ? 0 : index + 1
    const prevPoint = points.itemAtIndex(prevIndex)
    const nextPoint = points.itemAtIndex(nextIndex)

    const prevPointDirVector = prevPoint.calc().subtract(point).adjustToLength(radius)
    const nextPointDirVector = nextPoint.calc().subtract(point).adjustToLength(radius)
    const newPoint1 = new CubicPoint()
    const newPointPosition1 = point.calc().add(prevPointDirVector)
    newPoint1.setPosition({
      x: newPointPosition1.x,
      y: newPointPosition1.y,
      c1x: newPointPosition1.x,
      c1y: newPointPosition1.y,
      c2x: point.x,
      c2y: point.y
    })
    const newPoint2 = new CubicPoint()
    const newPointPosition2 = point.calc().add(nextPointDirVector)
    newPoint2.setPosition({
      x: newPointPosition2.x,
      y: newPointPosition2.y,
      c1x: point.x,
      c1y: point.y,
      c2x: newPointPosition2.x,
      c2y: newPointPosition2.y
    })
    points.disposeItem(point)
    points.insertAtIndex(newPoint2, index)
    points.insertAtIndex(newPoint1, index)
  }

  getHitTestPropOverrides () {
    const { hitTestColor } = this
    return {
      fill: hitTestColor,
      stroke: hitTestColor
    }
  }

  getMinMax () {
    return getMinMaxOfPoints(this.points.toArray())
  }

  getTemplateProps () {
    const templateAttributes = this.templateAttributes()
    const props = {}
    for (const attr of templateAttributes) {
      props[attr] = this[attr]
      if (attr === 'style') {
        props[attr] = { ...props[attr] }
      }
    }
    props.d = this.pathStr
    if (this.collectionIndex !== undefined) {
      props.style.position = 'relative'
      props.style.zIndex = this.collectionIndex
      props.style.cursor = this.cursor
    }
    return props
  }

  getFinalTemplateProps (options = {}) {
    const { propOverrides } = options
    const props = this.getTemplateProps()
    const filteredProps = {}
    for (const key in props) {
      filteredProps[key] = props[key]
      if (propOverrides !== undefined && propOverrides[key]) {
        filteredProps[key] = propOverrides[key]
      }
      if (key === 'style') {
        filteredProps[key] = { ...filteredProps[key] }
      }
    }
    filteredProps.className = filteredProps.className === undefined ? '' : filteredProps.className
    filteredProps.className += ' vector'

    return filteredProps
  }

  getTemplate (options = {}) {
    if (this.visible === false) { return null }
    const props = this.getFinalTemplateProps(options)
    return (
      <path { ...props } />
    )
  }

  setSvg (svg) { this.svg = svg }
}
