import { globals } from './globals'
import Event from '../core/event'

globals.observables = {}

export function initObservable (namespace, key) {
  if (globals.observables[namespace] === undefined) {
    globals.observables[namespace] = {}
  }
  if (globals.observables[namespace][key] !== undefined) { return }
  globals.observables[namespace][key] = new Event()
}

export function triggerObservable (namespace, key) {
  initObservable(namespace, key)
  globals.observables[namespace][key].trigger()
}
