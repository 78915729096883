import BasePackage from '../../core/base-package'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'ui'
    this.settingsId = this.id
  }

  setFontSize (value, options = {}) {
    value = parseFloat(value)
    this.fontSize = value
    document.documentElement.style.fontSize = value + 'px'
    document.body.style.fontSize = value + 'px'
    if (options.skipSave !== true) {
      const settings = this.packageFor('settings')
      settings.store(this.settingsId, { fontSize: value })
    }
  }

  activate () {
    const settings = this.packageFor('settings')
    const storedState = settings.retrieveState(this.settingsId, { fontSize: 12 })
    this.setFontSize(storedState.fontSize, { skipSave: true })
  }

  resetFontSize () {
    this.setFontSize(12)
  }

  increaseFontSize () {
    this.setFontSize(this.fontSize + 0.5)
  }

  decreaseFontSize () {
    this.setFontSize(this.fontSize - 0.5)
  }
}
