import Filter from '../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'show-texture'
  }

  getFragmentShader () {
    return `
      uniform sampler2D src;

      void main () {
        outputColor = texColorFor(src);
      }
    `
  }
}
