import ToolPackage from '../../core/tool-package'
import Text from './text'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'text'
    this.tools = [Text]
  }
}
