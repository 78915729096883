import Filter from '../../filter'
import { getStoreValueAsColorSnippet } from '../snippets/store-value-as-color'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'horizontal-boundary'
  }

  // this method uses the result of linearize-vertical
  // it loops through a horizontal strip to find the minimum and maximum point that has data
  // the input is expected to be a texture of size(width: 2, height: 1)
  getFragmentShader () {
    return getStoreValueAsColorSnippet() + `
      uniform sampler2D _src;
      uniform vec2 _srcSize;

      void calcMin () {
        for (float x = 0.0; x < _srcSize.x; x += 1.0) {
          vec4 color = texColorAt(_src, _srcSize, vec2(x + 0.5, 0.5));
          if (color.a > 0.0) {
            storeValueAsColor(x);
            return;
          }
        }
        clearOutputColor();
      }

      void calcMax () {
        for (float x = _srcSize.x - 1.0; x >= 0.0; x -= 1.0) {
          vec4 color = texColorAt(_src, _srcSize, vec2(x + 0.5, 0.5));
          if (color.a > 0.0) {
            storeValueAsColor(x + 1.0);
            return;
          }
        }
        clearOutputColor();
      }

      void main () {
        float x = coordX();
        float y = coordY();

        if (y != 0.5) { return; }

        if (x == 0.5) { calcMin(); }
        if (x == 1.5) { calcMax(); }
      }
    `
  }
}
