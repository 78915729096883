import $ from 'jquery'
import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import Slider from './slider'
import classNames from 'classnames'
import { ensureInt, ensureFloat } from '../../core/calc'

import './slidable-input.scss'

@observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.state = {
      actualInputValue: undefined
    }
  }

  onInputChange = (value, source) => {
    const formatter  = this.props.skipRounding === true ? ensureFloat : ensureInt
    const { onChange, onInputChange, onSliderChange } = this.props
    const formattedValue = formatter(value, this.props.min)
    if (onChange !== undefined) { onChange(formattedValue) }
    if (onInputChange !== undefined && source === 'input') { onInputChange(formattedValue) }
    if (onSliderChange !== undefined && source === 'slider') { onSliderChange(formattedValue) }
    this.setState({ actualInputValue: value })
  }

  onArrowClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const slider = this.refs.slider
    const sliderIsActive = slider.classList.contains('active')
    $('.slidable-input__slider').removeClass('active')
    if (!sliderIsActive) {
      slider.classList.add('active')
      this.draggable.resyncElementPositionRatio()
    }
  }

  onInputBlur () {
    const { value, inputValue } = this.props
    this.setState({
      actualInputValue: value === undefined ? inputValue : value
    })
  }

  render () {
    const { sliderValue, inputValue, value, min, max, skipRounding, onChange, className, align } = this.props
    const { actualInputValue } = this.state

    let alignmentClass = 'right-aligned'
    if (align === 'left') {
      alignmentClass = 'left-aligned'
    }

    const displayValue = value === undefined ? inputValue : value

    return (
      <div className={ classNames('slidable-input', className) }>
        <div className="arrow-input-container">
          <input className="char-3 blur-on-submit slidable-input__input"
            type="number"
            value={ displayValue }
            onBlur={ () => this.onInputBlur() }
            onChange={ (e) => this.onInputChange(e.currentTarget.value, 'input') } />
          <div className="arrow center-all"
            onMouseDown={ (e) => { e.stopPropagation() } }
            onClick={ this.onArrowClick }>
            <div className="fa fa-angle-down arrow-icon"></div>
          </div>
        </div>
        <div className={ classNames('slidable-input__slider border-1 shadow-1', alignmentClass) }
             ref="slider">
          <Slider onChange={ (value) => this.onInputChange(value, 'slider') }
            min={ min }
            max={ max }
            value={ value === undefined ? sliderValue : value }
            skipRounding={ skipRounding }
            onDraggableCreated={ (draggable) => { this.draggable = draggable } }
            absorbMouseEvents={ true } />
        </div>
      </div>
    )
  }
}
