import Filter from '../../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'corner-presence'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform vec2 _srcSize;

      bool hasFilledRange () {
        for (float i = 0.0; i < _srcSize.x; i += 1.0) {
          for (float j = 0.0; j < _srcSize.y; j += 1.0) {
            vec4 color = texColorAt(_src, _srcSize, vec2(i, j));
            if (color.a > 0.0) {
              return true;
            }
          }
        }
        return false;
      }

      void main () {
        float x = coordX();
        float y = coordY();

        if (x != 0.5 || y != 0.5 || !hasFilledRange()) {
          outputColor = EMPTY_COLOR;
          return;
        }

        outputColor = vec4(0.0, 0.0, 0.0, 1.0);
      }
    `
  }
}
