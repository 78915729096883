import { observer } from 'mobx-react'
import { render } from 'react-dom'
import React from 'react'
import Dom from './dom'
import BaseComponent from './base-component'
import Collection from './collection'
import SvgReferenceView from './svg-reference-view'
import { globals } from '../globals/globals'

// use shared svgs to reduce total number of elements
// when an svg is created it is added to a hidden svg container
// actual displayed svgs reference the svgs in this container
const SVG_CONTAINER = Dom.getElementById('svg-container')

@observer
class SvgReferenceContainer extends BaseComponent {
  render () {
    const { svgs } = this.props
    return (
      <div className="svg-container-view">
        { svgs.map((svg) => {
          return (
            <div key={ svg.id }>
              <SvgReferenceView svg={ svg } />
              {svg.shouldGenerateHitTestPic && <SvgReferenceView svg={ svg } forHitTest={ true } />}
            </div>
          ) })
        }
      </div>
    )
  }
}

const svgContainerView = React.createElement(SvgReferenceContainer, { svgs: globals.svgs })
render(svgContainerView, SVG_CONTAINER)
