import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import { generateId } from '../../core/id'

import './image-resizer-view.scss'

const SETTINGS_ID = 'image-resizer'
const PERSISTABLE_STATE = { constrainAspectRatio: true }

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.id = generateId()
    this.initSettings({ settingsId: SETTINGS_ID, persistableState: PERSISTABLE_STATE })
    this.state = { unitType: 'pixels', width: 0, height: 0, ...this.retrieveState() }
  }

  componentWillMount () {
    const doc = this.props.getDoc()
    this.setState({ width: doc.width, height: doc.height })
    this.originalSize = doc.size()
  }

  updateConstrainAspectRatio = (e) => {
    const value = e.currentTarget.checked
    const { originalSize } = this
    const updatedState = { constrainAspectRatio: value }
    if (value === true) {
      updatedState.width = originalSize.width
      updatedState.height = originalSize.height
    }
    this.setState(updatedState)
  }

  updateWidth = (e) => {
    const { state, originalSize } = this
    const width = e.currentTarget.value
    const dimensions = { width }
    if (state.constrainAspectRatio && state.unitType === 'pixels') {
      dimensions.height = parseInt(width / parseFloat(originalSize.width) * originalSize.height)
    }
    if (state.constrainAspectRatio && state.unitType === 'percent') {
      dimensions.height = parseInt(dimensions.width)
    }
    this.setState(dimensions)
  }

  updateHeight = (e) => {
    const { state, originalSize } = this
    const height = e.currentTarget.value
    const dimensions = { height }
    if (state.constrainAspectRatio && state.unitType === 'pixels') {
      dimensions.width = parseInt(height / parseFloat(originalSize.height) * originalSize.width)
    }
    if (state.constrainAspectRatio && state.unitType === 'percent') {
      dimensions.width = parseInt(dimensions.height)
    }
    this.setState(dimensions)
  }

  updateUnitType = (e) => {
    const { originalSize } = this
    const unitType = e.currentTarget.value
    const updatedState = { unitType }
    if (unitType === 'pixels') {
      updatedState.width = originalSize.width
      updatedState.height = originalSize.height
    }
    if (unitType === 'percent') {
      updatedState.width = 100
      updatedState.height = 100
    }
    this.setState(updatedState)
  }

  unitSelectorTemplate = () => {
    const { state } = this
    return (
      <select value={ state.unitType } onChange={ this.updateUnitType }>
        <option value="pixels">Pixels</option>
        <option value="percent">Percent</option>
      </select>
    )
  }

  onSubmit = (e) => {
    e.preventDefault()
    const { state, originalSize } = this
    const { onConfirm } = this.props
    let width = parseInt(state.width)
    let height = parseInt(state.height)
    if (state.unitType === 'percent') {
      width = (width / 100.0) * originalSize.width
      height = (height / 100.0) * originalSize.height
    }
    onConfirm({ width, height })
  }

  render () {
    const { state, unitSelectorTemplate } = this
    const { onCancel } = this.props
    return (
      <div className="image-resizer-view">
        <form className="row" onSubmit={ this.onSubmit }>
          <div className="image-resizer-view__inputs">
            <div className="form-fields">
              <div className="form-row">
                <label className="form-left">Width:</label>
                <div className="form-right">
                  <input type="number"
                    autoFocus="true"
                    className="char-5 mr-m"
                    value={ state.width }
                    onChange={ this.updateWidth } />
                  { unitSelectorTemplate() }
                </div>
              </div>
              <div className="form-row">
                <label className="form-left">Height:</label>
                <div className="form-right">
                  <input type="number"
                    className="char-5 mr-m"
                    value={ state.height }
                    onChange={ this.updateHeight } />
                  { unitSelectorTemplate() }
                </div>
              </div>
              <div className="form-row">
                <div className="form-left">
                  <input type="checkbox"
                    id={ 'constrain-aspect-ratio-input-' + this.id }
                    checked={ state.constrainAspectRatio }
                    onChange={ this.updateConstrainAspectRatio }
                  />
                </div>
                <label className="form-right"
                  htmlFor={ 'constrain-aspect-ratio-input-' + this.id }>
                  Preserve aspect ratio
                </label>
              </div>
            </div>
          </div>
          <div className="image-resizer-view__border-buttons">
            <button type="submit"
              className="border-button stretch mb-m">
              Ok
            </button>
            <button type="button"
              onClick={ onCancel }
              className="border-button stretch">
              Cancel
            </button>
          </div>
        </form>
      </div>
    )
  }
}
