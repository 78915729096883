import { action } from 'mobx'
import React from 'react'
import Tool from '../../core/tool'
import MoveOptionsView from './move-options-view'
import Transformer from '../../core/transformer'
import Point from '../../core/point'

export default class extends Tool {
  constructor (options) {
    super(options)
    if (options.id === undefined) { this.id = 'move' }
    if (options.name === undefined) { this.name = 'Move' }
    if (options.icon === undefined) { this.icon = 'wi-move' }
    this.validLayerTypes = { raster: true, text: true }
    this.selectionType = undefined
    this.transformer = new Transformer()
    this.info = 'Move (V)'
  }

  activate () {
    this.optionsView = this.createOptionsView()
  }

  createOptionsView () {
    return React.createElement(MoveOptionsView, {
      updateSelectionType: (value) => {
        this.selectionType = value
      }
    })
  }

  @action beginMovement = ({ doc, p }) => {
    if (this.selectionType === 'objects') {
      const clickedLayer = doc.layerAtPoint(p)
      if (clickedLayer !== undefined && clickedLayer.id !== doc.activeLayer.id) {
        doc.layerables.setActiveId(clickedLayer.id, { autoSelect: true })
      }
    }
    const layer = doc.activeLayer
    if (layer.isTextType()) {
      layer.beginTransform()
    } else {
      this.transformer.beginMovement({ doc, p, mode: 'move' })
    }
  }

  getCursorType = () => {
    return 'move'
  }

  continueMovement = ({ doc, e, p, movementDimensions }) => {
    const layer = doc.activeLayer
    if (layer.isTextType()) {
      layer.transform({ translate: movementDimensions })
      layer.touch()
    } else {
      this.transformer.continueMovement({ doc, e, p, movementDimensions, mode: 'move' })
    }

    this.queueDocRender({ mode: 'layersUpdated' })
  }

  endMovement ({ doc, p }) {
    const layer = doc.activeLayer
    if (!layer.isTextType()) {
      this.transformer.endMovement({ doc })
    }
    doc.recordVersion({ name: 'Move' })
  }

  handleKey ({ key, doc, shift }) {
    if (doc === undefined) { return }
    const layer = doc.activeLayer
    if (layer === undefined) { return }

    this.beginMovement({ doc, p: new Point({ x: 0, y: 0 }) })

    const translate = new Point({ x: 0, y: 0 })
    const amount = shift ? 10 : 1
    if (key === 'up') { translate.y = -amount }
    if (key === 'down') { translate.y = amount }
    if (key === 'left') { translate.x = -amount }
    if (key === 'right') { translate.x = amount }

    this.continueMovement({ doc, p: translate, movementDimensions: translate })
    if (!layer.isTextType()) {
      this.transformer.endMovement({ doc })
    }
  }
}
