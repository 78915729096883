import React from 'react'
import FilterPackage from '../../core/filter-package'
import BlackWhiteView from './black-white-view'
import BlackWhiteFilter from './black-white-filter'

export default class extends FilterPackage {
  constructor (options) {
    super(options)
    this.id = 'black-white'
    this.filter = new BlackWhiteFilter()
    this.filterOptions = {}
    this.resetFilterOptions()
  }

  getName () {
    return 'Black / White'
  }

  getViewClass () {
    return BlackWhiteView
  }

  hasInitialFilterValue () { return true }

  resetFilterOptions () {
    this.filterOptions.treshold = 0.5
  }
}
