import { inject } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './tool-colors-view.scss'

@inject('app')
export default class extends BaseComponent {
  render () {
    const { foregroundColor, backgroundColor,
      foregroundColorClicked, backgroundColorClicked,
      resetClicked, swapClicked } = this.props
    const { ColorView } = this.packageFor('colors')
    return (
      <div className="tool-colors-view">
        <div className="tool-colors-view__options">
          <div className="tool-colors-view__reset"
               onClick={ () => resetClicked() }
               title="Reset colors to black and white (D)">
            <div className="tool-colors-view__reset-black"></div>
            <div className="tool-colors-view__reset-white"></div>
          </div>
          <div className="tool-colors-view__swap"
               onClick={ () => swapClicked() }
               title="Swap foreground and background colors (X)">
            <div className="fa fa-long-arrow-left tool-colors-view__swap-left"></div>
            <div className="fa fa-long-arrow-down tool-colors-view__swap-down"></div>
          </div>
        </div>
        <div className="tool-colors-view__colors" title="Set color (C)">
          <ColorView color={ foregroundColor }
            className="foreground-color"
            onClick={ foregroundColorClicked } />
          <ColorView color={ backgroundColor }
            className="background-color"
            onClick={ backgroundColorClicked } />
        </div>
      </div>
    )
  }
}
