import BasePackage from '../../core/base-package'
import MouseTrap from 'mousetrap'
import Event from '../../core/event'
import Command from '../../core/command'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'shortcuts'
    this.shortcutReference = {}
    this.registerShortcuts()
    this.onEnter = new Event()
    this.onEsc = new Event()
    this.onAlt = new Event()
    this.onMeta = new Event()
  }

  registerShortcuts () {
    MouseTrap.reset()
    const keymap = {
      'enter': 'shortcuts:enter-key-pressed',
      'alt': 'shortcuts:alt-key-pressed',
      'meta': 'shortcuts:meta-key-pressed',
      'alt+meta': 'shortcuts:alt-meta-key-pressed',
      'meta+alt': 'shortcuts:alt-meta-key-pressed',
      'esc': 'shortcuts:esc-key-pressed',
      '[': 'docs:show-previous',
      ']': 'docs:show-next',
      '/': 'docs:select-previous-layer',
      '\'': 'docs:select-next-layer',

      'meta+=': 'docs:zoom-in',
      '=': 'docs:zoom-in',
      'meta+-': 'docs:zoom-out',
      '-': 'docs:zoom-out',
      'meta+0': 'docs:zoom-to-fit',
      '0': 'docs:zoom-to-fit',

      'alt+i': 'image-resizer:show-image-resizer-dialog',
      'alt+c': 'canvas-resizer:show-canvas-resizer-dialog',

      'alt+=': 'ui:increase-font-size',
      'alt+-': 'ui:decrease-font-size',
      'alt+0': 'ui:reset-font-size',

      'shift+n': 'docs:add-raster-layer',
      'backspace': 'tools.marquee:delete-selection',
      'meta+backspace': 'tools.marquee:background-fill',
      'alt+backspace': 'tools.marquee:foreground-fill',
      'meta+z': 'docs:undo',
      'z': 'docs:undo',
      'meta+shift+z': 'docs:redo',
      'shift+z': 'docs:redo',
      'alt+s': 'docs:save-doc',
      'shift+s': 'saver:quick-export',
      'shift+w': 'docs:close',
      'alt+shift+w': 'docs:close-all',

      'alt+n': 'doc-creator:show-doc-creator-dialog',
      'alt+o': 'doc-opener:show-doc-opener-dialog',

      'meta+j': 'layers:duplicate-layer',
      'meta+e': 'layers:merge-down',
      'meta+shift+e': 'layers:merge-visible',

      'alt+b': 'filters:show-filter-in-dialog(brightness-and-contrast)',
      'meta+u': 'filters:show-filter-in-dialog(hue-saturation)',
      'meta+b': 'filters:show-filter-in-dialog(color-balance)',
      'meta+i': 'filters:apply-filter(invert)',

      'v': 'tools:rotate-tool(move)',
      'm': 'tools:rotate-tool(marquee)',
      'w': 'tools: rotate-tool(magic-wand)',
      'b': 'tools:rotate-tool(brush)',
      's': 'tools:rotate-tool(clone-stamp)',
      'i': 'tools:rotate-tool(pipette)',
      'e': 'tools:rotate-tool(eraser)',
      'g': 'tools:rotate-tool(fill)',
      't': 'tools:rotate-tool(text)',
      'r': 'tools:rotate-tool(raster-transform)',
      'd': 'tool-colors:reset-colors',
      'x': 'tool-colors:swap-colors',
      'up': 'tools:up-key',
      'down': 'tools:down-key',
      'left': 'tools:left-key',
      'right': 'tools:right-key',
      'shift+up': 'tools:shift-up-key',
      'shift+down': 'tools:shift-down-key',
      'shift+left': 'tools:shift-left-key',
      'shift+right': 'tools:shift-right-key',

      'c': 'tool-colors:show-foreground-picker',

      'meta+c': 'clipboard:copy',
      'meta+x': 'clipboard:cut',
      'meta+v': 'clipboard:paste',

      'meta+s': 'saver:show-saver-dialog',

      'meta+a': 'tools.marquee:select-all',
      'meta+shift+i': 'tools.marquee:invert-selection',
      'meta+d': 'tools.marquee:clear-selection'
    }
    for (const shortcutKey in keymap) {
      const commandString = keymap[shortcutKey]
      const command = new Command({ commandString })
      this.registerShortcut(shortcutKey, command)
      const existingRef = this.shortcutReference[commandString]
      if (existingRef !== undefined && existingRef.length < shortcutKey.length) {
        continue
      }
      this.shortcutReference[commandString] = shortcutKey
    }
  }

  enterKeyPressed = () => { this.onEnter.trigger() }
  escKeyPressed = () => { this.onEsc.trigger() }
  altKeyPressed = () => { this.onAlt.trigger() }
  metaKeyPressed = () => { this.onMeta.trigger() }
  altMetaKeyPressed = () => { this.onAlt.trigger(); this.onMeta.trigger() }

  registerShortcut (shortcutKey, command) {
    MouseTrap.bind(shortcutKey, (e) => {
      e.preventDefault()
      command.execute(this.app)
    })
  }
}
