import BaseLayout from './base-layout'
import Collection from '../../core/collection'
import LayoutItem from './layout-item'

export default class extends BaseLayout {
  constructor (options) {
    super(options)
    this.layoutItems = new Collection({ syncItemIndexes: true })
  }

  addLayoutItem = (options) => {
    return this.layoutItems.add(new LayoutItem(options))
  }

  findOrAddLayoutItem = (id, options) => {
    return this.layoutItems.findOrAdd(id, () => {
      const layoutItem = new LayoutItem(options)
      layoutItem.id = id
      return layoutItem
    })
  }

  removeLayoutItem = (id) => {
    return this.layoutItems.remove({ id })
  }
}
