import { inject, observer } from 'mobx-react'
import { action } from 'mobx'
import React from 'react'
import BaseComponent from '../../core/base-component'
import Svg from '../../core/svg'
import UpTriangle from '../../core/vectors/up-triangle'
import Draggable from '../../core/draggable'
import SvgView from '../../core/svg-view'
import nbr from '../../lib/nbr'

import './slider.scss'

@inject('app') @observer
export default class extends BaseComponent {
  syncMarkerPosition = (value) => {
    const { min, max } = this.props
    const constrainedValue = nbr(value).withinRange(min, max) - min
    const range = max - min
    this.draggable.setElementPositionRatio({ x: constrainedValue / parseFloat(range) })
  }
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.value !== undefined && nextProps.value !== this.prevValue) {
      this.syncMarkerPosition(nextProps.value)
    }
  }
  updateValue = () => {
    const { onChange, min, max, skipRounding } = this.props
    const { elementPositionRatio } = this.draggable
    const range = max - min
    let positionValue = elementPositionRatio.x * range
    if (skipRounding !== true) { positionValue = Math.round(positionValue) }
    const value = min + positionValue
    if (value === this.prevValue) { return }
    this.prevValue = value
    if (onChange) { onChange(value) }
  }
  componentWillMount () {
    this.marker = new Svg()
  }
  componentDidMount () {
    this.initMarker()
    this.syncMarkerPosition(this.props.value)
  }
  componentWillUnmount () {
    this.draggable.dispose()
  }
  @action initMarker () {
    const { marker } = this
    const markerWidth = 13
    const markerHeight = 8
    marker.resize({ width: markerWidth, height: markerHeight })
    const upTriangle = new UpTriangle({
      topLeft: { x: 0, y: 0 },
      width: markerWidth,
      height: markerHeight,
      stroke: 'black',
      fill: 'white'
    })
    marker.addVector(upTriangle)
    const triangleRadius = 8
    upTriangle.chamferPoint(1, triangleRadius)
    upTriangle.chamferPoint(3, triangleRadius)

    const markerLayerContainer = this.refs.marker
    markerLayerContainer.style.width = `${markerWidth}px`
    markerLayerContainer.style.height = `${markerHeight}px`

    const sliderRef = this.refs.root
    this.draggable = new Draggable({
      element: markerLayerContainer,
      elementToCursorAlignment: 'centered',
      clickContainer: sliderRef,
      dragAxis: 'x',
      absorbMouseEvents: this.props.absorbMouseEvents
    })
    this.draggable.onContinueMovement = () => { this.updateValue() }
    const { onDraggableCreated } = this.props
    if (onDraggableCreated) { onDraggableCreated(this.draggable) }
  }
  render () {
    return (
      <div className="slider" ref="root">
        <div className="slider__bar" ref="bar">
          <div className="slider__marker" ref="marker">
            <SvgView svg={ this.marker } />
          </div>
        </div>
      </div>
    )
  }

  static defaultProps = {
    min: 0,
    max: 100,
    value: 0
  }
}
