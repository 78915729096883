import Filter from '../../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'linearize-vertical'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform vec2 _srcSize;

      bool currentRangeHasData () {
        float x = coordX();
        for (float y = 0.0; y < _srcSize.y; y += 1.0) {
          vec4 color = texColorAt(_src, _srcSize, vec2(x, y + 0.5));
          if (color.a > 0.0) { return true; }
        }

        return false;
      }

      void main () {
        float y = coordY();

        if (y != 0.5) { return; }

        if (!currentRangeHasData()) {
          clearOutputColor();
          return;
        }

        outputColor = BLACK;
      }
    `
  }
}
