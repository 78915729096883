import { action } from 'mobx'
import Item from '../item'
import { generateId } from '../id'

export default class extends Item {
  constructor (options) {
    super(options)
    this.storedData = {}
    this.storedResources = {}
    this.childIdMap = {}
  }

  initializableAttributes () {
    return ['name', 'combinable']
  }

  @action processItem ({ item, prevVersion }) {
    const { data, resources, childItems } = item.serialize({ prevVersion, forStorage: true })
    this.store({ id: item.id, data, resources })
    if (childItems === undefined) { return }

    const idMap = {}
    for (const childKey in childItems) {
      const child = childItems[childKey]
      if (Array.isArray(child) === false) {
        idMap[childKey] = child.id
        this.processItem({ item: child, prevVersion })
      } else {
        for (const arrayItem of child) {
          idMap[childKey] = childKey
          this.processItem({ item: arrayItem, prevVersion })
        }
      }
    }
    this.childIdMap[item.id] = idMap
  }

  @action restoreItem ({ item, id }) {
    if (id === undefined) { id = item.id }

    const { data, resources } = this.retrieve({ id })
    item.restore({ data, resources, version: this })

    const idMap = this.childIdMap[id]
    const { childItems } = item.serialize({ forStorage: false })

    if (idMap === undefined || childItems === undefined) { return }

    for (const childKey in idMap) {
      const child = childItems[childKey]
      if (Array.isArray(child) === false) {
        const childId = idMap[childKey]
        this.restoreItem({ item: child, id: childId })
      } else {
        for (const arrayItem of child) {
          this.restoreItem({ item: arrayItem, id: arrayItem.id })
        }
      }
    }

    if (item.afterRestore !== undefined) {
      item.afterRestore()
    }
  }

  store ({ id, data, resources }) {
    const storedInfo = this.retrieve({ id })
    Object.assign(storedInfo.data, data)
    this.storedData[id] = storedInfo.data
    if (resources === undefined || Object.keys(resources).length === 0) { return }

    for (const resourceKey in resources) {
      const resource = resources[resourceKey]
      if (resource.id === undefined) { resource.id = generateId() }
      storedInfo.resources[resourceKey] = resource
    }
    this.storedResources[id] = storedInfo.resources
  }

  forEachResource (callback) {
    for (const id in this.storedResources) {
      const storedInfo = this.retrieve({ id })
      for (const resourceKey in storedInfo.resources) {
        const resource = storedInfo.resources[resourceKey]
        callback(resource)
      }
    }
  }

  getResourceArray () {
    const resourceArray = []
    this.forEachResource((resource) => {
      resourceArray.push(resource)
    })
    return resourceArray
  }

  retrieve ({ id }) {
    const itemData = this.storedData[id]
    const itemResources = this.storedResources[id]
    return {
      data: itemData === undefined ? {} : itemData,
      resources: itemResources === undefined ? {} : itemResources,
      version: this
    }
  }
}
