import { inject } from 'mobx-react'
import { action } from 'mobx'
import React from 'react'
import BaseComponent from '../../core/base-component'
import Draggable from '../../core/draggable'
import Svg from '../../core/svg'
import LeftTriangle from '../../core/vectors/left-triangle'
import RightTriangle from '../../core/vectors/right-triangle'
import EventList from '../../core/event-list'
import SvgView from '../../core/svg-view'
import el from '../../lib/el'

import './hue-picker.scss'

@inject('app')
export default class extends BaseComponent {
  updateHue = () => {
    const { onHueUpdated } = this.props
    const { elementPositionRatio } = this.draggable
    const hue = Math.round((1 - elementPositionRatio.y) * 360)
    this.hue = hue
    if (onHueUpdated === undefined) { return }
    onHueUpdated(hue)
  }
  syncMarkerPosition = () => {
    const { newColor } = this.props
    this.draggable.setElementPositionRatio({ y: (360 - newColor.h) / 360.0 })
  }
  @action initMarker () {
    const colorStrip = this.refs.root
    const markerLayerContainer = this.refs.marker
    const stripWidth = el(colorStrip).outerWidth()
    const borderWidth = 1
    const markerHeight = 14
    const triangleHeight = markerHeight
    const triangleWidth = 8
    const markerWidth = stripWidth + triangleWidth * 2 + borderWidth * 2
    const { marker } = this
    marker.resize({ width: markerWidth, height: markerHeight })
    const leftTriangle = new LeftTriangle({
      topLeft: { x: 0, y: 0 },
      width: triangleWidth,
      height: triangleHeight,
      stroke: 'black',
      fill: 'white'
    })
    const triangleRadius = 8
    leftTriangle.chamferPoint(0, triangleRadius)
    leftTriangle.chamferPoint(3, triangleRadius)
    marker.addVector(leftTriangle)
    const rightTriangle = new RightTriangle({
      topLeft: { x: markerWidth - triangleWidth, y: 0 },
      width: triangleWidth,
      height: triangleHeight,
      stroke: 'black',
      fill: 'white'
    })
    rightTriangle.chamferPoint(0, triangleRadius)
    rightTriangle.chamferPoint(3, triangleRadius)
    marker.addVector(rightTriangle)

    markerLayerContainer.style.left = `${-(triangleWidth + borderWidth)}px`
    markerLayerContainer.style.width = `${markerWidth}px`
    markerLayerContainer.style.height = `${markerHeight}px`

    this.draggable = new Draggable({
      element: markerLayerContainer,
      elementToCursorAlignment: 'centered',
      clickContainer: colorStrip,
      dragAxis: 'y'
    })
  }
  componentWillMount () {
    this.marker = new Svg()
  }
  componentDidMount () {
    this.initMarker()
    this.draggable.onContinueMovement = () => { this.updateHue() }
    const { newColor } = this.props
    this.events = new EventList()
    this.events.addObserver(newColor, 'h', () => {
      if (newColor.h === this.hue) { return }
      this.syncMarkerPosition()
    })
    this.events.addDomEvent(this.refs.root, 'resize', () => this.syncMarkerPosition())
    this.syncMarkerPosition()
  }
  componentWillUnmount () {
    this.draggable.dispose()
    this.events.dispose()
  }
  render () {
    return (
      <div className="hue-picker fill-parent" ref="root">
        <div className="hue-picker__marker" ref="marker">
          <SvgView svg={ this.marker } />
        </div>
      </div>
    )
  }
}
