import React from 'react'
import FilterPackage from '../../core/filter-package'
import BrightnessAndContrastView from './brightness-and-contrast-view'
import BrightnessAndContrastFilter from './brightness-and-contrast-filter'

export default class extends FilterPackage {
  constructor (options) {
    super(options)
    this.id = 'brightness-and-contrast'
    this.filter = new BrightnessAndContrastFilter()
    this.filterOptions = {}
    this.resetFilterOptions()
  }

  getName () {
    return 'Brightness / Contrast'
  }

  getViewClass () {
    return BrightnessAndContrastView
  }

  resetFilterOptions () {
    this.filterOptions.brightness = 0
    this.filterOptions.contrast = 0
  }
}
