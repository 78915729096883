import $ from 'jquery'
import BasePackage from '../../core/base-package'
import Slider from './slider'
import InputSliderRow from './input-slider-row'
import SlidableInput from './slidable-input'
import EventList from '../../core/event-list'
import { globals } from '../../globals/globals'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'sliders'
    this.Slider = Slider
    this.InputSliderRow = InputSliderRow
    this.SlidableInput = SlidableInput
    this.events = new EventList()
  }

  activate () {
    const menuSliderSelector = '.slidable-input__slider'
    this.app.addMenuSelector(menuSliderSelector)
    this.events.addDomEvent(window, 'mousedown', () => {
      $(menuSliderSelector).removeClass('active')
    })
    this.events.addEvent(globals.observables.layers.mousedown, () => {
      $(menuSliderSelector).removeClass('active')
    })
    const shortcuts = this.packageFor('shortcuts')
    shortcuts.onEsc.subscribe(() => {
      $(menuSliderSelector).removeClass('active')
    })
  }

  dispose () {
    super.dispose()
    this.events.dispose()
  }
}
