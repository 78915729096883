import { observable, action } from 'mobx'
import React from 'react'
import BasePackage from '../../core/base-package'
import ColorPickerView from './color-picker-view'
import Color from '../../core/color'
import Event from '../../core/event'

export default class extends BasePackage {
  @observable viewOptions = { dynamicUpdate: false }

  constructor (options) {
    super(options)
    this.id = 'color-picker'
    this.currentColor = new Color()
    this.newColor = new Color({ r: 255, g: 150, b: 250 })
    this.colorRef = undefined
    this.colorWasPicked = new Event()
  }

  activate = () => {
    const { currentColor, newColor,
      cancelColorPick, pickColorFromDialog } = this
    const colorPickerView = React.createElement(ColorPickerView, {
      viewOptions: this.viewOptions,
      onConfirm: pickColorFromDialog,
      onCancel: cancelColorPick,
      updateColorRef: (hsv) => { this.colorRef.setHsv(hsv) },
      currentColor,
      newColor
    })
    this.colorPickerDialog = this.createDialog(colorPickerView, {
      name: 'Color Picker',
      dialogSettingsId: 'color-picker:color-picker-dialog',
      onConfirm: pickColorFromDialog,
      onCancel: cancelColorPick
    })
  }

  pickColorFromDialog = () => {
    this.colorRef.setRgb(this.newColor.rgb)
    this.colorWasPicked.trigger()
    this.hideColorPickerDialog()
  }

  cancelColorPick = () => {
    if (this.viewOptions.dynamicUpdate === true) {
      this.colorRef.setRgb(this.currentColor.rgb)
    }
    this.hideColorPickerDialog()
  }

  @action showColorPickerDialog = (color, options = {}) => {
    this.viewOptions.dynamicUpdate = options.dynamicUpdate === true
    this.colorRef = color
    this.currentColor.setRgb(color.rgb)
    this.setNewColor(color)
    this.packageFor('tools').colorPickerMode = true
    this.colorPickerDialog.show()
  }

  hideColorPickerDialog = () => {
    this.packageFor('tools').colorPickerMode = false
    this.colorPickerDialog.hide()
  }

  setNewColor = (color) => {
    this.newColor.setHsv(color.hsv)
  }
}
