import _ from 'lodash'
import Item from './item'
import Point from './point'
import { getCorners } from './rect-helpers'
import { getMinMaxOfPoints } from './calc'

export const CORNERS = ['topLeft', 'top', 'topRight', 'right', 'bottomRight', 'bottom', 'bottomLeft', 'left']
export const QUAD_CORNERS = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight']
export const SIDES = ['top', 'left', 'bottom', 'right']
export const OPPOSITE_CORNERS = {
  top: 'bottom',
  left: 'right',
  bottom: 'top',
  right: 'left',
  topLeft: 'bottomRight',
  bottomRight: 'topLeft',
  topRight: 'bottomLeft',
  bottomLeft: 'topRight'
}

export default class SimpleQuad extends Item {
  constructor (options = {}) {
    super(options)
    this.start = undefined
    this.end = undefined
    this.topLeft = new Point({ name: 'topLeft' })
    this.topRight = new Point({ name: 'topRight' })
    this.bottomLeft = new Point({ name: 'bottomLeft' })
    this.bottomRight = new Point({ name: 'bottomRight' })
    this.top = new Point({ name: 'top' })
    this.bottom = new Point({ name: 'bottom' })
    this.left = new Point({ name: 'left' })
    this.right = new Point({ name: 'right' })
    this.center = new Point({ name: 'center' })
    this.useIntegers = options.useIntegers
    this.points = [this.topLeft, this.topRight, this.bottomLeft, this.bottomRight,
      this.top, this.bottom, this.left, this.right]
  }

  clone () {
    const quad = new SimpleQuad()
    quad.setCorners(this.getCorners())
    return quad
  }

  setCorners ({ topLeft, topRight, bottomLeft, bottomRight, start, end }) {
    if (start !== undefined && end !== undefined) {
      topLeft = start
      bottomRight = end
      topRight = { x: end.x, y: start.y }
      bottomLeft = { x: start.x, y: end.y }
    }
    const { useIntegers } = this
    if (topLeft) { this.topLeft.setXy({ ...topLeft, useIntegers }) }
    if (topRight) { this.topRight.setXy({ ...topRight, useIntegers }) }
    if (bottomLeft) { this.bottomLeft.setXy({ ...bottomLeft, useIntegers }) }
    if (bottomRight) { this.bottomRight.setXy({ ...bottomRight, useIntegers }) }
    this.updateData()
  }

  translate (p) {
    this.topLeft.$add(p)
    this.topRight.$add(p)
    this.bottomLeft.$add(p)
    this.bottomRight.$add(p)
    this.updateData()
  }

  getOppositeCorner (corner) {
    return this[OPPOSITE_CORNERS[corner.name]]
  }

  nearestPoint (p, maxDistance) {
    let nearestPoint = this.points[0]
    let nearestDistance = this.points[0].distanceTo(p)
    for (const point of this.points) {
      const distance = point.distanceTo(p)
      if (distance < nearestDistance) {
        nearestDistance = distance
        nearestPoint = point
      }
    }
    if (nearestDistance > maxDistance) { return }
    return nearestPoint
  }

  getCorners () {
    const { topLeft, topRight, bottomLeft, bottomRight } = this
    return { topLeft, topRight, bottomLeft, bottomRight }
  }

  getCornerPoints () {
    const { topLeft, topRight, bottomLeft, bottomRight } = this
    return [topLeft, topRight, bottomLeft, bottomRight]
  }

  clear () {
    this.points.forEach((point) => point.zero())
    this.updateData()
  }

  updateData () {
    this.updatePoints()
    this.updateDimensions()
    this.touch()
  }

  updateDimensions () {
    this.dimensions = this.getDimensions()
  }

  updatePoints () {
    const { useIntegers } = this
    const { topLeft, topRight, bottomLeft, bottomRight, top, bottom, left, right } = this
    top.setXy({ ...topLeft.add(topRight).divideBy(2.0), useIntegers })
    bottom.setXy({ ...bottomLeft.add(bottomRight).divideBy(2.0), useIntegers })
    left.setXy({ ...topLeft.add(bottomLeft).divideBy(2.0), useIntegers })
    right.setXy({ ...topRight.add(bottomRight).divideBy(2.0), useIntegers })
    const corners = this.getCornerPoints()
    this.center.setXy({
      x: corners.reduce((total, p) => total + p.x, 0) / 4.0,
      y: corners.reduce((total, p) => total + p.y, 0) / 4.0,
    })
  }

  getDimensions () {
    const points = [this.topLeft, this.topRight, this.bottomRight, this.bottomLeft]
    const { min, max } = getMinMaxOfPoints(points)
    const lengths = this.points.map((point) => point.length())
    const { topLeft, bottomRight } = getCorners(this.topLeft, this.bottomRight)
    return {
      hasArea: topLeft.distanceTo(bottomRight) > 0,
      width: Math.abs(bottomRight.x - topLeft.x),
      height: Math.abs(bottomRight.y - topLeft.y),
      min,
      max
    }
  }

  rectangularize (p1, p2) {
    const { topLeft, bottomRight } = getCorners(p1, p2)

    const topRight = {
      x: bottomRight.x,
      y: topLeft.y
    }
    const bottomLeft = {
      x: topLeft.x,
      y: bottomRight.y
    }

    this.setCorners({ topLeft, topRight, bottomLeft, bottomRight })
  }

  serialize () {
    const info = super.serialize()
    const { topLeft, topRight, bottomLeft, bottomRight } = this
    return {
      data: {
        ...info.data,
      },
      childItems: {
        topLeft,
        topRight,
        bottomLeft,
        bottomRight
      }
    }
  }

  afterRestore () {
    this.updateData()
  }
}
