import React from 'react'
import FilterPackage from '../../core/filter-package'
import SharpenView from './sharpen-view'
import SharpenFilter from './sharpen-filter'
import { applyBlurFilter } from '../blur/blur-helpers'
import { wgl } from '../../core/wgl'

export default class extends FilterPackage {
  constructor (options) {
    super(options)
    this.id = 'sharpen'
    this.filter = new SharpenFilter()
    this.filterOptions = {}
    this.resetFilterOptions()
  }

  getName () {
    return 'Sharpen'
  }

  getViewClass () {
    return SharpenView
  }

  resetFilterOptions () {
    this.filterOptions.sharpenAmount = 1
    this.filterOptions.sharpenRadius = 1
  }

  hasInitialFilterValue () { return true }

  applyFilter () {
    const { sharpenAmount, sharpenRadius } = this.filterOptions
    applyBlurFilter({
      startTex: this.originalLayerTex,
      tempTex: this.layer.tex,
      blurAmount: sharpenRadius
    })
    wgl.applyFilter({
      filter: this.filter,
      data: {
        blurredTexture: this.layer.tex,
        originalTexture: this.originalLayerTex,
        sharpenAmount: sharpenAmount / 100.0
      },
      des: {
        texture: 'temp',
        width: this.layer.tex.width,
        height: this.layer.tex.height
      }
    })
    wgl.tempRenderedTex.swapTexture(this.layer.tex)
    this.queueDocRender({ mode: 'layersUpdated' })
  }
}
