import { inject, observer } from 'mobx-react'
import React from 'react'
import classNames from 'classnames'
import BaseComponent from '../../core/base-component'

const MODES = [
  { name: 'Move', id: 'move' },
  { name: 'Scale', id: 'scale' },
  { name: 'Rotate', id: 'rotate' },
  { name: 'Distort', id: 'distort' }
]

@inject('app') @observer
export default class extends BaseComponent {
  render () {
    const { props } = this
    const { setMode, docs } = props
    const doc = docs.getActiveItem()
    if (doc === undefined) { return null }
    return (
      <div className="raster-transform-options-view">
        { MODES.map((mode) => (
          <button className={ classNames('tool-options-button', 'mr-l', { active: mode.id === doc.transformation.mode }) }
                  key={ mode.id }
                  onClick={ () => setMode(mode.id) }>
            { mode.name }
          </button>
        ))}
        { (doc.transformation.mode !== 'move') &&
          <div className="inline-block">
            <div className="inline-block mr-l">|</div>
            <button className="tool-options-button mr-l" onClick={ () => props.applyTransformation() }>
              Apply
            </button>
          </div>
        }
      </div>
    )
  }
}
