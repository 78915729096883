import React from 'react'
import Tool from '../../core/tool'
import EventList from '../../core/event-list'
import Fabric from '../../core/fabric'
import MarqueeOptionsView from './marquee-options-view'
import { wgl } from '../../core/wgl'
import RectFilter from '../../core/filters/rect-filter'
import MoveFilter from '../../core/filters/move-filter'
import BlendFilter from '../../core/filters/blend-filter'
import Tex from '../../core/tex'
import { getCorners } from '../../core/rect-helpers'
import { COMPOSITE_MODE } from '../../constants/composite-modes'

const rectFilter = new RectFilter()
const moveFilter = new MoveFilter()
const blendFilter = new BlendFilter()

export default class extends Tool {
  constructor (options) {
    super(options)
    this.id = options.id
    this.name = options.name
    this.icon = options.icon
    this.events = new EventList()
    this.validLayerTypes = { raster: true }
    this.optionsView = React.createElement(MarqueeOptionsView, {})
  }

  getCursorType () { return 'crosshair' }

  beginMovement = ({ doc, element, e, p }) => {
    const clickedColor = doc.selectionTex.colorAtPoint(p)
    this.clickedColor = clickedColor
    this.startPoint = p.clone()
    this.selectionTexHadData = doc.selectionTex.hasData
    if (this.originalSelectionTex !== undefined) {
      this.originalSelectionTex.dispose()
      this.originalSelectionTex = undefined
    }
    if (!e.shiftKey && !e.altKey && clickedColor.a > 0) {
      this.moveSelection = true
      this.originalSelectionTex = doc.selectionTex.deepClone()
      return
    }
    if (!e.shiftKey && !e.altKey) {
      doc.selectionTex.clear()
    }
    this.selectionTexHadDataUpdated = doc.selectionTex.hasData
    this.originalSelectionTex = doc.selectionTex.deepClone()
    this.additive = !e.altKey
    if (this.createSelectionOnBegin()) {
      this.createSelection({ doc, p })
    }
    this.queueDocRender({ mode: 'maskUpdated' })
  }

  createSelectionOnBegin () { return false }

  getCompositeMode () {
    return this.additive ? COMPOSITE_MODE.NORMAL : COMPOSITE_MODE.SOURCE_OUT
  }

  createSelection ({ doc, start, end, p }) {
    const des = doc.selectionTex
    const _src = this.originalSelectionTex
    const _compositeMode = this.getCompositeMode()
    rectFilter.fillRect({ wgl, des, _src, _start: start, _end: end, _compositeMode })
  }

  _moveSelection ({ doc, p, originalSelectionTex }) {
    moveFilter.move({
      wgl,
      des: doc.selectionTex,
      _src: originalSelectionTex,
      _offset: [p.x, p.y]
    })
    this.queueDocRender({ mode: 'maskUpdated' })
    doc.selectionTex.calcHasData()
  }

  continueMovement ({ doc, element, e, movementRect, p }) {
    const rectDimensions = movementRect.getDimensions()
    if (this.moveSelection === true) {
      this._moveSelection({ doc, p: rectDimensions, originalSelectionTex: this.originalSelectionTex })
      return
    }
    if (rectDimensions && rectDimensions.area > 0) {
      let { start, end } = rectDimensions
      if (e.shiftKey && !this.selectionTexHadDataUpdated) {
        const { min, abs } = Math
        const length = min(abs(rectDimensions.x), abs(rectDimensions.y))
        start = this.startPoint
        end.x = this.startPoint.x + length * abs(rectDimensions.x) / rectDimensions.x
        end.y = this.startPoint.y + length * abs(rectDimensions.y) / rectDimensions.y
      }
      const { topLeft, bottomRight } = getCorners(start, end)
      this.createSelection({ doc, start: topLeft, end: bottomRight, p })
      doc.selectionTex.setHasData(true)
      this.queueDocRender({ mode: 'maskUpdated' })
    }
  }

  clearable () { return true }

  zeroMovementSelectable () {
    return false
  }

  endMovement ({ doc, element, movementDimensions, e }) {
    if (movementDimensions.area === 0 &&
        this.clearable() &&
        !e.shiftKey &&
        this.clickedColor.a > 0) {
      doc.selectionTex.clear()
    }
    doc.selectionTex.calcHasData()

    if (doc.selectionTex.hasData) {
      if (movementDimensions.area > 0 || this.zeroMovementSelectable()) {
        const versionName = this.moveSelection ? 'Move Marquee' : this.name
        doc.recordVersion({ name: versionName })
      }
    } else {
      if (this.selectionTexHadData && !doc.selectionTex.hasData) {
        doc.recordVersion({ name: 'Select None' })
      }
    }
    this.queueDocRender()
    this.moveSelection = false
  }

  selectAll () {
    const doc = this.getActiveDoc()
    doc.selectionTex.fill()
    this.queueDocRender()
    doc.recordVersion({ name: 'Select All' })
    doc.selectionTex.setHasData(true)
  }

  clearSelection () {
    const doc = this.getActiveDoc()
    doc.selectionTex.clear()
    this.queueDocRender()
    doc.recordVersion({ name: 'Select None' })
  }

  invertSelection () {
    const doc = this.getActiveDoc()
    const { selectionTex } = doc
    const tex = new Tex(selectionTex.size())
    tex.fill()
    blendFilter.blend({
      wgl,
      des: {
        texture: 'temp',
        width: tex.width,
        height: tex.height
      },
      _src1: tex,
      _src2: selectionTex,
      _compositeMode: COMPOSITE_MODE.SOURCE_OUT
    })
    doc.selectionTex.swapTexture(wgl.tempRenderedTex)
    this.queueDocRender()
    doc.recordVersion({ name: 'Invert Selection' })
    doc.selectionTex.calcHasData()
  }

  crop () {
    const doc = this.getActiveDoc()
    const boundary = doc.selectionTex.calcBoundary()
    const width = boundary.max.x - boundary.min.x
    const height = boundary.max.y - boundary.min.y
    if (width === 0 || height === 0) {
      alert('No selection has been made, you must first select the area you want to crop to')
      return
    }
    doc.resize({ width, height }, { crop: { x: boundary.min.x, y: boundary.min.y } })
    doc.selectionTex.clear()
    this.queueDocRender()
    doc.recordVersion({ name: 'Crop' })
  }

  _deleteSelection () {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const layer = doc.activeLayer
    blendFilter.blend({
      wgl,
      des: {
        texture: 'temp',
        width: layer.getWidth(),
        height: layer.getHeight()
      },
      _src1: layer.getTex(),
      _src2: doc.selectionTex,
      _compositeMode: COMPOSITE_MODE.SOURCE_OUT
    })
    layer.tex.swapTexture(wgl.tempRenderedTex)
    return doc
  }

  deleteSelection () {
    const doc = this._deleteSelection()
    if (doc === undefined) { return }
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Clear' })
  }

  _fillSelection (color) {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const layer = doc.activeLayer
    if (doc.selectionTex.hasData) {
      const tex = new Tex(layer.size())
      tex.fill({ fillStyle: color.rgb })
      blendFilter.blend({
        wgl,
        des: {
          texture: 'temp',
          width: layer.getWidth(),
          height: layer.getHeight()
        },
        _src1: layer.getTex(),
        _src2: tex,
        _mask: doc.selectionTex,
        _hasMask: true,
      })
      layer.tex.swapTexture(wgl.tempRenderedTex)
      tex.dispose()
    } else {
      layer.fill({ fillStyle: color.rgb })
    }
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: doc.selectionTex.hasData ? 'Fill Selection' : 'Fill Layer' })
  }

  foregroundFill () {
    const toolColors = this.packageFor('tool-colors')
    this._fillSelection(toolColors.foregroundColor)
  }

  backgroundFill () {
    const toolColors = this.packageFor('tool-colors')
    this._fillSelection(toolColors.backgroundColor)
  }

  nudgeSelection ({ doc, p }) {
    const { selectionTex } = doc
    moveFilter.move({
      wgl,
      des: {
        texture: 'temp',
        width: selectionTex.width,
        height: selectionTex.height
      },
      _src: doc.selectionTex,
      _offset: [p.x, p.y]
    })
    selectionTex.swapTexture(wgl.tempRenderedTex)
    doc.selectionTex.calcHasData()
    this.queueDocRender({ mode: 'maskUpdated' })
    doc.recordVersion({ name: 'Nudge Marquee', combinable: true })
  }

  handleKey ({ key, doc, shift }) {
    if (doc.selectionTex.hasData === false) { return }
    const translate = { x: 0, y: 0 }
    const amount = shift ? 10 : 1
    if (key === 'up') { translate.y = -amount }
    if (key === 'down') { translate.y = amount }
    if (key === 'left') { translate.x = -amount }
    if (key === 'right') { translate.x = amount }
    this.nudgeSelection({ doc, p: translate })
  }

  dispose () {
    super.dispose()
    this.events.dispose()
  }
}
