import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './grid-layout-view.scss'

@observer
export default class extends BaseComponent {
  groupLayouts (collection) {
    const itemsGroupedByRow = {}
    collection.each((item) => {
      const rowOrder = item.row
      if (!itemsGroupedByRow[rowOrder]) { itemsGroupedByRow[rowOrder] = { order: rowOrder, cols: [] } }
      itemsGroupedByRow[rowOrder].cols.push(item)
    })
    for (const rowOrder in itemsGroupedByRow) {
      const row = itemsGroupedByRow[rowOrder]
      const colItems = row.cols
      colItems.sort(function (a, b) { return a.col - b.col })
      for (const col of colItems) {
        if (col.height === 'fill') {
          row.fillHeight = true
        }
      }
    }
    const rowItems = Object.values(itemsGroupedByRow)
    rowItems.sort(function (a, b) { return a.order - b.order })
    return rowItems
  }

  rowStyle (row, index) {
    let colStyleStr = ''
    for (const col of row.cols) {
      let colWidth = undefined
      col.layoutItems.each((layoutItem) => {
        if (layoutItem.width === 'fill') {
          colWidth = 'fill'
        }
      })
      colStyleStr += colWidth === 'fill' ? '1fr ' : 'min-content '
    }

    return {
      gridRow: `${index + 1} / ${index + 1}`,
      gridColumn: '1 / 1',
      gridTemplateColumns: colStyleStr
    }
  }

  gridLayoutViewStyle (groupedLayouts) {
    let rowStyleStr = ''
    for (const row of groupedLayouts) {
      rowStyleStr += row.fillHeight ? '1fr ' : 'min-content '
    }
    return {
      gridTemplateRows: rowStyleStr
    }
  }

  render () {
    const { layouts, template } = this.props
    const groupedLayouts = this.groupLayouts(layouts)
    return (
      <div className="grid-layout-view fill-absolute" style={ { ...this.gridLayoutViewStyle(groupedLayouts) } }>
        { groupedLayouts.map((row, index) =>
          <div className="grid-layout-view__row" style={ { ...this.rowStyle(row, index) } } key={ index }>
            { row.cols.map((col, index) => template(col, index + 1)) }
          </div>
        )}
      </div>
    )
  }
}
