import React from 'react'
import { inject, observer } from 'mobx-react'
import BaseComponent from '../../core/base-component'
import TextLayerOptionsView from './text-layer-options-view'

import './layer-options-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  render () {
    const doc = this.getActiveDoc()

    return (
      <div className="layer-options-view align-items-center">
        {doc && doc.activeLayer && doc.activeLayer.isTextType() &&
          <TextLayerOptionsView doc={ doc } layer={ doc.activeLayer } />
        }
      </div>
    )
  }
}
