import { inject } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import EventList from '../../core/event-list'
import Fabric from '../../core/fabric'
import Dom from '../../core/dom'
import el from '../../lib/el'

@inject('app')
export default class extends BaseComponent {
  redraw = () => {
    const { fabric } = this
    const { root } = this.refs
    const { layer } = this.props
    if (!el(root).visible()) { return }
    const rootSize = el(root).innerSize()
    const pixelRatio = Dom.devicePixelRatio()
    const width = parseInt(rootSize.width * pixelRatio)
    const height = parseInt(rootSize.height * pixelRatio)
    if (width === 0 || height === 0) { return }
    fabric.resize({ width, height })
    fabric.canvas.style.width = rootSize.width + 'px'
    fabric.canvas.style.height = rootSize.height + 'px'
    layer.printToCanvas({ canvas: fabric.canvas, width, height })
  }

  shouldComponentUpdate () { return false }

  componentDidMount () {
    const { layer } = this.props
    const { root, canvas } = this.refs
    this.fabric = new Fabric({ canvas })
    this.events = new EventList()
    this.events.addEvent(layer.tex.wasUpdated, this.queueRedraw)
    this.events.addDomEvent(root, 'resize', this.queueRedraw)
    this.queueRedraw()
  }

  queueRedraw = () => {
    this.events.setTask('redraw', () => this.redraw())
  }

  componentWillUnmount () {
    this.events.dispose()
    this.fabric.dispose()
  }

  onCanvasClick = (e) => {
    if (e.metaKey) {
      const { doc, layer } = this.props
      doc.createSelectionFromLayer(layer)
      this.queueDocRender()
      e.stopPropagation()
    }
  }

  render () {
    return (
      <div className="fill-parent raster-layer-view" ref="root">
        <canvas ref="canvas" className="checkered-background-small" onClick={ this.onCanvasClick }></canvas>
      </div>
    )
  }
}
