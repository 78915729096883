import Item from './item'
import Collection from './collection'
import GlTexture from './gl-texture'
import _ from 'lodash'

export default class extends Item {
  constructor (options) {
    super(options)
    this.gl = options.gl
    this.filter = options.filter
    this.id = this.filter.id
    this.initProgram()
    this.glTextures = new Collection()
  }

  initProgram = () => {
    const { gl, filter } = this
    const vertexShader = gl.createShader(gl.VERTEX_SHADER)
    gl.shaderSource(vertexShader, filter.vertexShader)
    gl.compileShader(vertexShader)
    if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
      throw new Error(gl.getShaderInfoLog(vertexShader))
    }

    const fragmentShader = gl.createShader(gl.FRAGMENT_SHADER)
    gl.shaderSource(fragmentShader, filter.fragmentShader)
    gl.compileShader(fragmentShader)
    if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
      throw new Error(gl.getShaderInfoLog(fragmentShader))
    }

    const program = gl.createProgram()
    gl.attachShader(program, vertexShader)
    gl.attachShader(program, fragmentShader)
    gl.linkProgram(program)

    const vertexLoc = gl.getAttribLocation(program, 'vertex')
    gl.enableVertexAttribArray(vertexLoc)
    gl.vertexAttribPointer(vertexLoc, 2, gl.FLOAT, false, 0, 0)

    this.program = program
  }

  setUniform = (name, value) => {
    const { gl, program } = this
    const location = gl.getUniformLocation(program, name)
    if (location === null) { return }
    if (Array.isArray(value)) {
      if (Array.isArray(value[0])) {
        const flattenedValue = _.flatten(value)
        switch (value[0].length) {
          case 1: gl.uniform1fv(location, new Float32Array(flattenedValue)); break
          case 2: gl.uniform2fv(location, new Float32Array(flattenedValue)); break
          case 3: gl.uniform3fv(location, new Float32Array(flattenedValue)); break
          case 4: gl.uniform4fv(location, new Float32Array(flattenedValue)); break
        }
      } else {
        switch (value.length) {
          case 1: gl.uniform1fv(location, new Float32Array(value)); break
          case 2: gl.uniform2fv(location, new Float32Array(value)); break
          case 3: gl.uniform3fv(location, new Float32Array(value)); break
          case 4: gl.uniform4fv(location, new Float32Array(value)); break
          case 9: gl.uniformMatrix3fv(location, false, new Float32Array(value)); break
          case 16: gl.uniformMatrix4fv(location, false, new Float32Array(value)); break
          default: throw new Error('Unloadable length: "' + name + '" has an loadable length of ' + value.length)
        }
      }
    } else if (!isNaN(value)) {
      gl.uniform1f(location, value)
    } else {
      throw new Error('Invalid uniform value: "' + name + '" has an invalid value of ' + (value || 'undefined').toString())
    }
  }

  setUniforms = (uniforms = {}) => {
    for (const key in uniforms) {
      const value = uniforms[key]
      this.setUniform(key, value)
    }
  }

  setTextures = (textureObjs = {}) => {
    const { gl, program } = this
    for (const key in textureObjs) {
      const glTexture = this.glTextures.findOrAdd(key, () => {
        const glTexture = new GlTexture({ gl, textureNumber: this.glTextures.count() })
        glTexture.id = key
        return glTexture
      })
      const textureObj = textureObjs[key]
      glTexture.initTexture(textureObj)
      const textNameLoc = gl.getUniformLocation(program, key)
      gl.uniform1i(textNameLoc, glTexture.textureNumber)
      if (textureObj.src.width !== undefined && textureObj.src.height !== undefined) {
        this.setUniform(key + 'Width', textureObj.src.width)
        this.setUniform(key + 'Height', textureObj.src.height)
      }
      if (textureObj.src.getWidth !== undefined && textureObj.src.getHeight !== undefined) {
        this.setUniform(key + 'Width', textureObj.src.getWidth())
        this.setUniform(key + 'Height', textureObj.src.getHeight())
      }
    }
  }
}
