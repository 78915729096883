import { inject, observer } from 'mobx-react'
import React from 'react'
import DocTabView from './doc-tab-view'
import el from '../../lib/el'
import nbr from '../../lib/nbr'
import Dom from '../../core/dom'
import EventList from '../../core/event-list'
import BaseComponent from '../../core/base-component'

import './doc-tab-view-list.scss'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.tabMinWidth = Dom.remToPx(5)
    this.tabMaxWidth = Dom.remToPx(15)
    this.state = { tabWidth: this.tabMaxWidth }
  }

  resizeTabs = () => {
    const { docs } = this.props
    const { tabMinWidth, tabMaxWidth } = this
    const { root } = this.refs
    const availableWidth = el(root).innerWidth()
    const borderWidth = 1
    const availableTabWidth = availableWidth / parseFloat(docs.length) - borderWidth
    this.setState({ tabWidth: nbr(availableTabWidth).withinRange(tabMinWidth, tabMaxWidth) })
  }

  componentDidMount () {
    this.events = new EventList()
    this.events.addObserver(this.props.docs, 'length', this.resizeTabs)
    this.events.addDomEvent(this.refs.root, 'resize', this.resizeTabs)
  }
  componentWillUnmount () { this.events.dispose() }

  rootStyle () {
    const docsPackage = this.packageFor('docs')
    return {
      display: docsPackage.preloaded === true ? 'block' : 'none'
    }
  }
  render () {
    const { docs, closeDoc } = this.props
    return (
      <div className="doc-tab-view-list" ref="root" style={ this.rootStyle() }>
        { docs.map((doc, info) =>
            <DocTabView
              key={ doc.id }
              doc={ doc }
              docs={ docs }
              isActive={ info.isActive }
              width={ this.state.tabWidth }
              closeDoc={ closeDoc }
            />)
        }
      </div>
    )
  }
}
