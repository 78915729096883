import { observable, action, computed } from 'mobx'
import { generateId } from './id'
import ary from '../lib/ary'
import hsh from '../lib/hsh'
import _ from 'lodash'
import { getGlobalFactory } from './factory-references'
import Event from './event'

export default class {
  @observable ids = []
  @observable activeId = undefined

  constructor (options = {}) {
    this.id = generateId()
    this.syncItemIndexes = options.syncItemIndexes
    this.entities = {}
    this.selectedIdsHash = {}
    this.collectionLimit = options.collectionLimit
    this.itemIndexesWereUpdated = new Event()
    this.itemsLengthIncreased = new Event()
    this.itemsWereCleared = new Event()
    this.itemPendingDisposal = new Event()
  }

  @action updateItemIndexes = () => {
    this.itemIndexesWereUpdated.trigger()
    if (this.syncItemIndexes !== true) { return }
    this.ids.forEach((id, index) => {
      const item = this.find(id)
      if (item !== undefined && item.collectionIndex !== index) {
        item.setCollectionIndex(index)
      }
    })
  }

  @action create (entity) {
    if (!entity.id) { entity.id = generateId() }
    if (this.find(entity.id) !== undefined) {
      throw new Error(`An item with ID "${entity.id}" already exists, IDs must be unique`)
    }

    const id = entity.id
    this.entities[id] = entity
    return entity
  }

  @action push (entity, options) {
    const en = this.create(entity)
    this.ids.push(en.id)
    this.trimToLimit({ trimMethod: () => this.disposeFirst() })
    this.updateItemIndexes()
    this.itemsLengthIncreased.trigger(en, options)
    return en
  }

  @action trimToLimit ({ trimMethod }) {
    if (this.collectionLimit !== undefined) {
      while (this.count() > this.collectionLimit) {
        trimMethod()
      }
    }
  }

  @action unshift (entity, options) {
    const en = this.create(entity)
    this.ids.unshift(en.id)
    this.trimToLimit({ trimMethod: () => this.disposeLast() })
    this.updateItemIndexes()
    this.itemsLengthIncreased.trigger(en, options)
    return en
  }

  @action add (entity, options) {
    if (entity.getPriority !== undefined) {
      return this.insertByPriority(entity, options)
    }
    return this.push(entity, options)
  }

  @action insertAfterActiveItem (entity, options) {
    const index = this.ids.indexOf(this.activeId)
    if (index === -1) {
      return this.add(entity, options)
    }
    return this.insertAtIndex(entity, index + 1, options)
  }

  @action insertBeforeActiveItem (entity, options) {
    const index = this.ids.indexOf(this.activeId)
    if (index === -1) {
      return this.add(entity, options)
    }
    let newIndex = index
    return this.insertAtIndex(entity, newIndex, options)
  }

  @action insertAtIndex (entity, index, options = {}) {
    const { ids } = this
    const en = this.create(entity)
    ids.splice(index, 0, en.id)
    this.updateItemIndexes()
    this.itemsLengthIncreased.trigger(en, options)
    return en
  }

  @action insertByPriority (entity, options) {
    let index = this.ids.length
    if (entity.getPriority !== undefined) {
      for (const [i, id] of this.ids.entries()) {
        const item = this.find(id)
        if (item.getPriority === undefined ||
          item.getPriority() < entity.getPriority()) {
          index = i
          break
        }
      }
    }
    return this.insertAtIndex(entity, index, options)
  }

  @action findOrAdd (id, createEntity, options = {}) {
    let en = this.find(id)
    if (en) { return en }
    return this.add(createEntity(), options)
  }

  @action concat (entities) {
    const ids = []
    const ens = []
    for (const entity of entities) {
      const en = this.create(entity)
      ens.push(en)
      ids.push(en.id)
    }
    this.ids = this.ids.concat(ids)
    this.updateItemIndexes()
    return ens
  }

  @action clear () {
    this.ids = []
    this.entities = {}
    this.itemsWereCleared.trigger()
  }

  @action disposeAll () {
    this.each((item) => {
      if (item.dispose) { item.dispose() }
    })
    this.ids = []
    this.entities = {}
  }

  @action setItems (entities) {
    this.storeSelectionState()
    this.clear()
    this.concat(entities)
    this.restoreSelectionState()
  }

  storeSelectionState () {
    this.cachedSelectedIds = this.getSelectedIds()
    this.cachedActiveId = this.activeId
  }

  restoreSelectionState () {
    this.unselectAll()
    this.selectIds(this.cachedSelectedIds)
    this.setActiveId(this.cachedActiveId)
  }

  @action replace (oldItem, newItem) {
    const index = this.indexOf(oldItem)
    this.extract(oldItem)
    this.insertAtIndex(newItem, index)
  }

  @action disposeItem ({ id }, options = {}) {
    const item = this.extract({ id }, options)
    this.itemPendingDisposal.trigger(item)
    if (item && item.dispose) { item.dispose() }
    delete this.entities[id]
  }

  @action disposeItems (items, options = {}) {
    for (const item of items) { this.disposeItem(item, options) }
  }

  @action disposeFirst () {
    this.disposeItem({ id: this.ids[0] })
  }

  @action disposeLast () {
    this.disposeItem({ id: ary(this.ids).last() })
  }

  @action remove ({ id }, options = {}) { return this.extract({ id }, options) }

  @action removeItems (items) {
    items.forEach(item => this.remove(item))
  }

  @action extract ({ id }, options = {}) {
    if (id === undefined) { return }
    const item = this.find(id)
    if (item === undefined) { return }
    if (this.activeId === id) { this.setSiblingAsActive() }
    this.entities[id] = undefined
    const index = this.ids.indexOf(id)
    if (index === -1) { return }
    this.ids.splice(index, 1)
    this.updateItemIndexes()
    this.unselectId(item.id)
    return item
  }

  @action disposeActiveItem () {
    if (this.activeId === undefined) { return }
    const activeIndex = this.activeIndex()
    let prevIndex = activeIndex - 1
    this.disposeItem({ id: this.activeId })
    if (prevIndex < 0) { prevIndex = 0 }
    this.setActiveId(this.ids[prevIndex])
  }

  itemIsSelected ({ id }) {
    return this.selectedIdsHash[id] === true
  }

  getSelectedIds () {
    const ids = []
    for (const id in this.selectedIdsHash) {
      if (this.itemIsSelected({ id })) { ids.push(id) }
    }
    return ids
  }

  getItems (ids) {
    const items = []
    for (const id of ids) {
      items.push(this.find(id))
    }
    return items
  }

  getSelectedItems () {
    const selectedIds = this.getSelectedIds()
    const items = this.getItems(selectedIds)
    items.sort((a, b) => {
      const indexA = this.ids.indexOf(a.id)
      const indexB = this.ids.indexOf(b.id)
      return indexA - indexB
    })
    return items
  }

  @action disposeSelectedItems () {
    const activeIndex = this.activeIndex()
    const shouldUpdateActiveIndex = activeIndex !== undefined && this.itemIsSelected({ id: this.activeId })
    let prevIndex = (activeIndex || 0) - 1
    this.disposeItems(this.getSelectedIds().map(id => { return { id } }))
    if (shouldUpdateActiveIndex) {
      if (prevIndex < 0) { prevIndex = 0 }
      if (prevIndex >= this.ids.length) { prevIndex = this.ids.length - 1 }
      this.setActiveId(this.ids[prevIndex])
    }
  }

  @action reorder (id, newIndex) {
    const { ids } = this
    const fromIndex = ids.indexOf(id)
    if (fromIndex === -1) { return }
    if (newIndex < 0) { newIndex = 0 }
    if (newIndex >= ids.length) { newIndex = ids.length - 1 }
    ids.splice(fromIndex, 1)
    ids.splice(newIndex, 0, id)
    this.updateItemIndexes()
  }

  @action setIds (ids) {
    this.ids = ids
    this.updateItemIndexes()
  }

  find (id) {
    return this.entities[id]
  }

  exists (id) {
    return this.find(id) !== undefined
  }

  map = (callback) => {
    const arr = []
    this.each((item, info) => {
      arr.push(callback(item, info))
    })
    return arr
  }

  reverseMap = (callback) => {
    const arr = []
    this.reverseEach((item, info) => {
      arr.push(callback(item, info))
    })
    return arr
  }

  reverseEach = (callback) => {
    const ids = this.ids
    for (let i = ids.length - 1; i >= 0; i--) {
      const id = ids[i]
      const en = this.find(id)
      callback(en, this.getEntityLoopInfo(en, i))
    }
  }

  getEntityLoopInfo = (entity, index) => {
    if (entity === undefined) { return {} }
    const id = entity.id
    const isActive = (id === this.activeId)
    const isSelected = this.selectedIdsHash[id]
    return { index, isActive, isSelected }
  }

  filter = (filterCallback) => {
    const matches = []
    this.each((item) => {
      if (filterCallback(item)) { matches.push(item) }
    })
    return matches
  }

  any = (callback) => {
    for (const id of this.ids.entries()) {
      const en = this.find(id)
      if (callback(en) === true) { return true }
    }
    return false
  }

  each = (callback, options = {}) => {
    const startFrom = options.startFrom
    for (const [index, id] of this.ids.entries()) {
      if (index < startFrom) { continue }
      const en = this.find(id)
      const result = callback(en, this.getEntityLoopInfo(en, index))
      if (result === false) { break }
    }
  }

  toArray = () => {
    return this.map((item) => { return item })
  }

  @action setActiveId (id, { autoSelect, skipEvents } = {}) {
    if (autoSelect) {
      this.unselectAll()
      this.selectId(id)
    }

    const { activeItem } = this
    const newActiveItem = this.find(id)
    this.activeId = id

    if (skipEvents === true) { return }

    this.each((item) => {
      if (newActiveItem && item !== newActiveItem && item.isActive) {
        item.becameInactive.trigger()
      }
    })
    if (activeItem === newActiveItem && newActiveItem && newActiveItem.isActive) { return }
    if (newActiveItem && newActiveItem.becameActive) {
      newActiveItem.becameActive.trigger()
    }
  }

  @action selectId (id) {
    const item = this.find(id)
    if (this.selectedIdsHash[id] === true && item && item.isSelected) { return }
    this.selectedIdsHash[id] = true
    if (item && item.wasSelected) { item.wasSelected.trigger() }
  }

  @action selectIds (ids) {
    for (const id of ids) { this.selectId(id) }
  }

  @action selectIdsFromHash (idHash) {
    for (const id in idHash) { this.selectId(id) }
  }

  @action unselectAll () {
    for (const id of this.ids) { this.unselectId(id) }
  }

  @action unselectId (id) {
    if (this.selectedIdsHash[id] === undefined) { return }
    delete this.selectedIdsHash[id]
    const item = this.find(id)
    if (item && item.wasUnselected) {
      item.wasUnselected.trigger()
    }
  }

  itemAtIndex (index) {
    if (index >= this.ids.length) { return }
    const id = this.ids[index]
    if (id === undefined) { return undefined }
    return this.find(id)
  }

  @computed get activeItem () {
    if (this.activeId === undefined) {
      return undefined
    }
    return this.find(this.activeId)
  }

  getActiveItem () {
    if (this.activeId === undefined) {
      return undefined
    }
    return this.find(this.activeId)
  }

  @action setNextItemAsActive = (options = {}) => {
    const item = this.itemAfterActiveItem(options)
    if (item === undefined) { return }
    this.setActiveId(item.id)
  }

  @action setPreviousItemAsActive = (options = {}) => {
    const item = this.itemBeforeActiveItem(options)
    if (item === undefined) { return }
    this.setActiveId(item.id)
  }

  @action setSiblingAsActive = (options) => {
    const item = this.itemBeforeActiveItem(options) || this.itemAfterActiveItem(options)
    if (item === undefined) { return }
    this.setActiveId(item.id)
  }

  getClosestItem (item, options = {}) {
    if (item === undefined) { item = this.getActiveItem() }
    let index = this.ids.indexOf(item.id)
    let siblingAfter = undefined
    let siblingAfterIndex = undefined
    for (let i = index; i < this.ids.length; i++) {
      const sibling = this.find(this.ids[i])
      if (options.filter === undefined || options.filter(sibling)) {
        siblingAfter = sibling
        siblingAfterIndex = i
        break;
      }
    }
    let siblingBefore = undefined
    let siblingBeforeIndex = undefined
    for (let i = index; i >= 0; i--) {
      const sibling = this.find(this.ids[i])
      if (options.filter === undefined || options.filter(sibling)) {
        siblingBefore = sibling
        siblingBeforeIndex = i
        break;
      }
    }

    if (siblingAfter !== undefined && siblingBefore === undefined) { return siblingAfter }
    if (siblingAfter === undefined && siblingBefore !== undefined) { return siblingBefore }

    if ((siblingAfterIndex - index) < (index - siblingBeforeIndex)) {
      return siblingAfter
    }
    return siblingBefore
  }

  getClosestSelectedItem (item) {
    return this.getClosestItem(item, {
      filter: (sibling) => this.itemIsSelected(sibling)
    })
  }

  getClosestUnselectedItem (item) {
    return this.getClosestItem(item, {
      filter: (sibling) => !this.itemIsSelected(sibling)
    })
  }

  @action setClosestSelectedSiblingAsActive = (item, options) => {
    const sibling = this.getClosestSelectedItem(item)
    if (sibling === undefined) { return }
    this.setActiveId(sibling.id)
  }

  itemBefore (item) {
    const index = this.indexOf(item)
    if (index < 0) { return }
    let prevIndex = index - 1
    if (prevIndex < 0) { prevIndex = this.count() - 1 }
    return this.itemAtIndex(prevIndex)
  }

  itemAfter (item) {
    const index = this.indexOf(item)
    if (index < 0) { return }
    let nextIndex = index + 1
    if (nextIndex >= this.count()) { nextIndex = 0 }
    return this.itemAtIndex(nextIndex)
  }

  itemBeforeActiveItem (options = {}) {
    if (this.activeId === undefined) { return }
    const activeIndex = this.activeIndex()
    let prevIndex = activeIndex - 1
    if (prevIndex < 0 && options.loopable) {
      prevIndex = this.ids.length - 1
    }
    if (prevIndex < 0) { return }
    const prevActiveId = this.ids[prevIndex]
    return this.find(prevActiveId)
  }

  itemAfterActiveItem (options = {}) {
    if (this.activeId === undefined) { return }
    const activeIndex = this.activeIndex()
    let nextIndex = activeIndex + 1
    if (options.loopable) {
      nextIndex %= this.ids.length
    }
    if (nextIndex >= this.ids.length) { return }
    const nextActiveId = this.ids[nextIndex]
    return this.find(nextActiveId)
  }

  first () {
    if (this.empty()) { return undefined }
    return this.find(this.ids[0])
  }

  last () {
    if (this.empty()) { return undefined }
    return this.find(ary(this.ids).last())
  }

  count () { return this.ids.length }
  selectionCount () { return Object.values(this.selectedIdsHash).filter(v => v === true).length }

  @computed get length () { return this.ids.length }

  empty () {
    return this.ids.length === 0
  }

  activeIndex () {
    if (this.activeId === undefined) { return }
    return this.ids.indexOf(this.activeId)
  }

  indexOf (item) {
    return this.ids.indexOf(item.id)
  }

  serialize () {
    return {
      data: {
        id: this.id,
        activeId: this.activeId,
        collectionLimit: this.collectionLimit,
        selectedIdsHash: hsh(this.selectedIdsHash).clone(),
        syncItemIndexes: this.syncItemIndexes,
        items: this.map((item) => {
          return { id: item.id, factoryKey: item.getFactoryKey() }
        })
      },
      childItems: {
        items: this.toArray()
      }
    }
  }

  restore ({ data, resources, version }) {
    this.id = data.id
    this.unselectAll()
    this.syncItemIndexes = data.syncItemIndexes
    this.populateItems(data.items)
    this.setActiveId(data.activeId)
    this.selectIdsFromHash(data.selectedIdsHash)
    this.collectionLimit = data.collectionLimit
  }

  @action populateItems (serializedItems) {
    const serializedItemIds = serializedItems.map((item) => item.id)
    const idsToDispose = _.difference(this.ids, serializedItemIds)
    this.disposeItems(idsToDispose.map(id => { return { id } }))
    const items = []
    for (const serializedItem of serializedItems) {
      const factory = getGlobalFactory(serializedItem.factoryKey)
      const item = this.find(serializedItem.id) || factory({ id: serializedItem.id })
      items.push(item)
    }
    this.setItems(items)
  }

  detect (condition) {
    for (const [index, id] of this.ids.entries()) {
      const en = this.find(id)
      if (condition(en, this.getEntityLoopInfo(en, index))) { return en }
    }
  }

  clone () {
    return this.map((item) => item.clone())
  }

  dispose () {
    this.each((item) => {
      if (item.dispose) { item.dispose() }
    })
  }
}
