function ensureValidDimension (value) {
  if (value === 0 || isNaN(value)) { return value }
  return `${value}px`
}

const cssAttributeConvertors = {
  display: function (value) {
    if (value === true) { return 'block' }
    if (value === false) { return 'none' }
    return value
  },
  zIndex: function (value) {
    if (isNaN(value)) { return 0 }
    return value
  },
  width: ensureValidDimension,
  height: ensureValidDimension,
  top: ensureValidDimension,
  bottom: ensureValidDimension,
  left: ensureValidDimension,
  right: ensureValidDimension,
  marginTop: ensureValidDimension,
  marginBottom: ensureValidDimension,
  marginLeft: ensureValidDimension,
  marginRight: ensureValidDimension,
  paddingTop: ensureValidDimension,
  paddingBottom: ensureValidDimension,
  paddingLeft: ensureValidDimension,
  paddingRight: ensureValidDimension,
  borderWidth: ensureValidDimension
}

export function ensureValidCss (hash) {
  const h = {}
  for (const key in hash) {
    const convertor = cssAttributeConvertors[key]
    let value = hash[key]
    if (convertor) { value = convertor(value) }
    h[key] = value
  }
  return h
}

export function assignStyleToElement (element, style) {
  const validStyle = ensureValidCss(style)
  for (const key in validStyle) {
    element.style[key] = validStyle[key]
  }
}

export const COMPOSITE_MODES = [
  'normal', 'multiply', 'screen',
  'overlay', 'darken', 'lighten',
  'color-dodge', 'color-burn', 'hard-light',
  'soft-light', 'difference', 'exclusion',
  'hue', 'saturation', 'color', 'luminosity'
]
