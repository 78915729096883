import Dom from './dom'
import Item from './item'
import Collection from './collection'
import DragEvent from './drag-event'
import el from '../lib/el'

const MIN_MOVE_DISTANCE = 2
const SCROLL_INTERVAL = 10
const SCROLL_SPEED = 1
const SCROLL_SPEED_DIFF_MULTIPLIER = 0.05

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.initDragAxis(options.dragAxis)
    this.groupableCollection = options.groupableCollection
    this.currDragEvent = undefined
    this.dragEvents = new Collection()
    this.initialOffset = { x: 0, y: 0 }
  }

  initializableAttributes () {
    return super.initializableAttributes().concat([
      'onBegin', 'onContinue', 'onEnd', 'getSiblingNodes',
      'dragMarkerClass', 'updateGhostElementPosition'
    ])
  }

  initDragAxis = (dragAxis) => {
    this.dragAxis = { x: true, y: true }
    if (dragAxis === undefined) { return }
    if (dragAxis === 'x') { this.dragAxis.y = false }
    if (dragAxis === 'y') { this.dragAxis.x = false }
  }

  setGhostElement (ghostElement) { this.ghostElement = ghostElement }
  setContainer (container) { this.container = container }
  setScrollContainer (scrollContainer) { this.scrollContainer = scrollContainer }
  setAnchorContainer (anchorContainer) { this.anchorContainer = anchorContainer }

  updateMarkerIndex = () => {
    this.markerIndex = this.getMarkerIndex()
  }

  setAnchorElement = (element) => {
    this.anchorElement = element
  }

  getAnchorOffset = () => {
    return this.anchorContainer.offsetTop + this.anchorElement.offsetTop + this.getScrollPosition().y
  }

  getMarkerIndex = () => {
    const { dragAxis } = this
    const anchorOffset = this.getAnchorOffset()
    if (dragAxis.y) {
      if (anchorOffset <= 0) { return 0 }
      const siblingNodes = this.getSiblingNodes()
      for (let i = 0; i < siblingNodes.length; i++) {
        const sibling = siblingNodes[i]
        if (anchorOffset < sibling.offsetTop) {
          return i
        }
      }
      return siblingNodes.length
    }
  }

  setInitialIndex (index) {
    this.initialIndex = index
  }

  getScrollPosition () {
    const { scrollContainer } = this
    const scrollLeft = scrollContainer ? scrollContainer.scrollLeft : 0
    const scrollTop = scrollContainer ? scrollContainer.scrollTop : 0
    return { x: scrollLeft, y: scrollTop }
  }

  updateMarkerPosition () {
    const { marker, secondaryMarker, markerIndex } = this
    const siblingNodes = this.getSiblingNodes()
    const markerHeight = el(marker).outerHeight()
    if (markerIndex < siblingNodes.length) {
      const sibling = siblingNodes[markerIndex]
      const top = Math.floor(sibling.offsetTop - markerHeight / 2)
      marker.style.top = top + 'px'
      secondaryMarker.style.top = top + 'px'
      return
    }
    const lastSibling = siblingNodes[siblingNodes.length - 1]
    const siblingHeight = el(lastSibling).outerHeight()
    const top = lastSibling.offsetTop + siblingHeight - markerHeight / 2
    marker.style.top = top + 'px'
    secondaryMarker.style.top = top + 'px'
  }

  beginDrag = (e) => {
    this.scrollIntervalId = setInterval(this.autoScroll, SCROLL_INTERVAL)
    this.onBegin(e)
  }

  autoScroll = () => {
    const { scrollContainer, anchorElement } = this
    if (scrollContainer === undefined) { return }
    const anchorOffset = this.getAnchorOffset()
    const padding = 10
    if (anchorOffset - padding < scrollContainer.scrollTop) {
      const diff = scrollContainer.scrollTop - anchorOffset
      const scrollValue = SCROLL_SPEED + diff * SCROLL_SPEED_DIFF_MULTIPLIER
      scrollContainer.scrollTop = scrollContainer.scrollTop - scrollValue
      return
    }
    const bottomOfAnchor = anchorOffset + el(anchorElement).outerHeight()
    const scrollContainerBottom = el(scrollContainer).innerHeight() + scrollContainer.scrollTop
    if (bottomOfAnchor + padding > scrollContainerBottom) {
      const diff = bottomOfAnchor - scrollContainerBottom
      const scrollValue = SCROLL_SPEED + diff * SCROLL_SPEED_DIFF_MULTIPLIER
      scrollContainer.scrollTop = scrollContainer.scrollTop + scrollValue
    }
  }

  addMarker = () => {
    this.marker = Dom.div({ parent: this.container, className: `drag-marker ${this.dragMarkerClass}` })
    this.secondaryMarker = Dom.div({ parent: this.container, className: `drag-marker secondary-drag-marker ${this.dragMarkerClass}` })
    this.updateMarkerIndex()
    this.updateMarkerPosition()
  }

  continueDrag = (e) => {
    this.updateGhostElementPosition(e)
    this.updateMarkerIndex()
    this.updateMarkerPosition()
    this.onContinue(e)
  }

  endDrag = (e) => {
    this.onEnd(e)
    el(this.container).removeChild(this.marker)
    el(this.container).removeChild(this.secondaryMarker)
    clearInterval(this.scrollIntervalId)
  }

  registerDraggable (id, element, options = {}) {
    const { onMouseDown, onClick, shouldBeginDrag } = options
    const self = this
    const dragEvent = new DragEvent({
      id,
      minMoveDistance: MIN_MOVE_DISTANCE,
      targetElement: element,
      onBegin: function (...args) {
        self.currDragEvent = this
        self.beginDrag(...args)
      },
      onContinue: this.continueDrag,
      onEnd: this.endDrag,
      absorbMouseEvents: true,
      onMouseDown, onClick, shouldBeginDrag
    })
    this.dragEvents.add(dragEvent)
  }

  unregisterDraggable (id) {
    this.dragEvents.disposeItem({ id })
  }

  // placeholder methods
  onBegin () {}
  onContinue () {}
  onEnd () {}
  siblingNodes () {}
}
