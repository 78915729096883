import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './canvas-resizer-anchor-selector.scss'

@inject('app') @observer
export default class extends BaseComponent {
  getCellTemplateX0 = (yDiff, { heightReduced }) => {
    if (yDiff === -1) {
      return heightReduced ? <div className="dir dir-s"></div> : <div className="dir dir-n"></div>
    }
    if (yDiff === 0) {
      return <div className="dir dir-center"></div>
    }
    if (yDiff === 1) {
      return heightReduced ? <div className="dir dir-n"></div> : <div className="dir dir-s"></div>
    }
  }

  getCellTemplateX1 = (yDiff, { anyReduced, widthReduced }) => {
    if (yDiff === -1) {
      return anyReduced ? <div className="dir dir-sw"></div> : <div className="dir dir-ne"></div>
    }
    if (yDiff === 0) {
      return widthReduced ? (<div className="dir dir-w"></div>) : (<div className="dir dir-e"></div>)
    }
    if (yDiff === 1) {
      return anyReduced ? <div className="dir dir-nw"></div> : <div className="dir dir-se"></div>
    }
  }

  getCellTemplateXSub1 = (yDiff, { anyReduced, widthReduced }) => {
    if (yDiff === -1) {
      return anyReduced ? <div className="dir dir-se"></div> : <div className="dir dir-nw"></div>
    }
    if (yDiff === 0) {
      return widthReduced ? <div className="dir dir-e"></div> : <div className="dir dir-w"></div>
    }
    if (yDiff === 1) {
      return anyReduced ? <div className="dir dir-ne"></div> : <div className="dir dir-sw"></div>
    }
  }

  getCellTemplate = (x, y, currentCoordinate, options) => {
    let xDiff = x - currentCoordinate.x
    let yDiff = y - currentCoordinate.y
    if (xDiff === -1) { return this.getCellTemplateXSub1(yDiff, options) }
    if (xDiff === 0) { return this.getCellTemplateX0(yDiff, options) }
    if (xDiff === 1) { return this.getCellTemplateX1(yDiff, options) }

    return (<div></div>)
  }

  onCellClick = (x, y) => {
    this.props.onAnchorChange({ x, y })
  }

  getRowTemplate = (y, currentCoordinate, options) => {
    const row = []
    for (let x = 0; x < 3; x++) {
      row.push(
        <td key={ x } onClick={ () => this.onCellClick(x, y) }>
          { this.getCellTemplate(x, y, currentCoordinate, options) }
        </td>
      )
    }
    return row
  }

  getTableTemplate = () => {
    const { anchor, originalSize, newSize } = this.props
    let widthReduced = newSize.width < originalSize.width
    let heightReduced = newSize.height < originalSize.height
    if (newSize.relative) {
      widthReduced = newSize.width < 0
      heightReduced = newSize.height < 0
    }
    const options = { widthReduced, heightReduced, anyReduced: widthReduced || heightReduced }
    const rows = []
    for (let y = 0; y < 3; y++) {
      rows.push(
        <tr key={ y }>
          { this.getRowTemplate(y, anchor, options) }
        </tr>
      )
    }
    return (
      <table>
        <tbody>
          { rows }
        </tbody>
      </table>
    )
  }

  render () {
    return (
      <div className="canvas-resizer-anchor-selector">
        { this.getTableTemplate() }
      </div>
    )
  }
}
