export default class {
  constructor (options = {}) {
    this.transformBaseKey = options.transformBaseKey
    this.items = {}
  }

  store (baseKey, item) {
    const prevItem = this.retrieve(baseKey)
    if (prevItem && prevItem.dispose) {
      prevItem.dispose()
    }
    const key = this.transformBaseKey(baseKey)
    this.items[key] = item
  }

  retrieve (baseKey) {
    const key = this.transformBaseKey(baseKey)
    return this.items[key]
  }

  invalidate () {
    for (const key in this.items) {
      const item = this.items[key]
      if (item && item.dipose) { item.dispose() }
    }
    this.items = {}
  }

  dispose () {
    this.invalidate()
  }
}
