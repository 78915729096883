export function getSoftValueSnippet () {
  return `
    float erf (float x) {
      x = x / 0.41597;
      float denominator = 1.0 + 0.278393 * x + 0.230389 * pow(x, 2.0) + 0.000972 * pow(x, 3.0) + 0.078108 * pow(x, 4.0);
      float value = 1.0 - 1.0 / pow(denominator, 4.0);
      if (value >= 0.999) { return 1.0; }
      return value;
    }

    float softValue (float dRatio, float solidCutoff) {
      if (dRatio < solidCutoff) { return 1.0; }
      float transitionWidth = 1.0 - solidCutoff;
      float transitionPoint = dRatio - solidCutoff;
      float normalizedTransitionPoint = transitionPoint / transitionWidth;
      if (normalizedTransitionPoint < 0.5) {
        return 0.5 + 0.5 * erf(1.0 - normalizedTransitionPoint * 2.0);
      }
      return 0.5 - 0.5 * erf((normalizedTransitionPoint - 0.5) * 2.0);
    }
  `
}
