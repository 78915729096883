import matrix from 'matrix-js'
import Dom from './dom'
import Tex from './tex'
import { getMinMaxOfPoints } from './calc'
import DistortFilter from './filters/distort-filter'
import MoveFilter from './filters/move-filter'
import BlendFilter from './filters/blend-filter'
import { wgl } from './wgl'

const distortFilter = new DistortFilter()
const moveFilter = new MoveFilter()
const blendFilter = new BlendFilter()
const clippingCanvas = Dom.canvas()

export function applyDistortionFilter ({ transformableTex, quad, afterRender }, callback) {
  const points = [quad.topLeft.round(), quad.topRight.round(), quad.bottomRight.round(), quad.bottomLeft.round()]

  const { min, max } = getMinMaxOfPoints(points)
  const width = max.x - min.x
  const height = max.y - min.y
  if (width < 1 || height < 1) { return }
  const relativePoints = points.map(point => {
    return {
      x: point.x - min.x,
      y: point.y - min.y
    }
  })
  updateClippingCanvas({ width, height, relativePoints })

  const matrix = getTransformationMatrix(relativePoints)
  const inv = matrix.inv()
  const uniforms = {
    _a11: inv[0][0], _a12: inv[0][1], _a13: inv[0][2],
    _a21: inv[1][0], _a22: inv[1][1], _a23: inv[1][2],
    _a31: inv[2][0], _a32: inv[2][1], _a33: inv[2][2]
  }
  const clippingTex = new Tex({ width, height })
  clippingTex.loadImage({ image: clippingCanvas, width, height })

  wgl.applyFilter({
    filter: distortFilter,
    des: {
      texture: 'temp',
      width,
      height
    },
    data: {
      _src: transformableTex,
      _mask: clippingTex,
      ...uniforms
    }
  })

  clippingTex.dispose()
  afterRender({ tempRenderedTex: wgl.tempRenderedTex, min })
}

export function getTransformationMatrix (quadPoints) {
  const x0 = quadPoints[0].x
  const y0 = quadPoints[0].y

  const x1 = quadPoints[1].x
  const y1 = quadPoints[1].y

  const x2 = quadPoints[2].x
  const y2 = quadPoints[2].y

  const x3 = quadPoints[3].x
  const y3 = quadPoints[3].y

  const dx1 = x1 - x2
  const dx2 = x3 - x2
  const dx3 = (x0 - x1) + (x2 - x3)
  const dy1 = y1 - y2
  const dy2 = y3 - y2
  const dy3 = (y0 - y1) + (y2 - y3)

  const transformationMatrix = getPerspectiveTransformationMatrix({
    x0, y0,
    x1, y1,
    x2, y2,
    x3, y3,
    dx1, dx2, dx3,
    dy1, dy2, dy3
  })

  return matrix(transformationMatrix)
}

function getPerspectiveTransformationMatrix ({ x0, y0, x1, y1, x2, y2, x3, y3, dx1, dx2, dx3, dy1, dy2, dy3 }) {
  const m1 = matrix([
    [dx3, dx2],
    [dy3, dy2]
  ])
  const m2 = matrix([
    [dx1, dx2],
    [dy1, dy2]
  ])
  const m3 = matrix([
    [dx1, dx3],
    [dy1, dy3]
  ])
  const m4 = matrix([
    [dx1, dx2],
    [dy1, dy2]
  ])
  const m1d = m1.det()
  const m2d = m2.det()
  const m3d = m3.det()
  const m4d = m4.det()

  const a13 = (m1d === 0 || m2d === 0) ? 0 : m1d / m2d
  const a23 = (m3d === 0 || m4d === 0) ? 0 : m3d / m4d

  const a11 = x1 - x0 + a13 * x1
  const a12 = y1 - y0 + a13 * y1

  const a21 = x3 - x0 + a23 * x3
  const a22 = y3 - y0 + a23 * y3

  const a31 = x0
  const a32 = y0
  const a33 = 1

  return [
    [a11, a12, a13],
    [a21, a22, a23],
    [a31, a32, a33]
  ]
}

function updateClippingCanvas ({ relativePoints, width, height }) {
  if (width === 0 || height === 0) { return }
  const canvas = clippingCanvas
  if (canvas.width !== width) { canvas.width = width }
  if (canvas.height !== height) { canvas.height = height }
  const context = canvas.getContext('2d')
  context.clearRect(0, 0, width, height)
  // context.fillRect(0, 0, width, height)
  // return
  context.fillStyle = 'black'
  context.beginPath()
  context.moveTo(relativePoints[0].x, relativePoints[0].y)
  context.lineTo(relativePoints[1].x, relativePoints[1].y)
  context.lineTo(relativePoints[2].x, relativePoints[2].y)
  context.lineTo(relativePoints[3].x, relativePoints[3].y)
  context.closePath()
  context.fill()
  return canvas
}
