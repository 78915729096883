import React from 'react'
import BasePackage from '../../core/base-package'
import ImageResizerView from './image-resizer-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'image-resizer'
  }

  activate = () => {
    this.initImageResizerDialog()
  }

  resizeImage = (options) => {
    const { width, height } = options
    const doc = this.getActiveDoc()
    if (width !== doc.width || height !== doc.height) {
      doc.resize({ width, height }, { resizeContent: true })
      doc.recordVersion({ name: 'Image Size' })
      this.queueDocRender({ mode: 'layersUpdated' })
    }
  }

  initImageResizerDialog = () => {
    const sharedOptions = {
      onConfirm: (options) => {
        this.hideImageResizerDialog()
        this.resizeImage(options)
      },
      onCancel: this.hideImageResizerDialog
    }
    const imageResizer = React.createElement(ImageResizerView, {
      ...sharedOptions,
      getDoc: this.getActiveDoc
    })
    this.imageResizerDialog = this.createDialog(imageResizer, {
      name: 'Image Size',
      dialogSettingsId: 'image-resizer:image-resizer-dialog',
      onCancel: sharedOptions.onCancel
    })
  }

  showImageResizerDialog = () => { this.imageResizerDialog.show() }

  hideImageResizerDialog = () => {
    this.imageResizerDialog.hide()
  }
}
