import classNames from 'classnames'
import React from 'react'
import BaseComponent from '../../core/base-component'
import Slider from './slider'
import { ensureInt } from '../../core/calc'

import './input-slider-row.scss'

export default class extends BaseComponent {
  onInputChange = (value) => {
    this.props.onChange(ensureInt(value, this.props.min))
  }

  render () {
    const { label, value, min, max, onChange, className, autoFocus } = this.props
    return (
      <div className={ classNames('input-slider-row', className) }>
        <div className="input-slider-row__input-row spread align-items-center">
          <label>{ label }</label>
          <input className="char-3"
            type="number"
            autoFocus={ autoFocus }
            value={ value }
            onChange={ (e) => this.onInputChange(e.currentTarget.value) } />
        </div>
        <Slider onChange={ this.onInputChange }
          min={ min }
          max={ max }
          value={ value } />
      </div>
    )
  }
}
