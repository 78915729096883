import ToolPackage from '../../core/tool-package'
import Brush from './brush'
import Pencil from './pencil'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'brush'
    this.tools = [Brush, Pencil]
  }
}
