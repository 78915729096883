import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './layer-actions-bar.scss'

@inject('app') @observer
export default class extends BaseComponent {
  addRasterLayer = () => {
    this.app.executeCommand('docs:add-raster-layer')
  }

  disposeSelectedLayers = () => {
    this.app.executeCommand('docs:delete-selected-layers')
  }

  render () {
    return (
      <div className="layer-actions-bar">
        <button className="text-button layer-actions-bar__new"
                onClick={ this.addRasterLayer }
                title="Add New Layer (Shift+N)">
          <div className="fa fa-file"></div>
          New
        </button>
        <button className="text-button layer-actions-bar__delete"
                onClick={ this.disposeSelectedLayers }
                title="Delete Layer">
          <div className="fa fa-trash"></div>
          Delete
        </button>
      </div>
    )
  }
}
