import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './doc-creator-view.scss'

const SETTINGS_ID = 'doc-creator'
const PERSISTABLE_STATE = { width: 800, height: 600 }

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.initSettings({ settingsId: SETTINGS_ID, persistableState: PERSISTABLE_STATE })
    this.state = { name: 'Untitled', ...this.retrieveState() }
  }

  getDefaultName = () => {
    const { docs } = this.packageFor('docs')
    const docNames = docs.map((doc) => doc.name)
    let index = 1
    let name = 'Untitled-' + index
    while (docNames.includes(name)) {
      index++
      name = 'Untitled-' + index
    }
    return name
  }

  componentWillMount () {
    this.setState({ name: this.getDefaultName() })
  }

  render () {
    const { state } = this
    const { onCancel, onConfirm } = this.props
    return (
      <div className="doc-creator-view">
        <form className="row" onSubmit={ () => onConfirm(state) }>
          <div className="doc-creator-view__inputs">
            <div className="spread align-items-center mb-m">
              <input type="text"
                className="stretch doc-creator-view__title-input"
                autoFocus="true"
                placeholder="Document Title"
                value={ state.name }
                onChange={ (e) => this.setState({ name: e.currentTarget.value }) } />
            </div>
            <div className="form-fields">
              <div className="form-row">
                <label className="form-left">Width:</label>
                <div className="form-right">
                  <input type="number"
                    className="char-5"
                    value={ state.width }
                    onChange={ (e) => this.setState({ width: e.currentTarget.value }) } />
                </div>
              </div>
              <div className="form-row">
                <label className="form-left">Height:</label>
                <div className="form-right">
                  <input type="number"
                    className="char-5"
                    value={ state.height }
                    onChange={ (e) => this.setState({ height: e.currentTarget.value }) } />
                </div>
              </div>
            </div>
          </div>
          <div className="doc-creator-view__border-buttons">
            <button type="submit"
              className="border-button stretch mb-m">
              Ok
            </button>
            <button type="button"
              onClick={ onCancel }
              className="border-button stretch">
              Cancel
            </button>
          </div>
        </form>
      </div>
    )
  }
}
