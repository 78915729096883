import React from 'react'
import BasePackage from '../../core/base-package'
import SaverView from './saver-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'saver'
  }

  activate () {
    this.initSaverDialog()
  }

  quickExport () {
    const settings = this.packageFor('settings')
    const storedState = settings.retrieveState('saver', { exportType: 'png' })
    const exportType = storedState.exportType
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    doc.exportToBlob({ type: exportType }, ({ blob }) => {
      saveAs(blob, doc.name + '.' + exportType)
    })
  }

  initSaverDialog = () => {
    const sharedOptions = {
      onConfirm: this.hideSaverDialog,
      onCancel: this.hideSaverDialog
    }
    const saver = React.createElement(SaverView, {
      getDoc: this.getActiveDoc,
      ...sharedOptions
    })
    this.saverDialog = this.createDialog(saver, {
      name: 'Save File',
      ...sharedOptions
    })
  }

  showSaverDialog = () => {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    this.saverDialog.show()
  }

  hideSaverDialog = () => {
    this.saverDialog.hide()
  }
}
