import ToolPackage from '../../core/tool-package'
import Eraser from './eraser'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'eraser'
    this.tools = [Eraser]
  }
}
