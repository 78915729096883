import { action } from 'mobx'
import Item from './item'
import Tex from './tex'
import ClipFilter from './filters/clip-filter'
import MoveFilter from './filters/move-filter'
import BlendFilter from './filters/blend-filter'
import SimpleQuad, { SIDES } from './simple-quad'
import Point from './point'

import { wgl } from './wgl'
import { getMinMaxOfPoints } from './calc'
import { COMPOSITE_MODE } from '../constants/composite-modes'
import { applyDistortionFilter } from './distort-helpers'

const clipFilter = new ClipFilter()
const moveFilter = new MoveFilter()
const blendFilter = new BlendFilter()

export default class extends Item {
  constructor (options) {
    super(options)
    this.originalMask = undefined
    this.stationaryTex = undefined
    this.transformableTex = undefined
    this.originalQuad = new SimpleQuad()
    this.currentQuad = new SimpleQuad()
    this.transformedQuad = new SimpleQuad()
    this.initialized = false
    this.hasMask = false
    this.clickedQuadPoint = undefined
  }

  initialize ({ layer, mask, boundary }) {
    this.originalQuad.setCorners({ start: boundary.min, end: boundary.max })
    this.currentQuad.setCorners({ start: boundary.min, end: boundary.max })
    this.transformedQuad.setCorners({ start: boundary.min, end: boundary.max })

    this.hasMask = mask.hasData
    if (mask.hasData) {
      if (this.originalMask !== undefined) { this.originalMask.dispose() }
      this.originalMask = new Tex({ width: boundary.width, height: boundary.height })
      wgl.applyFilter({
        filter: clipFilter,
        des: this.originalMask,
        data: {
          _src: mask,
          _hasMask: false,
          _offset: [boundary.min.x, boundary.min.y],
          _compositeMode: COMPOSITE_MODE.NORMAL
        }
      })

      if (this.stationaryTex !== undefined) { this.stationaryTex.dispose() }
      this.stationaryTex = new Tex(layer.size())
      wgl.applyFilter({
        filter: clipFilter,
        des: this.stationaryTex,
        data: {
          _src: layer.tex,
          _mask: mask.hasData ? mask: undefined,
          _hasMask: mask.hasData,
          _offset: [0, 0],
          _compositeMode: COMPOSITE_MODE.SOURCE_OUT
        }
      })
    }

    if (this.transformableTex !== undefined) { this.transformableTex.dispose() }
    this.transformableTex = new Tex({ width: boundary.width, height: boundary.height })
    wgl.applyFilter({
      filter: clipFilter,
      des: this.transformableTex,
      data: {
        _src: layer.tex,
        _mask: this.hasMask ? mask : undefined,
        _hasMask: this.hasMask,
        _offset: [boundary.min.x, boundary.min.y],
        _compositeMode: COMPOSITE_MODE.DESTINATION_IN
      }
    })

    this.initialized = true
  }

  setClickedQuadPoint (clickedPoint) {
    this.clickedQuadPoint = clickedPoint
  }

  handleMoveTransform ({ doc, e, layer, movementDimensions }) {
    const { transformedQuad } = this
    const movementVector = new Point(movementDimensions)
    if (e && e.shiftKey) {
      if (movementVector.x > movementVector.y) {
        movementVector.y = 0
      } else {
        movementVector.x = 0
      }
    }
    transformedQuad.translate({
      x: movementVector.x,
      y: movementVector.y
    })
    this.matchTexsToTransformedQuad({ doc, layer })
    this.lastMode = 'move'
  }

  matchTexsToTransformedQuad ({ doc, layer }) {
    const { transformedQuad, transformableTex, stationaryTex, originalMask } = this
    const texs = [transformableTex, stationaryTex, originalMask]
    for (const tex of texs) {
      if (tex === undefined) { continue }
      tex.saveContext()
      // tex.setTextureParameters({ minFilter: 'LINEAR', magFilter: 'LINEAR' })
      tex.setTextureParameters({ minFilter: 'NEAREST', magFilter: 'NEAREST' })
    }

    applyDistortionFilter({
      transformableTex: this.transformableTex,
      quad: transformedQuad,
      afterRender: ({ tempRenderedTex, min }) => {
        if (this.hasMask) {
          blendFilter.blend({
            wgl,
            des: layer.tex,
            _src1: this.stationaryTex,
            _src2: tempRenderedTex,
            _offset1: [0, 0],
            _offset2: [min.x, min.y]
          })
        } else {
          moveFilter.move({
            wgl,
            des: layer.tex,
            _src: tempRenderedTex,
            _offset: [min.x, min.y]
          })
        }
      }
    })

    if (this.hasMask) {
      applyDistortionFilter({
        transformableTex: this.originalMask,
        quad: transformedQuad,
        afterRender: ({ tempRenderedTex, min }) => {
          moveFilter.move({
            wgl,
            des: doc.selectionTex,
            _src: tempRenderedTex,
            _offset: [min.x, min.y]
          })
        }
      })
    }

    for (const tex of texs) {
      if (tex === undefined) { continue }
      tex.restoreContext()
    }
  }

  handleDistortTransform ({ doc, layer, movementDimensions }) {
    const { clickedQuadPoint, transformedQuad } = this
    transformedQuad.setCorners({
      [clickedQuadPoint.name]: clickedQuadPoint.add(movementDimensions)
    })
    this.matchTexsToTransformedQuad({ doc, layer })
    this.lastMode = 'distort'
  }

  handleScaleTransform ({ doc, e, layer, movementDimensions, p }) {
    const { clickedQuadPoint, currentQuad, transformedQuad } = this
    const pivot = transformedQuad.getOppositeCorner(clickedQuadPoint)
    const { center } = transformedQuad
    const { abs } = Math
    const newCorners = {}
    const clickedPointToPivot = clickedQuadPoint.subtract(pivot)
    let movementVector = new Point(movementDimensions)
    if (e.shiftKey) {
      movementVector = movementVector.projectionOn(clickedPointToPivot)
    }
    for (const cornerName in transformedQuad.getCorners()) {
      const corner = transformedQuad[cornerName]
      if (corner === pivot) { continue }
      if (clickedQuadPoint.name === cornerName) {
        newCorners[cornerName] = clickedQuadPoint.add(movementVector)
        continue
      }
      const cornerToPivot = corner.subtract(pivot)
      const projectedMovement = movementVector.projectionOn(cornerToPivot)
      const newCorner = cornerToPivot.add(projectedMovement)
      newCorners[cornerName] = pivot.add(newCorner)
    }
    transformedQuad.setCorners(newCorners)
    this.matchTexsToTransformedQuad({ doc, layer })
    this.lastMode = 'scale'
  }

  rotate ({ doc, layer, angle }) {
    const { currentQuad, transformedQuad } = this
    const { center } = currentQuad
    const newCorners = {}
    const { cos, sin } = Math
    for (const cornerName in currentQuad.getCorners()) {
      const corner = currentQuad[cornerName]
      newCorners[cornerName] = corner.subtract(center).rotate(angle).add(center)
    }
    transformedQuad.setCorners(newCorners)
    this.matchTexsToTransformedQuad({ doc, layer })
  }

  flipHorizontal ({ doc, layer }) {
    const { currentQuad, transformedQuad } = this
    const { center } = currentQuad
    const newCorners = {
      topLeft: currentQuad.topRight,
      topRight: currentQuad.topLeft,
      bottomLeft: currentQuad.bottomRight,
      bottomRight: currentQuad.bottomLeft
    }
    transformedQuad.setCorners(newCorners)
    this.matchTexsToTransformedQuad({ doc, layer })
  }

  flipVertical ({ doc, layer }) {
    const { currentQuad, transformedQuad } = this
    const { center } = currentQuad
    const newCorners = {
      topLeft: currentQuad.bottomLeft,
      bottomLeft: currentQuad.topLeft,
      topRight: currentQuad.bottomRight,
      bottomRight: currentQuad.topRight
    }
    transformedQuad.setCorners(newCorners)
    this.matchTexsToTransformedQuad({ doc, layer })
  }

  handleRotateTransform ({ doc, layer, movementDimensions, p }) {
    const { clickedQuadPoint, currentQuad, transformedQuad } = this
    const { center } = currentQuad
    const initialVector = clickedQuadPoint.subtract(center)
    const initialAngle = clickedQuadPoint.subtract(center).angle()
    const newAngle = p.subtract(center).angle()
    const angleToRotate = newAngle - initialAngle
    this.rotate({ doc, layer, angle: angleToRotate })
    this.lastMode = 'rotate'
  }

  beginMovement ({ doc, p, mode, ctrlPtBuffer }) {
    this.moveMode = false
    if (p !== undefined) {
      this.clickedQuadPoint = this.currentQuad.nearestPoint(p, ctrlPtBuffer / doc.zoom)
      this.initialPoint = p.clone()
      this.moveMode = this.clickedQuadPoint === undefined
    }
  }

  endMovement ({ doc }) {
    this.moveMode = false
    this.currentQuad.setCorners(this.transformedQuad.getCorners())
    this.clickedQuadPoint = undefined
  }

  updateTransformedQuad () {
    this.transformedQuad.setCorners(this.currentQuad.getCorners())
  }

  transform ({ doc, e, layer, mode, movementDimensions, p }) {
    this.updateTransformedQuad()
    if (this.moveMode || mode === 'move') {
      this.handleMoveTransform({ doc, e, layer, movementDimensions })
      return
    }

    if (this.clickedQuadPoint === undefined) { return }

    if (mode === 'distort') {
      this.handleDistortTransform({ doc, layer, movementDimensions })
      return
    }

    if (mode === 'scale') {
      this.handleScaleTransform({ doc, e, layer, movementDimensions, p })
      return
    }

    if (mode === 'rotate') {
      this.handleRotateTransform({ doc, layer, movementDimensions, p })
      return
    }
  }

  end () {}

  dispose () {
    super.dispose()
    if (this.originalMask !== undefined) { this.originalMask.dispose() }
    if (this.stationaryTex !== undefined) { this.stationaryTex.dispose() }
    if (this.transformableTex !== undefined) { this.transformableTex.dispose() }
  }

  serialize () {
    const info = super.serialize()
    return {
      data: info.data,
      childItems: {
        originalQuad: this.originalQuad,
        currentQuad: this.currentQuad,
        transformedQuad: this.transformedQuad
      }
    }
  }
}
