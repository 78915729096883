import React from 'react'
import FilterPackage from '../../core/filter-package'
import ColorBalanceView from './color-balance-view'
import ColorBalanceFilter from './color-balance-filter'

export default class extends FilterPackage {
  constructor (options) {
    super(options)
    this.id = 'color-balance'
    this.filter = new ColorBalanceFilter()
    this.filterOptions = {}
    this.resetFilterOptions()
  }

  getName () {
    return 'Color Balance'
  }

  getViewClass () {
    return ColorBalanceView
  }

  resetFilterOptions () {
    this.filterOptions.redAmount = 0
    this.filterOptions.greenAmount = 0
    this.filterOptions.blueAmount = 0
  }
}
