import RasterLayer from './raster-layer'
import SvgLayer from './svg-layer'

export function constructLayer (type, options) {
  switch (type) {
    case 'raster':
      return new RasterLayer(options)
    case 'svg':
      return new SvgLayer(options)
    default:
      throw new Error(`Unable to construct a layer with type: ${type}`)
  }
}
