import React from 'react'
import FilterPackage from '../../core/filter-package'
import HueSaturationView from './hue-saturation-view'
import HueSaturationFilter from './hue-saturation-filter'

export default class extends FilterPackage {
  constructor (options) {
    super(options)
    this.id = 'hue-saturation'
    this.filter = new HueSaturationFilter()
    this.filterOptions = {}
    this.resetFilterOptions()
  }

  getName () {
    return 'Hue / Saturation'
  }

  getViewClass () {
    return HueSaturationView
  }

  resetFilterOptions () {
    this.filterOptions.hue = 0
    this.filterOptions.saturation = 0
    this.filterOptions.lightness = 0
  }
}
