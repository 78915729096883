import React from 'react'
import BasePackage from './base-package'
import { wgl } from './wgl'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'filter'
    this.name = this.getName()
    this.filter = undefined
    this.layer = undefined
    this.doc = undefined
    this.originalLayerTex = undefined
    this.filterOptions = {}
    const viewComponent = this.getViewComponent()
    if (viewComponent !== undefined) {
      const viewComponentOptions = this.getViewComponentOptions()
      this.filterDialog = this.createDialog(viewComponent, viewComponentOptions)
    }
  }

  getViewClass () {}

  getName () { return 'Filter' }

  showInDialog ({ doc, layer }) {
    if (this.filterDialog === undefined) {
      throw new Error(this.name + ' does not have a dialog view')
    }
    this.setContext({ doc, layer })
    this.beginFilter()
    this.filterDialog.show()
  }

  hasInitialFilterValue () { return false }

  beginFilter () {
    this.resetFilterOptions()
    if (this.hasInitialFilterValue() === true) {
      this.applyFilter()
    }
  }

  setContext ({ doc, layer }) {
    this.doc = doc
    this.layer = layer
    this.originalLayerTexUpdatedAt = this.layer.tex.updatedAt
    this.originalLayerTex = this.layer.tex.deepClone()
  }

  applyFilter () {
    wgl.applyFilter({
      filter: this.filter,
      data: {
        ...this.filterOptions,
        src: this.originalLayerTex
      },
      des: this.layer.tex
    })
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  revertFilter () {
    this.layer.tex.swapTexture(this.originalLayerTex)
    this.layer.tex.updatedAt = this.originalLayerTexUpdatedAt
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  endFilter () {
    this.originalLayerTex.dispose()
    this.originalLayerTex = undefined
    this.layer = undefined
    this.doc = undefined
  }

  resetFilterOptions () {}

  getDialogName () {
    return this.name
  }

  setFilterOption (key, value) {
    this.filterOptions[key] = value
    this.applyFilter()
  }

  getViewComponent () {
    if (this.getViewClass() === undefined) { return }
    const view = React.createElement(this.getViewClass(), {
      onCancel: (...args) => this.cancelFilter(...args),
      onConfirm: (...args) => this.confirmFilter(...args),
      setFilterOption: (...args) => this.setFilterOption(...args)
    })
    return view
  }

  getViewComponentOptions () {
    return {
      name: this.getDialogName(),
      dialogSettingsId: 'filters:' + this.id + ':filter-dialog' ,
      onConfirm: (e) => this.confirmFilter(e),
      onCancel: () => this.cancelFilter()
    }
  }

  confirmFilter = (e) => {
    e.preventDefault()
    this.doc.recordVersion({ name: this.name })
    this.endFilter()
    this.filterDialog.hide()
  }

  cancelFilter = () => {
    this.revertFilter()
    this.endFilter()
    this.filterDialog.hide()
  }
}
