import React from 'react'
import BasePackage from '../../core/base-package'
import DocStatusView from './doc-status-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'doc-status'
  }

  activate () {
    const { docs } = this.packageFor('docs')
    const docStatusView = React.createElement(DocStatusView, { docs })
    this.packageFor('status-bar').addLeftItem({ viewComponent: docStatusView,
      priority: 100 })
  }
}
