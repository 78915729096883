import Filter from '../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'doc-view'
  }

  getFragmentShader () {
    return `
      const float CURSOR_TYPE_CIRCLE = 1.0;
      const float CURSOR_TYPE_CROSSHAIR = 2.0;
      const float CURSOR_TYPE_SQUARE = 3.0;

      const float _maskAlphaTreshold = 0.01;

      uniform sampler2D _docTexure;
      uniform sampler2D _mask;

      uniform float _skipViewportClipping;
      uniform float _tileSize;
      uniform float _tick;

      uniform float _cursorTypes[10];
      uniform float _cursorSizes[10];
      uniform vec2 _cursorOffsets[10];
      uniform float _cursorThicknesses[10];
      uniform float _cursorCount;

      uniform vec2 _rectCenters[10];
      uniform vec2 _rectSizes[10];
      uniform float _rectThicknesses[10];
      uniform float _rectCount;

      uniform vec2 _lineStarts[10];
      uniform vec2 _lineEnds[10];
      uniform float _lineThicknesses[10];
      uniform float _lineCount;

      uniform vec2 _crosshairCenters[10];
      uniform float _crosshairSizes[10];
      uniform float _crosshairThicknesses[10];
      uniform float _crosshairCount;

      uniform bool _drawCursor;
      uniform vec2 _mousePosition;

      bool allMaskNeighboursAreFilled () {
        float x = coordX();
        float y = coordY();
        float count = 0.0;
        for (float i = -1.0; i <= 1.0; i += 1.0) {
          for (float j = -1.0; j <= 1.0; j += 1.0) {
            float x1 = (x + i - _viewOffset.x) / _viewSize.x;
            float y1 = (y + j - _viewOffset.y) / _viewSize.y;
            if (i == 0.0 && j == 0.0) { continue; }
            if (x1 < 0.0 || x1 > 1.0 || y1 < 0.0 || y1 > 1.0) { return false; }

            vec2 texCoord = vec2(x1, y1);
            vec4 texColor = texture(_mask, texCoord);
            if (texColor.a < _maskAlphaTreshold) { return false; }
          }
        }
        return true;
      }

      int intMod (int x, int y) {
        return int(float(x) - float(y) * floor(float(x) / float(y)));
      }

      vec4 getDashedBorderColor (float x, float y, vec2 _dashOffset) {
        int floorX = -(int(x - 0.5) + int(_tick) - int(_dashOffset.x));
        int floorY = int(y - 0.5) - int(_dashOffset.y);
        int dashWidth = 6;
        int modX = intMod(floorX, dashWidth * 2);
        int modX2 = intMod(floorX + dashWidth, dashWidth * 2);
        int modY = intMod(floorY, dashWidth * 2);
        float rgbValue = 0.0;
        if ((modY >= modX && modY < modX + dashWidth) ||
            (modX2 < modX && modY < modX2)) {
          rgbValue = 1.0;
        }
        return vec4(rgbValue, rgbValue, rgbValue, 1.0);
      }

      vec4 getMaskColor () {
        vec4 maskColor = viewportTexColorFor(_mask);
        if (maskColor.a < _maskAlphaTreshold || allMaskNeighboursAreFilled()) {
          return EMPTY_COLOR;
        }
        return getDashedBorderColor(coordX(), coordY(), _viewOffset);
      }

      vec4 getTileColor () {
        float tileFactor = _tileSize * 2.0;
        vec2 relativeCoord = relativeViewCoord();
        float x = relativeCoord.x;
        float y = relativeCoord.y;
        float offsetX = x - floor(x / tileFactor) * tileFactor;
        float offsetY = y - floor(y / tileFactor) * tileFactor;
        float greyValue = 214.0 / 255.0;
        vec4 grey = vec4(greyValue, greyValue, greyValue, 1.0);
        vec4 white = vec4(1.0, 1.0, 1.0, 1.0);

        if (!coordInView()) { return vec4(0.0, 0.0, 0.0, 0.0); }

        if (offsetX < _tileSize && offsetY < _tileSize) {
          return grey;
        }

        if (offsetX > _tileSize && offsetY > _tileSize) {
          return grey;
        }

        return white;
      }

      float getBrightnessDifference (vec4 color) {
        float brightness = getBrightnessOfColor(color);
        float value = 1.0 - brightness;
        return value;
      }

      vec4 getCircleCursorColor (float size, float thickness, vec2 offset, vec4 currentColor) {
        vec2 center = _mousePosition + offset;
        float radius = size / 2.0;
        float innerRadius = radius - thickness / 2.0;
        float outerRadius = radius + thickness / 2.0;
        vec2 position = currPosition();
        float distance = getDistance(position, center);
        if (distance > outerRadius || distance < innerRadius) { return EMPTY_COLOR; }
        float value = getBrightnessDifference(currentColor);
        return vec4(value, value, value, 0.5);
      }

      bool inRect (vec2 center, vec2 size, vec2 position) {
        vec2 topLeft = center - size / 2.0;
        vec2 bottomRight = center + size / 2.0;
        return position.x >= topLeft.x &&
               position.y >= topLeft.y &&
               position.x < bottomRight.x &&
               position.y < bottomRight.y;
      }

      float getDistanceToLine (vec2 start, vec2 end, vec2 p) {
        float numerator = (end.y - start.y) * p.x -
                          (end.x - start.x) * p.y +
                          end.x * start.y - end.y * start.x;
        float denominator = getDistance(start, end);
        return abs(numerator) / denominator;
      }

      vec4 getLineColor (vec4 currentColor) {
        vec2 position = currPosition();
        for (int i = 0; i < int(_lineCount); i++) {
          float thickness = _lineThicknesses[i];
          vec2 lineStart = _lineStarts[i];
          vec2 lineEnd = _lineEnds[i];
          float distance = getDistanceToLine(lineStart, lineEnd, position);
          if (distance < thickness) {
            vec2 center = (lineStart + lineEnd) / 2.0;
            vec2 size = vec2(
              max(abs(lineEnd.x - lineStart.x), thickness * 2.0),
              max(abs(lineEnd.y - lineStart.y), thickness * 2.0)
            );
            if (inRect(center, size, position)) {
              float value = getBrightnessDifference(currentColor);
              return vec4(value, value, value, 0.5);
            }
          }
        }
        return EMPTY_COLOR;
      }

      vec4 getRectColor (vec4 currentColor) {
        vec2 position = currPosition();
        for (int i = 0; i < int(_rectCount); i++) {
          vec2 center = _rectCenters[i];
          float thickness = _rectThicknesses[i];
          vec2 innerSize = _rectSizes[i] - (thickness / 2.0);
          vec2 outerSize = _rectSizes[i] + (thickness / 2.0);
          if (inRect(center, outerSize, position) && !inRect(center, innerSize, position)) {
            float value = getBrightnessDifference(currentColor);
            return vec4(value, value, value, 0.5);
          }
        }
        return EMPTY_COLOR;
      }

      vec4 getCrosshairColor (vec4 currentColor) {
        vec2 position = currPosition();
        for (int i = 0; i < int(_crosshairCount); i++) {
          vec2 center = _crosshairCenters[i];
          float size = _crosshairSizes[i];
          float thickness = _crosshairThicknesses[i];
          if (inRect(center, vec2(thickness, size), position) ||
              inRect(center, vec2(size, thickness), position)) {
            float value = getBrightnessDifference(currentColor);
            return vec4(value, value, value, 0.5);
          }
        }
        return EMPTY_COLOR;
      }

      vec4 getCrosshairCursorColor (float size, float thickness, vec2 offset, vec4 currentColor) {
        vec2 center = _mousePosition + offset;
        vec2 position = currPosition();
        if (inRect(center, vec2(thickness, size), position) ||
            inRect(center, vec2(size, thickness), position)) {
          float value = getBrightnessDifference(currentColor);
          return vec4(value, value, value, 0.5);
        }
        return EMPTY_COLOR;
      }

      vec4 getSquareCursorColor (float size, float thickness, vec2 offset, vec4 currentColor) {
        vec2 center = _mousePosition + offset;
        vec2 position = currPosition();
        float innerSize = size - (thickness / 2.0);
        float outerSize = size + (thickness / 2.0);
        if (inRect(center, vec2(outerSize, outerSize), position) &&
            !inRect(center, vec2(innerSize, innerSize), position)) {
          float value = getBrightnessDifference(currentColor);
          return vec4(value, value, value, 0.5);
        }
        return EMPTY_COLOR;
      }

      vec4 getCursorColor (vec4 currentColor) {
        for (int i = 0; i < int(_cursorCount); i++) {
          float cursorType = _cursorTypes[i];
          float size = _cursorSizes[i];
          float thickness = _cursorThicknesses[i];
          vec2 offset = _cursorOffsets[i];
          if (cursorType == CURSOR_TYPE_CIRCLE) {
            vec4 color = getCircleCursorColor(size, thickness, offset, currentColor);
            if (color.a > 0.0) { return color; }
          }
          if (cursorType == CURSOR_TYPE_CROSSHAIR) {
            vec4 color = getCrosshairCursorColor(size, thickness, offset, currentColor);
            if (color.a > 0.0) { return color; }
          }
          if (cursorType == CURSOR_TYPE_SQUARE) {
            vec4 color = getSquareCursorColor(size, thickness, offset, currentColor);
            if (color.a > 0.0) { return color; }
          }
        }
        return EMPTY_COLOR;
      }

      void main () {
        vec4 color = getTileColor();
        vec4 docColor;

        if (_skipViewportClipping == 1.0) {
          docColor = texColorFor(_docTexure);
        } else {
          docColor = viewportTexColorFor(_docTexure);
        }
        color = drawColorOnTop(docColor, color);

        vec4 maskColor = getMaskColor();
        color = drawColorOnTop(maskColor, color);

        if (_lineCount > 0.0) {
          vec4 lineColor = getLineColor(color);
          color = drawColorOnTop(lineColor, color);
        }

        if (_rectCount > 0.0) {
          vec4 rectColor = getRectColor(color);
          color = drawColorOnTop(rectColor, color);
        }

        if (_crosshairCount > 0.0) {
          vec4 crosshairColor = getCrosshairColor(color);
          color = drawColorOnTop(crosshairColor, color);
        }

        if (_drawCursor) {
          vec4 cursorColor = getCursorColor(color);
          color = drawColorOnTop(cursorColor, color);
        }

        outputColor = color;
      }
    `
  }
}
