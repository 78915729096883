import $ from 'jquery'
import { ensureValidCss } from '../core/style-helpers'
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed'
import Point from '../core/point'
import Dom from '../core/dom'

export class El {
  constructor (element) {
    this.element = element
    this.jElement = $(element)
  }
  removeClass (...args) { return this.jElement.removeClass(...args) }
  offset () { return this.jElement.offset() }
  relativeCoordinates (element, options = {}) {
    const parentOffset = element === window ? { left: 0, top: 0 } : el(element).offset()
    const offset = this.offset()
    let x = offset.left - parentOffset.left
    let y = offset.top - parentOffset.top
    if (options.includeScroll) {
      const hasScrollbars = el(element).hasScrollbars()
      if (hasScrollbars.horizontal) { x += element.scrollLeft }
      if (hasScrollbars.vertical) { y += element.scrollTop }
    }
    return new Point({ x, y })
  }
  closest (selector) { return this.jElement.closest(selector) }
  innerWidth () { return this.jElement.innerWidth() }
  innerHeight () { return this.jElement.innerHeight() }
  innerSize () { return { width: this.innerWidth(), height: this.innerHeight() } }
  outerWidth () { return this.jElement.outerWidth() }
  outerHeight () { return this.jElement.outerHeight() }
  outerSize (options = {}) {
    const size = { width: this.outerWidth(), height: this.outerHeight() }
    if (options.excludeScrollbars !== true) { return size }
    const hasScrollbars = this.hasScrollbars()
    const scrollBarSize = Dom.getScrollBarSize()
    if (hasScrollbars.vertical) { size.width = size.width - scrollBarSize.width }
    if (hasScrollbars.horizontal) { size.height = size.height - scrollBarSize.height }
    return size
  }
  position () { return this.jElement.position() }
  hasScrollbars () {
    return { horizontal: this.hasHorizontalScroll(), vertical: this.hasVerticalScroll() }
  }
  hasHorizontalScroll () {
    return this.element.scrollWidth > this.element.clientWidth
  }
  hasVerticalScroll () {
    return this.element.scrollHeight > this.element.clientHeight
  }
  visible () { return this.jElement.is(':visible') }
  style = (value) => {
    const validStyle = ensureValidCss(value)
    for (const key in validStyle) {
      this.element.style[key] = validStyle[key]
    }
  }

  removeChild (child) {
    const parent = this.element
    if ([parent, child].includes(undefined)) { return }
    if (Array.from(parent.childNodes).indexOf(child) === -1) { return }
    parent.removeChild(child)
  }

  removeAllChildNodes () {
    const parent = this.element
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild)
    }
  }

  clone () {
    const canvases = []
    this.jElement.find('canvas').each(function (index) {
      canvases[index] = this
    })
    const c = this.element.cloneNode(true)
    $(c).find('canvas').each(function (index) {
      this.getContext('2d').drawImage(canvases[index], 0, 0)
    })
    return c
  }

  canvasClone () {
    const c = this.element.cloneNode(true)
    if (c.width > 0 || c.height > 0) {
      c.getContext('2d').drawImage(this.element, 0, 0)
    }
    return c
  }

  scrollToBottom () {
    this.element.scrollTop = this.element.scrollHeight
  }

  siblings (selector) { return this.jElement.siblings(selector) }

  find (selector) {
    const elements = this.jElement.find(selector)
    if (elements.length === 0) { return }

    return elements[0]
  }

  children (selector) { return this.jElement.children(selector) }

  scrollIntoView () {
    scrollIntoViewIfNeeded(this.element)
  }
}

export default function el (element) {
  return (element instanceof El) ? element : new El(element)
}
