import React from 'react'
import BasePackage from '../../core/base-package'
import DocTabViewList from './doc-tab-view-list'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'doc-tabs'
  }

  activate () {
    this.initDocTabViewList()
  }

  closeDoc = (doc) => {
    this.packageFor('docs').close(doc)
  }

  initDocTabViewList = () => {
    const { docs } = this.packageFor('docs')
    const docTabViewList = React.createElement(DocTabViewList, { docs, closeDoc: this.closeDoc })
    this.packageFor('layouts').addLayoutItem('center',
      { viewComponent: docTabViewList, row: 25 })
  }
}
