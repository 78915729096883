import { inject, observer } from 'mobx-react'
import React from 'react'
import classNames from 'classnames'
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed'
import BaseComponent from '../../core/base-component'

import './doc-version-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  componentDidMount () {
    if (this.props.isActive) {
      scrollIntoViewIfNeeded(this.refs.root)
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (!this.props.isActive && nextProps.isActive && this.refs.root) {
      scrollIntoViewIfNeeded(this.refs.root)
    }
  }

  restoreVersion = (versionId) => {
    this.packageFor('docs').restoreVersion(versionId)
  }

  render () {
    const { doc, version, isActive } = this.props
    return (
      <div ref="root"
        onClick={ () => this.restoreVersion(version.id) }
        className={ classNames('p-m activeable doc-version-view', { active: isActive }) }>
        { version.name }
      </div>
    )
  }
}
