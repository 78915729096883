import hsh from '../lib/hsh'
import el from '../lib/el'
import EventList from './event-list'
import Event from './event'
import Dom from './dom'

const DEFAULT_INITIAL_SCROLL_RATIO = { left: 0.5, top: 0.5 }
const DEFAULT_MIN_REMNANT_AREA = { width: 100, height: 100 }

export default class {
  constructor (options) {
    this.events = new EventList()
    this.programmaticScroll = false
    this.initialScrollRatio = options.initialScrollRatio || DEFAULT_INITIAL_SCROLL_RATIO
    this.minRemnantArea = options.minRemnantArea || DEFAULT_MIN_REMNANT_AREA
    hsh(this).assignValues(options, ['element', 'container', 'scrollContainer'])
    this.scrollRatio = {
      left: this.initialScrollRatio.left,
      top: this.initialScrollRatio.top
    }
    this.scrollRatio.left = this.initialScrollRatio.left
    this.scrollRatio.top = this.initialScrollRatio.top
    this.events.addDomEvent(this.scrollContainer, 'scroll', this.onScroll)
    this.onElementPositionUpdate = new Event()
  }

  hasScrollbars () {
    return el(this.scrollContainer).hasScrollbars()
  }

  resetScrollRatioIfNeeded () {
    requestAnimationFrame(() => {
      const { scrollRatio, initialScrollRatio } = this
      const hasScroll = this.hasScrollbars()
      if (!hasScroll.horizontal) { scrollRatio.left = initialScrollRatio.left }
      if (!hasScroll.vertical) { scrollRatio.top = initialScrollRatio.top }
    })
  }

  storeScrollRatio () {
    const { scrollContainer, element, scrollRatio, programmaticScroll } = this
    if (!el(element).visible() || programmaticScroll) { return }
    const hasScroll = this.hasScrollbars()
    const scrollableArea = this.scrollableArea()
    if (hasScroll.horizontal) { scrollRatio.left = scrollContainer.scrollLeft / scrollableArea.width }
    if (hasScroll.vertical) { scrollRatio.top = scrollContainer.scrollTop / scrollableArea.height }

    this.resetScrollRatioIfNeeded()
  }

  restoreScrollRatio () {
    const { scrollContainer, scrollRatio } = this
    const hasScroll = this.hasScrollbars()
    const scrollableArea = this.scrollableArea()
    this.programmaticScroll = true
    if (hasScroll.horizontal) {
      scrollContainer.scrollLeft = scrollableArea.width * scrollRatio.left
    }
    if (hasScroll.vertical) {
      scrollContainer.scrollTop = scrollableArea.height * scrollRatio.top
    }
  }

  onScroll = () => {
    this.updateElementPosition()
    if (this.programmaticScroll) {
      this.programmaticScroll = false
      return
    }
    this.storeScrollRatio()
  }

  scrollableArea () {
    const { container } = this
    const scrollContainerSize = this.getScrollContainerSize()
    const containerSize = el(container).innerSize()
    return {
      width: parseFloat(containerSize.width - scrollContainerSize.width),
      height: parseFloat(containerSize.height - scrollContainerSize.height)
    }
  }

  getScrollContainerSize () {
    return el(this.scrollContainer).innerSize()
  }

  updateElementPosition () {
    const { scrollContainer, container, element } = this
    const scrollContainerSize = this.getScrollContainerSize()
    const containerSize = el(container).outerSize()
    const elementSize = el(element).outerSize()
    const prevPosition = {
      left: element.offsetLeft,
      top: element.offsetTop
    }
    const elementOffset = {
      left: (containerSize.width - elementSize.width) / 2,
      top: (containerSize.height - elementSize.height) / 2
    }
    let elementLeft
    let elementTop
    if (containerSize.width < scrollContainerSize.width) {
      elementLeft = (scrollContainerSize.width - elementSize.width) / 2
    } else {
      elementLeft = elementOffset.left - scrollContainer.scrollLeft
    }
    if (containerSize.height < scrollContainerSize.height) {
      elementTop = (scrollContainerSize.height - elementSize.height) / 2
    } else {
      elementTop = elementOffset.top - scrollContainer.scrollTop
    }
    elementLeft = Math.round(elementLeft)
    elementTop = Math.round(elementTop)
    element.style.left = `${elementLeft + scrollContainer.scrollLeft}px`
    element.style.top = `${elementTop + scrollContainer.scrollTop}px`
    if (prevPosition.left !== elementLeft || prevPosition.top !== elementTop) {
      this.onElementPositionUpdate.trigger()
    }
  }

  elementsResized () {
    this.restoreScrollRatio()
    this.resetScrollRatioIfNeeded()
    this.updateElementPosition()
  }

  updateContainerPadding (newElementSize) {
    const { minRemnantArea, container } = this
    const newPadding = { x: 0, y: 0 }
    const scrollBarSize = Dom.getScrollBarSize()
    const scrollContainerSize = this.getScrollContainerSize()
    const willHaveHorizontalScrollbar = newElementSize.width > scrollContainerSize.width
    const willHaveVerticalScrollbar = newElementSize.height > scrollContainerSize.height
    const hasScroll = this.hasScrollbars()

    if (willHaveHorizontalScrollbar) {
      // Add an adjustment if a scrollbar would become present but is currently not present
      const scrollBarWidthAdjustment = willHaveVerticalScrollbar && !hasScroll.vertical ? scrollBarSize.width : 0
      const adjustedContainerWidth = scrollContainerSize.width - scrollBarWidthAdjustment
      newPadding.x = adjustedContainerWidth - minRemnantArea.width
    }
    if (willHaveVerticalScrollbar) {
      // Add an adjustment if a scrollbar would become present but is currently not present
      const scrollBarHeightAdjustment = willHaveHorizontalScrollbar && !hasScroll.horizontal ? scrollBarSize.height : 0
      const adjustedContainerHeight = scrollContainerSize.height - scrollBarHeightAdjustment
      newPadding.y = adjustedContainerHeight - minRemnantArea.height
    }

    el(container).style({
      width: newElementSize.width + newPadding.x * 2,
      height: newElementSize.height + newPadding.y * 2
    })
  }

  dispose () {
    this.events.dispose()
  }
}
