import { observer } from 'mobx-react'
import React from 'react'
import DocLayerView from './doc-layer-view'
import classNames from 'classnames'
import BaseComponent from '../../core/base-component'

import './doc-layer-view-list.scss'

@observer
export default class extends BaseComponent {
  render () {
    const { doc, layers, className, viewportRectContainer, viewportRect,
      onViewportRectUpdate } = this.props
    return (
      <div className={ classNames('doc-layer-view-list non-blocking', className) }
        ref="root">
        { layers.map((layer) =>
          <DocLayerView doc={ doc }
            layer={ layer }
            key={ layer.id }
            viewportRect={ viewportRect }
            viewportRectContainer={ viewportRectContainer }
            onViewportRectUpdate={ onViewportRectUpdate } />
        ) }
      </div>
    )
  }
}
