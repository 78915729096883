import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import { parseShortcut } from '../../core/shortcuts-helpers'

@inject('app') @observer
export default class extends BaseComponent {
  crop = () => { this.app.executeCommand('tools.marquee:crop') }
  selectAll = () => { this.app.executeCommand('tools.marquee:select-all') }
  clearSelection = () => { this.app.executeCommand('tools.marquee:clear-selection') }
  invertSelection = () => { this.app.executeCommand('tools.marquee:invert-selection') }

  render () {
    const { crop, selectAll, clearSelection, invertSelection } = this
    return (
      <div className="marquee-options-view align-items-center">
        <button className="tool-options-button mr-l"
                onClick={ invertSelection }
                title={ parseShortcut('Invert Selection (Meta+Shift+I)')}>
          Invert Selection
        </button>
        <button className="tool-options-button mr-l" onClick={ crop }>
          Crop to Selection
        </button>
        <button className="tool-options-button mr-l" onClick={ clearSelection }
                title={ parseShortcut('Select None (Meta+D)')}>
          Select None
        </button>
      </div>
    )
  }
}
