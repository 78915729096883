import Filter from '../filter'
import { getBilinearInterpolationSnippet } from './snippets/bilinear-interpolation'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'distort'
  }

  getFragmentShader () {
    return getBilinearInterpolationSnippet() + `
      uniform sampler2D _src;
      uniform vec2 _srcSize;

      uniform sampler2D _mask;
      uniform vec2 _maskSize;

      uniform float _a11;
      uniform float _a12;
      uniform float _a13;

      uniform float _a21;
      uniform float _a22;
      uniform float _a23;

      uniform float _a31;
      uniform float _a32;
      uniform float _a33;

      vec2 getTransformedTexCoord (float x, float y) {
        float w = _a13 * x + _a23 * y + _a33;
        float transformedX = (_a11 * x + _a21 * y + _a31) / w;
        float transformedY = (_a12 * x + _a22 * y + _a32) / w;

        return vec2(transformedX, transformedY);
      }

      void main () {
        float x = coordX();
        float y = coordY();

        vec2 texCoord = getTransformedTexCoord(x, y);
        vec2 position = texCoord * _srcSize;
        vec4 color = getInterpolatedColor(_src, _srcSize, position);
        // vec4 color = texture(_src, texCoord);
        vec4 maskColor = texColorFor(_mask, _maskSize);
        color.a = color.a * maskColor.a;
        outputColor = color;
      }
    `
  }
}
