import Filter from '../filter'
import { withDefault } from '../utils'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'fill'
  }

  fill ({ wgl, refTex, mask, useMask, seedColor, tolerance, fillStyle, desTexture }) {
    wgl.applyFilter({
      filter: this,
      des: {
        texture: desTexture,
        width: refTex.width,
        height: refTex.height
      },
      data: {
        _src: refTex,
        _mask: mask,
        _useMask: useMask,
        _seedColor: seedColor,
        _tolerance: tolerance,
        _fillStyle: withDefault(fillStyle, [0, 0, 0, 1])
      }
    })
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform vec2 _srcSize;

      uniform sampler2D _mask;
      uniform vec2 _maskSize;

      uniform bool _useMask;
      uniform float _tolerance;

      uniform vec4 _seedColor;
      uniform vec4 _fillStyle;

      bool isComponentWithinTolerance (float c1, float c2) {
        return abs(c1 - c2) <= _tolerance;
      }

      bool isRgbWithinTolerance (vec4 c1, vec4 c2) {
        if (!isComponentWithinTolerance(c1.r, c2.r)) { return false; }
        if (!isComponentWithinTolerance(c1.g, c2.g)) { return false; }
        if (!isComponentWithinTolerance(c1.b, c2.b)) { return false; }
        return true;
      }

      bool isAlphaWithinTolerance (vec4 c1, vec4 c2) {
        return isComponentWithinTolerance(c1.a, c2.a);
      }

      float alphaForRgbDistance (vec4 c1, vec4 c2) {
        float r = abs(c1.r - c2.r);
        float g = abs(c1.g - c2.g);
        float b = abs(c1.b - c2.b);
        float diff = r + g + b;
        float alpha = 1.0 - diff;
        if (alpha < 0.0) { return 0.0; }
        return alpha;
      }

      void main () {
        vec4 maskColor = texColorFor(_mask, _maskSize);

        if (_useMask && maskColor.a == 0.0) {
          outputColor = EMPTY_COLOR;
          return;
        }

        vec4 srcColor = texColorFor(_src, _srcSize);
        bool emptySeedColor = _seedColor.a == 0.0;

        if (emptySeedColor == false && !isRgbWithinTolerance(_seedColor, srcColor)) {
          outputColor = EMPTY_COLOR;
          return;
        }

        if (emptySeedColor == true && !isAlphaWithinTolerance(_seedColor, srcColor)) {
          outputColor = EMPTY_COLOR;
          return;
        }

        vec4 color = vec4(_fillStyle.rgb, 0.0);

        if (emptySeedColor) {
          color.a = 1.0;
        } else {
          color.a = alphaForRgbDistance(_seedColor, srcColor);
        }

        float srcMultiplier = emptySeedColor ? (1.0 - srcColor.a) : srcColor.a;
        color.a = color.a * srcMultiplier;

        if (_useMask) {
          color.a = color.a * maskColor.a;
        }

        outputColor = color;
      }
    `
  }
}
