import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import EventList from '../../core/event-list'
import el from '../../lib/el'
import SvgView from '../../core/svg-view'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.state = {
      svgWidth: 0,
      svgHeight: 0,
      scale: 1,
      translate: { x: 0, y: 0 }
    }
  }

  updateSvgDimensions = () => {
    const { root } = this.refs
    const rootSize = el(root).innerSize()
    this.setState({
      svgWidth: rootSize.width,
      svgHeight: rootSize.height
    })
  }

  componentDidMount () {
    this.events = new EventList()
    this.events.addDomEvent(this.refs.root, 'resize', this.updateSvgDimensions)
    this.updateSvgDimensions()
  }

  componentWillUnmount () { this.events.dispose() }

  render () {
    const { layer } = this.props
    return (
      <div className="svg-layer-view fill-absolute checkered-background-small" ref="root">
        <SvgView svg={ layer }
          fitToContainer={ true }
          width={ this.state.svgWidth }
          height={ this.state.svgHeight } />
      </div>
    )
  }
}
