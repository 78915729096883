import { inject, observer } from 'mobx-react'
import { action } from 'mobx'
import React from 'react'
import BaseComponent from '../../core/base-component'
import Draggable from '../../core/draggable'
import Svg from '../../core/svg'
import Circle from '../../core/vectors/circle'
import tinycolor from 'tinycolor2'
import EventList from '../../core/event-list'
import SvgView from '../../core/svg-view'

import './shade-picker.scss'

const CIRCLE_MARKER_ID = 'circle-marker'

@inject('app') @observer
export default class extends BaseComponent {
  updateMarkerColor = () => {
    const circle = this.marker.findVector(CIRCLE_MARKER_ID)
    const { newColor } = this.props
    const strokeColor = newColor.isDark() ? 'white' : 'black'
    if (strokeColor === circle.stroke) { return }
    circle.updateAttribute('stroke', strokeColor)
  }
  updateShade = () => {
    const { onShadeUpdated } = this.props
    if (onShadeUpdated === undefined) { return }
    const { elementPositionRatio } = this.draggable
    const hsvSaturation = Math.round(elementPositionRatio.x * 100)
    const hsvValue = Math.round((1 - elementPositionRatio.y) * 100)
    this.hsvSaturation = hsvSaturation
    this.hsvValue = hsvValue
    onShadeUpdated(hsvSaturation, hsvValue)
    this.updateMarkerColor()
  }
  syncMarkerPosition () {
    const { newColor } = this.props
    this.draggable.setElementPositionRatio({
      x: newColor.s / 100.0,
      y: (100 - newColor.v) / 100.0
    })
    this.updateMarkerColor()
  }
  @action initMarker () {
    const r = 7
    const borderWidth = 1
    const marker = this.marker
    marker.resize({ width: r * 2 + borderWidth * 2, height: r * 2 + borderWidth * 2 })
    const circle = new Circle({ id: CIRCLE_MARKER_ID, stroke: 'white' })
    circle.setCenter(r + borderWidth, r + borderWidth)
    circle.setRadius(r)
    marker.addVector(circle)
    this.marker = marker

    const element = this.refs.marker
    element.style.width = `${this.marker.getWidth()}px`
    element.style.height = `${this.marker.getHeight()}px`
    const clickContainer = this.refs.root
    this.draggable = new Draggable({
      element,
      elementToCursorAlignment: 'centered',
      clickContainer
    })
  }
  componentWillMount () {
    this.marker = new Svg()
  }
  componentDidMount () {
    this.initMarker()
    this.draggable.onContinueMovement = () => { this.updateShade() }
    const { newColor } = this.props
    this.events = new EventList()
    this.events.addObserver(newColor, 'sv', () => {
      if (newColor.s === this.hsvSaturation && newColor.v === this.hsvValue) {
        return
      }
      this.syncMarkerPosition()
    })
    this.events.addDomEvent(this.refs.root, 'resize', () => this.syncMarkerPosition())
    this.syncMarkerPosition()
  }
  componentWillUnmount () {
    this.draggable.dispose()
    this.events.dispose()
  }
  shadePickerStyle = () => {
    const style = {
      background: this.props.hueColor.styleString
    }
    return style
  }
  render () {
    return (
      <div className="shade-picker fill-absolute" ref="root" style={ { ...this.shadePickerStyle() } }>
        <div className="shade-picker__light-gradient fill-absolute"></div>
        <div className="shade-picker__dark-gradient fill-absolute"></div>
        <div className="shade-picker__marker-container fill-absolute" ref="markerContainer">
          <div className="shade-picker__marker" ref="marker">
            <SvgView svg={ this.marker } />
          </div>
        </div>
      </div>
    )
  }
}
