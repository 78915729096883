import { observer } from 'mobx-react'
import React from 'react'
import EventList from '../../core/event-list'
import { getRelativeViewportDimensions } from './doc-layer-view-helpers'
import BaseComponent from '../../core/base-component'
import classNames from 'classnames'
import './doc-viewport-follower.scss'

@observer
export default class extends BaseComponent {
  componentDidMount () {
    const { onViewportRectUpdate } = this.props
    this.events = new EventList()
    this.events.addEvent(onViewportRectUpdate, this.queueResizeElementsToViewport)
    this.queueResizeElementsToViewport()
  }
  componentWillUnmount () { this.events.dispose() }

  queueResizeElementsToViewport = () => {
    this.events.setTask('resize-elements-to-viewport', this.resizeElementsToViewport)
  }

  resizeToViewport (element, viewportDimensions, borderWidth) {
    element.style.left = (viewportDimensions.x - borderWidth) + 'px'
    element.style.top = (viewportDimensions.y - borderWidth) + 'px'
    const { viewportWidthOverride, viewportHeightOverride } = this.props
    const finalWidth = viewportWidthOverride === undefined ? viewportDimensions.width : viewportWidthOverride
    const finalHeight = viewportHeightOverride === undefined ? viewportDimensions.height : viewportHeightOverride
    element.style.width = finalWidth + 'px'
    element.style.height = finalHeight + 'px'
  }

  resizeElementsToViewport = () => {
    const { viewportRectContainer, viewportRect } = this.props
    const { rect } = this
    const viewportDimensions = getRelativeViewportDimensions({
      viewportContainer: viewportRectContainer,
      viewport: viewportRect
    })

    const borderWidth = 0
    this.resizeToViewport(rect, viewportDimensions, borderWidth)
  }

  rectRefCallback = (rect) => {
    this.rect = rect
    if (this.props.rectRef) { this.props.rectRef(rect) }
  }

  render () {
    return (
      <div className={ classNames('doc-viewport-follower', this.props.className) }
        ref="root">
        <div className={ classNames('doc-viewport-follower__rect position-absolute', this.props.rectClassName) }
          ref={ this.rectRefCallback }>
          { this.props.children }
        </div>
      </div>
    )
  }
}
