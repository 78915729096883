import React from 'react'
import { observable, action } from 'mobx'
import Tool from '../../core/tool'
import Stabilizer from '../../core/stabilizer'
import { pool } from '../..//core/gl-canvas-pool'
import { getSmoothPoints } from '../../core/curve-helpers'
import Point from '../../core/point'
import hsh from '../../lib/hsh'
import StrokableOptionsView from './strokable-options-view'
import StrokeFilter from '../../core/filters/stroke-filter'
import BlendFilter from '../../core/filters/blend-filter'
import Tex from '../../core/tex'
import EventList from '../../core/event-list'
import { MID_GRAY } from '../../constants/colors'
import { wgl } from '../../core/wgl'
import { COMPOSITE_MODE } from '../../constants/composite-modes'
import { CURSOR } from '../../constants/cursors'

const MIN_CURSOR_SIZE = 5
const MANUAL_OFFSET_UNIT = 0.01
const strokeFilter = new StrokeFilter()
const blendFilter = new BlendFilter()

export const BRUSH_TYPE = { CIRCLE: 1, SQUARE: 2 }

export default class extends Tool {
  @observable options = {
    opacityPercentage: 100,
    sliderSize: 10,
    size: 30,
    softnessPercentage: 100,
    stabilization: 1,
    speedSensitivity: 10
  }

  constructor (options = {}) {
    super(options)
    this.id = options.id
    this.name = options.name
    this.settingsId = options.settingsId
    this.validLayerTypes = { raster: true }
    this.strokeTex = new Tex()
    this.cursorOffset = { x: 0, y: 0 }
  }

  getGlBrushType () {
    return BRUSH_TYPE.CIRCLE
  }

  activate = () => {
    this.optionsView = React.createElement(this.getOptionsViewClass(), {
      setOpacityPercentage: this.setOpacityPercentage,
      setSoftnessPercentage: this.setSoftnessPercentage,
      setSize: this.setSize,
      setStabilization: this.setStabilization,
      settingsId: this.settingsId,
      setOption: this.setOption,
      className: this.getOptionsViewClassName(),
      takeUpdateViewSize: (updateViewSize) => {
        this.updateViewSize = updateViewSize
      }
    })
  }

  getOptionsViewClassName () { return '' }

  getOptionsViewClass () {
    return StrokableOptionsView
  }

  getCursorType ({ doc }) {
    if (this.dragToResize) { return 'default' }
    if (doc !== undefined) {
      const customCursorSize = this.options.size * doc.zoom
      if (customCursorSize < MIN_CURSOR_SIZE) {
        return 'crosshair'
      }
    }
    return 'none'
  }

  getAltCursorType () {
    return 'crosshair'
  }

  getCustomCursorType () {
    return CURSOR.CIRCLE
  }

  getCustomCursors () {
    return [
      {
        type: this.getCustomCursorType(),
        size: this.options.size,
        sizeIsZoomDependent: true,
        offset: [this.cursorOffset.x, this.cursorOffset.y],
        thickness: 1
      }
    ]
  }

  @action setOpacityPercentage = (opacityPercentage) => {
    this.options.opacityPercentage = opacityPercentage
  }

  @action setSoftnessPercentage = (softnessPercentage) => {
    this.options.softnessPercentage = softnessPercentage
  }

  @action setSize = (size) => {
    if (size < 1) { size = 1 }
    this.options.size = parseInt(size)
  }

  @action setStabilization = (stabilization) => {
    this.options.stabilization = stabilization
  }

  @action setOption = (key, value) => {
    this.options[key] = value
  }

  getMinDistanceBetweenPatterns = () => {
    return 1
  }

  pickColor ({ doc, p }) {
    const color = doc.colorAtPoint(p)
    if (color === undefined) { return }
    this.activeForegroundColor().setRgb(color)
  }

  altBeginMovement ({ doc, p }) {
    this.pickColor({ doc, p })
  }

  beginMovement ({ doc, p, e }) {
    this.cursorOffset = { x: 0, y: 0 }
    if (e.altKey && (e.ctrlKey || e.metaKey)) {
      e.preventDefault()
      this.dragToResize = true
      this.originalCursorPoint = p
      this.originalStrokeSize = this.options.size
      return
    }
    if (e.altKey) { return this.altBeginMovement({ doc, p }) }
    this.prevPoint3 = undefined // the previous previous previous point
    this.prevPoint2 = undefined // the previous previous point
    this.prevPoint1 = undefined // the previous point
    this.stabilizer = new Stabilizer({ stabilization: this.options.stabilization })
    this.stroking = true

    this.lastDrawnPoints = []

    const layer = doc.activeLayer
    this.strokeTex.resize(layer.size())
    this.strokeTex.clear()
    this.originalLayerTex = layer.tex.deepClone()
    this.strokeTexHasData = false
    doc.lockPan()
    this.strokePoints = []
    this.lastProcessedPointIndex = 0
    if (e.shiftKey && this.endPoint !== undefined) {
      this.stroke({ doc, p: this.endPoint })
    }
    if (e.shiftKey) {
      this.beginPoint = p
    }
    this.stroke({ doc, p })
  }

  continueStrokeFlow ({ doc, cursorPoint, stabilizedPoint, movementRect }) {
    this.cancelStrokeFlow()
    this.strokeFlowTimoutId = setTimeout(() => {
      if (stabilizedPoint.distanceTo(cursorPoint) > 5) {
        this.continueMovement({ doc, p: cursorPoint, movementRect })
      }
    }, 10)
  }

  cancelStrokeFlow () {
    if (this.strokeFlowTimoutId !== undefined) {
      clearTimeout(this.strokeFlowTimoutId)
    }
  }

  continueMovement ({ doc, p, e, movementRect }) {
    const movementDimensions = movementRect.getDimensions()
    if (this.dragToResize) {
      const { originalStrokeSize } = this
      if (movementRect.start.x < movementRect.end.x) {
        this.setSize(originalStrokeSize + movementDimensions.width)
      } else {
        this.setSize(originalStrokeSize - movementDimensions.width)
      }
      this.cursorOffset = { x: -movementDimensions.xDiff, y: -movementDimensions.yDiff }
      this.updateViewSize(this.options.size)
      return
    }
    if (e !== undefined && e.buttons !== 1) { return }
    if (e !== undefined && e.altKey) { return this.altBeginMovement({ doc, p }) }
    if (!this.stroking) { return }
    if (e !== undefined && e.shiftKey && this.beginPoint === undefined) {
      this.beginPoint = p
    }
    const pClone = p.clone()
    if (e !== undefined && e.shiftKey && this.beginPoint !== undefined) {
      if (Math.abs(movementDimensions.x) > Math.abs(movementDimensions.y)) {
        pClone.y = this.beginPoint.y
      } else {
        pClone.x = this.beginPoint.x
      }
    }
    const stabilizedPoint = this.stabilizer.stabilize(pClone)
    this.stroke({ doc, p: stabilizedPoint })
    if (this.options.stabilization > 1) {
      this.continueStrokeFlow({ doc, cursorPoint: pClone, stabilizedPoint, movementRect })
    }
  }

  endMovement ({ doc }) {
    this.beginPoint = undefined
    this.cursorOffset = { x: 0, y: 0 }
    if (this.dragToResize === true) {
      this.dragToResize = false
      this.queueDocRender()
      return
    }
    if (!this.stroking) { return }
    this.stroke({ doc, p: this.prevPoint1 })
    this.endPoint = this.prevPoint1
    this.cancelStrokeFlow()
    const layer = doc.activeLayer
    doc.recordVersion({ name: this.name })
    this.stroking = false
    this.stabilizer.dispose()
    if (this.originalLayerTex !== undefined) {
      this.originalLayerTex.dispose()
    }
    doc.unlockPan()
  }

  stroke ({ doc, p }) {
    const currentPoint = p

    this.paintStroke(this.prevPoint3, this.prevPoint2, this.prevPoint1, currentPoint)
    this.prevPoint3 = this.prevPoint2
    this.prevPoint2 = this.prevPoint1
    this.prevPoint1 = currentPoint
  }

  patternizeStrokeTex () {}

  paintStroke = (p0, p1, p2, p3) => {
    const doc = this.getActiveDoc()
    const points = this.getInterpolatedPoints(p0, p1, p2, p3)
    const spacing = this.getMinDistanceBetweenPatterns()
    const filteredPoints = []
    for (const point of points) {
      let skip = false
      for (const lastPoint of this.lastDrawnPoints) {
        if (Math.hypot(point.x - lastPoint.x, point.y - lastPoint.y) >= spacing) {
          skip = true
          break
        }
      }
      filteredPoints.push(point)
    }
    const batchSize = 100
    for (let i = 0; i < filteredPoints.length; i += batchSize) {
      const currPoints = filteredPoints.slice(i, i + batchSize)
      this.paintStrokeBatch(doc, currPoints)
    }

    this.lastDrawnPoints = filteredPoints.slice(10)

    this.patternizeStrokeTex()

    const layer = doc.activeLayer
    blendFilter.blend({
      wgl,
      des: layer.getTex(),
      _src1: this.originalLayerTex,
      _src2: this.getPatternedStrokeTex(),
      _mask: doc.selectionTex,
      _alpha: this.options.opacityPercentage / 100.0,
      _compositeMode: this.getEraserMode() ? COMPOSITE_MODE.SOURCE_OUT : COMPOSITE_MODE.NORMAL,
      _hasMask: doc.selectionTex.hasData
    })

    this.queueDocRender({ mode: 'layersUpdated' })
  }

  getPatternedStrokeTex () {
    return this.strokeTex
  }

  paintStrokeBatch (doc, points) {
    const layer = doc.activeLayer

    const transformedPoints = points.map((point) => {
      return [
        point.x,
        point.y
      ]
    })

    const { strokeTex } = this
    const radius = this.options.size / 2.0
    const width = layer.getWidth()
    const height = layer.getHeight()

    wgl.applyFilter({
      filter: strokeFilter,
      des: { texture: 'temp', width, height },
      data: {
        _fillStyle: this.activeForegroundColor().forShader(),
        _points: transformedPoints,
        _radius: Math.round(radius),
        _numPoints: transformedPoints.length,
        _softnessRatio: this.options.softnessPercentage / 100.0,
        _src: this.strokeTex,
        _srcHasData: this.strokeTexHasData,
        _brushType: this.getGlBrushType()
      }
    })
    this.strokeTex.swapTexture(wgl.tempRenderedTex)
    this.strokeTexHasData = true
  }

  getImageSmoothingEnabled () {
    return true
  }

  getEraserMode () {
    return false
  }

  getInterpolatedPoints (p0, p1, p2, p3) {
    if (p0 === undefined && p1 === undefined && p2 === undefined) { return [p3] }
    if (p0 === undefined) { p0 = new Point({ x: p2.x - MANUAL_OFFSET_UNIT * 2, y: p2.y - MANUAL_OFFSET_UNIT * 2 }) }
    if (p1 === undefined) { p1 = new Point({ x: p2.x - MANUAL_OFFSET_UNIT * 1, y: p2.y - MANUAL_OFFSET_UNIT * 1 }) }

    let points = [p0, p1, p2, p3]
    if (points.includes(undefined)) { return [p3] }
    points = points.map((p) => new Point(p))
    const maxCurveLength = p0.distanceTo(p1) + p1.distanceTo(p2) + p2.distanceTo(p3)
    const minDistanceBetweenPatterns = this.getMinDistanceBetweenPatterns()
    const numberOfCurvePoints = maxCurveLength / minDistanceBetweenPatterns
    if (numberOfCurvePoints < 3) { return [p3] }
    const smoothPoints = getSmoothPoints(points, minDistanceBetweenPatterns)
    return smoothPoints
  }

  dispose () {
    super.dispose()
    this.strokeTex.dispose()
  }
}
