import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import EventList from '../../core/event-list'
import classNames from 'classnames'

@observer
export default class extends BaseComponent {
  componentWillMount () {
    const { newColor } = this.props
    this.hsvColor = { h: newColor.h, s: newColor.s, v: newColor.v }
    this.events = new EventList()
  }
  componentDidMount () {
    const { hsvColor } = this
    const { newColor } = this.props
    const hsvKeys = ['h', 's', 'v']
    hsvKeys.forEach((key) => {
      this.events.addObserver(newColor, key, () => {
        if (hsvColor[key] === newColor[key]) { return }
        hsvColor[key] = newColor[key]
        this.setState({ hsvColor })
      })
    })
  }
  onHsvInputChange = (e, key) => {
    const inputValue = e.currentTarget.value
    const { hsvColor } = this
    hsvColor[key] = inputValue
    this.setState({ hsvColor })
    const value = parseFloat(inputValue)
    if (isNaN(value)) { return }
    const { newColor } = this.props
    const options = { h: newColor.h, s: newColor.s, v: newColor.v }
    options[key] = value
    newColor.setHsv(options)
  }
  componentWillUnmount () {
    this.events.dispose()
  }
  onHsvInputBlur = (e, key) => {
    const { hsvColor } = this
    const { newColor } = this.props
    if (hsvColor[key] === newColor[key]) { return }
    hsvColor[key] = newColor[key]
    this.setState({ hsvColor })
  }
  render () {
    const { onHsvInputChange, onHsvInputBlur, hsvColor } = this
    const hsvKeys = ['h', 's', 'v']

    return (
      <tbody>
      { hsvKeys.map((key) => {
        return (
          <tr key={ key }
            className={ classNames({ 'last-row-of-group': key === 'v' }) }>
            <td>{ key.toUpperCase() }:</td>
            <td>
              <input type="number"
                autoFocus={ key === 'h' }
                value={ hsvColor[key] }
                onChange={ (e) => onHsvInputChange(e, key) }
                onBlur={ (e) => onHsvInputBlur(e, key) }/>
            </td>
            <td>
              { key === 'h' ? <span>&deg;</span> : '%' }
            </td>
          </tr>
        )
      })}
      </tbody>)
  }
}
