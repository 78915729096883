import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './status-bar-item-view.scss'

@observer
export default class extends BaseComponent {
  render () {
    const { item } = this.props
    const { viewComponent } = item
    return (
      <div className="status-bar-item-view">
        { viewComponent }
      </div>
    )
  }
}
