import ToolPackage from '../../core/tool-package'
import CloneStamp from './clone-stamp'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'clone-stamp'
    this.tools = [CloneStamp]
  }
}
