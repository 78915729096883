import Item from './item'
import Dom from './dom'
import hsh from '../lib/hsh'
import Color from './color'
import el from '../lib/el'
import { generateId } from './id'
import { fabricHasData, getFabricBoundary } from './fabric-helpers'

export default class Fabric extends Item {
  constructor (options = {}) {
    super(options)
    this.setCanvas(options.canvas || Dom.canvas())
    if (!options.canvas) {
      const { width, height } = options
      this.resize({ width, height })
    }
    this.hasData = false
    this.boundary = undefined
  }

  desForAnchor (fabric, fabricRef, anchor) {
    return {
      x: parseInt((fabric.getWidth() - fabricRef.getWidth()) / 2.0 * anchor.x),
      y: parseInt((fabric.getHeight() - fabricRef.getHeight()) / 2.0 * anchor.y)
    }
  }

  resize ({ width, height }, options = {}) {
    if (width === undefined) { width = 0 }
    if (height === undefined) { height = 0 }
    if (this.getWidth() === width && this.getHeight() === height) { return }
    let fabricRef
    if (options.resizeContent || options.anchor || options.crop) {
      fabricRef = this.deepClone()
    }
    this.canvas.width = width
    this.canvas.height = height
    if (options.resizeContent) {
      this.pasteFabric(fabricRef, { options: { fitToDes: true } })
    }
    if (options.anchor) {
      const des = this.desForAnchor(this, fabricRef, options.anchor)
      this.pasteFabric(fabricRef, { des })
    }
    if (options.crop) {
      this.pasteFabric(fabricRef, {
        des: { x: -options.crop.x, y: -options.crop.y }
      })
    }
    if (fabricRef) { fabricRef.dispose() }
    this.touch()
  }

  size () {
    return { width: this.getWidth(), height: this.getHeight() }
  }

  transferCanvasTo (fabric) {
    fabric.replaceCanvas(this.canvas)
    this.canvas = undefined
  }

  getContext () { return this.canvas.getContext('2d') }
  getWidth () { return this.canvas === undefined ? 0 : this.canvas.width }
  getHeight () { return this.canvas === undefined ? 0 : this.canvas.height }

  clear (options = {}) {
    const defaultValues = { x: 0, y: 0, width: this.getWidth(), height: this.getHeight() }
    hsh(options).assignDefaultValues(defaultValues)
    this.getContext().clearRect(options.x, options.y, options.width, options.height)
    if (hsh(options).isEqualTo(defaultValues)) {
      this.hasData = false
    }
    this.touch()
  }

  pasteFabric (fabric, { src = {}, des = {}, options = {}, contextOptions = {}, transform = {} } = {}) {
    hsh(src).assignDefaultValues({
      x: 0,
      y: 0,
      width: fabric.getWidth(),
      height: fabric.getHeight()
    })
    hsh(des).assignDefaultValues({
      x: 0,
      y: 0,
      width: fabric.getWidth(),
      height: fabric.getHeight()
    })
    if (options.fitToDes) {
      des.width = this.getWidth()
      des.height = this.getHeight()
    }
    this.drawImage(fabric.canvas, { src, des, options, contextOptions, transform })
  }

  getFactoryKey () {
    return 'Fabric'
  }

  drawImage (img, { src = {}, des = {}, options = {}, contextOptions = {}, transform = {} } = {}) {
    if (img === undefined) { return }
    const defaultWidth = (img.getWidth !== undefined) ? img.getWidth() : img.width
    const defaultHeight = (img.getHeight !== undefined) ? img.getHeight() : img.height
    if (defaultWidth === undefined || defaultWidth === 0) { return }
    if (defaultHeight === undefined || defaultHeight === 0) { return }
    const defaultDimensions = { x: 0, y: 0, width: defaultWidth, height: defaultHeight }

    hsh(src).assignDefaultValues(defaultDimensions)
    hsh(des).assignDefaultValues(defaultDimensions)

    this.draw((context) => {
      context.drawImage(img, src.x, src.y, src.width, src.height, des.x, des.y, des.width, des.height)
    }, contextOptions, transform)
  }

  drawRect ({ x, y, width, height, contextOptions = {} }) {
    this.draw((context) => {
      if (contextOptions.fillStyle) { context.fillRect(x, y, width, height) }
      if (contextOptions.strokeStyle) { context.strokeRect(x, y, width, height) }
    }, contextOptions)
  }

  drawCircle ({ x, y, r, startAngle = 0, endAngle = 2 * Math.PI, contextOptions = {} }) {
    this.draw((context) => {
      context.beginPath()
      context.arc(x, y, r, startAngle, endAngle)
      if (contextOptions.fillStyle) { context.fill() }
      if (contextOptions.strokeStyle) { context.stroke() }
      context.closePath()
    }, contextOptions)
  }

  drawOval ({ x, y, width, height, rotation = 0, startAngle = 0, endAngle = 2 * Math.PI, contextOptions = {} }) {
    this.draw((context) => {
      context.beginPath()
      context.ellipse(x, y, width / 2.0, height / 2.0, rotation, startAngle, endAngle)
      if (contextOptions.fillStyle) { context.fill() }
      if (contextOptions.strokeStyle) { context.stroke() }
      context.closePath()
    }, contextOptions)

  }

  drawLine ({ p1, p2, contextOptions = {} }) {
    this.draw((context) => {
      context.beginPath()
      context.moveTo(p1.x, p1.y)
      context.lineTo(p2.x, p2.y)
      context.stroke()
    }, contextOptions)
  }

  draw (callback, contextOptions = {}, transform = {}) {
    const settableContextOptions = [
      'imageSmoothingEnabled', 'globalCompositeOperation', 'globalAlpha',
      'strokeStyle', 'fillStyle', 'lineWidth'
    ]
    const savedContextOptions = {}
    const context = this.getContext()
    for (const key in contextOptions) {
      if (!settableContextOptions.includes(key)) { continue }
      savedContextOptions[key] = context[key]
      this.setContextOption(key, contextOptions[key])
    }
    this.saveContext()
    this.transformContext(transform)
    callback(context)
    this.restoreContext()
    for (const key in savedContextOptions) {
      context[key] = savedContextOptions[key]
    }
    this.hasData = true
    this.touch()
  }

  setContextOption (key, value) {
    const context = this.getContext()
    context[key] = value
  }

  transformContent (transform) {
    const oldCanvas = this.canvas
    const newCanvas = Dom.canvas(this.size())
    this.setCanvas(newCanvas)
    this.drawImage(oldCanvas, { transform })
  }

  transformContext (transform = {}) {
    const context = this.getContext()
    const { translate, scale } = transform
    if (translate !== undefined) { context.translate(translate.x, translate.y) }
    if (scale !== undefined) { context.scale(scale.x, scale.y) }
  }

  /*
   * Atributes saved:
   * The current transformation matrix.
   * The current clipping region.
   * The current dash list.
   * The current values of the following attributes:
   * strokeStyle, fillStyle, globalAlpha,
   * lineWidth, lineCap, lineJoin, miterLimit, lineDashOffset,
   * shadowOffsetX, shadowOffsetY, shadowBlur, shadowColor,
   * globalCompositeOperation, font, textAlign, textBaseline,
   * direction, imageSmoothingEnabled.
  */
  saveContext () {
    this.getContext().save()
  }

  restoreContext () {
    this.getContext().restore()
  }

  fill (options = {}) {
    const start = options.start || { x: 0, y: 0 }
    const end = options.end || { x: this.getWidth(), y: this.getHeight() }
    const topLeft = {
      x: start.x < end.x ? start.x : end.x,
      y: start.y < end.y ? start.y : end.y
    }
    const bottomRight = {
      x: start.x > end.x ? start.x : end.x,
      y: start.y > end.y ? start.y : end.y
    }
    topLeft.x = topLeft.x
    topLeft.y = topLeft.y
    bottomRight.x = bottomRight.x
    bottomRight.y = bottomRight.y
    const fillWidth = bottomRight.x - topLeft.x
    const fillHeight = bottomRight.y - topLeft.y

    this.draw((context) => {
      context.fillRect(topLeft.x, topLeft.y, fillWidth, fillHeight)
    }, options.contextOptions)
  }

  setCanvas (canvas) {
    this.disposeCanvas()
    if (canvas.id === undefined || canvas.id.length === 0) { canvas.id = generateId() }
    this.canvas = canvas
    this.touch()
  }

  replaceCanvas (canvas) {
    this.canvas = canvas
    this.touch()
  }

  calcHasData () {
    this.hasData = fabricHasData(this)
    return this.hasData
  }

  calcBoundary () {
    const boundary = getFabricBoundary(this)
    this.boundary = boundary
    const { min, max } = boundary
    if (min.x === 0 && max.x === 0 && min.y === 0 && max.y === 0) {
      this.hasData = false
    } else {
      this.hasData = true
    }
    return boundary
  }

  clone () {
    return new Fabric(this.size())
  }

  deepClone = () => {
    const b = new Fabric(this.size())
    b.pasteFabric(this)
    b.hasData = this.hasData
    return b
  }

  colorAtPoint (p) {
    const data = this.getContext().getImageData(p.x, p.y, 1, 1).data
    return new Color({
      r: data[0],
      g: data[1],
      b: data[2],
      a: data[3] / 255.0
    })
  }

  toBlob (callback) {
    this.canvas.toBlob(function (blob) {
      const url = URL.createObjectURL(blob)
      callback(url)
    })
  }

  asTexture () {
    return {
      src: this.canvas,
      id: this.id,
      updatedAt: this.updatedAt
    }
  }

  getCanvas () { return this.canvas }

  disposeCanvas () {
    if (this.canvas) {
      this.canvas.width = 0
      this.canvas.height = 0
    }
  }

  zero () {
    const { canvas } = this
    if (canvas.width !== 0) { canvas.width = 0 }
    if (canvas.height !== 0) { canvas.height = 0 }
  }

  dispose () {
    super.dispose()
    this.disposeCanvas()
    this.canvas = undefined
  }
}
