import BasePackage from '../../core/base-package'
import DialogView from './dialog-view'
import Event from '../../core/event'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'dialogs'
    this.DialogView = DialogView
    this.dialogStateWasChanged = new Event()
  }

  activate () {
    this.app.addMenuSelector('.dialog-view')
  }
}
