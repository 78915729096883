import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import { computed } from 'mobx'
import React from 'react'
import BaseComponent from '../../core/base-component'
import EventList from '../../core/event-list'
import { BLEND_MODE_LABELS } from '../../constants/blend-modes'

import './layer-options-bar.scss'

@inject('app') @observer
export default class extends BaseComponent {
  componentWillMount () {
    this.events = new EventList()
  }

  componentWillUnmount () {
    this.events.dispose()
  }

  @computed get activeLayer () {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    return doc.activeLayer
  }

  onOpacityInputChange = (value) => {
    this.getActiveDoc().setLayerOpacityPercentage(value)
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  onBlendModeInputChange = (e) => {
    const { value } = e.currentTarget
    this.getActiveDoc().setLayerBlendMode(value)
    this.queueDocRender({ mode: 'layersUpdated' })
    requestAnimationFrame(() => {
      this.refs.blendModeInput.blur()
    })
  }

  rootStyle () {
    const docsPackage = this.packageFor('docs')
    return {
      display: docsPackage.preloaded === true ? 'block' : 'none'
    }
  }

  layerOptionsTemplate = (docs) => {
    const layer = this.activeLayer
    const SlidableInput = this.packageFor('sliders').SlidableInput
    if (layer === undefined) { return }
    return (
      <div className="align-items-center" style={ this.rootStyle() }>
        <select ref="blendModeInput" value={ layer.blendMode }
          onChange={ this.onBlendModeInputChange }>
          { BLEND_MODE_LABELS.map((mode) =>
            <option key={ mode } value={ mode }>{ _.startCase(mode.toLowerCase()) }</option>
          )}
        </select>
        <SlidableInput value={ layer.opacityPercentage }
          onChange={ this.onOpacityInputChange } />
      </div>
    )
  }

  render () {
    const docsPackage = this.packageFor('docs')
    const { docs } = docsPackage
    return (
      <div className="layer-options-bar align-items-center">
        { docs.count() > 0 && this.layerOptionsTemplate() }
      </div>
    )
  }
}
