import Item from '../../core/item'

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.viewComponent = options.viewComponent
    this.priority = options.priority
  }

  getPriority = () => { return this.priority }
}
