import { observable, action } from 'mobx'
import Dom from '../../core/dom'
import React from 'react'
import Tool from '../../core/tool'
import TextOptionsView from './text-options-view'
import Color from '../../core/color'
import EventList from '../../core/event-list'
import { globals } from '../../globals'
import el from '../../lib/el'
import $ from 'jquery'

const PADDING_BUFFER = 50

export default class extends Tool {
  @observable options = {
    fontSize: 25,
    fontFamily: 'Arial',
    color: '000000',
    textAlignment: 'left',
    textWidth: 0
  }

  constructor (options = {}) {
    super({ ...options, id: 'text', name: 'Text', icon: null })
    this.icon = 'wi-text'
    this.info = 'Text (T)'
    this.colorObj = new Color()
    this.colorObj.colorWasUpdated.subscribe(() => {
      this.options.color = this.colorObj.hex
      if (this.setViewState === undefined) { return }
      this.setViewState({ color: this.colorObj.hex })
      this.updateSelectedLayer()
    })
    this.events = new EventList()
  }

  @action syncOptionsToActiveLayer = () => {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const layer = doc.activeLayer
    if (layer === undefined) { return }
    if (layer.isTextType() !== true) { return }
    if (this.setViewState === undefined) { return }
    const { options } = this
    if (layer.style.fontSize === undefined || isNaN(layer.style.fontSize) || layer.style.fontSize.length === 0) {
      return
    }
    options.fontSize = layer.style.fontSize
    options.fontFamily = layer.style.fontFamily
    options.color = layer.style.color
    options.textAlignment = layer.style.textAlignment
    options.textWidth = layer.textWidth
    this.colorObj.setHex(layer.style.color)
    this.setViewState({
      fontSize: layer.style.fontSize,
      fontFamily: layer.style.fontFamily,
      color: layer.style.color,
      textAlignment: layer.style.textAlignment,
      textWidth: layer.textWidth
    })
  }

  activate () {
    super.activate()
    this.optionsView = this.createOptionsView()
    const docsPackage = this.packageFor('docs')
    this.events.addEvent(globals.observables.layerables.activeId, () => {
      this.syncOptionsToActiveLayer()
    })
    this.events.addEvent(globals.observables.doc.size, () => {
      this.syncOptionsToActiveLayer()
    })
  }

  createOptionsView () {
    return React.createElement(TextOptionsView, {
      setOption: (key, value) => this.setOption(key, value),
      colorObj: this.colorObj,
      colorClicked: () => this.showColorPickerDialog(),
      addTextLayer: () => this.addTextLayer(),
      editText: () => this.editText(),
      takeSetState: (setState) => {
        this.setViewState = setState
      }
    })
  }

  colorWasPicked = () => {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return  }
    doc.recordVersion({ name: 'Format Text Layer' })
  }

  colorPickerWasClosed = () => {
    const colorPickerPackage = this.packageFor('color-picker')
    const { colorPickerDialog } = colorPickerPackage
    colorPickerPackage.colorWasPicked.unsubscribe(this.colorWasPicked)
    colorPickerDialog.dialogWasHidden.unsubscribe(this.colorPickerWasClosed)
  }

  showColorPickerDialog () {
    const colorPickerPackage = this.packageFor('color-picker')
    const { colorPickerDialog } = colorPickerPackage
    colorPickerPackage.colorWasPicked.subscribe(this.colorWasPicked)
    colorPickerDialog.dialogWasHidden.subscribe(this.colorPickerWasClosed)
    colorPickerPackage.showColorPickerDialog(this.colorObj, { dynamicUpdate: true })
  }

  @action updateSelectedLayer () {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const layer = doc.activeLayer
    if (layer === undefined) { return }
    if (layer.isTextType() !== true) { return }

    layer.textWidth = this.options.textWidth
    layer.updateStyling(this.options)
    layer.touch()
  }

  @action setOption (key, value) {
    this.options[key] = value
    this.updateSelectedLayer()
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const layer = doc.activeLayer
    if (layer.isTextType()) {
      doc.recordVersion({ name: 'Format Text Layer' })
    }
  }

  getCursorType = ({ doc, mousePositionInDoc }) => {
    if (mousePositionInDoc !== undefined) {
      const hoveredLayer = doc.layerAtPoint(mousePositionInDoc)
      if (hoveredLayer !== undefined && hoveredLayer.isTextType()) {
        return 'text'
      }
    }
    return 'crosshair'
  }

  enterTextEditMode = (options = {}) => {
    const doc = this.getActiveDoc()
    const layer = doc.activeLayer
    if (layer.isTextType() !== true) {
      alert('The selected layer is not a text layer, you must first select a text layer or use the "Add Text Layer" button to add a text layer')
      return
    }
    doc.enterTextEditMode(options)
  }

  editText = () => {
    this.enterTextEditMode()
  }

  getDefaultTextWidth ({ doc, position, fontSize }) {
    const buffer = PADDING_BUFFER
    let textWidth = fontSize * 10
    if (textWidth > doc.width - buffer) { textWidth = doc.width - buffer }
    if (position !== undefined && this.options.textAlignment !== 'right') {
      if (position.x + textWidth > doc.width - buffer) {
        textWidth = doc.width - buffer - position.x
      }
    }
    return parseInt(textWidth)
  }

  getDefaultTextPoint ({ doc, fontSize, textWidth }) {
    const textHeight = fontSize
    const p = {
      x: (doc.width - textWidth) / 2.0,
      y: (doc.height - textHeight) / 2.0,
    }
    if (p.x > doc.width / 2.0) { p.x = 0 }
    if (p.y > doc.height / 2.0) { p.y = 0 }

    return p
  }

  addTextLayer = (position) => {
    const doc = this.getActiveDoc()
    const { options } = this
    const width = doc.getWidth()
    const height = doc.getHeight()
    const textWidth = this.getDefaultTextWidth({ doc, position, fontSize: options.fontSize })
    const p = position !== undefined ? position : this.getDefaultTextPoint({ doc, fontSize: options.fontSize, textWidth })
    if (this.options.textAlignment === 'right' && position !== undefined) { p.x -= textWidth }
    const layerOptions = {
      width,
      height,
      textWidth,
      x: p.x,
      y: p.y - options.fontSize / 2.0,
      style: {
        color: options.color,
        fontSize: options.fontSize,
        fontFamily: options.fontFamily,
        textAlignment: options.textAlignment
      }
    }
    const docsPackage = this.packageFor('docs')
    docsPackage.addTextLayer(doc.id, {}, layerOptions)
    requestAnimationFrame(() => {
      doc.enterTextEditMode({ skipSelection: true })
    })
  }

  @action beginMovement ({ doc, element, e, p }) {
    const clickedLayer = doc.layerAtPoint(p)
    if (clickedLayer.isTextType()) {
      doc.layerables.setActiveId(clickedLayer.id, { autoSelect: true })
      doc.enterTextEditMode({ skipSelection: true })
      e.preventDefault()
      e.stopPropagation()
      return
    }
    this.addTextLayer(p)
  }

  dispose () {
    this.events.dispose()
  }
}
