import { inject } from 'mobx-react'
import React from 'react'
import Draggable from '../../core/draggable'
import el from '../../lib/el'
import BaseComponent from '../../core/base-component'

import './dialog-view.scss'

@inject('app')
export default class extends BaseComponent {
  getDefaultPosition () {
    const root = this.refs.root
    const rootSize = el(root).outerSize()
    const windowSize = el(window).outerSize()
    const left = (windowSize.width - rootSize.width) / 2
    const top = (windowSize.height - rootSize.height) / 2
    return { left, top }
  }
  componentDidMount () {
    const propOptions = this.props.options
    const root = this.refs.root
    const defaultPosition = this.getDefaultPosition()
    el(root).style(defaultPosition)
    this.draggable = new Draggable({
      element: root,
      handle: this.refs.topbar,
      container: window,
      elementLimit: 'unbounded'
    })
    const settingsPackage = this.packageFor('settings')
    const { dialogSettingsId } = propOptions
    if (dialogSettingsId !== undefined) {
      const prevSettings = settingsPackage.retrieve(dialogSettingsId)
      if (prevSettings !== undefined && prevSettings !== null) {
        this.draggable.setElementPositionRatio(prevSettings.elementPositionRatio)
      }
      this.draggable.onEndMovement = () => {
        settingsPackage.store(dialogSettingsId, {
          elementPositionRatio: this.draggable.elementPositionRatio
        })
      }
    }
  }
  componentWillUnmount () {
    this.draggable.dispose()
  }
  render () {
    const { options, viewComponent } = this.props
    return (
      <div className="dialog-view border-1 shadow-1 active" ref="root">
        <div className="dialog-view__topbar p-m" ref="topbar">
          { options.name }
        </div>
        <div className="dialog-view__content">
          { viewComponent }
        </div>
      </div>
    )
  }

  static defaultProps = {
    options: {}
  }
}
