import Collection from './collection'

const globalFactories = {}

export function getGlobalFactories () { return globalFactories }

export function registerGlobalFactory (factoryKey, factoryClass) {
  if (globalFactories[factoryKey] !== undefined) {
    throw new Error(`A factory with key "${factoryKey}" already exists, factory keys must be unique`)
  }
  globalFactories[factoryKey] = (options) => new factoryClass(options)
}

export function getGlobalFactory (factoryKey) {
  const factory = globalFactories[factoryKey]
  if (factory === undefined) {
    throw new Error('Unable to retrieve factory with key ' + factoryKey)
  }
  return factory
}
