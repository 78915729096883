import { observable, action } from 'mobx'
import Layerable from './layerable'
import { registerGlobalFactory } from './factory-references'

export default class LayerGroup extends Layerable {
  @observable isExpanded = true

  @action setIsExpanded (expanded) {
    this.isExpanded = expanded
  }

  type () { return 'group' }
  getFactoryKey () { return 'LayerGroup' }
}

registerGlobalFactory('LayerGroup', LayerGroup)
