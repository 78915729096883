import BaseMarquee from './base-marquee'
import OvalFilter from '../../core/filters/oval-filter'
import { wgl } from '../../core/wgl'

const ovalFilter = new OvalFilter()

export default class extends BaseMarquee {
  constructor (options) {
    super({ ...options, id: 'oval-marquee', name: 'Ellipse Marquee', icon: 'wi-ellipse-marquee' })
    this.info = 'Ellipse Marquee (M)'
  }

  createSelection ({ doc, start, end }) {
    const des = doc.selectionTex
    const _src = this.originalSelectionTex
    const _compositeMode = this.getCompositeMode()
    const _center = {
      x: (start.x + end.x) / 2.0,
      y: (start.y + end.y) / 2.0
    }
    const _size = {
      width: Math.abs(end.x - start.x),
      height: Math.abs(end.y - start.y)
    }
    ovalFilter.fillOval({ wgl, des, _src, _center, _size, _compositeMode })
  }
}
