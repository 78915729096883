import Item from './item'

export default class extends Item {
  constructor (options) {
    super(options)
    this.gl = options.gl
    this.prevTextureObjId = undefined
    this.textureNumber = options.textureNumber
    if (this.textureNumber > 31) {
      throw new Error('Exceeded maximum number of textures')
    }
  }

  initTexture (textureObj) {
    if (textureObj.src === undefined) {
      throw new Error('textureObj.src cannot be undefined')
    }
    if (this.prevTextureObjId !== undefined &&
      textureObj.id !== undefined &&
      this.prevTextureObjId === textureObj.id &&
      textureObj.updatedAt !== undefined &&
      textureObj.updatedAt <= this.updatedAt) {
      return
    }
    const { gl } = this
    const texture = gl.createTexture()
    const textureKey = 'TEXTURE' + this.textureNumber
    gl.activeTexture(gl[textureKey])
    gl.bindTexture(gl.TEXTURE_2D, texture)
    // TODO: benchmark gl.LINEAR vs gl.NEAREST
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
    // gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, textureObj.src)
    this.touch()
  }
}
