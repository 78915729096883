import React from 'react'
import FilterPackage from '../../core/filter-package'
import BlurView from './blur-view'
import BlurFilter from './blur-filter'
import { applyBlurFilter } from './blur-helpers'

const KERNEL = [[0.06136], [0.24477], [0.38774], [0.24477], [0.06136]]

export default class extends FilterPackage {
  constructor (options) {
    super(options)
    this.id = 'blur'
    this.filter = new BlurFilter()
    this.filterOptions = {}
    this.resetFilterOptions()
  }

  getName () {
    return 'Blur'
  }

  getViewClass () {
    return BlurView
  }

  resetFilterOptions () {
    this.filterOptions.blurAmount = 1
  }

  hasInitialFilterValue () { return true }

  applyFilter () {
    const { blurAmount } = this.filterOptions
    applyBlurFilter({
      startTex: this.originalLayerTex,
      tempTex: this.layer.tex,
      blurAmount
    })
    this.queueDocRender({ mode: 'layersUpdated' })
  }
}
