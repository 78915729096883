import React from 'react'
import Definition from './definition'

export default class extends Definition {
  initializableAttributes () {
    return ['id', 'units', 'width', 'height', 'image', 'transform']
  }
  getTemplate () {
    const { id, units, width, height, image, transform } = this
    const imageHref = image.href, imageWidth = image.width, imageHeight = image.height
    return (
      <pattern
        id={ id }
        patternUnits={ units }
        patternTransform={ transform }
        width={ width }
        height={ height }>
        <image xlinkHref={ imageHref } width={ imageWidth } height={ imageHeight } />
      </pattern>
    )
  }
}
