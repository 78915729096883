import React from 'react'
import BasePackage from '../../core/base-package'
import FileDropView from './file-drop-view'
import { isDragFileEvent } from '../../core/dnd-helpers'
import EventList from '../../core/event-list'

const FILE_DROP_OVERLAY_ID = 'default-file-drop-overlay'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'file-drop'
    this.events = new EventList()
  }

  activate = () => {
    const { onFileDrop, onFileDragEnter, onFileDragLeave } = this.packageFor('workspace')
    this.events.addEvent(onFileDrop, this.onFileDrop)
    this.events.addEvent(onFileDragEnter, this.onFileDragEnter)
    this.events.addEvent(onFileDragLeave, this.onFileDragLeave)

    this.fileDropView = React.createElement(FileDropView, {})
  }

  showFileDropView = () => {
    this.packageFor('layouts').findOrAddFreeformItem(FILE_DROP_OVERLAY_ID,
      { viewComponent: this.fileDropView })
  }

  hideFileDropView = () => {
    this.packageFor('layouts').removeFreeformItem(FILE_DROP_OVERLAY_ID)
  }

  onFileDrop = (files) => {
    this.hideFileDropView()
    this.packageFor('docs').openFiles(files)
    this.packageFor('layouts').clearFreeformItems()
  }

  onFileDragEnter = (e) => {
    if (!isDragFileEvent(e)) { return }
    this.showFileDropView()
  }

  onFileDragLeave = () => {
    this.hideFileDropView()
  }

  dispose = () => {
    this.events.dispose()
  }
}
