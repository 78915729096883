import React from 'react'
import { observable, action } from 'mobx'

import Tool from '../../core/tool'
import BucketFillOptionsView from './bucket-fill-options-view'

import { fillTex } from '../../core/bucket-fill-helpers'

const PERSISTABLE_STATE = {
  opacityPercentage: 100,
  tolerancePercentage: 80,
  contiguous: true
}

export default class extends Tool {
  @observable options = { opacityPercentage: 100, tolerancePercentage: 80, contiguous: true }

  constructor (options) {
    super(options)
    this.id = 'bucket-fill'
    this.settingsId = this.id
    this.name = 'Bucket Fill'
    this.icon = 'wi-bucket'
    this.info = 'Bucket Fill (G)'
    this.validLayerTypes = { raster: true }
    this.optionsView = React.createElement(BucketFillOptionsView, {
      bucketFillOptions: this.options,
      setOpacityPercentage: this.setOpacityPercentage,
      setTolerancePercentage: this.setTolerancePercentage,
      setContiguous: this.setContiguous
    })
  }

  @action setOpacityPercentage = (opacityPercentage) => {
    this.options.opacityPercentage = opacityPercentage
    const settings = this.packageFor('settings')
    settings.store(this.settingsId, { opacityPercentage: opacityPercentage })
  }

  @action setTolerancePercentage = (tolerancePercentage) => {
    this.options.tolerancePercentage = tolerancePercentage
    const settings = this.packageFor('settings')
    settings.store(this.settingsId, { tolerancePercentage: tolerancePercentage })
  }

  @action setContiguous = (contiguous) => {
    this.options.contiguous = contiguous
    const settings = this.packageFor('settings')
    settings.store(this.settingsId, { contiguous: contiguous })
  }

  @action activate () {
    const settings = this.packageFor('settings')
    const storedOptions = settings.retrieveState(this.settingsId, PERSISTABLE_STATE)
    this.options.opacityPercentage = storedOptions.opacityPercentage
    this.options.tolerancePercentage = storedOptions.tolerancePercentage
    this.options.contiguous = storedOptions.contiguous
  }

  beginMovement = ({ doc, p }) => {
    const point = p.round()
    const layer = doc.activeLayer
    const mask = doc.selectionTex
    const fillStyle = this.activeForegroundColor()
    const tolerance = this.options.tolerancePercentage / 100.0
    const seedColor = layer.colorAtPoint(point)

    const refTex = layer.getTex()
    const baseTex = refTex.deepClone()
    fillTex({
      refTex,
      baseTex,
      des: refTex,
      seedColor,
      mask,
      point,
      tolerance,
      fillStyle,
      contiguous: this.options.contiguous
    })
    baseTex.dispose()
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Bucket Fill' })
  }
}
