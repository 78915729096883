import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import { triggerObservable } from '../../globals/global-observables'

import './layerable-view.scss'

@inject('app') @observer
export default class LayerableView extends BaseComponent {
  componentDidMount () {
    const { layerable, draggableTree } = this.props
    if (draggableTree !== undefined) {
      draggableTree.registerDraggable(layerable.id, this.root, {
        onMouseDown: this.mouseDownOnLayer,
        onClick: this.clickOnLayer
      })
    }
  }

  componentWillUnmount () {
    const { layerable, draggableTree } = this.props
    if (draggableTree !== undefined) {
      draggableTree.unregisterDraggable(layerable.id)
    }
  }

  clickOnLayer = (e) => {
    const { doc, layerable, layerables } = this.props
    const docsPackage = this.packageFor('docs')

    if (e.metaKey) {
      if (layerable.isSelected) {
        if (layerables.selectionCount() === 1) {
          return
        }
        layerables.unselectId(layerable.id)
        layerables.setClosestSelectedSiblingAsActive(layerable)
        return
      }
      layerables.selectId(layerable.id)
      return
    }

    if (e.shiftKey) {
      const activeIndex = layerables.ids.indexOf(layerables.activeId)
      const clickedIndex = layerables.ids.indexOf(layerable.id)
      const startIndex = Math.min(activeIndex, clickedIndex)
      const endIndex = Math.max(activeIndex, clickedIndex)
      for (let i = 0; i < layerables.ids.length; i++) {
        const itemId = layerables.ids[i]
        if (i < startIndex || i > endIndex) {
          layerables.unselectId(itemId)
        } else {
          layerables.selectId(itemId)
        }
      }
      return
    }

    docsPackage.setActiveLayerable(doc.id, layerable.id, { autoSelect: true })
  }

  mouseDownOnLayer = (e) => {
    triggerObservable('layers', 'mousedown')
    const { doc, layerable, layerables } = this.props
    if (layerable.isSelected) {
      layerables.pendingSelectionItems = []
    } else {
      layerables.pendingSelectionItems = [layerable]
    }
  }

  rootRefCallback = (root) => {
    this.root = root
    if (this.props.root) { this.props.root(root) }
  }

  render () {
    const { layerable } = this.props
    return (
      <div className={ 'layerable-view layerable-view-' + layerable.id }
        ref={ this.rootRefCallback }>
        { this.props.children }
      </div>
    )
  }
}
