import BasePackage from '../../core/base-package'
import ColorView from './color-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'colors'
    this.ColorView = ColorView
  }
}
