import _ from 'lodash'

export class Hsh {
  constructor (hash) {
    this.hash = hash
  }

  assignValues (src, keys) {
    if (keys === undefined) { keys = Object.keys(src) }
    for (const key of keys) {
      const value = src[key]
      if (value !== undefined) { this.hash[key] = value }
    }
  }

  assignDefaultValues (src, keys) {
    if (keys === undefined) { keys = Object.keys(src) }
    for (const key of keys) {
      if (this.hash[key] !== undefined) { continue }
      const value = src[key]
      if (value !== undefined) { this.hash[key] = value }
    }
  }

  isEqualTo (comparisonHash, keys) {
    if (keys === undefined) {
      return _.isEqual(this.hash, comparisonHash)
    }
    return _.isEqual(_.pick(this.hash, keys), _.pick(comparisonHash, keys))
  }

  clone () {
    return JSON.parse(JSON.stringify(this.hash))
  }

  pick (keys) {
    return _.pick(this.hash, keys)
  }

  exclude (keys) {
    const h = {}
    for (const key in this.hash) {
      if (keys.includes(key)) { continue }
      h[key] = this.hash[key]
    }
    return h
  }
}

export default function hsh (hash) {
  return (hash instanceof Hsh) ? hash : new Hsh(hash)
}
