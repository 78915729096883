import Filter from '../filter'
import { withDefault } from '../utils'
import { COMPOSITE_MODE } from '../../constants/composite-modes'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'clip'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform vec2 _srcSize;

      uniform sampler2D _mask;
      uniform vec2 _maskSize;

      uniform bool _hasMask;
      uniform vec2 _offset;
      uniform float _compositeMode;

      void main () {
        vec2 position = currPosition();
        vec2 offsetPosition = position + _offset;
        vec4 srcColor = texColorAt(_src, _srcSize, offsetPosition);
        if (_hasMask == false) {
          outputColor = srcColor;
          return;
        }
        vec4 maskColor = texColorAt(_mask, _maskSize, offsetPosition);
        vec4 color = drawColorOnTop(maskColor, srcColor, 1.0, _compositeMode);
        outputColor = color;
      }
    `
  }
}
