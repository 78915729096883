import { action } from 'mobx'
import BasePackage from '../../core/base-package'
import LayerView from './layer-view'
import RasterLayer from '../../core/raster-layer'
import BlendFilter from '../../core/filters/blend-filter'
import { COMPOSITE_MODE } from '../../constants/composite-modes'
import { wgl } from '../../core/wgl'

const blendFilter = new BlendFilter()

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'layers'
    this.LayerView = LayerView
  }

  @action mergeDown () {
    const doc = this.getActiveDoc()
    let layer = doc.activeLayer
    let layerBelow = doc.layerables.itemAfter(layer)
    if (layerBelow === undefined || layerBelow.isGroupType()) {
      alert('The currently active layer has no layer below it to merge into')
      return
    }
    layer = this._rasterizeLayer({ doc, layer })
    layerBelow = this._rasterizeLayer({ doc, layer: layerBelow })
    doc.generateFlattenedTexture({
      useViewport: false,
      layers: [layerBelow, layer],
      des: layer.getTex()
    })
    doc.layerables.disposeItem(layerBelow)
    doc.layerables.setActiveId(layer.id)
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Merge Down' })
  }

  @action mergeVisible () {
    const doc = this.getActiveDoc()
    const layers = doc.getVisibleLayers().reverse()
    if (layers.length === 0) {
      alert('There are no visible layers to merge')
      return
    }
    if (layers.length === 1) { return }
    const newLayer = new RasterLayer(doc.size())
    newLayer.name = doc.activeLayer.name
    doc.generateFlattenedTexture({
      useViewport: false,
      layers,
      des: newLayer.getTex()
    })
    doc.layerables.insertBeforeActiveItem(newLayer)
    doc.layerables.disposeItems(layers)
    doc.layerables.setActiveId(newLayer.id)
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Merge Visible' })
  }

  @action flattenImage () {
    const doc = this.getActiveDoc()
    const layers = doc.getVisibleLayers().reverse()
    if (layers.length === 0) {
      alert('There are no visible layers to flatten')
      return
    }
    if (layers.length === 1) { return }

    const newLayer = new RasterLayer(doc.size())
    newLayer.name = doc.activeLayer.name
    doc.generateFlattenedTexture({
      useViewport: false,
      layers,
      des: newLayer.getTex()
    })
    doc.layerables.disposeItems(doc.getLayers())
    doc.layerables.push(newLayer)
    doc.layerables.setActiveId(newLayer.id)
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Flatten Image' })
  }

  @action cloneRasterLayer ({ doc, layer }) {
    let newLayer
    if (doc.selectionTex.hasData) {
      newLayer = layer.clone()
      blendFilter.blend({
        wgl,
        des: newLayer.getTex(),
        _src1: layer.getTex(),
        _src2: doc.selectionTex,
        _compositeMode: COMPOSITE_MODE.DESTINATION_IN
      })
    } else {
       newLayer = layer.deepClone()
    }
    return newLayer
  }

  @action duplicateLayer () {
    const doc = this.getActiveDoc()
    const layer = doc.activeLayer
    let layerName = layer.name + ' copy'
    let baseName = layer.name.split(' copy ')[0]
    let count = 0
    if (baseName !== undefined) {
      count = doc.getLayers().filter(layer => layer.name.startsWith(baseName)).length
      if (count === 0) { layerName = baseName + ' copy' }
      else { layerName = baseName + ' copy ' + count }
    }

    const newLayer = layer.isTextType() ? layer.deepClone() : this.cloneRasterLayer({ doc, layer })
    newLayer.setName(layerName)
    doc.layerables.insertBeforeActiveItem(newLayer)
    doc.layerables.setActiveId(newLayer.id, { autoSelect: true })
    doc.selectionTex.clear()
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Duplicate Layer' })
  }

  @action _rasterizeLayer ({ doc, layer }) {
    if (layer.isRasterType()) { return layer }
    const newLayer = new RasterLayer(layer.size())
    newLayer.setName(layer.name)
    newLayer.setOpacity(layer.opacity)
    newLayer.setBlendMode(layer.blendMode)
    newLayer.tex.swapTexture(layer.tex)
    doc.layerables.insertBeforeActiveItem(newLayer)
    doc.layerables.disposeItem(layer)
    return newLayer
  }

  @action rasterizeLayer () {
    const doc = this.getActiveDoc()
    const layer = doc.activeLayer
    if (!layer.isTextType()) {
      alert('The current layer is already a raster layer')
      return
    }
    this._rasterizeLayer({ doc, layer })
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Rasterize Layer' })
  }
}
