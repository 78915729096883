import React from 'react'
import BaseComponent from '../../core/base-component'

import './file-drop-view.scss'

export default class extends BaseComponent {
  componentWillMount () {
    this.fileDropOptions = [
      {
        id: 'new-file',
        description: 'Open as a new file'
      }
    ]
  }

  render () {
    return (
      <div className="file-drop-view fill-window background-overlay">
        { this.fileDropOptions.map((option) =>
          <h2 key={ option.id } className='file-drop-option center-all'>
            { option.description }
          </h2>
        )}
      </div>
    )
  }
}
