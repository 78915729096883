import ToolPackage from '../../core/tool-package'
import Marquee from './marquee'
import OvalMarquee from './oval-marquee'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'marquee'
    this.tools = [Marquee, OvalMarquee]
  }
}
