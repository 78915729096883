import Filter from '../../core/filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'color-balance'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform float redAmount;
      uniform float greenAmount;
      uniform float blueAmount;

      void main() {
          vec4 color = texColorFor(_src);
          color.r = color.r + redAmount;
          color.g = color.g + greenAmount;
          color.b = color.b + blueAmount;
          // color.r = color.r * (1.0 + redAmount);
          // color.g = color.g * (1.0 + greenAmount);
          // color.b = color.b * (1.0 + blueAmount);
          outputColor = color;
      }
    `
  }
}
