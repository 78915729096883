import _ from 'lodash'
import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import el from '../../lib/el'
import $ from 'jquery'
import { osName, beautifyShortcut } from '../../core/shortcuts-helpers'

import './menu-item-view.scss'

@inject('app') @observer
export default class MenuItemView extends BaseComponent {
  activateMenuItem = () => {
    const menuItemEl = this.refs.menuItem
    el(el(menuItemEl).find('.menu-item-view')).removeClass('active')
    el(menuItemEl).siblings('.menu-item-view').removeClass('active')
    menuItemEl.classList.add('active')
  }

  onMenuItemClick = (menuItem, e) => {
    if (menuItem.command) { menuItem.command.execute(this.props.app) }
    if (menuItem.level !== 0 || this.refs.menuItem.classList.contains('active')) { return }
    e.preventDefault()
    e.stopPropagation()
    this.activateMenuItem()
    this.packageFor('menu-bar').hasActiveMenuItem = true
  }

  onMenuItemMouseEnter = (menuItem) => {
    if (menuItem.level === 0 && $('.menu-item-view.active').length === 0) {
      return
    }
    this.activateMenuItem()
  }

  shortcutLabelStyle () {
    if (osName === 'macOS') {
      return {
        // letterSpacing: '2px'
      }
    }
    return {}
  }

  render () {
    const { onMenuItemClick, onMenuItemMouseEnter } = this
    const { menuItem, app } = this.props
    return (
      <div className={ `menu-item-view level-${menuItem.level}` }
        ref="menuItem">
        { menuItem.type !== 'DIVIDER' &&
          <div onClick={ (e) => onMenuItemClick(menuItem, e) }
            onMouseEnter={ (e) => onMenuItemMouseEnter(menuItem, e) }
            className="menu-item-view__label spread">
            <div className="menu-item-view__name">
              { menuItem.id }
            </div>
              { menuItem.shortcutLabel !== undefined &&
                <div className="menu-item-view__shortcut" style={ this.shortcutLabelStyle() }>
                  { beautifyShortcut(menuItem.shortcutLabel) }
                </div>
              }
          </div>
        }
        { menuItem.type === 'DIVIDER' &&
          <div className="menu-item-view__divider"></div>
        }
        { !menuItem.subItems.empty() &&
          <div className="menu-item-view__sub-items border-1">
            { menuItem.subItems.map((subItem) =>
              <MenuItemView key={ subItem.id }
                app={ app }
                menuItem={ subItem } />
            )}
          </div>
        }
      </div>
    )
  }
}
