import { observer } from 'mobx-react'
import React from 'react'
import DocTextLayerView from './doc-text-layer-view'
import BaseComponent from '../../core/base-component'
import { ensureValidCss } from '../../core/style-helpers'

@observer
export default class extends BaseComponent {
  getLayerComponentClass (layer) {
    if (layer.isTextType()) { return DocTextLayerView }
    return null
  }

  layerStyle (layer) {
    const { opacity, collectionIndex, blendMode } = layer
    const hasPointerEvents = layer.isTextType() && layer.inTextEditMode
    return ensureValidCss({
      zIndex: -collectionIndex,
      pointerEvents: hasPointerEvents ? 'none' : 'default'
    })
  }

  render () {
    const { layer } = this.props
    const Component = this.getLayerComponentClass(layer)
    return (
      <div className="doc-layer-view non-blocking" style={ { ...this.layerStyle(layer) } }>
        {Component !== null && <Component { ...this.props } />}
      </div>
    )
  }
}
