import Layerable from './layerable'
import { registerGlobalFactory } from './factory-references'

export default class Layer extends Layerable {
  setPreviewable = (previewable) => {
    this.previewable = previewable
  }

  getWidth () { return this.width }
  getHeight () { return this.height }
  size () { return { width: this.getWidth(), height: this.getHeight() } }

  getPrintMethod () { return 'drawImage' }
  applyPrintSrc (callback) {}

  print (des) {
    const printMethod = this.getPrintMethod()
    this.applyPrintSrc((src) => {
      des[printMethod](src, {
        contextOptions: {
          globalAlpha: this.opacity,
          globalCompositeOperation: this.canvasCompositeOperation()
        }
      })
    })
  }

  colorAtPoint () {}
  hasContentAtPoint () {}

  canvasCompositeOperation () {
    if (this.blendMode === 'normal') { return 'source-over' }
    return this.blendMode
  }

  getFactoryKey () { return 'Layer' }
}

registerGlobalFactory('Layer', Layer)
