import Strokable, { BRUSH_TYPE } from '../strokable/strokable'
import { CURSOR } from '../../constants/cursors'
import PencilOptionsView from './pencil-options-view'

export default class extends Strokable {
  constructor (options = {}) {
    super({ ...options, id: 'pencil', name: 'Pencil', settingsId: 'pencil' })
    this.icon = 'wi-pencil'
    this.info = 'Pencil (B)'
  }

  getOptionsViewClass () { return PencilOptionsView }
  getOptionsViewClassName () { return 'pencil-options-view' }

  getImageSmoothingEnabled () {
    return false
  }

  getGlBrushType () {
    return BRUSH_TYPE.SQUARE
  }

  getCustomCursorType () {
    return CURSOR.SQUARE
  }
}
