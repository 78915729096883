import Item from './item'

export default class extends Item {
  constructor (options) {
    super(options)
    this.id = 'filter'
    this.vertexShader = this.getVertexShader()
    this.fragmentShader = this.fragmentShaderHeader() + this.getFragmentShader()
  }

  activate () {}

  getVertexShader () {
    return `#version 300 es

    in vec2 vertex;
    void main () {
      gl_Position = vec4(vertex.x, vertex.y, 0.0, 1.0);
    }
    `
  }

  fragmentShaderHeader () {
    return `#version 300 es

    precision mediump float;

    const float COMPOSITE_MODE_NORMAL = 1.0;
    const float COMPOSITE_MODE_SOURCE_OUT = 2.0;
    const float COMPOSITE_MODE_DESTINATION_IN = 3.0;

    const float BLEND_MODE_NORMAL = 1.0;
    const float BLEND_MODE_MULTIPLY = 2.0;
    const float BLEND_MODE_SCREEN = 3.0;
    const float BLEND_MODE_OVERLAY = 4.0;

    const float BLEND_MODE_DARKEN = 5.0;
    const float BLEND_MODE_LIGHTEN = 6.0;

    const float BLEND_MODE_COLOR_DODGE = 7.0;
    const float BLEND_MODE_LINEAR_DODGE = 8.0;
    const float BLEND_MODE_COLOR_BURN = 9.0;
    const float BLEND_MODE_LINEAR_BURN = 10.0;

    const float BLEND_MODE_VIVID_LIGHT = 11.0;
    const float BLEND_MODE_LINEAR_LIGHT = 12.0;
    const float BLEND_MODE_HARD_LIGHT = 13.0;
    const float BLEND_MODE_SOFT_LIGHT = 14.0;

    const float BLEND_MODE_DIVIDE = 15.0;
    const float BLEND_MODE_ADDITION = 16.0;
    const float BLEND_MODE_SUBTRACT = 17.0;
    const float BLEND_MODE_DIFFERENCE = 18.0;

    const vec4 EMPTY_COLOR = vec4(0.0, 0.0, 0.0, 0.0);
    const vec4 RED = vec4(1.0, 0.0, 0.0, 0.5);
    const vec4 GREEN = vec4(0.0, 1.0, 0.0, 0.5);
    const vec4 BLUE = vec4(0.0, 0.0, 1.0, 0.5);
    const vec4 BLACK = vec4(0.0, 0.0, 0.0, 1.0);

    uniform float desWidth;
    uniform float desHeight;
    uniform vec2 desSize;

    uniform vec2 _viewOffset;
    uniform vec2 _viewSize;

    out vec4 outputColor;

    float coordX () { return gl_FragCoord.x; }
    float coordY () { return gl_FragCoord.y; }
    vec2 currPosition () { return vec2(coordX(), coordY()); }

    void clearOutputColor () {
      outputColor = EMPTY_COLOR;
    }

    float getLength (float x, float y) {
      return sqrt(x * x + y * y);
    }

    float getLength (vec2 p) {
      return getLength(p.x, p.y);
    }

    float getDistance (vec2 p1, vec2 p2) {
      float dx = p2.x - p1.x;
      float dy = p2.y - p1.y;
      return getLength(dx, dy);
    }

    float getBrightnessOfColor (vec4 color) {
        return 0.299 * color.r + 0.587 * color.g + 0.114 * color.b;
    }

    vec2 getTexCoord () {
      if (desWidth == 0.0 || desHeight == 0.0) { return vec2(0.0, 0.0); }
      return vec2(coordX() / desWidth, coordY() / desHeight);
    }

    vec4 texColorFor (sampler2D tex) { return texture(tex, getTexCoord()); }

    vec4 texColorFor (sampler2D tex, vec2 texSize) {
      vec2 texCoord = vec2(coordX() / texSize.x, coordY() / texSize.y);
      return texture(tex, texCoord);
    }

    vec4 texColorFor (sampler2D tex, vec2 texSize, vec2 offset, bool clip) {
      float x = coordX() - offset.x;
      float y = coordY() - offset.y;
      vec2 texCoord = vec2(x / texSize.x, y / texSize.y);
      if (texCoord.x < 0.0 || texCoord.y < 0.0 || texCoord.x > 1.0 || texCoord.y > 1.0) {
        return EMPTY_COLOR;
      }
      return texture(tex, texCoord);
    }

    vec4 texColorFor (sampler2D tex, vec2 texSize, vec2 offset) {
      return texColorFor(tex, texSize, offset, true);
    }

    vec4 texColorAtOffset (sampler2D tex, float x, float y) {
      if (desWidth == 0.0 || desHeight == 0.0) { return vec4(0.0, 0.0, 0.0, 0.0); }
      vec2 texCoord = vec2((coordX() + x) / desWidth, (coordY() + y) / desHeight);
      return texture(tex, texCoord);
    }

    vec4 texColorAt (sampler2D tex, vec2 texSize, vec2 point) {
      vec2 texCoord = vec2(point.x / texSize.x, point.y / texSize.y);
      if (texCoord.x < 0.0 || texCoord.y < 0.0 || texCoord.x > 1.0 || texCoord.y > 1.0) {
        return EMPTY_COLOR;
      }
      return texture(tex, texCoord);
    }

    vec4 texColorAt (sampler2D tex, float x, float y) {
      if (desWidth == 0.0 || desHeight == 0.0) { return vec4(0.0, 0.0, 0.0, 0.0); }
      vec2 texCoord = vec2(x / desWidth, y / desHeight);
      return texture(tex, texCoord);
    }

    float blendRgbComponent (float b, float a, float blendMode) {
      if (blendMode == BLEND_MODE_NORMAL) {
        return b;
      }
      if (blendMode == BLEND_MODE_MULTIPLY) {
        return b * a;
      }
      if (blendMode == BLEND_MODE_SCREEN) {
        return 1.0 - (1.0 - a) * (1.0 - b);
      }
      if (blendMode == BLEND_MODE_OVERLAY) {
        if (a < 0.5) { return 2.0 * a * b; }
        return 1.0 - 2.0 * (1.0 - a) * (1.0 - b);
      }
      if (blendMode == BLEND_MODE_DARKEN) {
        return min(a, b);
      }
      if (blendMode == BLEND_MODE_LIGHTEN) {
        return max(a, b);
      }
      if (blendMode == BLEND_MODE_COLOR_DODGE) {
        return a / (1.0 - b);
      }
      if (blendMode == BLEND_MODE_LINEAR_DODGE) {
        return a + b;
      }
      if (blendMode == BLEND_MODE_COLOR_BURN) {
        return 1.0 - ((1.0 - a) / b);
      }
      if (blendMode == BLEND_MODE_LINEAR_BURN) {
        return a + b - 1.0;
      }
      if (blendMode == BLEND_MODE_VIVID_LIGHT) {
        if (b > 0.5) { return a / (1.0 - b); }
        return 1.0 - ((1.0 - a) / b);
      }
      if (blendMode == BLEND_MODE_LINEAR_LIGHT) {
        return a + b * 2.0 - 1.0;
      }
      if (blendMode == BLEND_MODE_HARD_LIGHT) {
        if (b < 0.5) { return 2.0 * a * b; }
        return 1.0 - 2.0 * (1.0 - a) * (1.0 - b);
      }
      if (blendMode == BLEND_MODE_SOFT_LIGHT) {
        if (b <= 0.5) {
          return a - (1.0 - 2.0 * b) * a * (1.0 - a);
        }

        float g = 0.0;
        if (a <= 0.25) {
          g = ((16.0 * a - 12.0) * a + 4.0) * a;
        } else {
          g = sqrt(a);
        }
        return a + (2.0 * b - 1.0) * (g - a);
      }

      if (blendMode == BLEND_MODE_DIVIDE) {
        return a / b;
      }

      if (blendMode == BLEND_MODE_ADDITION) {
        return b + a;
      }

      if (blendMode == BLEND_MODE_SUBTRACT) {
        return a - b;
      }

      if (blendMode == BLEND_MODE_DIFFERENCE) {
        return abs(b - a);
      }

      return 0.0;
    }

    vec3 blendRgb (vec3 color2, vec3 color1, float blendMode) {
      float r = blendRgbComponent(color2.r, color1.r, blendMode);
      float g = blendRgbComponent(color2.g, color1.g, blendMode);
      float b = blendRgbComponent(color2.b, color1.b, blendMode);
      return vec3(r, g, b);
    }

    // color2 is on top of color1
    vec4 drawColorOnTop (vec4 color2, vec4 color1, float a, float compositeMode, float blendMode) {
      if (compositeMode == COMPOSITE_MODE_SOURCE_OUT) {
        return vec4(color1.rgb, color1.a * (1.0 - color2.a));
      }
      if (compositeMode == COMPOSITE_MODE_DESTINATION_IN) {
        return vec4(color1.rgb, color1.a * color2.a);
      }
      color2.a = color2.a * a;
      if (color1.a == 0.0) { color1.rgb = vec3(1.0); }
      float multiplier = color1.a * (1.0 - color2.a);
      color2.rgb = blendRgb(color2.rgb, color1.rgb, blendMode);
      vec3 rgb = color2.rgb * color2.a + color1.rgb * multiplier;
      float alpha = color2.a + multiplier;
      if (alpha == 0.0) { return vec4(0.0, 0.0, 0.0, 0.0); }
      rgb = rgb / alpha;
      return vec4(rgb, alpha);
    }

    vec4 drawColorOnTop (vec4 color2, vec4 color1, float a, float compositeMode) {
      return drawColorOnTop(color2, color1, a, compositeMode, BLEND_MODE_NORMAL);
    }

    vec4 drawColorOnTop (vec4 color2, vec4 color1) {
      return drawColorOnTop(color2, color1, 1.0, COMPOSITE_MODE_NORMAL, BLEND_MODE_NORMAL);
    }

    bool coordInView () {
      float x = coordX();
      float y = coordY();

      float rightBound = _viewOffset.x + _viewSize.x;
      float topBound = _viewOffset.y + _viewSize.y;

      if (x < _viewOffset.x || y < _viewOffset.y || x >= rightBound || y >= topBound) {
        return false;
      }
      if (_viewSize.x == 0.0 || _viewSize.y == 0.0) {
        return false;
      }

      return true;
    }

    vec2 relativeViewCoord () {
      float x = coordX();
      float y = coordY();
      return vec2(x - _viewOffset.x, y - _viewOffset.y);
    }

    vec4 viewportTexColorFor (sampler2D tex) {
      if (!coordInView()) {
        return vec4(0.0, 0.0, 0.0, 0.0);
      }
      vec2 relativeCoord = relativeViewCoord();
      vec2 texCoord = vec2(relativeCoord.x / _viewSize.x, relativeCoord.y / _viewSize.y);
      return texture(tex, texCoord);
    }
    `
  }

  getFragmentShader () {
    return `
    void main() {
      float x = coordX();
      float y = coordY();
      vec4 color1 = vec4(x / desWidth, y / desHeight, 0, 1);
      outputColor = color1;
    }
    `
  }
}
