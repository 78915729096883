import { observable, action, computed } from 'mobx'
import Item from './item'
import Collection from './collection'
import RasterLayer from './raster-layer'
import TextLayer from './text-layer'
import SvgLayer from './svg-layer'
import Fabric from './fabric'
import DocHistory from './versions/doc-history'
import Event from './event'
import EventList from './event-list'
import GroupableCollection from './groupable-collection'
import LayerGroup from './layer-group'
import SimpleQuad from './simple-quad'
import Dom from './dom'
import { blendColors } from './color-helpers'
import { getFittingDimensions, getMinMaxOfArray } from './calc'
import { constructLayer } from './layer-helpers'
import ary from '../lib/ary'
import { initObservable, triggerObservable } from '../globals/global-observables'
import { wgl } from './wgl'
import { exportDoc, saveDoc, openDoc } from './doc-helpers'
import FlattenFilter from './filters/flatten-filter'
import el from '../lib/el'
import Tex from './tex'
import { BLEND_MODE } from '../constants/blend-modes'

const flattenFilter = new FlattenFilter()

const ZOOM_PERCENTAGE_PRESETS = [0.125, 0.25, 0.33, 0.5, 0.66, 0.75, 1,
  1.125, 1.25, 1.33, 1.5, 1.66, 1.75, 2, 3.125, 6.25,
  12.5, 25, 33.33, 50, 66.67, 75, 100,
  150, 200, 400, 800, 1600, 3200, 6400]

const ZOOM_TO_FIT_SIMILARITY_TRESHOLD = 3
const MAX_DIMENSIONS = 5000

initObservable('doc', 'zoom')
initObservable('doc', 'size')
initObservable('layerables', 'activeId')

export default class extends Item {
  @observable width = 0
  @observable height = 0
  @observable zoomPercentage = 100
  @observable name
  @observable inTextEditMode = false
  @observable transformation = { mode: 'move' }

  constructor (options = {}) {
    super(options)
    this.toolLayers = new Collection()
    this.selectedVectors = new Collection()
    this.transformationQuad = new SimpleQuad()
    this.layerables = new GroupableCollection({ root: new LayerGroup() })
    this.layerables.syncItemIndexes = true
    this.history = new DocHistory({ doc: this })
    this.events = new EventList()
    this.scrollLockUpdated = new Event()
    this.rename(options.name || 'Untitled')
    this.isZoomLocked = false
    this.isScrollLocked = false
    const { width, height } = options
    this.selectionFabric = new Fabric({ width, height })
    this.selectionTex = new Tex({ width, height })
    this.resize({ width, height })

    this.flattenedTex = new Tex(this.size())

    this.events.addObserver(this, 'zoomPercentage', () => {
      triggerObservable('doc', 'zoom')
    })
    this.events.addObserver(this.layerables, 'activeId', () => {
      triggerObservable('layerables', 'activeId')
    })
    this.lastFullSaveAt = Date.now()
    this.lastExportedAt = Date.now()
  }

  generateFlattenedTexture (options = {}) {
    const useViewport = options.useViewport !== undefined ? options.useViewport : true
    const des = options.des !== undefined ? options.des : this.flattenedTex
    if (useViewport === true) {
      des.resize(options.containerSize)
    } else {
      des.resize(this.size())
    }
    if (des === this.flattenedTex) {
      this.flattenedTex.clear()
    }

    const layers = options.layers !== undefined ? options.layers : this.getVisibleLayers().reverse()
    const batchSize = 3

    for (let i = 0; i < layers.length; i) {
      let textureDataArray = []
      if (i > 0) {
        textureDataArray.push({
          tex: des,
          alpha: 1.0,
          viewportClipped: useViewport,
          blendMode: BLEND_MODE.NORMAL
        })
      }
      const remainingSpace = batchSize - textureDataArray.length
      const layerBatch = layers.slice(i, i + remainingSpace)
      i += remainingSpace
      for (const layer of layerBatch) {
        textureDataArray.push({
          tex: layer.getTex(),
          alpha: layer.opacity,
          viewportClipped: false,
          blendMode: layer.blendModeForShader()
        })
      }

      const filterData = {}
      for (let j = 0; j < textureDataArray.length; j++) {
        const textureData = textureDataArray[j]
        filterData['src' + (j + 1)] = textureData.tex
        filterData['alpha' + (j + 1)] = textureData.alpha
        filterData['viewportClipped' + (j + 1)] = textureData.viewportClipped
        filterData['blendMode' + (j + 1)] = textureData.blendMode
        if (useViewport === true) {
          textureData.tex.setTextureParameters({ minFilter: 'LINEAR', magFilter: 'NEAREST' })
        }
      }

      wgl.applyFilter({
        filter: flattenFilter,
        data: {
          ...filterData,
          textureCount: textureDataArray.length,
          _viewOffset: options.viewOffset,
          _viewSize: options.viewSize,
          useViewport: useViewport === true ? 1 : 0
        },
        des: {
          texture: 'temp',
          width: des.width,
          height: des.height
        }
      })

      des.swapTexture(wgl.tempRenderedTex)

      if (useViewport === true) {
        textureDataArray.forEach((textureData) => textureData.tex.setTextureParameters({ minFilter: 'LINEAR', magFilter: 'LINEAR' }))
      }
    }
  }

  clearTransformationQuad () {
    this.transformationQuad.clear()
  }

  updateTransformationQuad (data) {
    this.transformationQuad.setCorners(data)
  }

  getLayers () {
    const { layerables } = this
    return layerables.filter(item => item !== undefined && !item.isGroupType())
  }

  getVisibleLayers () {
    return this.getLayers().filter(item => item.visible)
  }

  getTextLayers () {
    return this.getLayers().filter((layer) => layer.isTextType())
  }

  getLayerIndexes (layers) {
    if (layers === undefined) { layers = this.getLayers() }
    return layers.map((layer) => this.layerables.indexOf(layer))
  }

  getSelectedLayers () {
    const { layerables } = this
    return layerables.getSelectedItems().filter(item => !item.isGroupType())
  }

  getSelectedLayerIndexes () {
    return this.getLayerIndexes(this.getSelectedLayers())
  }

  @action rename (name) {
    if (name === undefined || name.length === 0) { return }
    this.name = name
  }

  lockScroll = () => {
    this.isScrollLocked = true
    this.scrollLockUpdated.trigger()
  }

  unlockScroll = () => {
    this.isScrollLocked = false
    this.scrollLockUpdated.trigger()
  }

  lockZoom () { this.isZoomLocked = true }
  unlockZoom () { this.isZoomLocked = false }

  lockPan () {
    this.lockScroll()
    this.lockZoom()
  }

  unlockPan () {
    this.unlockScroll()
    this.unlockZoom()
  }

  hasUnsavedChanges () {
    const lastVersion = this.history.versions.last()
    if (lastVersion === undefined) { return false }
    const lastUpdatedAt = lastVersion.createdAt
    if (this.history.versions.count() <= 1) { return false }
    if (this.getLayers().length > 1) {
      return lastUpdatedAt > this.lastFullSaveAt
    } else {
      return lastUpdatedAt > this.lastExportedAt
    }
    return false
  }

  recordVersion ({ name, combinable }) {
    const version = this.history.record({ name, combinable })
    return version
  }

  size () { return { width: this.getWidth(), height: this.getHeight() } }

  getWidth () { return parseInt(this.width) }
  getHeight () { return parseInt(this.height) }

  restoreVersion (versionId) {
    this.history.restore(versionId)
  }

  versions () {
    return this.history.versions
  }

  @action enterTextEditMode (options = {}) {
    const layer = this.activeLayer
    layer.enterTextEditMode(options)
    this.inTextEditMode = true
  }

  @action exitTextEditMode () {
    const layer = this.activeLayer
    layer.exitTextEditMode()
    this.inTextEditMode = false
  }

  @action resize = ({ width, height }, options = {}) => {
    if (width > MAX_DIMENSIONS || height > MAX_DIMENSIONS) {
      alert('Your image width / height cannot exceed ' + MAX_DIMENSIONS + ' pixels, defaulting to ' + MAX_DIMENSIONS + ' pixels')
      width = MAX_DIMENSIONS
      height = MAX_DIMENSIONS
    }
    if (width === undefined) { width = 0 }
    if (height === undefined) { height = 0 }
    this.selectionTex.resize({ width, height }, options)
    this.getLayers().forEach((layer) => {
      layer.resize({ width, height }, options)
    })
    this.toolLayers.each((layer) => layer.resize({ width, height }, options))
    this.width = parseInt(width)
    this.height = parseInt(height)
    triggerObservable('doc', 'size')
  }

  @action createSelectionFromLayer (layer) {
    if (!layer.isRasterType()) { return }
    layer.tex.printToTex(this.selectionTex)
    this.selectionTex.calcHasData()
  }

  @computed get activeLayer () {
    return this.layerables.getActiveItem()
  }

  @computed get zoom () {
    return this.zoomPercentage / 100.0
  }

  getFileExtension (fileName) {
    return ary(fileName.split('.')).last()
  }

  @action loadImageFromSrc (src, callback) {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      const { width, height } = img
      this.resize({ width, height })
      const layer = this.addRasterLayer({ skipVersion: true })
      layer.loadImage({ image: img, width, height })
      if (callback) { callback() }
      this.recordVersion({ name: 'Open Image' })
    }
    img.src = src
  }

  @action loadImageFile (file, callback) {
    const src = URL.createObjectURL(file)
    this.loadImageFromSrc(src, () => {
      URL.revokeObjectURL(src)
      if (callback) { callback() }
    })
  }

  @action loadDocFile (file, callback) {
    openDoc({ doc: this, file }, () => {
      if (callback) { callback() }
      this.recordVersion({ name: 'Open File' })
    })
  }

  @action loadFile (file, callback) {
    const ext = this.getFileExtension(file.name)
    if (ext === 'pxl' || ext === 'zip') {
      this.loadDocFile(file, callback)
    } else {
      this.loadImageFile(file, callback)
    }

    const nameSegments = file.name.split('.')
    const fileName = nameSegments.length === 1 ? nameSegments[0] : nameSegments.slice(0, -1).join('.')
    this.rename(fileName)
  }

  @computed get zoomedWidth () {
    return Math.round(this.width * this.zoom)
  }

  @computed get zoomedHeight () {
    return Math.round(this.height * this.zoom)
  }

  getNewLayerName () {
    return 'Layer ' + (this.getLayers().length + 1)
  }

  @action addLayer = (LayerClass, options = {}, layerOptions = {}) => {
    const { width, height } = this
    if (layerOptions.width === undefined) { layerOptions.width = width }
    if (layerOptions.height === undefined) { layerOptions.height = height }
    if (layerOptions.name === undefined) { layerOptions.name = this.getNewLayerName() }

    const layer = this.layerables.insertBeforeActiveItem(new LayerClass(layerOptions))
    if (options.fill && layer.fill) { layer.fill({ fillStyle: 'white' }) }
    this.layerables.setActiveId(layer.id, { autoSelect: true })
    if (options.skipVersion !== true) {
      const versionName = LayerClass === TextLayer ? 'New Text Layer' : 'New Layer'
      this.recordVersion({ name: versionName })
    }
    return layer
  }

  @action addTextLayer = (options = {}, layerOptions = {}) => {
    return this.addLayer(TextLayer, options, layerOptions)
  }

  @action addRasterLayer = (options = {}, layerOptions = {}) => {
    return this.addLayer(RasterLayer, options, layerOptions)
  }

  @action addSvgLayer = (options = {}) => {
    return this.addLayer(SvgLayer, options)
  }

  @action buildToolLayer = (id, type = 'svg', options = {}) => {
    const { width, height } = this
    options.id = id
    options.width = options.width || width
    options.height = options.height || height
    return constructLayer(type, options)
  }

  @action addToolLayer = (id, type, options = {}) => {
    const layer = this.buildToolLayer(id, type, options)
    return this.toolLayers.add(layer)
  }

  @action findOrAddToolLayer = (id, type, options = {}) => {
    return this.toolLayers.findOrAdd(id, () => {
      return this.buildToolLayer(id, type, options)
    })
  }

  @action disposeSelectedLayers = () => {
    const { layerables } = this
    const deletionCount = layerables.selectionCount()
    const nextActiveItem = layerables.getClosestItem(layerables.getActiveItem(), {
      filter: (layerable) => !layerables.itemIsSelected(layerable) && !layerable.isGroupType()
    })
    layerables.disposeSelectedItems()
    layerables.setActiveId(nextActiveItem.id)
    layerables.selectId(nextActiveItem.id)
    const versionName = deletionCount === 1 ? 'Delete Layer' : 'Delete Layers'
    this.recordVersion({ name: versionName })
  }

  appendZoomToFitValue (zoomPercentages, maxDimensions) {
    if (maxDimensions === undefined) { return }
    const zoomToFitValue = this.getZoomToFitValue(maxDimensions)
    for (const zoomPercentage of zoomPercentages) {
      const diff = Math.abs(zoomPercentage - zoomToFitValue)
      if (diff < ZOOM_TO_FIT_SIMILARITY_TRESHOLD) { return }
    }
    zoomPercentages.push(zoomToFitValue)
  }

  @action zoomIn = (maxDimensions) => {
    if (this.isZoomLocked) { return }
    const zoomPercentages = ZOOM_PERCENTAGE_PRESETS.slice()
    this.appendZoomToFitValue(zoomPercentages, maxDimensions)
    zoomPercentages.sort((a, b) => a - b)
    for (const zoomPercentage of zoomPercentages) {
      if (zoomPercentage > this.zoomPercentage) {
        this.setZoomPercentage(zoomPercentage)
        break
      }
    }
  }

  @action zoomOut = (maxDimensions) => {
    if (this.isZoomLocked) { return }
    const zoomPercentages = ZOOM_PERCENTAGE_PRESETS.slice()
    this.appendZoomToFitValue(zoomPercentages, maxDimensions)
    zoomPercentages.sort((a, b) => b - a)
    for (const zoomPercentage of zoomPercentages) {
      if (zoomPercentage < this.zoomPercentage) {
        this.setZoomPercentage(zoomPercentage)
        break
      }
    }
  }

  @action setZoomPercentage = (zoomPercentage) => {
    if (this.isZoomLocked) { return }
    this.zoomPercentage = zoomPercentage
  }

  getZoomToFitValue = (maxDimensions) => {
    const fittingDimensions = getFittingDimensions(this, maxDimensions)
    return Math.floor(fittingDimensions.width / parseFloat(this.width) * 100)
  }

  @action zoomToFit = (maxDimensions) => {
    if (this.isZoomLocked) { return }
    if (this.width === 0 || this.height === 0) { return }
    const zoom = this.getZoomToFitValue(maxDimensions)
    if (zoom > 100) { return }
    this.setZoomPercentage(zoom)
  }

  selectPreviousLayer = () => { this.layerables.setPreviousItemAsActive() }
  selectNextLayer = () => { this.layerables.setNextItemAsActive() }

  serialize () {
    const info = super.serialize()

    return {
      data: {
        ...info.data,
        width: this.width,
        height: this.height,
        transformation: {
          mode: this.transformation.mode
        }
      },
      childItems: {
        selectionTex: this.selectionTex,
        layerables: this.layerables
      }
    }
  }

  @action restore ({ data }) {
    super.restore({ data })
    this.width = data.width
    this.height = data.height
    this.transformation.mode = data.transformation.mode
  }

  undo () {
    this.history.goBack()
  }

  redo () {
    this.history.goForward()
  }

  pointWithinDoc (p) {
    return p.x >= 0 && p.x <= this.getWidth() &&
           p.y >= 0 && p.y <= this.getHeight()
  }

  colorAtPoint (p) {
    if (!this.pointWithinDoc(p)) { return }
    const colors = this.getLayers().map((layer) => layer.colorAtPoint(p))
    const rgba = blendColors(colors.reverse())
    if (rgba.a === 0) { return }
    rgba.setAlpha(1)
    return rgba
  }

  layerAtPoint (p) {
    if (!this.pointWithinDoc(p)) { return }
    for (const layer of this.getLayers()) {
      if (layer.hasContentAtPoint(p)) {
        return layer
      }
    }
  }

  setLayerOpacityPercentage (value) {
    if (isNaN(value)) { return }
    this.activeLayer.setOpacityPercentage(value)
    const prevVersionId = this.prevLayerOpacityPercentageVersionId
    const versionId = this.recordVersion({ name: 'Layer Opacity', combinable: true }).id
    this.prevLayerOpacityPercentageVersionId = versionId
  }

  setLayerBlendMode (value) {
    this.activeLayer.setBlendMode(value)
    this.recordVersion({ name: 'Layer Blend Mode' })
  }

  @action addLayerGroup ({ parent }) {
    const { layerables } = this
    const layerGroup = layerables.insertBeforeActiveItem(new LayerGroup(), { parent })
    layerables.setActiveId(layerGroup.id, { autoSelect: true })
    return layerGroup
  }

  @action groupSelectedLayerables () {
    const selectedLayerables = this.layerables.getSelectedItems()
    const parent = this.layerables.getActiveGroupParent()
    const newLayerGroup = this.addLayerGroup({ parent })
    this.layerables.moveItems(selectedLayerables, { newParent: newLayerGroup })
    this.recordVersion({ name: 'Group Layers' })
  }

  expandTransformationQuadToVectors (vectors) {
    if (vectors.count() === 0) {
      this.transformationQuad.clear()
      return
    }
    const minMaxArray = vectors.map((vector) => vector.getMinMax())
    const minMax = getMinMaxOfArray(minMaxArray)
    const topLeft = minMax.min
    const bottomRight = minMax.max
    this.updateTransformationQuad({
      topLeft,
      topRight: { x: bottomRight.x, y: topLeft.y },
      bottomRight,
      bottomLeft: { x: topLeft.x, y: bottomRight.y }
    })
  }

  beginSave () {
    saveDoc({ doc: this })
    this.lastExportedAt = Date.now()
    this.lastFullSaveAt = Date.now()
  }

  exportImageToBlob (options = {}, callback) {
    const des = new Tex(this.size())
    this.generateFlattenedTexture({
      des,
      useViewport: false
    })

    des.toBlob(options, ({ url, blob }) => {
      callback({ url, blob })
      des.dispose()
    })
    this.lastExportedAt = Date.now()
  }

  exportDocToBlob (options = {}, callback) {
    exportDoc({ doc: this }, ({ blob }) => {
      const url = URL.createObjectURL(blob)
      callback({ url, blob })
    })
    this.lastFullSaveAt = Date.now()
    this.lastExportedAt = Date.now()
  }

  exportToBlob (options = {}, callback) {
    if (options.type === 'jpg') { options.type = 'jpeg' }
    const imageTypes = ['png', 'jpeg']
    if (imageTypes.includes(options.type)) {
      this.exportImageToBlob(options, callback)
    }
    if (options.type === 'pxl') {
      this.exportDocToBlob(options, callback)
    }
  }

  dispose () {
    super.dispose()
    this.layerables.dispose()
    this.toolLayers.dispose()
    this.history.dispose()
    this.selectionFabric.dispose()
    this.events.dispose()
    this.flattenedTex.dispose()
  }
}
