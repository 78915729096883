import Filter from '../filter'
import { parseForShader } from '../color'
import { withDefault } from '../utils'
import { COMPOSITE_MODE } from '../../constants/composite-modes'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'rect'
  }

  fillRect ({ wgl, _src, _hasSrc, _start, _end, _fillStyle, desTexture, _alpha, _compositeMode, des }) {
    return wgl.applyFilter({
      filter: this,
      des: des !== undefined ? des : { texture: desTexture, width: _src.width, height: _src.height },
      data: {
        _src: withDefault(_src, wgl.tempRenderedTex),
        _hasSrc: withDefault(_hasSrc, true),
        _start: [_start.x, _start.y],
        _end: [_end.x, _end.y],
        _alpha: withDefault(_alpha, 1),
        _compositeMode: withDefault(_compositeMode, COMPOSITE_MODE.NORMAL),
        _fillStyle: parseForShader(withDefault(_fillStyle, 'black'))
      }
    })
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform bool _hasSrc;
      uniform vec2 _start;
      uniform vec2 _end;
      uniform vec4 _fillStyle;
      uniform float _alpha;
      uniform float _compositeMode;

      void main () {
        float x = coordX();
        float y = coordY();

        vec4 color = EMPTY_COLOR;
        if (_hasSrc) {
          vec2 texCoord = vec2(x / desWidth, y / desHeight);
          color = texture(_src, texCoord);
        }

        vec4 fillColor = EMPTY_COLOR;
        if (x >= _start.x && y >= _start.y && x < _end.x && y < _end.y) {
          fillColor = _fillStyle;
        }
        outputColor = drawColorOnTop(fillColor, color, _alpha, _compositeMode);
      }
    `
  }
}
