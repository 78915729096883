import Tool from '../../core/tool'

export default class extends Tool {
  constructor (options) {
    super(options)
    this.id = 'pipette'
    this.name = 'Pipette'
    this.icon = 'wi-eyedropper'
    this.info = 'Eyedropper (I)'
  }

  getCursorType () { return 'crosshair' }

  beginMovement = ({ doc, p }) => {
    const color = this.pickForegroundColorFromDoc({ doc, p })
    this.activeForegroundColor().setRgb(color)
  }

  continueMovement = ({ doc, p, e }) => {
    if (e.buttons !== 1) { return }
    const color = this.pickForegroundColorFromDoc({ doc, p })
    this.activeForegroundColor().setRgb(color)
  }

  pickForegroundColorFromDoc = ({ doc, p }) => {
    const color = doc.colorAtPoint(p)
    if (color === undefined) { return }
    const colorPicker = this.packageFor('color-picker')
    if (color.s === 0 || color.v === 0) {
      color.setHsv({ h: colorPicker.newColor.h })
    }
    colorPicker.setNewColor(color)
    return color
  }
}
