import React from 'react'
import Collection from '../../core/collection'
import BasePackage from '../../core/base-package'
import StatusBarItem from './status-bar-item'
import StatusBarView from './status-bar-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'status-bar'
    this.leftItems = new Collection()
  }

  activate () {
    const statusBarView = React.createElement(StatusBarView, { leftItems: this.leftItems })
    this.packageFor('layouts').addLayoutItem('bottom-1',
      { viewComponent: statusBarView, row: 50, width: 'fill' })
  }

  addLeftItem (options) {
    this.leftItems.insertByPriority(new StatusBarItem(options))
  }
}
