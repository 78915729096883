import BasePackage from '../../core/base-package'
import Collection from '../../core/collection'
import * as filterPackageList from '../../filter-packages/filter-package-list'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'filters'
    this.filters = new Collection()
    this.filterPackages = new Collection()
  }

  activate = () => {
    this.initFilters()
  }

  initFilters () {
    const { app } = this
    const { filters, filterPackages } = this
    for (const key in filterPackageList) {
      const filterPackage = new filterPackageList[key]({ app })
      const filter = filterPackage.filter
      filters.add(filter)
      filterPackages.add(filterPackage)
    }
    filterPackages.each((filterPackage) => filterPackage.activate())
    filters.each((filter) => filter.activate())
  }

  getFilter (filterId) {
    return this.filters.find(filterId)
  }

  showFilterInDialog = (filterId) => {
    const filterPackage = this.filterPackages.find(filterId)
    const activeDoc = this.getActiveDoc()
    const activeLayer = this.getActiveLayer()
    filterPackage.showInDialog({
      doc: activeDoc,
      layer: activeLayer
    })
  }

  applyFilter = (filterId) => {
    const filterPackage = this.filterPackages.find(filterId)
    const activeDoc = this.getActiveDoc()
    const activeLayer = this.getActiveLayer()
    filterPackage.setContext({
      doc: activeDoc,
      layer: activeLayer
    })
    filterPackage.applyFilter()
    filterPackage.endFilter()
    activeDoc.recordVersion({ name: filterPackage.name })
  }
}
