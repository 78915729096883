import Item from '../../core/item'
import Collection from '../../core/collection'

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.subItems = new Collection()
    this.type = options.type
    this.shortcutLabel = options.shortcutLabel
  }
  initializableAttributes () { return ['level'] }
}
