import _ from 'lodash'
import React from 'react'
import { action, observable } from 'mobx'
import Tool from '../../core/tool'
import EventList from '../../core/event-list'
import Transformer from '../../core/transformer'
import RasterTransformOptionsView from './raster-transform-options-view'

export default class extends Tool {
  constructor (options) {
    super(options)
    this.id = 'raster-transform'
    this.name = 'Raster Transform'
    this.icon = 'wi-transform'
    this.info = 'Raster Transform (R)'
    this.validLayerTypes = { raster: true }
    this.events = new EventList()
  }

  getCursorType ({ doc, mousePositionInDoc }) {
    if (this.transformer === undefined) { return 'move' }
    return this.transformer.getCursorType({ doc, mousePositionInDoc })
  }

  @action applyTransformation () {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const tools = this.packageFor('tools')
    if (tools.getActiveTool().id !== this.id) { return }
    doc.transformation.mode = 'move'
    this.queueDocRender()
  }

  @action cancelTransformation () {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    doc.transformation.mode = 'move'
    this.queueDocRender()
  }

  activate () {
    const docsPackage = this.packageFor('docs')
    this.transformer = new Transformer({})
    this.optionsView = React.createElement(RasterTransformOptionsView, {
      docs: docsPackage.docs,
      setMode: (mode) => this.setMode(mode),
      applyTransformation: () => this.applyTransformation(),
      cancelTransformation: () => this.cancelTransformation()
    })
    const shortcuts = this.packageFor('shortcuts')
    shortcuts.onEnter.subscribe(() => this.applyTransformation())
    shortcuts.onEsc.subscribe(() => this.applyTransformation())
  }

  showControlBoundary ({ doc }) {
    return this.transformer !== undefined && doc.transformation.mode !== 'move'
  }

  getControlPoints ({ doc }) {
    if (this.showControlBoundary({ doc })) {
      return this.transformer.getControlPoints({ doc })
    }
  }

  getControlLines ({ doc }) {
    if (this.showControlBoundary({ doc })) {
      return this.transformer.getControlLines({ doc })
    }
  }

  getControlCrosshairs ({ doc }) {
    if (this.showControlBoundary({ doc })) {
      return this.transformer.getControlCrosshairs({ doc })
    }
  }

  @action setMode (mode) {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    doc.transformation.mode = mode
    this.queueDocRender()
  }

  onSelect ({ doc }) {
    this.transformer.init({ doc })
    this.queueDocRender({ mode: 'controlPointsUpdated' })
  }

  beginMovement = ({ doc, p }) => {
    this.transformer.beginMovement({ doc, p, mode: doc.transformation.mode })
    this.queueDocRender({ mode: 'controlPointsUpdated' })
  }

  continueMovement = ({ doc, e, p, movementDimensions }) => {
    this.transformer.continueMovement({ doc, e, p, movementDimensions, mode: doc.transformation.mode })
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  _beginTransform () {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const layer = doc.activeLayer
    if (layer === undefined) { return }
    this.transformer.init({ doc })
    this.transformer.beginMovement({ doc })
    const transformable = this.transformer.getTransformable({ doc })
    transformable.updateTransformedQuad()
    return { doc, layer, transformable }
  }

  _flip (flipMethod) {
    const objs = this._beginTransform()
    if (objs === undefined) { return }
    const { doc, layer, transformable } = objs
    transformable[flipMethod]({ doc, layer })
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  rotate ({ angle }) {
    const objs = this._beginTransform()
    if (objs === undefined) { return }
    const { doc, layer, transformable } = objs
    transformable.rotate({ doc, layer, angle: angle * Math.PI / 180.0})
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  rotateClockwise () {
    this.rotate({ angle: 90 })
  }

  rotateCounterClockwise () {
    this.rotate({ angle: -90 })
  }

  rotate180 () {
    this.rotate({ angle: 180 })
  }

  flipHorizontal () {
    this._flip('flipHorizontal')
  }

  flipVertical () {
    this._flip('flipVertical')
  }

  endMovement ({ doc, p, movementDimensions }) {
    this.transformer.endMovement({ doc })
    if (movementDimensions.area > 0) {
      const layer = doc.activeLayer
      if (layer === undefined) { return }
      doc.recordVersion({ name: _.capitalize(layer.transformable.lastMode) })
    }
  }

  dispose () {
    super.dispose()
    this.events.dispose()
  }
}
