import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import LayerManager from './layer-manager'

import './layer-manager-list.scss'

@inject('app') @observer
export default class extends BaseComponent {
  rootStyle () {
    const docsPackage = this.packageFor('docs')
    return {
      display: docsPackage.preloaded === true ? 'block' : 'none'
    }
  }
  render () {
    const docsPackage = this.packageFor('docs')
    const { docs } = this.props
    return (
      <div className="layer-manager-list fill-absolute" style={ this.rootStyle() }>
        <div className="layer-managers">
          { docs.map((doc, { isActive }) =>
            <LayerManager
              doc={ doc }
              layerables={ doc.layerables }
              key={ doc.id }
              visible={ isActive }
            />)
          }
        </div>
      </div>
    )
  }
}
