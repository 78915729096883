import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import MenuItemView from './menu-item-view'

import './menu-bar-view.scss'

@observer
export default class extends BaseComponent {
  clickedOnLogo () {
    showSplashPage()
  }

  render () {
    const { menuItems } = this.props
    return (
      <div className="menu-bar-view">
        <div className="menu-item-view level-0">
          <div className="menu-item-view__label menu-bar-view__logo" onClick={ () => this.clickedOnLogo() }>
            <img src="img/pixal-logo-white.svg"/>
            Pixal
          </div>
        </div>
        { menuItems.map((menuItem) =>
          <MenuItemView key={ menuItem.id }
            menuItem={ menuItem } />
        ) }
      </div>
    )
  }
}
