import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

import './doc-opener-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.state = {
      imageUrl: ''
    }
  }

  imageUrlChanged (e) {
    this.setState({
      imageUrl: e.currentTarget.value
    })
  }

  onFileSelected (e) {
    this.props.openFiles(e.target.files)
  }

  onUrlSelected (e) {
    this.props.openUrl(this.state.imageUrl)
  }

  render () {
    const { state } = this
    const { close } = this.props
    return (
      <div className="doc-opener-view">
        <div className="doc-opener-view__browse-section">
          <div>
            <label className="border-button stretch doc-opener-view__browse-button"
                   htmlFor="doc-opener-view__file-input">
              Browse Files
            </label>
            <input id="doc-opener-view__file-input"
                   onChange={ (e) => this.onFileSelected(e) }
                   multiple
                   type="file" />
          </div>
        </div>
        <div className="doc-opener-view__section">
          <div className="doc-opener-view__info">
            <span className="fa fa-info-circle mr-xl"></span>
            <div className="doc-opener-view__info-label">You can also drag and drop files to open them</div>
          </div>
          <button className="border-button doc-opener-view__side-button" onClick={ close } >Close</button>
        </div>
      </div>
    )
  }
}
          // <input className="border-button stretch doc-opener-view__browse-button"
          //        type="file">
          //   Open files from your computer
          // </input>
