import BasePackage from '../../core/base-package'
import EventList from '../../core/event-list'
import { KEY_CODES } from '../../core/key-codes'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'inputs'
    this.events = new EventList()
  }

  activate () {
    this.events.addSelectorEvent('.blur-on-submit', 'keypress', function (e) {
      if (e.which === KEY_CODES.ENTER && !e.shiftKey) {
        e.currentTarget.blur()
      }
    })
    this.events.addSelectorEvent('input, select', 'keydown', function (e) {
      if (e.which === KEY_CODES.ESC) {
        e.currentTarget.blur()
      }
    })
    this.events.addSelectorEvent('input', 'focus', function (e) {
      e.currentTarget.select()
    })
  }

  dispose () {
    super.dispose()
    this.events.dispose()
  }
}
