import { action } from 'mobx'
import BasePackage from '../../core/base-package'
import Svg from '../../core/svg'
import MarqueeImagePattern from '../../core/vector-definitions/marquee-image-pattern'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'svg'
    this.initSharedDefinitionsLayer()
  }

  @action initSharedDefinitionsLayer () {
    const sharedDefinitionsSvg = new Svg()
    const marqueeImagePattern = new MarqueeImagePattern({ id: 'shared-marquee-image-pattern' })
    sharedDefinitionsSvg.addDefinition(marqueeImagePattern)
    this.sharedDefinitionsSvg = sharedDefinitionsSvg
  }
}
