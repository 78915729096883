import React from 'react'
import FilterPackage from '../../core/filter-package'
import AlphaFilter from './alpha-filter'

export default class extends FilterPackage {
  constructor (options) {
    super(options)
    this.id = 'alpha'
    this.filter = new AlphaFilter()
    this.filterOptions = {}
    this.resetFilterOptions()
  }

  getName () {
    return 'Alpha'
  }
}
