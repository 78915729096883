import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from './base-component'
import VectorView from './vector-view'

@observer
export default class extends BaseComponent {
  svgWidth () {
    const { svg, scale, width } = this.props
    return width || (svg.getWidth() * scale)
  }

  svgHeight () {
    const { svg, scale, height } = this.props
    return height || (svg.getHeight() * scale)
  }

  transformableGroupTransform () {
    const { scale } = this.props
    return `scale(${scale})`
  }

  mainGroupTransform () {
    const { translate } = this.props
    return `translate(${translate.x}, ${translate.y})`
  }

  mainViewBox () {
    const { svg, fitToContainer } = this.props
    if (fitToContainer) {
      return `0 0 ${svg.getWidth()} ${svg.getHeight()}`
    }
  }

  render () {
    const { svg } = this.props
    const { vectors, fixedVectors } = svg

    return (
      <div className="svg-view fill-parent" ref="root">
        <svg xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width={ this.svgWidth() }
          height={ this.svgHeight() }
          viewBox={ this.mainViewBox() }
          className="svg-view__svg fill-absolute">
          <g className="svg-view__main-group" transform={ this.mainGroupTransform() }>
            <g className="svg-view__fixed-group">
              { fixedVectors.map((vector) =>
                <VectorView
                  key={ vector.id }
                  vector={ vector }
                />)
              }
            </g>
            <g transform={ this.transformableGroupTransform() } className="svg-view__transformable-group">
              {svg.hasReferenceView() &&
                <use xlinkHref={ '#' + svg.id } />
              }
              {!svg.hasReferenceView() &&
                <g>
                  { vectors.map((vector) =>
                    <VectorView
                      key={ vector.id }
                      vector={ vector }
                    />)
                  }
                </g>
              }
            </g>
          </g>
        </svg>
      </div>
    )
  }

  static defaultProps = {
    scale: 1,
    translate: { x: 0, y: 0 }
  }
}
