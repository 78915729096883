import Item from './item'

export default class Point extends Item {
  constructor (options = {}) {
    super(options)
    this.setXy(options)
    this.name = options.name
  }

  length () {
    const { x, y } = this
    return Math.hypot(x, y)
  }

  angle () {
    const { x, y } = this
    return Math.atan2(y, x)
  }

  rotate (a) {
    const { cos, sin } = Math
    const { x, y } = this
    return new Point({
        x: x * cos(a) - y * sin(a),
        y: y * cos(a) + x * sin(a)
    })
  }

  dot ({ x, y }) {
    return this.x * x + this.y * y
  }

  projectionOn ({ x, y }) {
    const dotProduct = this.dot({ x, y, })
    const targetLength = Math.hypot(x, y)
    const lengthSquare = targetLength * targetLength
    return new Point({
      x: dotProduct * x / lengthSquare,
      y: dotProduct * y / lengthSquare
    })
  }

  subtract ({ x, y }) {
    return new Point({ x: this.x - x, y: this.y - y })
  }

  add ({ x, y }) {
    return new Point({ x: this.x + x, y: this.y + y })
  }

  $add ({ x, y }) {
    this.x += x
    this.y += y
  }

  multiplyWith (point) {
    return new Point({ x: this.x * point.x, y: this.y * point.y })
  }

  unitVector () {
    const { x, y } = this
    return new Point({
      x: x === 0 ? 0 : x / Math.hypot(x, y),
      y: y === 0 ? 0 : y / Math.hypot(x, y)
    })
  }

  multiplyBy (multiplier) {
    return new Point({ x: this.x * multiplier, y: this.y * multiplier })
  }

  divideBy (divisor) {
    return new Point({ x: this.x / divisor, y: this.y / divisor })
  }

  clone () {
    return new Point({ x: this.x, y: this.y })
  }

  distanceTo (p) {
    return this.subtract(p).length()
  }

  setXy ({ x, y, useIntegers }) {
    if (x !== undefined) { this.x = useIntegers ? Math.round(x) : x }
    if (y !== undefined) { this.y = useIntegers ? Math.round(y) : y }
  }

  adjustToLength (newLength) {
    const currentLength = this.length()
    const multiplier = newLength / currentLength
    return this.multiplyBy(multiplier)
  }

  round () {
    return new Point({ x: Math.round(this.x), y: Math.round(this.y) })
  }

  toString () {
    return 'x: ' + this.x + ', y: ' + this.y
  }

  isEmpty () {
    return this.x === undefined || this.y === undefined
  }

  zero () {
    this.x = 0
    this.y = 0
  }

  serialize () {
    const info = super.serialize()
    return {
      data: {
        ...info.data,
        x: this.x,
        y: this.y
      }
    }
  }

  restore ({ data }) {
    super.restore({ data })
    this.setXy(data)
  }
}
