import Point from '../point'

export function computePathStr (points, closePath = false) {
  if (points === undefined || points.empty()) { return '' }

  const p0 = points.itemAtIndex(0).normalized()
  let str = `M ${p0.x} ${p0.y} `
  str += `C ${p0.c2x} ${p0.c2y}, `

  for (var i = 1; i < points.count(); i++) {
    const p = points.itemAtIndex(i).normalized()
    str += `${p.c1x} ${p.c1y}, ${p.x} ${p.y} C ${p.c2x} ${p.c2y}, `
  }

  if (closePath) {
    str += `${p0.c1x} ${p0.c1y}, ${p0.x} ${p0.y} `
    str += 'Z'
  }

  return str
}

function getBezierPoint (A, B, C, t) {
  const tDiff = 1 - t
  const sum1 = A.multiplyBy(tDiff * tDiff)
  const sum2 = B.multiplyBy(2 * tDiff * t)
  const sum3 = C.multiplyBy(t * t)
  return sum1.add(sum2).add(sum3)
}

export function getBezierPoints (A, B, C, maxInterval) {
  A = new Point(A)
  B = new Point(B)
  C = new Point(C)
  // use the outer bounds of the curve to calculate
  // the maximum possible length of the bezier curve
  const AB = B.subtract(A)
  const BC = C.subtract(B)
  const maxCurveLength = AB.length() + BC.length()

  // dividing maxCurveLength by maxInterval gives us the number of steps we
  // should use to ensure that the maxInterval between points is not exceeded
  var numSteps = maxCurveLength / parseFloat(maxInterval)

  // the getBezierPoint function takes in a value t from 0-1
  // we divide "1" by the number of steps to get the value we should increment t by
  var tIncrement = 1 / numSteps

  var points = []
  var t = 0
  for (let i = 0; i < numSteps; i++) {
    var point = getBezierPoint(A, B, C, t)
    points.push(point)
    t += tIncrement
  }
  return points
}

// function computePointStr (points, index, closePath) {
//   const point = points.itemAtIndex(index)
//   const { radius } = point
//   let prevPoint = points.itemAtIndex(index - 1)
//   let nextPoint = points.itemAtIndex(index + 1)
//   if (index === 0 && closePath) {
//     prevPoint = points.itemAtIndex(points.count() - 1)
//   }
//   if (index === points.count() - 1 && closePath) {
//     nextPoint = points.itemAtIndex(0)
//   }
//   if (point.radius === undefined || point.radius === 0 || [prevPoint, nextPoint].includes(undefined)) {
//     return `${point.x} ${point.y}`
//   }
//   const prevPointDiff = prevPoint.calc().subtract(point)
//   const startPoint = point.calc().add(prevPointDiff.adjustToLength(radius))
//   const nextPointDiff = nextPoint.calc().subtract(point)
//   const endPoint = point.calc().add(nextPointDiff.adjustToLength(radius))
//
//   return `${startPoint.x} ${startPoint.y}
//           Q ${point.x} ${point.y}
//           ${endPoint.x} ${endPoint.y}`
// }
//
// export function computePathStr (points, closePath = false) {
//   if (points === undefined || points.empty()) { return '' }
//
//   let str = `M${computePointStr(points, 0, closePath)}`
//   for (var i = 1; i < points.count(); i++) {
//     str += ` L${computePointStr(points, i, closePath)}`
//   }
//
//   if (closePath) {
//     str += 'Z'
//   }
//
//   return str
// }
