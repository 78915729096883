/* eslint-disable */
import nbr from '../lib/nbr'
import tinycolor from 'tinycolor2'
import Color from './color'

export function sanitizeRgb ({ r, g, b, a }) {
  const sanitize = (value, max = 255, parser = parseInt) => {
    const val = parser(value)
    if (isNaN(val)) { return undefined }
    return nbr(val).withinRange(0, max)
  }
  return {
    r: sanitize(r),
    g: sanitize(g),
    b: sanitize(b),
    a: sanitize(a, 1, parseFloat)
  }
}

export function sanitizeHsv ({ h, s, v }) {
  const sanitize = (value, max = 100) => {
    const val = parseInt(value)
    if (isNaN(val)) { return undefined }
    return nbr(val).withinRange(0, max)
  }
  return {
    h: sanitize(h, 360),
    s: sanitize(s),
    v: sanitize(v)
  }
}

export function hsvToRgb (hsv) {
  return tinycolor(hsv).toRgb()
}

export function hexToRgb (hex) {
  return tinycolor(hex).toRgb()
}

export function rgbToHsv (rgb) {
  const hsv = tinycolor(rgb).toHsv()
  return {
    h: Math.round(hsv.h),
    s: Math.round(hsv.s * 100),
    v: Math.round(hsv.v * 100)
  }
}

export function rgbToHex (rgb) {
  return tinycolor(rgb).toHex()
}

export function isValidColor (color) {
  return tinycolor(color).isValid()
}

export function blendColors (colors) {
  let blendedColor = new Color({ a: 0 })
  for (const color of colors) {
    blendedColor = blendedColor.drawColorOnTop(color)
  }
  return blendedColor
}
