export function selectionMenu () {
  const name = 'Selection'
  return [
    {
      path: [name, 'Select All'],
      action: 'tools.marquee:select-all'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Select None'],
      action: 'tools.marquee:clear-selection'
    },
    {
      path: [name, 'Invert Selection'],
      action: 'tools.marquee:invert-selection'
    }
  ]
}
