import { observable, action } from 'mobx'
import React from 'react'
import BasePackage from '../../core/base-package'
import LayerOptionsView from './layer-options-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'layer-options'
    const layerOptionsView = React.createElement(LayerOptionsView)
    this.layerOptionsView = layerOptionsView
  }
}
