import React from 'react'
import BasePackage from '../../core/base-package'
import LayerManagerList from './layer-manager-list'
import LayerOptionsBar from './layer-options-bar'
import LayerActionsBar from './layer-actions-bar'
import { initObservable } from '../../globals/global-observables'

initObservable('layers', 'mousedown')

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'layer-manager'
  }

  activate = () => {
    this.initLayerManagerList()
  }

  initLayerManagerList = () => {
    const { docs, activeLayer } = this.packageFor('docs')
    const layerManagerList = React.createElement(LayerManagerList, { docs, activeLayer })
    const layerActionsBar = React.createElement(LayerActionsBar, { docs })
    const layerOptionsBar = React.createElement(LayerOptionsBar, { docs })
    const width = '17rem'
    this.packageFor('layouts').addLayoutItem('right-1',
      { viewComponent: layerOptionsBar, row: 49, width })
    this.packageFor('layouts').addLayoutItem('right-1',
      { viewComponent: layerManagerList, row: 50, width, height: 'fill' })
    this.packageFor('layouts').addLayoutItem('right-1',
      { viewComponent: layerActionsBar, row: 51, width })
  }
}
