import { inject, observer } from 'mobx-react'
import prettyBytes from 'pretty-bytes'
import React from 'react'
import BaseComponent from '../../core/base-component'
import EventList from '../../core/event-list'
import { KEY_CODES } from '../../core/key-codes'

import './saver-view.scss'

const PERSISTABLE_STATE = {
  exportType: 'png'
}

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    const doc = this.props.getDoc()
    this.settingsId = 'saver'
    this.initSettings({ settingsId: this.settingsId, persistableState: PERSISTABLE_STATE })
    const storedState = this.retrieveState(props.settingsId, PERSISTABLE_STATE)
    this.state = {
      loading: true,
      previewUrl: undefined,
      name: doc.name,
      exportType: storedState.exportType,
      quality: 90,
      fileSize: 0
    }
    this.blobUrls = []
    doc.exportToBlob({ type: 'png' }, ({ url, blob }) => {
      this.setState({ loading: false, previewUrl: url, fileUrl: url, fileSize: blob.size })
      this.mainPreviewUrl = url
      this.blobUrls.push(url)
    })
    this.events =  new EventList()
  }

  queueRegenerateFileUrl () {
    this.events.setTask('regenerate-file-url', this.regenerateFileUrl)
  }

  regenerateFileUrl = () => {
    const doc = this.props.getDoc()
    const quality = this.state.quality / 100.0
    const type = this.state.exportType

    doc.exportToBlob({ type, quality }, ({ url, blob }) => {
      const previewUrl = this.state.exportType === 'jpeg' ? url : this.mainPreviewUrl
      this.setState({ loading: false, previewUrl, fileUrl: url, fileSize: blob.size })
      this.clearBlobUrls()
      this.blobUrls.push(url)
    })
  }

  resizePreviewContainer () {
    this.refs.previewContainer.style.width = this.refs.preview.width + 'px'
  }

  clearBlobUrls () {
    for (const url of this.blobUrls) {
      if (url !== this.mainPreviewUrl) {
        URL.revokeObjectURL(url)
      }
    }
  }

  componentWillUnmount () {
    for (const url of this.blobUrls) {
      URL.revokeObjectURL(url)
    }
    const doc = this.props.getDoc()
    doc.rename(this.state.name)
    this.events.dispose()
  }

  onNameInputKeypress = (e) => {
    if (e.which === KEY_CODES.ENTER) {
      this.refs.downloadLink.click()
      setTimeout(this.props.onCancel)
    }
  }

  updateExportType (e) {
    const value = e.currentTarget.value
    this.setState({ exportType: value, loading: true })
    this.queueRegenerateFileUrl()
  }

  updateQuality (value) {
    this.setState({ quality: value, loading: true })
    this.queueRegenerateFileUrl()
  }

  clickOnSaveAs () {
    alert('To save your file to a custom location, right-click on the image and select "Save Link As..."')
  }

  qualityStyle () {
    return {
      display: this.state.exportType === 'jpeg' ? 'inline-block' : 'none'
    }
  }

  previewStyle () {
    return {
      opacity: this.state.loading ? 0.5 : 1.0
    }
  }

  loaderStyle () {
    return {
      opacity: this.state.loading ? 1.0 : 0.0
    }
  }

  rootStyle () {
    return {
      pointerEvents: this.state.loading ? 'none' : 'auto'
    }
  }

  render () {
    const { state } = this
    const fileName = state.name + '.' + state.exportType
    const SlidableInput = this.packageFor('sliders').SlidableInput
    return (
      <div className="saver-view" style={ this.rootStyle() }>
        <div className="row">
          <a className="saver-view__preview-container center-all"
             href={ state.fileUrl }
             target="_blank"
             ref="previewContainer">
            <img className="saver-view__preview"
                 style={ this.previewStyle() }
                 ref="preview"
                 src={ state.previewUrl }
                 onLoad={ () => this.resizePreviewContainer() } />

            <div className="saver-view__loader fill-absolute center-all"
                 style={ this.loaderStyle() }>
              <img src="/img/loaders/puff.svg" />
            </div>
          </a>
          <div className="saver-view__inputs align-items-center">
            <div className="stretch">
              <div className="mb-l">
                <input type="text"
                  className="stretch"
                  autoFocus="true"
                  onKeyPress={ this.onNameInputKeypress }
                  value={ state.name }
                  onChange={ (e) => this.setState({ name: e.currentTarget.value }) }
                  placeholder="File name" />
              </div>
              <div className="mb-l">
                <label className="no-wrap mr-l">
                  <input type="radio"
                    className="mr-m"
                    name="saver-view__export-type"
                    checked={ state.exportType === 'png' }
                    value="png"
                    onChange={ (e) => this.updateExportType(e) } />
                  PNG
                </label>
                <label className="no-wrap mr-l">
                  <input type="radio"
                    className="mr-m"
                    name="saver-view__export-type"
                    checked={ state.exportType === 'jpeg' }
                    value="jpeg"
                    onChange={ (e) => this.updateExportType(e) } />
                  JPEG
                </label>
                <label className="no-wrap mr-l">
                  <input type="radio"
                    className="mr-m"
                    name="saver-view__export-type"
                    checked={ state.exportType === 'pxl' }
                    value="pxl"
                    onChange={ (e) => this.updateExportType(e) } />
                  PXL
                </label>
              </div>
              <div>
                <div style={ this.qualityStyle() }>
                  <label className="mr-s">Quality:</label>
                  <SlidableInput value={ state.quality }
                    className="mr-l"
                    align="left"
                    min={ 1 }
                    onChange={ (value) => this.updateQuality(value) } />
                </div>
                <span>
                  Size: { state.loading ? 'Loading...' : prettyBytes(state.fileSize) }
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="saver-view__additional-options">
          <span className="link saver-view__link-option" onClick={ () => this.clickOnSaveAs() }>
            Save As
          </span>
          <a href={ state.fileUrl }
             ref="downloadLink"
             download={ fileName }
             className="link saver-view__link-option"
             onMouseUp={ () => requestAnimationFrame(this.props.onCancel) }>
            Download
          </a>
          <span className="link saver-view__link-option" onClick={ () => this.props.onCancel() }>
            Close
          </span>
        </div>
      </div>
    )
  }
}
