import Svg from './svg'
import { registerGlobalFactory } from './factory-references'

export default class SvgLayer extends Svg {
  constructor (options = {}) {
    if (options.shouldGeneratePic === undefined) { options.shouldGeneratePic = true }
    if (options.shouldCreateReference === undefined) { options.shouldCreateReference = true }
    if (options.shouldGenerateHitTestPic === undefined) { options.shouldGenerateHitTestPic = true }
    super(options)
  }
  getFactoryKey () { return 'SvgLayer' }
}

registerGlobalFactory('SvgLayer', SvgLayer)
