import Strokable from '../strokable/strokable'
import EraserOptionsView from './eraser-options-view'

export default class extends Strokable {
  constructor (options = {}) {
    super({ ...options, id: 'eraser', name: 'Eraser', settingsId: 'eraser' })
    this.icon = 'wi-eraser'
    this.info = 'Eraser (E)'
  }

  getOptionsViewClass () { return EraserOptionsView }
  getOptionsViewClassName () { return 'eraser-options-view' }

  getEraserMode () {
    return true
  }
}
