import React from 'react'
import Event from './event'
import { generateId } from './id'

export default class {
  constructor (options = {}) {
    this.app = options.app
    this.dialogStateWasChanged = new Event()
  }

  packageFor = (id) => {
    return this.app.packageFor(id)
  }

  activate () {}

  createDialog = (viewComponent, options = {}) => {
    const { DialogView } = this.packageFor('dialogs')
    const dialogView = React.createElement(DialogView, { viewComponent, options })
    const dialogId = generateId()
    const dialogWasShown = new Event()
    const dialogWasHidden = new Event()
    return {
      id: dialogId,
      view: dialogView,
      dialogWasShown,
      dialogWasHidden,
      show: () => {
        this.showDialog(dialogId, dialogView, options)
        dialogWasShown.trigger()
      },
      hide: () => {
        this.hideDialog(dialogId, options)
        dialogWasHidden.trigger()
      }
    }
  }

  showDialog = (dialogId, dialogView, options = {}) => {
    this.packageFor('layouts').clearFreeformItems()
    this.packageFor('layouts').findOrAddFreeformItem(dialogId,
      { viewComponent: dialogView, name: options.name })
    const shortcuts = this.packageFor('shortcuts')
    const { onConfirm, onCancel } = options
    if (onConfirm) { shortcuts.onEnter.subscribe(onConfirm) }
    if (onCancel) { shortcuts.onEsc.subscribe(onCancel) }
    this.packageFor('dialogs').dialogStateWasChanged.trigger()
  }

  hideDialog = (dialogId, options = {}) => {
    this.packageFor('layouts').removeFreeformItem(dialogId)
    const shortcuts = this.packageFor('shortcuts')
    const { onConfirm, onCancel } = options
    if (onConfirm) { shortcuts.onEnter.unsubscribe(onConfirm) }
    if (onCancel) { shortcuts.onEsc.unsubscribe(onCancel) }
    this.packageFor('dialogs').dialogStateWasChanged.trigger()
  }

  getActiveDoc = () => {
    return this.packageFor('docs').fetchActiveDoc()
  }

  getActiveTool = () => {
    return this.packageFor('tools').fetchActiveTool()
  }

  getTool = (toolId) => {
    return this.packageFor('tools').tools.find(toolId)
  }

  getActiveLayer = () => {
    return this.packageFor('docs').fetchActiveLayer()
  }

  queueDocRender = (options = {}) => {
    this.packageFor('docs').scheduleDocRender(options)
  }

  dispose () {}
}
