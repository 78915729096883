import { inject, observer } from 'mobx-react'
import React from 'react'
import { limitDecimalPlaces } from '../../core/format'
import BaseComponent from '../../core/base-component'

import './doc-status-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  render () {
    const docsPackage = this.packageFor('docs')
    const { docs } = this.props
    const activeDoc = docs.getActiveItem()
    return (
      <div className="doc-status-view">
        { docsPackage.preloaded && docs.count() > 0 && activeDoc !== undefined &&
          `${activeDoc.width} x ${activeDoc.height} | ${limitDecimalPlaces(activeDoc.zoomPercentage)}%`
        }
      </div>
    )
  }
}
