import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import EventList from '../../core/event-list'
import classNames from 'classnames'

@observer
export default class extends BaseComponent {
  componentWillMount () {
    const { newColor } = this.props
    this.rgbColor = { r: newColor.r, g: newColor.g, b: newColor.b }
    this.events = new EventList()
  }
  componentDidMount () {
    const { rgbColor } = this
    const { newColor } = this.props
    const rgbKeys = ['r', 'g', 'b']
    rgbKeys.forEach((key) => {
      this.events.addObserver(newColor, key, () => {
        if (rgbColor[key] === newColor[key]) { return }
        rgbColor[key] = newColor[key]
        this.setState({ rgbColor })
      })
    })
  }
  onRgbInputBlur = (e, key) => {
    const { rgbColor } = this
    const { newColor } = this.props
    if (rgbColor[key] === newColor[key]) { return }
    rgbColor[key] = newColor[key]
    this.setState({ rgbColor })
  }
  onRgbInputChange = (e, key) => {
    const inputValue = e.currentTarget.value
    const { rgbColor } = this
    rgbColor[key] = inputValue
    this.setState({ rgbColor })
    const value = parseInt(inputValue)
    if (isNaN(value)) { return }
    const { newColor } = this.props
    newColor.setRgb({ [key]: value })
  }
  componentWillUnmount () {
    this.events.dispose()
  }
  render () {
    const { onRgbInputChange, onRgbInputBlur, rgbColor } = this
    const rgbKeys = ['r', 'g', 'b']

    return (
      <tbody>
      { rgbKeys.map((key) => {
        return (
          <tr key={ key }
            className={ classNames({ 'last-row-of-group': key === 'b' }) }>
            <td>{ key.toUpperCase() }:</td>
            <td>
              <input type="number"
                autoFocus={ key === 'r' }
                value={ rgbColor[key] }
                onChange={ (e) => onRgbInputChange(e, key) }
                onBlur={ (e) => onRgbInputBlur(e, key) }
                min="0"
                max="255"/>
            </td>
            <td></td>
          </tr>
        )
      })}
      </tbody>)
  }
}
