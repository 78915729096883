import $ from 'jquery'
import DomEvent from './dom-event'
import TaskBuffer from './task-buffer'
import { observe } from 'mobx'

export default class {
  constructor () {
    this.events = []
    this.domEvents = []
    this.observerDisposers = []
    this.intervalIds = []
    this.selectorEvents = []
    this.taskBuffers = {}
  }

  addEvent (event, listener) {
    event.subscribe(listener)
    this.events.push({ event, listener })
  }

  addDomEvent (element, event, listener, eventOptions) {
    this.domEvents.push(new DomEvent({ element, event, listener, eventOptions }))
  }

  addObserver (obj, key, callback) {
    this.observerDisposers.push(observe(obj, key, callback))
  }

  addInterval (callback, duration) {
    this.intervalIds.push(setInterval(callback, duration))
  }

  addSelectorEvent (selector, event, callback) {
    $(document).on(event, selector, callback)
    this.selectorEvents.push({ selector, event, callback })
  }

  setTask (taskKey, task) {
    if (this.taskBuffers[taskKey] === undefined) {
      this.taskBuffers[taskKey] = new TaskBuffer()
    }
    const taskBuffer = this.taskBuffers[taskKey]
    taskBuffer.setTask(task)
  }

  dispose () {
    for (const { event, listener } of this.events) { event.unsubscribe(listener) }
    this.events = []

    for (const event of this.domEvents) { event.dispose() }
    this.domEvents = []

    for (const disposer of this.observerDisposers) { disposer() }
    this.observerDisposers = []

    for (const intervalId of this.intervalIds) { clearInterval(intervalId) }
    this.intervalIds = []

    for (const { selector, event, callback } of this.selectorEvents) {
      $(document).off(event, selector, callback)
    }

    for (const taskBuffer of Object.values(this.taskBuffers)) {
      taskBuffer.dispose()
    }

    this.selectorEvents = []
  }
}
