import React from 'react'
import { observer } from 'mobx-react'
import BaseComponent from '../../core/base-component'

import './tool-options-view.scss'

@observer
export default class extends BaseComponent {
  render () {
    const { toolOptionsWrapper } = this.props
    return (
      <div className="tool-options-view align-items-center">
        { toolOptionsWrapper.toolOptions ||
          <div>
            No tool options
          </div>
        }
      </div>
    )
  }
}
