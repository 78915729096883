import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import DocView from './doc-view'
import EventList from '../../core/event-list'
import { wgl } from '../../core/wgl'
import { globals } from '../../globals'

import './doc-view-list.scss'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)

    this.state = {
      showBlankOptions: false
    }
    this.events = new EventList()
    this.events.addEvent(globals.observables.doc.zoom, () => {
      const { canvas } = this
      if (canvas === undefined) { return }
      const doc = this.props.docs.getActiveItem()
      if (doc === undefined) { return }
      canvas.style.imageRendering = doc.zoom > 1 ? 'pixelated' : 'auto'
    })
  }

  updateBlankOptions = () => {
    const layoutsPackage = this.packageFor('layouts')
    let hasNewOrOpenDocDialog = false
    layoutsPackage.freeformItemsContainer.layoutItems.each((item) => {
      if (item.name === 'New Document' || item.name === 'Open Document') {
        hasNewOrOpenDocDialog = true
      }
    })
    this.setState({
      showBlankOptions: !hasNewOrOpenDocDialog
    })
  }

  componentDidMount () {
    const { canvasContainer } = this.refs
    const { canvas } = wgl.gl
    canvas.style.width = '100%'
    canvas.style.height = '100%'
    canvas.style.transform = 'scaleY(-1)'
    canvasContainer.appendChild(canvas)
    this.canvas = canvas
    const dialogspackage = this.packageFor('dialogs')
    this.events.addEvent(dialogspackage.dialogStateWasChanged, () => {
      this.updateBlankOptions()
    })
    this.updateBlankOptions()
  }

  rootRefCallback = (root) => {
    this.root = root
    this.props.root(root)
  }

  dispose () {
    this.events.dispose()
  }

  docViewsStyle = () => {
    return {
      pointerEvents: this.props.docs.count() > 0 ? 'auto' : 'none'
    }
  }

  canvasContainerStyle = () => {
    const docsPackage = this.packageFor('docs')
    return {
      pointerEvents: this.props.docs.count() > 0 ? 'auto' : 'none',
      visibility: this.props.docs.count() > 0 ? 'visible' : 'hidden',
      opacity: docsPackage.preloaded === true && docsPackage.loading !== true ? 1 : 0
    }
  }

  clickOnCreateImage () {
    this.app.executeCommand('doc-creator:show-doc-creator-dialog')
  }

  clickOnOpenImage () {
    this.app.executeCommand('doc-opener:show-doc-opener-dialog')
  }

  loaderStyle = () => {
    const docsPackage = this.packageFor('docs')
    return {
      display: docsPackage.preloaded === true && docsPackage.loading === true ? 'flex' : 'none'
    }
  }

  render () {
    const docsPackage = this.packageFor('docs')
    const { docs, onMouseDown, onMouseMove, docViewMounted } = this.props
    return (
      <div className="doc-view-list fill-absolute background-inset"
        ref={ this.rootRefCallback }
        onMouseMove={ onMouseMove }>
        <div className="doc-view-list__doc-views fill-absolute" style={ this.docViewsStyle() }>
          { docs.map((doc, info) =>
            <DocView key={ doc.id }
                    doc={ doc }
                    visible={ info.isActive }
                    selectionViewCanvas = { this.selectionViewCanvas }
                    onMouseDown={ onMouseDown }
                    docViewMounted={ docViewMounted }
            />)
          }
        </div>
        <div className="fill-absolute center-all doc-view-list__loader" style={ this.loaderStyle() }>
          <img src="/img/loaders/puff.svg" />
        </div>
        <div className="doc-view-list__canvas-container fill-absolute"
             style={ this.canvasContainerStyle() }
             ref="canvasContainer"></div>
        { this.state.showBlankOptions && (docs.count() === 0 || docsPackage.preloaded !== true) &&
          <div className="doc-view-list__blank-options-container center-all fill-absolute">
            <div className="doc-view-list__blank-options">
              <button className="btn" onClick={ () => this.clickOnCreateImage() }>Create a new image</button>
              <span className="or">or</span>
              <button className="btn" onClick={ () => this.clickOnOpenImage() }>Open an image</button>
            </div>
          </div>
        }
      </div>
    )
  }
}
