import { inject, observer } from 'mobx-react'
import { observable, action } from 'mobx'
import React from 'react'
import BaseComponent from '../../core/base-component'
import DocViewportFollower from './doc-viewport-follower'
import { Editor, RichUtils } from 'draft-js'
import EventList from '../../core/event-list'
import el from '../../lib/el'

import './doc-text-layer-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  @observable inTextEditMode = false

  constructor (props) {
    super(props)
    this.layer = props.layer
    this.events = new EventList()
    this.events.addEvent(this.layer.tex.wasUpdated, () => {
      this.queueDocRender({ mode: 'layersUpdated' })
    })
    this.events.addEvent(this.layer.textEditModeEntered, ({ skipSelection }) => {
      this.initialContent = this.layer.editorState
      this.refs.editor.focus()
      if (skipSelection !== true) {
        requestAnimationFrame(() => {
          document.execCommand('selectAll', false, null)
        })
      }
    })
    this.state = {
      editorState: this.layer.editorState
    }
  }

  handleMouseEvent = (e) => {
    if (this.layer.inTextEditMode) { e.stopPropagation() }
  }

  componentDidMount () {
    const editor = el(this.refs.editorContainer).find('.DraftEditor-root')

    this.events.addDomEvent(editor, 'mousedown', this.handleMouseEvent)
    this.events.addDomEvent(editor, 'mousemove', this.handleMouseEvent)
    this.events.addDomEvent(editor, 'mouseup', this.handleMouseEvent)
    this.events.addDomEvent(editor, 'click', this.handleMouseEvent)
  }

  editorStyle = () => {
    const { doc } = this.props
    const { layer } = this
    const style = {
      overflow: 'hidden',
      position: 'absolute',
      color: 'rgba(0, 0, 0, 0)',
      caretColor: '#' + layer.style.color,
      fontSize: (layer.style.fontSize * doc.zoom) + 'px',
      fontFamily: layer.style.fontFamily,
      textAlign: layer.style.textAlignment,
      width: (layer.textWidth * doc.zoom) + 'px',
      pointerEvents: layer.inTextEditMode ? 'auto' : 'none',
      left: (layer.x * doc.zoom) + 'px',
      top: (layer.y * doc.zoom) + 'px'
    }
    return style
  }

  setEditorState = (editorState) => {
    this.layer.setEditorState(editorState)
    this.setState({ editorState })
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) { this.setEditorState(newState) }
  }

  exitTextEditMode = () => {
    this.props.doc.exitTextEditMode()
    this.refs.editor.blur()

    if (this.initialContent !== undefined && this.initialContent != this.layer.editorState) {
      const { doc } = this.props
      doc.recordVersion({ name: 'Edit Text' })
    }
    this.initialContent = undefined
  }

  componentWillUnmount () { this.events.dispose() }

  boundaryStyle = () => {
    const { doc } = this.props
    const boundary = this.props.layer.getBoundary()
    return {
      border: '1px solid ' + '#' + this.props.layer.style.color,
      borderStyle: 'dashed',
      opacity: 0.5,
      position: 'absolute',
      left: (boundary.x * doc.zoom) + 'px',
      top: (boundary.y * doc.zoom) + 'px',
      width: (boundary.width * doc.zoom) + 'px',
      height: (boundary.height * doc.zoom) + 'px',
      display: this.props.layer.inTextEditMode ? 'block' : 'none'
    }
  }

  render () {
    return (
      <div className="doc-text-layer-view non-blocking" ref="root">
        <DocViewportFollower { ...this.props } viewportWidthOverride={ 0 } viewportHeightOverride={ 0 }>
          <div style={ { ...this.boundaryStyle() } }></div>
          <div style={ { ...this.editorStyle() } } ref="editorContainer">
            <Editor ref="editor"
                    editorState={ this.state.editorState }
                    onChange={ this.setEditorState }
                    handleKeyCommand={ this.handleKeyCommand }
                    onEscape={ this.exitTextEditMode }
                    onBlur={ this.exitTextEditMode }/>
          </div>
        </DocViewportFollower>
      </div>
    )
  }
}
