import Filter from '../filter'
import { COMPOSITE_MODE } from '../../constants/composite-modes'
import { withDefault } from '../utils'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'move'
  }

  move ({ wgl, des, _src, _offset }) {
    wgl.applyFilter({
      filter: this,
      des,
      data: {
        _src,
        _offset,
      }
    })
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform vec2 _srcSize;
      uniform vec2 _offset;

      void main () {
        outputColor = texColorFor(_src, _srcSize, _offset);
        return;
      }
    `
  }
}
