import Filter from '../filter'
import { getSoftValueSnippet } from './snippets/soft-value'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'stroke'
  }

  getFragmentShader () {
    return getSoftValueSnippet() + `
      const float e = 2.718281828459;
      const float CIRCLE_BRUSH = 1.0;
      const float SQUARE_BRUSH = 2.0;

      uniform vec4 _fillStyle;
      uniform vec2 _points[100];
      uniform float _radius;
      uniform float _numPoints;
      uniform float _softnessRatio;
      uniform float _brushType;

      uniform sampler2D _src;
      uniform bool _srcHasData;

      float getSolidCutoff (float r) {
        float blurWidth = _softnessRatio * r;
        if (blurWidth < 2.0) {
          blurWidth = 2.0;
        }
        if (blurWidth > r) { blurWidth = r; }
        return 1.0 - (blurWidth / r);
      }

      float getDistance (vec2 point) {
        return getDistance(currPosition(), point);
      }

      vec2 getClosestPoint () {
        vec2 closestPoint = _points[0];
        float minDistance = getDistance(closestPoint);
        for (int i = 0; i < int(_numPoints); i++) {
          float distance = getDistance(_points[i]);
          if (distance < minDistance) {
            minDistance = distance;
            closestPoint = _points[i];
          }
        }
        return closestPoint;
      }

      float calcCircleAlpha (vec2 point, float r) {
        float d = getDistance(point);
        if (d > r) { return 0.0; }
        float dRatio = d / r;
        float solidCutoff = getSolidCutoff(r);
        float alpha = softValue(dRatio, solidCutoff);
        return alpha;
      }

      float calcSquareAlpha (vec2 point, float r) {
        float x = coordX();
        float y = coordY();
        if (abs(point.x - x) < r && abs(point.y - y) < r) {
          return 1.0;
        }
        return 0.0;
      }

      float getMaxAlphaFromDistances () {
        vec2 closestPoint = getClosestPoint();
        if (_brushType == SQUARE_BRUSH) {
          return calcSquareAlpha(closestPoint, _radius);
        }
        return calcCircleAlpha(closestPoint, _radius);
      }

      void main () {
        vec4 texColor = _srcHasData ? texColorFor(_src) : vec4(0.0, 0.0, 0.0, 0.0);

        float calculatedAlpha = getMaxAlphaFromDistances();
        if (calculatedAlpha == 0.0) {
          outputColor = texColor;
          return;
        }
        // calculatedAlpha = calculatedAlpha * calculatedAlpha * calculatedAlpha;

        float textureAlpha = texColor.a;

        float baseAlpha = calculatedAlpha > textureAlpha ? calculatedAlpha : textureAlpha;
        float overlayAlpha = calculatedAlpha < textureAlpha ? calculatedAlpha : textureAlpha;

        overlayAlpha = overlayAlpha * 0.0;
        float finalAlpha = baseAlpha + overlayAlpha * (1.0 - baseAlpha);
        outputColor = vec4(_fillStyle.rgb, finalAlpha);
      }
    `
  }
}
