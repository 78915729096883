function removeListener (listeners, listener) {
  const index = listeners.indexOf(listener)
  if (index > -1) { listeners.splice(index, 1) }
}

export default class {
  constructor () {
    this.beforeListeners = []
    this.listeners = []
    this.afterListeners = []
    this.listenerQueue = []
  }

  before (listener) { this.beforeListeners.push(listener) }
  subscribe (listener) { this.listeners.push(listener) }
  after (listener) { this.afterListeners.push(listener) }

  enqueueSubscriber (listener) { this.listenerQueue.push(listener) }

  removeBeforeListener (listener) { removeListener(this.beforeListeners, listener) }
  unsubscribe (listener) { removeListener(this.listeners, listener) }
  removeAfterListener (listener) { removeListener(this.afterListeners, listener) }

  trigger (...args) {
    for (const listener of this.beforeListeners) { listener(...args) }

    for (const listener of this.listeners) { listener(...args) }
    const listenerQueueRemovables = []
    for (const listener of this.listenerQueue) {
      const result = listener(...args)
      if (result && result.removeFromQueue) {
        listenerQueueRemovables.push(listener)
      }
    }
    for (const listener of listenerQueueRemovables) { removeListener(this.listenerQueue, listener) }

    for (const listener of this.afterListeners) { listener(...args) }
  }
}
