import HorizontalPresenceFilter from './filters/presence-filter/horizontal-presence-filter'
import VerticalPresenceFilter from './filters/presence-filter/vertical-presence-filter'
import CornerPresenceFilter from './filters/presence-filter/corner-presence-filter'

import LinearizeHorizontalFilter from './filters/boundary-filter/linearize-horizontal'
import LinearizeVerticalFilter from './filters/boundary-filter/linearize-vertical'
import VerticalBoundaryFilter from './filters/boundary-filter/vertical-boundary'
import HorizontalBoundaryFilter from './filters/boundary-filter/horizontal-boundary'

import { pool } from './gl-canvas-pool'

const presenceHorizontalFilter = new HorizontalPresenceFilter()
const presenceVerticalFilter = new VerticalPresenceFilter()
const presenceCornerFilter = new CornerPresenceFilter()

const linearizeHorizontalFilter = new LinearizeHorizontalFilter()
const linearizeVerticalFilter = new LinearizeVerticalFilter()
const verticalBoundaryFilter = new VerticalBoundaryFilter()
const horizontalBoundaryFilter = new HorizontalBoundaryFilter()

function hasFilledPixel (glCanvas) {
  const { width, height } = glCanvas.size()
  const pixels = new Uint8Array(4)
  const { gl } = glCanvas
  gl.readPixels(0, height - 1, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixels)
  return pixels[3] > 0
}

function colorToIndex ({ r, g, b }) {
  return r * (256 * 256) + g * 256 + b
}

function readBoundaryValue (glCanvas) {
  const { width, height } = glCanvas.size()
  const pixels = new Uint8Array(8)
  const { gl } = glCanvas
  gl.readPixels(0, height - 1, 2, 1, gl.RGBA, gl.UNSIGNED_BYTE, pixels)
  // values are encoded as colors
  const minColor = {
    r: pixels[0],
    g: pixels[1],
    b: pixels[2]
  }
  const maxColor = {
    r: pixels[4],
    g: pixels[5],
    b: pixels[6]
  }
  return {
    min: colorToIndex(minColor),
    max: colorToIndex(maxColor)
  }
}

export function fabricHasData (fabric) {
  const glCanvas = pool.requestGlCanvas()
  glCanvas.resize(fabric.size())

  glCanvas.applyFilter(presenceHorizontalFilter, { srcTexture: fabric.asTexture() })
  if (hasFilledPixel(glCanvas)) {
    pool.releaseGlCanvas(glCanvas)
    return true
  }

  const tempFabric = fabric.clone()

  tempFabric.drawImage(glCanvas.canvas)
  glCanvas.applyFilter(presenceVerticalFilter, { srcTexture: tempFabric.asTexture() })
  if (hasFilledPixel(glCanvas)) {
    pool.releaseGlCanvas(glCanvas)
    return true
  }

  tempFabric.drawImage(glCanvas.canvas)
  glCanvas.applyFilter(presenceCornerFilter, { srcTexture: tempFabric.asTexture() })
  if (hasFilledPixel(glCanvas)) {
    pool.releaseGlCanvas(glCanvas)
    return true
  }
  return false
}

export function getFabricBoundary (fabric) {
  const tempFabric = fabric.clone()
  const glCanvas = pool.requestGlCanvas()
  glCanvas.resize(fabric.size())

  // get vertical boundary
  glCanvas.applyFilter(presenceHorizontalFilter, { srcTexture: fabric.asTexture() })

  tempFabric.drawImage(glCanvas.canvas)
  glCanvas.applyFilter(linearizeHorizontalFilter, { srcTexture: tempFabric.asTexture() })

  tempFabric.drawImage(glCanvas.canvas)
  glCanvas.applyFilter(verticalBoundaryFilter, { srcTexture: tempFabric.asTexture() })

  const verticalMinMax = readBoundaryValue(glCanvas)

  // get horizontal boundary
  glCanvas.applyFilter(presenceVerticalFilter, { srcTexture: fabric.asTexture() })

  tempFabric.clear()
  tempFabric.drawImage(glCanvas.canvas)
  glCanvas.applyFilter(linearizeVerticalFilter, { srcTexture: tempFabric.asTexture() })

  tempFabric.drawImage(glCanvas.canvas)
  glCanvas.applyFilter(horizontalBoundaryFilter, { srcTexture: tempFabric.asTexture() })

  const horizontalMinMax = readBoundaryValue(glCanvas)

  pool.releaseGlCanvas(glCanvas)
  tempFabric.dispose()

  const result = {
    min: {
      x: horizontalMinMax.min,
      y: verticalMinMax.min
    },
    max: {
      x: horizontalMinMax.max === 0 ? 0 : horizontalMinMax.max + 1,
      y: verticalMinMax.max === 0 ? 0 : verticalMinMax.max + 1
    }
  }

  return result
}
