import Filter from '../filter'
import { COMPOSITE_MODE } from '../../constants/composite-modes'
import { withDefault } from '../utils'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'blend'
  }

  blend ({ wgl, des, _src1, _src2, _offset1, _offset2, _alpha, _compositeMode, _mask, _hasMask }) {
    wgl.applyFilter({
      filter: this,
      des,
      data: {
        _src1,
        _src2,
        _offset1: withDefault(_offset1, [0, 0]),
        _offset2: withDefault(_offset2, [0, 0]),
        _alpha: withDefault(_alpha, 1),
        _mask,
        _hasMask: withDefault(_hasMask, false),
        _compositeMode: withDefault(_compositeMode, COMPOSITE_MODE.NORMAL)
      }
    })
  }

  getFragmentShader () {
    return `
      // _src2 is on top of _src1

      uniform sampler2D _src2;
      uniform vec2 _src2Size;

      uniform sampler2D _src1;
      uniform vec2 _src1Size;

      uniform vec2 _offset1;
      uniform vec2 _offset2;

      uniform float _alpha;
      uniform float _compositeMode;

      uniform sampler2D _mask;
      uniform vec2 _maskSize;
      uniform bool _hasMask;

      void main () {
        vec4 color1 = texColorFor(_src1, _src1Size, _offset1);
        if (_hasMask == false) {
          vec4 color2 = texColorFor(_src2, _src2Size, _offset2);
          outputColor = drawColorOnTop(color2, color1, _alpha, _compositeMode);
          return;
        }

        vec4 maskColor = texColorFor(_mask, _maskSize);
        if (maskColor.a == 0.0) {
          outputColor = color1;
          return;
        }
        vec4 color2 = texColorFor(_src2, _src2Size, _offset2);
        color2.a = color2.a * maskColor.a;
        outputColor = drawColorOnTop(color2, color1, _alpha, _compositeMode);
        return;
      }
    `
  }
}
