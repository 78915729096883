import { format as formatDate } from 'date-fns'

export function limitDecimalPlaces (number, maxDecimals = 3) {
  return +(Math.round(number + `e+${maxDecimals}`) + `e-${maxDecimals}`)
}

export function displayTime (date) {
  return formatDate(date, 'h:mma')
}

export function displayShortDate (date) {
  return formatDate(date, 'D MMM')
}
