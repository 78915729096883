function tToV ({ t, v }, { t0, t1, t2, t3, p0, p1, p2, p3 }) {
  const A1 = (t1 - t) / (t1 - t0) * p0[v] + (t - t0) / (t1 - t0) * p1[v]
  const A2 = (t2 - t) / (t2 - t1) * p1[v] + (t - t1) / (t2 - t1) * p2[v]
  const A3 = (t3 - t) / (t3 - t2) * p2[v] + (t - t2) / (t3 - t2) * p3[v]
  const B1 = (t2 - t) / (t2 - t0) * A1 + (t - t0) / (t2 - t0) * A2
  const B2 = (t3 - t) / (t3 - t1) * A2 + (t - t1) / (t3 - t1) * A3
  const value = (t2 - t) / parseFloat(t2 - t1) * B1 + (t - t1) / parseFloat(t2 - t1) * B2
  return value
}

// https://qroph.github.io/2018/07/30/smooth-paths-using-catmull-rom-splines.html
function precalculateCurveConstants ({ t0, t1, t2, t3, p0, p1, p2, p3 }, v) {
  const tension = 0
  const divide = (a, b) => {
    if (a === 0.0) { return 0.0 }
    return a / parseFloat(b)
  }
  const m1a = divide(p1[v] - p0[v], t1 - t0)
  const m1b = divide(p2[v] - p0[v], t2 - t0)
  const m1c = divide(p2[v] - p1[v], t2 - t1)
  const m1 = (t2 - t1) * (m1a - m1b + m1c) * (1 - tension)
  const m2a = divide(p2[v] - p1[v], t2 - t1)
  const m2b = divide(p3[v] - p1[v], t3 - t1)
  const m2c = divide(p3[v] - p2[v], t3 - t2)
  const m2 = (t2 - t1) * (m2a - m2b + m2c) * (1 - tension)
  const a = 2 * p1[v] - 2 * p2[v] + m1 + m2
  const b = (-3) * p1[v] + 3 * p2[v] - 2 * m1 - m2
  const c = m1
  const d = p1[v]
  return { a, b, c, d }
}

function tToV ({ t }, { a, b, c, d }) {
  return a * (t * t * t) + b * (t * t) + c * (t) + d
}

// https://en.wikipedia.org/wiki/Centripetal_Catmull%E2%80%93Rom_spline
export function getSmoothPoints (points, spacing) {
  const p0 = points[0]
  const p1 = points[1]
  const p2 = points[2]
  const p3 = points[3]
  const t0 = 0.0
  const t1 = calcT(t0, p0, p1)
  const t2 = calcT(t1, p1, p2)
  const t3 = calcT(t2, p2, p3)
  const maxCurvePoints = 1000.0
  const tStep = 1.0 / maxCurvePoints
  let prevP = undefined
  const curvePoints = []
  const xConstants = precalculateCurveConstants({ t0, t1, t2, t3, p0, p1, p2, p3 }, 'x')
  const yConstants = precalculateCurveConstants({ t0, t1, t2, t3, p0, p1, p2, p3 }, 'y')
  for (let t = 0; t < 1; t += tStep) {
    const p = {
      x: tToV({ t }, xConstants),
      y: tToV({ t }, yConstants)
    }
    if (prevP !== undefined) {
      const distance = Math.hypot(p.x - prevP.x, p.y - prevP.y)
      if (distance < spacing) { continue }
    }
    curvePoints.push(p)
    prevP = p
  }
  const dArr = []
  for (let i = 1; i < curvePoints.length; i++) {
    const a = curvePoints[i - 1]
    const b = curvePoints[i]
    const d = Math.hypot(a.x - b.x, a.y - b.y)
    dArr.push(d)
  }
  return curvePoints
}

function calcT (t, p0, p1) {
  const a = Math.pow((p1.x - p0.x), 2) + Math.pow((p1.y - p0.y), 2)
  const b = Math.pow(a, 0.5)
  const alpha = 0.5
  const c = Math.pow(b, alpha)
  return parseFloat(c + t)
}
