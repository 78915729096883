export function getBilinearInterpolationSnippet () {
  return `
    vec4 linearBlend (vec4 color1, vec4 color2, float distanceToColor1) {
      if (color1.a == 0.0 && color2.a == 0.0) { return EMPTY_COLOR; }
      if (color1.a == 0.0) { color1.rgb = color2.rgb; }
      if (color2.a == 0.0) { color2.rgb = color1.rgb; }
      return color1 * (1.0 - distanceToColor1) + color2 * distanceToColor1;
    }

    vec4 bilinearBlend (vec4 topLeftColor, vec4 topRightColor, vec4 bottomLeftColor, vec4 bottomRightColor, vec2 distanceToTopLeft) {
      vec4 topColor = linearBlend(topLeftColor, topRightColor, distanceToTopLeft.x);
      vec4 bottomColor = linearBlend(bottomLeftColor, bottomRightColor, distanceToTopLeft.x);
      return linearBlend(topColor, bottomColor, distanceToTopLeft.y);
    }

    vec4 getInterpolatedColor (sampler2D tex, vec2 texSize, vec2 position) {
      vec2 topLeft = vec2(floor(position.x - 0.5) + 0.5, floor(position.y - 0.5) + 0.5);
      vec2 topRight = topLeft + vec2(1.0, 0.0);
      vec2 bottomLeft = topLeft + vec2(0.0, 1.0);
      vec2 bottomRight = topLeft + vec2(1.0, 1.0);

      vec2 unitVector = position - topLeft;

      vec4 topLeftColor = texColorAt(tex, texSize, topLeft);
      vec4 topRightColor = texColorAt(tex, texSize, topRight);
      vec4 bottomLeftColor = texColorAt(tex, texSize, bottomLeft);
      vec4 bottomRightColor = texColorAt(tex, texSize, bottomRight);

      return bilinearBlend(topLeftColor, topRightColor, bottomLeftColor, bottomRightColor, unitVector);
    }
  `
}
