import { observable, action, computed } from 'mobx'
import { BLEND_MODE } from '../constants/blend-modes'
import Item from './item'
import nbr from '../lib/nbr'

export default class extends Item {
  @observable blendMode = 'NORMAL'
  @observable opacity = 1
  @observable visible = true
  @observable name = ''

  constructor (options = {}) {
    super(options)
    this.wasRenamed = false
  }

  blendModeForShader () {
    return BLEND_MODE[this.blendMode.toUpperCase()]
  }

  @computed get opacityPercentage () { return parseInt(this.opacity * 100) }

  @action setBlendMode (mode) { this.blendMode = mode }

  @action setOpacityPercentage (opacityPercentage) {
    if (isNaN(opacityPercentage)) { return }
    this.opacity = nbr(opacityPercentage).withinRange(0, 100) / 100.0
  }

  @action setOpacity (opacity) {
    if (isNaN(opacity)) { return }
    this.opacity = nbr(opacity).withinRange(0, 1)
  }

  @action setVisibility (visible) {
    this.visible = visible
  }

  @action setName (name, options = {}) {
    name = name.trim()
    if (this.name === name) { return }
    this.name = name
    if (options.autoRename !== true) {
      this.wasRenamed = true
    }
  }

  initializableAttributes () {
    return ['opacity', 'name']
  }

  type () { throw new Error('type() method must be implemented') }

  isSvgType = () => { return this.type() === 'svg' }
  isVectorType = () => { return this.type() === 'vector' }
  isTextType = () => { return this.type() === 'text' }
  isRasterType () { return this.type() === 'raster' }
  isGroupType () { return this.type() === 'group' }

  serialize () {
    const info = super.serialize()
    return {
      data: {
        ...info.data,
        blendMode: this.blendMode,
        opacity: this.opacity,
        visible: this.visible,
        name: this.name
      }
    }
  }

  @action restore ({ data }) {
    super.restore({ data })
    this.blendMode = data.blendMode
    this.opacity = data.opacity
    this.visible = data.visible
    this.name = data.name
  }
}
