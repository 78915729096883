export function animateToTarget ({ element, target, duration, onEnd, removeTransitions }) {
  const currentRect = element.getBoundingClientRect()

  let targetRect = target
  if (target.nodeType === 1) {
    targetRect = target.getBoundingClientRect() // target is an element
  }

  if (element.animated) { clearTimeout(element.animated) }

  const translate = {
    x: (targetRect.left - currentRect.left) + 'px',
    y: (targetRect.top - currentRect.top) + 'px'
  }

  element.style.transition = 'all ' + duration + 'ms, opacity 0ms'
  element.style.transform = `translate(${translate.x}, ${translate.y})`

  return new Promise((resolve) => {
    element.animated = setTimeout(function () {
      if (removeTransitions !== false) {
        element.style.transition = ''
        element.style.transform = ''
      }
      element.animated = undefined
      resolve()
      if (onEnd) { onEnd() }
    }, duration)
  })
}
