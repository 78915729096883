import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.state = { redAmount: 0, greenAmount: 0, blueAmount: 0 }
  }

  updateOption = (key) => {
    return (value) => {
      this.setState({ [key]: value })
      this.props.setFilterOption(key, value / 100.0)
    }
  }

  render () {
    const { onCancel, onConfirm } = this.props
    const InputSliderRow = this.packageFor('sliders').InputSliderRow
    return (
      <div className="color-balance-view">
        <form className="row" onSubmit={ onConfirm }>
          <div className="filter-inputs">
            <InputSliderRow label="Red:"
              className="mb-s"
              min={ -100 }
              max={ 100 }
              autoFocus="true"
              value={ this.state.redAmount }
              onChange={ this.updateOption('redAmount') } />
            <InputSliderRow label="Green:"
              min={ -100 }
              max={ 100 }
              value={ this.state.greenAmount }
              onChange={ this.updateOption('greenAmount') } />
            <InputSliderRow label="Blue:"
              min={ -100 }
              max={ 100 }
              value={ this.state.blueAmount }
              onChange={ this.updateOption('blueAmount') } />
          </div>
          <div className="ml-xl filter-buttons">
            <button type="submit"
              className="border-button stretch mb-m">
              Ok
            </button>
            <button type="button"
              onClick={ onCancel }
              className="border-button stretch">
              Cancel
            </button>
          </div>
        </form>
      </div>
    )
  }
}
