const degree = '\u00B0'

export function imageMenu () {
  const name = 'Image'
  return [
    {
      path: [name, 'Image Size'],
      action: 'image-resizer:show-image-resizer-dialog'
    },
    {
      path: [name, 'Canvas Size'],
      action: 'canvas-resizer:show-canvas-resizer-dialog'
    },
    {
      path: [name, 'Crop'],
      action: 'tools.marquee:crop'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Flip Canvas Horizontal'],
      action: 'docs:flip-horizontal'
    },
    {
      path: [name, 'Flip Canvas Vertical'],
      action: 'docs:flip-vertical'
    },
    {
      path: [name, 'DIVIDER'],
    },
    {
      path: [name, 'Rotate Canvas 90' + degree + ' Clockwise'],
      action: 'docs:rotate-clockwise'
    },
    {
      path: [name, 'Rotate Canvas 90' + degree + ' Counter Clockwise'],
      action: 'docs:rotate-counter-clockwise'
    },
    {
      path: [name, 'Rotate Canvas 180' + degree],
      action: 'docs:rotate-180'
    }
  ]
}
