import Item from './item'
import Point from './point'
import { getCorners } from './rect-helpers'

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.start = undefined
    this.end = undefined
    this.topLeft = new Point()
    this.topRight = new Point()
    this.bottomLeft = new Point()
    this.bottomRight = new Point()
    this.useIntegers = options.useIntegers
    this.points = [this.topLeft, this.topRight, this.bottomLeft, this.bottomRight]
  }

  setCorners ({ start, end }) {
    const { useIntegers } = this
    this.start = useIntegers ? new Point(start).round() : new Point(start)
    this.end = useIntegers ? new Point(end).round() : new Point(end)
    this.updateData()
  }

  setStart (point) {
    const { useIntegers } = this
    this.start = useIntegers ? new Point(point).round() : new Point(point)
    this.updateData()
  }

  setEnd (point) {
    const { useIntegers } = this
    this.end = useIntegers ? new Point(point).round() : new Point(point)
    this.updateData()
  }

  clear () {
    this.start = undefined
    this.end = undefined
    this.updateData()
  }

  updateData () {
    this.updateDimensions()
    this.updatePoints()
    this.touch()
  }

  updateDimensions () {
    this.dimensions = this.getDimensions()
  }

  updatePoints () {
    const { start, end } = this
    if (start === undefined || end === undefined) { return }
    const { topLeft, bottomRight } = getCorners(start, end)
    this.topLeft.setXy(topLeft)
    this.topRight.setXy({ x: bottomRight.x, y: topLeft.y })
    this.bottomRight.setXy(bottomRight)
    this.bottomLeft.setXy({ x: topLeft.x, y: bottomRight.y })
  }

  getDimensions () {
    const { start, end } = this
    if (start === undefined || end === undefined) { return }
    const { topLeft, bottomRight } = getCorners(start, end)
    const rectDimensions = { start: topLeft, end: bottomRight }
    const width = Math.abs(start.x - end.x)
    const height = Math.abs(start.y - end.y)
    rectDimensions.width = width
    rectDimensions.height = height
    rectDimensions.area = rectDimensions.width * rectDimensions.height
    rectDimensions.diagonalLength = Math.sqrt(width * width + height * height)
    rectDimensions.xDiff = end.x - start.x
    rectDimensions.yDiff = end.y - start.y
    rectDimensions.x = end.x - start.x
    rectDimensions.y = end.y - start.y
    return rectDimensions
  }
}
