import Filter from '../../core/filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'black-white'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;
      uniform float treshold;

      void main() {
          vec4 color = texColorFor(_src);
          float brightness = getBrightnessOfColor(color);
          float value = 0.0;
          if (brightness > treshold) { value = 1.0; }
          outputColor = vec4(value, value, value, color.a);
      }
    `
  }
}
