import BasePackage from '../../core/base-package'
import Collection from '../../core/collection'
import BlendFilter from '../../core/filters/blend-filter'
import MoveFilter from '../../core/filters/move-filter'
import Tex from '../../core/tex'
import { COMPOSITE_MODE } from '../../constants/composite-modes'
import { wgl } from '../../core/wgl'

const blendFilter = new BlendFilter()
const moveFilter = new MoveFilter()

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'clipboard'
    this.clips = new Collection({ collectionLimit: 1 })
  }

  storeCopy = () => {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const { selectionTex } = doc
    const layer = doc.activeLayer
    let clip
    if (selectionTex.hasData) {
      clip = new Tex(layer.size())
      blendFilter.blend({
        wgl,
        des: clip,
        _src1: layer.getTex(),
        _src2: selectionTex,
        _compositeMode: COMPOSITE_MODE.DESTINATION_IN
      })
    } else {
      clip = layer.getTex().deepClone()
    }

    this.clips.push(clip)
    return doc
  }

  copy = () => {
    this.storeCopy()
  }

  cut = () => {
    const doc = this.storeCopy()
    if (doc === undefined) { return }
    const marquee = this.getTool('marquee')
    marquee._deleteSelection()
    doc.selectionTex.clear()
    doc.recordVersion({ name: 'Cut' })
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  paste () {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }

    const clip = this.clips.last()
    if (clip === undefined) {
      alert('There is nothing to paste, you must first copy or cut pixels to the clipboard')
      return
    }
    const layer = doc.addRasterLayer({ skipVersion: true })
    moveFilter.move({
      wgl,
      des: layer.tex,
      _src: clip,
      _offset: [0, 0]
    })
    doc.selectionTex.clear()
    doc.recordVersion({ name: 'Paste' })
    this.queueDocRender({ mode: 'layersUpdated' })
  }
}
