import Filter from '../../core/filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'sharpen'
  }

  getFragmentShader () {
    return `
      uniform sampler2D blurredTexture;
      uniform sampler2D originalTexture;
      uniform float sharpenAmount;

      vec4 mixColors (vec4 color1, vec4 color2, float amount) {
        if (color1.a == 0.0) { color1.rgb = color2.rgb; }
        if (color2.a == 0.0) { color2.rgb = color1.rgb; }
        return color1 * (1.0 - amount) + color2 * amount;
      }

      void main() {
          vec4 blurred = texColorFor(blurredTexture);
          vec4 original = texColorFor(originalTexture);
          // outputColor = mix(blurred, original, 1.0 + sharpenAmount);
          outputColor = mixColors(blurred, original, 1.0 + sharpenAmount);
      }
    `
  }
}
