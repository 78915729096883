import Dom from './dom'
import DragEvent from './drag-event'
import EventList from './event-list'
import Item from './item'
import nbr from '../lib/nbr'
import ev from '../lib/ev'
import el from '../lib/el'
import { animateToTarget } from './animate'

const MIN_MOVE_DISTANCE = 2
const SCROLL_INTERVAL = 10
const SCROLL_SPEED = 1
const SCROLL_SPEED_DIFF_MULTIPLIER = 0.02

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.initDragAxis(options.dragAxis)
    this.initCursorAlignment(options.elementToCursorAlignment)
    this.initElementLimit(options.elementLimit)
    this.container = options.container || this.element.parentNode
    this.scrollContainer = options.scrollContainer
    this.handle = options.handle || this.element
    this.events = new EventList()
    this.initEvents(options)
    this.storeElementPositionRatio()
  }

  initializableAttributes () {
    return super.initializableAttributes().concat([
      'element', 'ghostDrag', 'markDrag', 'siblingMatcher', 'dragMarkerClass',
      'scrollContainer', 'clickContainer', 'absorbMouseEvents',
      'hideElementOnDrag', 'ignorableClasses', 'animateGhostToElementDuration',
      'onGhostAnimationEnd', 'swapElementWithSiblings', 'swapSiblingsAnimationDuration',
      'onClick', 'onMouseDown'
    ])
  }

  initElementLimit = (elementLimit) => {
    let parsedLimit = { elementCenterWithinContainer: true }
    if (elementLimit === 'default-list-limit') {
      parsedLimit = { defaultListLimit: true }
    } else if (elementLimit === 'element-within-container') {
      parsedLimit = { elementWithinContainer: true }
    } else if (elementLimit === 'unbounded') {
      parsedLimit = { unbounded: true }
    }
    this.elementLimit = parsedLimit
  }

  initCursorAlignment = (elementToCursorAlignment) => {
    let parsedAlignment = { useInitialOffset: true }
    if (elementToCursorAlignment === 'centered') { parsedAlignment = { centered: true } }
    this.elementToCursorAlignment = parsedAlignment
  }

  initDragAxis = (dragAxis) => {
    this.dragAxis = { x: true, y: true }
    if (dragAxis === undefined) { return }
    if (dragAxis === 'x') { this.dragAxis.y = false }
    if (dragAxis === 'y') { this.dragAxis.x = false }
  }

  initEvents = (options = {}) => {
    const { handle, clickContainer, ignorableClasses } = this
    const { beginMovement, continueMovement, endMovement } = this
    const targetElement = clickContainer === undefined ? handle : clickContainer
    let minMoveDistance
    if (options.minMoveDistance !== undefined) {
      minMoveDistance = options.minMoveDistance
    } else {
      minMoveDistance = clickContainer === undefined ? MIN_MOVE_DISTANCE : 0
    }
    this.dragEvent = new DragEvent({
      targetElement,
      minMoveDistance,
      ignorableClasses,
      onBegin: beginMovement,
      onContinue: continueMovement,
      onEnd: endMovement,
      onClick: this.onClick,
      onMouseDown: this.onMouseDown
    })
    if (this.absorbMouseEvents) {
      this.events.addDomEvent(targetElement, 'click', (e) => {
        e.preventDefault()
        e.stopPropagation()
      })
    }
  }

  // placeholder methods to be overwritten externally
  onBeginMovement () {}
  onContinueMovement () {}
  onEndMovement () {}

  beginMovement = (e) => {
    if (this.absorbMouseEvents) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.initialOffset = ev(e).relativeCoordinates(this.element)
    const { element, container, ghostDrag, markDrag, dragMarkerClass } = this
    if (ghostDrag) {
      this.dragClone = el(element).clone()
      this.dragClone.classList.add('drag-clone')
      container.appendChild(this.dragClone)
    }
    if (markDrag) {
      this.marker = Dom.div({ parent: container,
        className: `drag-marker ${dragMarkerClass}` })
    }
    this.initialIndex = this.getElementIndex()
    if (this.hideElementOnDrag) { this.element.style.visibility = 'hidden' }
    this.onBeginMovement(this)
    this.continueMovement(e)
    this.scrollIntervalId = setInterval(this.updateScroll, SCROLL_INTERVAL)
    const siblingNodes = this.siblingNodes()
    this.siblingRects = []
    for (const sibling of siblingNodes) {
      this.siblingRects.push(sibling.getBoundingClientRect())
    }
  }

  continueMovement = (e) => {
    const { markDrag, swapElementWithSiblings } = this
    this.moveAlongDragAxis(this.getDraggedElement(), e)
    this.markerIndex = this.getMarkerIndex()
    this.draggedElementIndex = this.getDraggedElementIndex()
    if (markDrag) { this.updateMarkerPosition() }
    if (swapElementWithSiblings) { this.updateSiblingOrder() }
    this.updateScroll()
    this.onContinueMovement(this)
  }

  getElementIndex () {
    const siblingNodes = this.siblingNodes()
    return siblingNodes.indexOf(this.element)
  }

  updateSiblingOrder () {
    const { draggedElementIndex, element, siblingRects } = this
    const siblingNodes = this.siblingNodes()
    const currentIndex = siblingNodes.indexOf(element)
    const newIndex = draggedElementIndex
    if (currentIndex !== newIndex && newIndex !== this.updateSiblingOrderAnimationIndex) {
      this.updateSiblingOrderAnimationIndex = newIndex
      const indexChanges = {}
      indexChanges[currentIndex] = newIndex
      if (newIndex > currentIndex) {
        for (let i = currentIndex + 1; i <= newIndex; i++) {
          indexChanges[i] = i - 1
        }
      } else {
        for (let i = currentIndex - 1; i >= newIndex; i--) {
          indexChanges[i] = i + 1
        }
      }
      const promises = []
      for (const prevIndex in indexChanges) {
        const nextIndex = indexChanges[prevIndex]
        if (this.animationIndexChanges !== undefined &&
          this.animationIndexChanges[prevIndex] === nextIndex) { continue }
        const promise = animateToTarget({
          element: siblingNodes[prevIndex],
          target: siblingRects[nextIndex],
          duration: this.swapSiblingsAnimationDuration,
          removeTransitions: false
        })
        promises.push(promise)
      }
      this.animationIndexChanges = indexChanges
      Promise.all(promises).then(() => {
        this.updateSiblingOrderAnimationIndex = undefined
        this.animationIndexChanges = undefined
        const sibling = siblingNodes[draggedElementIndex]
        const insertionType = draggedElementIndex < currentIndex ? 'beforebegin' : 'afterend'
        sibling.insertAdjacentElement(insertionType, element)
        for (const sibling of siblingNodes) {
          sibling.style.transition = ''
          sibling.style.transform = ''
        }
      })
    }
  }

  updateScroll = () => {
    const { scrollContainer } = this
    if (scrollContainer === undefined) { return }
    const draggedElement = this.getDraggedElement()
    if (draggedElement.offsetTop - scrollContainer.scrollTop < 0) {
      const diff = scrollContainer.scrollTop - draggedElement.offsetTop
      const scrollValue = SCROLL_SPEED + diff * SCROLL_SPEED_DIFF_MULTIPLIER
      scrollContainer.scrollTop = scrollContainer.scrollTop - scrollValue
      return
    }
    const bottomOfDraggedElement = draggedElement.offsetTop + el(draggedElement).outerHeight()
    if (bottomOfDraggedElement > el(scrollContainer).innerHeight()) {
      const diff = bottomOfDraggedElement - el(scrollContainer).innerHeight()
      const scrollValue = SCROLL_SPEED + diff * SCROLL_SPEED_DIFF_MULTIPLIER
      scrollContainer.scrollTop = scrollContainer.scrollTop + scrollValue
    }
  }

  updateMarkerPosition () {
    const { marker, markerIndex, initialIndex } = this
    const siblingNodes = this.siblingNodes()
    const markerHeight = el(marker).outerHeight()
    if (markerIndex < siblingNodes.length) {
      const sibling = siblingNodes[markerIndex]
      const top = Math.floor(sibling.offsetTop - markerHeight / 2)
      marker.style.top = `${top}px`
    } else {
      const lastSibling = siblingNodes[siblingNodes.length - 1]
      const siblingHeight = el(lastSibling).outerHeight()
      const top = lastSibling.offsetTop + siblingHeight - markerHeight / 2
      marker.style.top = `${top}px`
    }
    if (markerIndex === initialIndex || markerIndex === initialIndex + 1) {
      marker.style.opacity = 0.2
    } else {
      marker.style.opacity = 1.0
    }
  }

  endMovement = (e) => {
    if (this.absorbMouseEvents) {
      e.preventDefault()
      e.stopPropagation()
    }
    const { container, element, dragClone, markDrag, marker,
      animateGhostToElementDuration, onGhostAnimationEnd, hideElementOnDrag } = this
    this.markerIndex = this.getMarkerIndex()
    this.elementIndex = this.getElementIndex()
    if (animateGhostToElementDuration !== undefined) {
      animateToTarget({ element: dragClone, target: element, duration: animateGhostToElementDuration,
        onEnd: () => {
          el(container).removeChild(dragClone)
          if (hideElementOnDrag) { this.element.style.visibility = 'visible' }
          if (onGhostAnimationEnd) { onGhostAnimationEnd() }
        }
      })
    } else {
      el(container).removeChild(dragClone)
      if (hideElementOnDrag) { this.element.style.visibility = 'visible' }
    }
    if (markDrag) { el(container).removeChild(marker) }
    clearInterval(this.scrollIntervalId)
    this.onEndMovement(this)
  }

  getDraggedElement = () => {
    if (this.ghostDrag) { return this.dragClone }
    return this.element
  }

  getMarkerIndex = () => {
    const { dragAxis } = this
    const draggedElement = this.getDraggedElement()
    if (dragAxis.y) {
      if (draggedElement.offsetTop <= 0) { return 0 }
      const siblingNodes = this.siblingNodes()
      for (let i = 0; i < siblingNodes.length; i++) {
        const sibling = siblingNodes[i]
        if (draggedElement.offsetTop < sibling.offsetTop) {
          return i
        }
      }
      return siblingNodes.length
    }
  }

  getDraggedElementIndex = () => {
    const { dragAxis } = this
    const draggedElement = this.getDraggedElement()
    const draggedElementSize = el(draggedElement).outerSize()
    const siblingNodes = this.siblingNodes()
    if (dragAxis.x) {
      let offset = 0
      for (const siblingNode of siblingNodes) {
        if (draggedElement.offsetTop <= siblingNode.offsetTop) {
          break
        }
        offset++
      }
      const breakpoint = draggedElement.offsetLeft - draggedElementSize.width / 2.0
      return nbr(offset + Math.ceil(breakpoint / draggedElementSize.width)).withinRange(0, siblingNodes.length - 1)
    }
  }

  siblingNodes = () => {
    const { siblingMatcher, container, dragClone } = this
    if (siblingMatcher === undefined) { return [] }
    const nodes = []
    for (const node of container.childNodes) {
      if (node === dragClone) { continue }
      if (siblingMatcher(node)) { nodes.push(node) }
    }
    return nodes
  }

  constrainDragPointWithinLimit (point) {
    const { element, elementLimit, container } = this
    const elementSize = el(element).outerSize()
    const halfOfElementHeight = elementSize.height / 2.0
    const halfOfElementWidth = elementSize.width / 2.0
    if (elementLimit.unbounded) { return }
    const containerSize = el(container).innerSize()
    let leftLimit = 0
    let rightLimit = containerSize.width
    let topLimit = 0
    let bottomLimit = containerSize.height
    if (elementLimit.elementCenterWithinContainer) {
      leftLimit = -halfOfElementWidth
      rightLimit = containerSize.width - halfOfElementWidth
      topLimit = -halfOfElementHeight
      bottomLimit = containerSize.height - halfOfElementHeight
    } else if (elementLimit.elementWithinContainer) {
      rightLimit = containerSize.width - elementSize.width
      bottomLimit = containerSize.height - elementSize.height
    } else if (elementLimit.defaultListLimit) {
      const siblingNodes = this.siblingNodes()
      const lastSiblingNode = siblingNodes[siblingNodes.length - 1]
      if (lastSiblingNode !== undefined) {
        bottomLimit = lastSiblingNode.offsetTop
      }
    }
    const verticalLimits = { min: topLimit, max: bottomLimit }
    const horizontalLimits = { min: leftLimit, max: rightLimit }
    point.x = nbr(point.x).withinRange(horizontalLimits.min, horizontalLimits.max)
    point.y = nbr(point.y).withinRange(verticalLimits.min, verticalLimits.max)
  }

  moveAlongDragAxis = (draggedElement, e) => {
    const { element, container, initialOffset,
      elementToCursorAlignment, dragAxis } = this

    const elementSize = el(element).outerSize()
    const halfOfElementHeight = elementSize.height / 2.0
    const halfOfElementWidth = elementSize.width / 2.0
    const eventPoint = ev(e).relativeCoordinates(container, { includeScroll: true })
    const point = eventPoint.clone()
    const siblingNodes = this.siblingNodes()

    if (elementToCursorAlignment.useInitialOffset) {
      point.x -= initialOffset.x
      point.y -= initialOffset.y
    } else if (elementToCursorAlignment.centered) {
      point.x -= halfOfElementWidth
      point.y -= halfOfElementHeight
    }
    this.constrainDragPointWithinLimit(point)
    if (dragAxis.y) {
      draggedElement.style.top = point.y + 'px'
    }
    if (dragAxis.x) {
      draggedElement.style.left = point.x + 'px'
      let offsetTop = element.offsetTop
      for (const sibling of siblingNodes) {
        const siblingHeight = el(sibling).outerHeight()
        offsetTop = sibling.offsetTop
        const breakpoint = sibling.offsetTop + siblingHeight
        if (eventPoint.y < breakpoint) { break }
      }
      draggedElement.style.top = offsetTop + 'px'
    }
    this.storeElementPositionRatio()
  }

  setElementPositionRatio ({ x, y }) {
    const { container, element } = this
    const elementSize = el(element).outerSize()
    const halfOfElementHeight = elementSize.height / 2.0
    const halfOfElementWidth = elementSize.width / 2.0
    const containerSize = el(container).innerSize()

    if (x !== undefined) {
      element.style.left = `${containerSize.width * x - halfOfElementWidth}px`
      this.elementPositionRatio.x = x
    }
    if (y !== undefined) {
      element.style.top = `${containerSize.height * y - halfOfElementHeight}px`
      this.elementPositionRatio.y = y
    }
  }

  resyncElementPositionRatio = () => {
    this.setElementPositionRatio(this.elementPositionRatio)
  }

  storeElementPositionRatio () {
    const { container, dragAxis, element } = this
    const elementSize = el(element).outerSize()
    const halfOfElementHeight = elementSize.height / 2.0
    const halfOfElementWidth = elementSize.width / 2.0
    const containerSize = el(container).innerSize()
    const elementPosition = el(element).position()
    const point = {
      x: elementPosition.left,
      y: elementPosition.top
    }

    this.elementPositionRatio = {}
    if (dragAxis.x) {
      this.elementPositionRatio.x = (point.x + halfOfElementWidth) / containerSize.width
    }
    if (dragAxis.y) {
      this.elementPositionRatio.y = (point.y + halfOfElementHeight) / containerSize.height
    }
  }

  dispose () {
    super.dispose()
    this.dragEvent.dispose()
    this.events.dispose()
  }
}
