import React from 'react'
import BasePackage from '../../core/base-package'
import ToolColorsView from './tool-colors-view'
import Color from '../../core/color'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'tool-colors'
    this.settingsId = this.id
    this.foregroundColor = new Color()
    this.backgroundColor = new Color()
  }

  activate = () => {
    this.initToolColorsView()
    const settings = this.packageFor('settings')
    const storedColors = settings.retrieveState(this.settingsId, {
      foregroundColor: { r: 25, g: 98, b: 224 },
      backgroundColor: { r: 0, g: 0, b: 0 }
    })
    this.foregroundColor.setRgb(storedColors.foregroundColor)
    this.backgroundColor.setRgb(storedColors.backgroundColor)
    this.foregroundColor.colorWasUpdated.subscribe(() => {
      settings.store(this.settingsId, { foregroundColor: this.foregroundColor.rgb })
    })
    this.backgroundColor.colorWasUpdated.subscribe(() => {
      settings.store(this.settingsId, { backgroundColor: this.backgroundColor.rgb })
    })
  }

  showColorPickerDialog = (color) => {
    this.packageFor('color-picker').showColorPickerDialog(color)
  }

  showForegroundPicker = () => {
    this.showColorPickerDialog(this.foregroundColor)
  }

  showBackgroundPicker = () => {
    this.showColorPickerDialog(this.backgroundColor)
  }

  resetColors = () => {
    const { foregroundColor, backgroundColor } = this
    foregroundColor.setRgb({ r: 0, g: 0, b: 0 })
    backgroundColor.setRgb({ r: 255, g: 255, b: 255 })
  }

  swapColors = () => {
    const { foregroundColor, backgroundColor } = this
    const tempRgb = {
      r: foregroundColor.r,
      g: foregroundColor.g,
      b: foregroundColor.b
    }
    foregroundColor.setRgb(backgroundColor.rgb)
    backgroundColor.setRgb(tempRgb)
  }

  initToolColorsView = () => {
    const { foregroundColor, backgroundColor,
      showForegroundPicker, showBackgroundPicker } = this
    const toolColorsView = React.createElement(ToolColorsView,
      {
        foregroundColor, backgroundColor,
        foregroundColorClicked: showForegroundPicker,
        backgroundColorClicked: showBackgroundPicker,
        resetClicked: this.resetColors,
        swapClicked: this.swapColors
      })
    this.packageFor('layouts').addLayoutItem('left-1',
      { viewComponent: toolColorsView,
        row: 75 })
  }
}
