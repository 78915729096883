import React from 'react'
import BasePackage from '../../core/base-package'
import DocHistoryViewList from './doc-history-view-list'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'doc-history'
  }

  activate () {
    const { docs } = this.packageFor('docs')
    const docHistoryViewList = React.createElement(DocHistoryViewList, { docs })
    this.packageFor('layouts').addLayoutItem('right-1',
      { viewComponent: docHistoryViewList,
        row: 25,
        height: 'fill' })
  }
}
