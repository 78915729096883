import ToolPackage from '../../core/tool-package'
import RasterTransform from './raster-transform'

export default class extends ToolPackage {
  constructor (options) {
    super(options)
    this.id = 'raster-transform'
    this.tools = [RasterTransform]
  }
}
