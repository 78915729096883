import Filter from '../../filter'
import { getStoreValueAsColorSnippet } from '../snippets/store-value-as-color'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'vertical-boundary'
  }

  // this method uses the result of linearize-horizontal
  // it loops through a vertical strip to find the minimum and maximum point that has data
  // the input is expected to be a texture of size(width: 2, height: 1)
  getFragmentShader () {
    return getStoreValueAsColorSnippet() + `
      uniform sampler2D _src;
      uniform vec2 _srcSize;

      void calcMin () {
        for (float y = 0.0; y < _srcSize.y; y += 1.0) {
          vec4 color = texColorAt(_src, _srcSize, vec2(0.5, y + 0.5));
          if (color.a > 0.0) {
            storeValueAsColor(y);
            return;
          }
        }
        clearOutputColor();
      }

      void calcMax () {
        for (float y = _srcSize.y - 1.0; y >= 0.0; y -= 1.0) {
          vec4 color = texColorAt(_src, _srcSize, vec2(0.5, y + 0.5));
          if (color.a > 0.0) {
            storeValueAsColor(y + 1.0);
            return;
          }
        }
        clearOutputColor();
      }

      void main () {
        float x = coordX();
        float y = coordY();

        if (y != 0.5) { return; }

        if (x == 0.5) { calcMin(); }
        if (x == 1.5) { calcMax(); }
      }
    `
  }
}
