import $ from 'jquery'
const packageProp = Symbol('Used to reference the package prop')
import Command from './core/command'

export default class App {
  constructor () {
    this[packageProp] = {}
    this.menuSelectors = {}
  }

  hasUnsavedChanges () {
    const { docs } = this.packageFor('docs')
    let hasChanged = false
    docs.each((doc) => {
      if (doc.hasUnsavedChanges()) {
        hasChanged = true
      }
    })
    return hasChanged
  }

  registerPackage = (packageItem) => {
    if (this[packageProp][packageItem.id] !== undefined) {
      throw new Error(`A package with id "${packageItem.id}" already exists.`)
    }
    this[packageProp][packageItem.id] = packageItem
  }

  packageFor = (packageId) => {
    const p = this[packageProp][packageId]
    if (p === undefined) {
      throw new Error(`No package with ID "${packageId}" has been registered yet.`)
    }
    return p
  }

  addMenuSelector = (selector) => {
    this.menuSelectors[selector] = true
  }

  hasOpenedMenu = () => {
    for (const selector in this.menuSelectors) {
      if (this.menuSelectors[selector]) {
        if ($(selector + '.active').length > 0) {
          return true
        }
      }
    }
    return false
  }

  getTool = (toolId) => {
    return this.packageFor('tools').tools.find(toolId)
  }

  executeCommand = (commandString) => {
    const command = new Command({ commandString })
    command.execute(this)
  }
}
