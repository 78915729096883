import $ from 'jquery'
import { action, observable } from 'mobx'
import React from 'react'
import BasePackage from '../../core/base-package'
import Collection from '../../core/collection'
import Dom from '../../core/dom'
import Event from '../../core/event'
import DocViewList from './doc-view-list'
import Doc from '../../core/doc'
import Fabric from '../../core/fabric'
import EventList from '../../core/event-list'
import Point from '../../core/point'
import el from '../../lib/el'
import ev from '../../lib/ev'
import { wgl } from '../../core/wgl'
import { triggerObservable } from '../../globals/global-observables'
import DocViewFilter from '../../core/filters/doc-view-filter'
import FlipFilter from '../../core/filters/flip-filter'
import RotateFilter from '../../core/filters/rotate-filter'
import ary from '../../lib/ary'
import { readDataFileFromZip } from '../../core/doc-helpers'

const docViewFilter = new DocViewFilter()
const flipFilter = new FlipFilter()
const rotateFilter = new RotateFilter()

const TICK_WIDTH = 12
const TICK_INTERVAL = 200

const HORIZONTAL_FLIP = 1
const VERTICAL_FLIP = 2

const ROTATE_CLOCKWISE = 1
const ROTATE_COUNTER_CLOCKWISE = 2

export default class extends BasePackage {
  @observable preloaded = false
  @observable loading = false

  constructor (options) {
    super(options)
    this.id = 'docs'
    this.docs = new Collection()
    this.mouseDownInDocView = new Event()
    this.mouseMoveInDocViewList = new Event()
    this.docViewMounted = new Event()
    this.events = new EventList()
    this.events.addObserver(this.docs, 'activeId', () => {
      triggerObservable('doc', 'zoom')
      triggerObservable('layerables', 'activeId')
    })
    this.mousePosition = new Point()
    this.relativeMousePosition =  new Point()
    this.mousePositionInDoc = new Point()
    this.mouseInDocView = false
    this.tick = 0
  }

  @action setPreloaded (value) {
    this.preloaded = value
  }

  activate = () => {
    this.initDocViewList()
    this.events.addInterval(() => this.queueUpdateTicks(), TICK_INTERVAL)
    $(document).keyup((e) => {
      if (e.key === 'Alt') {
        this.altPressed = false
        this.queueDocRender({ mode: 'cursorMoved' })
        this.updateCursor()
      }
      if (e.key === 'Meta') {
        this.metaPressed = false
        this.queueDocRender({ mode: 'cursorMoved' })
        this.updateCursor()
      }
    })
    const shortcuts = this.packageFor('shortcuts')
    shortcuts.onAlt.subscribe(() => {
      this.altPressed = true
      this.queueDocRender({ mode: 'cursorMoved' })
      this.updateCursor()
    })
    shortcuts.onMeta.subscribe(() => {
      this.metaPressed = true
      this.queueDocRender({ mode: 'cursorMoved' })
      this.updateCursor()
    })
    this.packageFor('dialogs').dialogStateWasChanged.subscribe(() => {
      this.queueDocRender({ mode: 'cursorMoved' })
      this.updateCursor()
    })
    this.events.addObserver(this.docs, 'activeId', () => {
      this.queueDocRender({ mode: 'layersUpdated' })
      this.updateCursor()
    })

    Dom.onMouseMove((e) => {
      this.mousePosition.setXy({ x: e.pageX, y: e.pageY })
      const doc = this.getActiveDoc()
      if (doc === undefined) { return }
      const { viewportContainer, viewportRectElement } = doc.getMeta('doc-view')
      if (viewportContainer === undefined || viewportRectElement === undefined) { return }

      const coords = ev(e).relativeCoordinates(viewportContainer)
      const size = el(viewportContainer).outerSize({ excludeScrollbars: true })
      const mouseInDocView = coords.x >= 0 && coords.y >= 0 && coords.x < size.width && coords.y < size.height
      const mouseInViewStateChanged = mouseInDocView != this.mouseInDocView
      this.relativeMousePosition.setXy(coords)
      const positionInDoc = ev(e).relativeCoordinates(viewportRectElement)
      this.mousePositionInDoc.setXy(positionInDoc.divideBy(doc.zoom))
      this.mouseInDocView = mouseInDocView
      if (mouseInViewStateChanged || mouseInDocView) {
        this.queueDocRender({ mode: 'cursorMoved' })
      }
      this.updateCursor()
    })
  }

  initDocViewList = () => {
    const docs = this.docs
    const docViewList = React.createElement(DocViewList, {
      docs: docs,
      docViewMounted: this.docViewMounted,
      onMouseDown: (element, e) => {
        if (this.mouseInDocView) {
          this.mouseDownInDocView.trigger(docs.getActiveItem(), element, e)
        }
      },
      onMouseMove: (e) => {
        this.mouseMoveInDocViewList.trigger(this.docViewListElement, e)
      },
      root: (el) => {
        this.docViewListElement = el
      }
    })
    this.packageFor('layouts').addLayoutItem('center',
      { viewComponent: docViewList,
        row: 50,
        width: 'fill', height: 'fill' })
    this.docViewList = docViewList
  }

  updateCursor = () => {
    const { docViewListElement } = this
    if (docViewListElement === undefined) { return }

    const doc = this.getActiveDoc()
    if (doc === undefined || this.docs.count() === 0) {
      docViewListElement.style.cursor = 'default'
      return
    }

    const tool = this.getActiveTool()
    if (tool === undefined) { return }

    let cursorType = 'default'
    if (this.mouseInDocView === true) {
      const cursorArgs = {
        doc,
        mousePositionInDoc: this.mousePositionInDoc
      }
      if (this.altPressed && this.metaPressed !== true) {
        cursorType = tool.getAltCursorType(cursorArgs)
      } else {
        cursorType = tool.getCursorType(cursorArgs)
      }
    }
    if (this.app.hasOpenedMenu()) {
      cursorType = 'default'
    }

    if (this.packageFor('tools').colorPickerMode) {
      cursorType = 'crosshair'
    }

    docViewListElement.style.cursor = cursorType === undefined ? 'crosshair' : cursorType
  }

  @action setLoading (value) {
    this.loading = value
  }

  @action addDoc = (options = {}, callback) => {
    this.setLoading(true)
    const doc = this.docs.add(new Doc(options))
    requestAnimationFrame(() => {
      doc.addRasterLayer({ fill: true })
      this.zoomToFit(doc)
      if (callback) { callback(doc) }
      this.setLoading(false)
      this.queueDocRender()
    })
    return doc
  }

  docViewListPaddedDimensions = () => {
    const viewDimensions = el(this.docViewListElement).innerSize()
    const padding = 20
    viewDimensions.width -= padding * 2
    viewDimensions.height -= padding * 2
    return viewDimensions
  }

  zoomToFit = (doc) => {
    doc = doc !== undefined ? doc : this.getActiveDoc()
    if (doc === undefined) { return }
    const viewDimensions = this.docViewListPaddedDimensions()
    doc.zoomToFit(viewDimensions)
  }

  @action openImageFromSrc = (src) => {
    this.setLoading(true)
    const doc = this.docs.add(new Doc())
    doc.loadImageFromSrc(src, () => {
      this.setActiveDoc(doc.id)
      this.zoomToFit(doc)
      this.setLoading(false)
    })
    return doc
  }

  @action openFile = (file) => {
    this.setLoading(true)
    const doc = this.docs.add(new Doc())
    doc.loadFile(file, () => {
      this.setActiveDoc(doc.id)
      this.zoomToFit(doc)
      window.URL.revokeObjectURL(file.preview)
      this.setLoading(false)
    })
  }

  setActiveDoc (id) {
    this.docs.setActiveId(id)
  }

  openFiles = (files) => {
    for (const file of files) {
      this.openFile(file)
    }
  }

  close = (doc) => {
    doc = doc !== undefined ? doc : this.docs.getActiveItem()
    if (doc === undefined) { return }
    let shouldClose = true
    if (doc.hasUnsavedChanges()) {
      shouldClose = confirm(doc.name + ' has unsaved changes, continue closing?')
    }
    if (shouldClose) {
      this.docs.disposeItem(doc)
      this.queueDocRender()
    }
    return shouldClose
  }

  closeWithDelay = (doc) => {
    requestAnimationFrame(() => {
      const wasClosed = this.close(doc)
      if (wasClosed) {
        requestAnimationFrame(() => {
          this.closeAll()
        })
      }
    })
  }

  closeAll = () => {
    if (this.docs.count() === 0) { return }
    this.closeWithDelay(this.docs.last())
  }

  dispose = () => {
    this.events.dispose()
  }

  showPrevious = () => { this.docs.setPreviousItemAsActive({ loopable: true }) }
  showNext = () => { this.docs.setNextItemAsActive({ loopable: true }) }

  selectPreviousLayer = () => {
    this.getActiveDoc().selectPreviousLayer()
  }

  selectNextLayer = () => {
    this.getActiveDoc().selectNextLayer()
  }

  zoomIn = () => {
    this.getActiveDoc().zoomIn(this.docViewListPaddedDimensions())
  }

  zoomOut = () => {
    this.getActiveDoc().zoomOut(this.docViewListPaddedDimensions())
  }

  setActiveLayerable = (docId, layerableId, options = {}) => {
    const doc = this.docs.find(docId)
    doc.layerables.setActiveId(layerableId, options)
  }

  addRasterLayer = (docId, options = {}) => {
    const doc = docId !== undefined ? this.docs.find(docId) : this.getActiveDoc()
    const layer = doc.addRasterLayer(options)
    return layer
  }

  addTextLayer = (docId, options = {}, layerOptions = {}) => {
    const doc = docId !== undefined ? this.docs.find(docId) : this.getActiveDoc()
    const layer = doc.addTextLayer(options, layerOptions)
    return layer
  }

  deleteSelectedLayers = () => {
    const doc = this.getActiveDoc()
    if (doc.layerables.selectionCount() === doc.getLayers().length) {
      alert('You cannot delete all layers, at least one layer must remain')
      return
    }
    doc.disposeSelectedLayers()
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  fetchActiveDoc = () => { return this.docs.getActiveItem() }
  fetchActiveLayer = () => { return this.getActiveDoc().activeLayer }

  saveDoc = () => {
    const doc = this.getActiveDoc()
    doc.beginSave()
  }

  restoreVersion = (versionId) => {
    this.getActiveDoc().restoreVersion(versionId)
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  undo = () => {
    this.getActiveDoc().undo()
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  redo = () => {
    this.getActiveDoc().redo()
    this.queueDocRender({ mode: 'layersUpdated' })
  }

  addDefaultDoc = () => {
    this.addDoc({ width: 500, height: 500, name: 'Untitled-' + (this.docs.count() + 1) })
  }

  queueUpdateTicks () {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    if (doc.selectionTex.hasData) {
      this.tick = (this.tick - 1) % 12 // 12 is the tickWidth
      this.queueDocRender({ mode: 'tickUpdated' })
    }
  }

  scheduleDocRender (options = {}) {
    this.updateCursor()
    if (options.mode === 'layersUpdated' || options.mode === 'viewportUpdated') {
      this.shouldReRender = true
    }
    this.events.setTask('render-active-doc', () => {
      this.renderActiveDoc(options)
    })
  }

  getCustomCursorsData () {
    const data = {
      _cursorTypes: [],
      _cursorSizes: [],
      _cursorOffsets: [],
      _cursorThicknesses: [],
      _cursorCount: 0
    }

    const doc = this.getActiveDoc()
    if (doc === undefined) { return data }

    const tool = this.getActiveTool()
    if (tool === undefined) { return data }

    const customCursors = tool.getCustomCursors()
    if (customCursors === undefined) { return data }

    const pixelRatio = Dom.devicePixelRatio()
    const multiplier = doc.zoom * pixelRatio
    for (const customCursor of customCursors) {
      const sizeMultiplier = customCursor.sizeIsZoomDependent ? multiplier : pixelRatio
      data._cursorTypes.push([customCursor.type])
      data._cursorSizes.push([customCursor.size * sizeMultiplier])
      data._cursorOffsets.push([
        customCursor.offset[0] * multiplier,
        customCursor.offset[1] * multiplier
      ])
      data._cursorThicknesses.push([customCursor.thickness * pixelRatio])
      data._cursorCount++
    }
    return data
  }

  getLineData ({ viewOffset }) {
    const data = {
      _lineStarts: [],
      _lineEnds: [],
      _lineThicknesses: [],
      _lineCount: 0
    }
    const doc = this.getActiveDoc()
    if (doc === undefined) { return data }
    const tool = this.getActiveTool()
    if (tool === undefined) { return data }
    const controlLines = tool.getControlLines({ doc })
    if (controlLines === undefined) { return data }

    const pixelRatio = Dom.devicePixelRatio()
    const multiplier = doc.zoom * pixelRatio

    for (const controlLine of controlLines) {
      data._lineStarts.push([
        controlLine.start[0] * multiplier + viewOffset[0],
        controlLine.start[1] * multiplier + viewOffset[1]
      ])
      data._lineEnds.push([
        controlLine.end[0] * multiplier + viewOffset[0],
        controlLine.end[1] * multiplier + viewOffset[1]
      ])
      data._lineThicknesses.push([controlLine.thickness])
      data._lineCount++
    }

    return data
  }

  getRectData ({ viewOffset }) {
    const data = {
      _rectCenters: [],
      _rectSizes: [],
      _rectThicknesses: [],
      _rectCount: 0
    }

    const doc = this.getActiveDoc()
    if (doc === undefined) { return data }
    const tool = this.getActiveTool()
    if (tool === undefined) { return data }
    const controlPoints = tool.getControlPoints({ doc })
    if (controlPoints === undefined) { return data }

    const pixelRatio = Dom.devicePixelRatio()
    const multiplier = doc.zoom * pixelRatio
    for (const controlPoint of controlPoints) {
      const sizeMultiplier = controlPoint.sizeIsZoomDependent ? multiplier : pixelRatio
      data._rectCenters.push([
        controlPoint.center[0] * multiplier + viewOffset[0],
        controlPoint.center[1] * multiplier + viewOffset[1]
      ])
      data._rectSizes.push([controlPoint.size[0] * sizeMultiplier, controlPoint.size[1] * sizeMultiplier])
      data._rectThicknesses.push([controlPoint.thickness * pixelRatio])
      data._rectCount++
    }

    return data
  }

  getCrosshairData ({ viewOffset }) {
    const data = {
      _crosshairCenters: [],
      _crosshairSizes: [],
      _crosshairThicknesses: [],
      _crosshairCount: 0
    }

    const doc = this.getActiveDoc()
    if (doc === undefined) { return data }
    const tool = this.getActiveTool()
    if (tool === undefined) { return data }
    const crosshairs = tool.getControlCrosshairs({ doc })
    if (crosshairs === undefined) { return data }

    const pixelRatio = Dom.devicePixelRatio()
    const multiplier = doc.zoom * pixelRatio
    for (const crosshair of crosshairs) {
      const sizeMultiplier = crosshair.sizeIsZoomDependent ? multiplier : pixelRatio
      data._crosshairCenters.push([
        crosshair.center[0] * multiplier + viewOffset[0],
        crosshair.center[1] * multiplier + viewOffset[1]
      ])
      data._crosshairSizes.push([crosshair.size])
      data._crosshairThicknesses.push([crosshair.thickness * pixelRatio])
      data._crosshairCount++
    }

    return data
  }

  renderActiveDoc (options = {}) {
    this.generated = true
    const doc = this.getActiveDoc()
    if (doc === undefined || this.docs.count() === 0) {
      wgl.clear()
      return
    }
    const { viewport, viewportContainer } = doc.getMeta('doc-view')
    if (viewport === undefined || viewportContainer === undefined) { return }
    const customCursorsData = this.getCustomCursorsData()

    const pixelRatio = Dom.devicePixelRatio()
    const containerSize = el(viewportContainer).innerSize()
    const adjustedContainerSize = {
      width: containerSize.width * pixelRatio,
      height: containerSize.height * pixelRatio
    }
    const viewOffset = [viewport.x * pixelRatio, viewport.y * pixelRatio]
    const viewSize = [viewport.width * pixelRatio, viewport.height * pixelRatio]

    const rectData = this.getRectData({ viewOffset })
    const lineData = this.getLineData({ viewOffset })
    const crosshairData = this.getCrosshairData({ viewOffset })

    if (doc.prevRenderMode === undefined || this.shouldReRender) {
      doc.generateFlattenedTexture({
        mode: options.mode,
        viewOffset,
        containerSize: adjustedContainerSize,
        viewSize,
        useViewport: true
      })
      doc.prevRenderMode = options.mode
      this.shouldReRender = false
    }

    doc.selectionTex.setTextureParameters({ minFilter: 'NEAREST', magFilter: 'NEAREST' })

    const mousePosition = [
      this.relativeMousePosition.x * pixelRatio,
      this.relativeMousePosition.y * pixelRatio
    ]

    const drawCursor = this.mouseInDocView &&
                       !this.app.hasOpenedMenu() &&
                       !(this.altPressed && this.metaPressed !== true)

    wgl.applyFilter({
      filter: docViewFilter,
      data: {
        _viewOffset: viewOffset,
        _viewSize: viewSize,
        _docTexure: doc.flattenedTex,
        _mask: doc.selectionTex,
        _skipViewportClipping: 1,
        _tileSize: 8,
        _tick: this.tick,
        _mousePosition: mousePosition,
        _drawCursor: drawCursor,
        ...customCursorsData,
        ...rectData,
        ...lineData,
        ...crosshairData
      },
      des: {
        texture: null,
        width: adjustedContainerSize.width,
        height: adjustedContainerSize.height
      }
    })
  }

  _flip ({ _direction }) {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const layers = doc.getLayers()
    for (const layer of layers) {
      if (layer.isRasterType()) {
        wgl.applyFilter({
          filter: flipFilter,
          des: {
            texture: 'temp',
            width: layer.getWidth(),
            height: layer.getHeight()
          },
          data: {
            _src: layer.getTex(),
            _direction
          }
        })
        layer.tex.swapTexture(wgl.tempRenderedTex)
      }
      if (layer.isTextType()) {
        if (_direction === HORIZONTAL_FLIP) {
          layer.setPosition({ x: layer.getWidth() - layer.x })
        }
        if (_direction === VERTICAL_FLIP) {
          layer.setPosition({ y: layer.getHeight() - layer.y })
        }
        layer.touch()
      }
    }
    return { doc }
  }

  _rotate ({ _direction }) {
    const doc = this.getActiveDoc()
    if (doc === undefined) { return }
    const layers = doc.getLayers()
    const initialSize = doc.size()
    const newSize = { width: initialSize.height, height: initialSize.width }
    for (const layer of layers) {
      if (layer.isRasterType()) {
        wgl.applyFilter({
          filter: rotateFilter,
          des: {
            texture: 'temp',
            width: newSize.width,
            height: newSize.height
          },
          data: {
            _src: layer.getTex(),
            _direction
          }
        })
        layer.tex.swapTexture(wgl.tempRenderedTex)
      }
      if (layer.isTextType()) {
        if (_direction === ROTATE_CLOCKWISE) {
          layer.setPosition({ x: initialSize.height - layer.y, y: layer.x })
        }
        if (_direction === ROTATE_COUNTER_CLOCKWISE) {
          layer.setPosition({ x: layer.y, y: initialSize.width - layer.x })
        }
      }
      layer.resize(newSize)
    }
    doc.resize(newSize)
    return { doc }
  }

  rotate180 () {
    let objs = this._flip({ _direction: HORIZONTAL_FLIP })
    if (objs === undefined) { return }
    objs = this._flip({ _direction: VERTICAL_FLIP })
    if (objs === undefined) { return }
    const { doc } = objs
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Rotate Canvas' })
  }

  rotateClockwise () {
    const objs = this._rotate({ _direction: ROTATE_CLOCKWISE })
    if (objs === undefined) { return }
    const { doc } = objs
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Rotate Canvas' })
  }

  rotateCounterClockwise () {
    const objs = this._rotate({ _direction: ROTATE_COUNTER_CLOCKWISE })
    if (objs === undefined) { return }
    const { doc } = objs
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Rotate Canvas' })
  }

  flipHorizontal () {
    const objs = this._flip({ _direction: HORIZONTAL_FLIP })
    if (objs === undefined) { return }
    const { doc } = objs
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Flip Canvas' })
  }

  flipVertical () {
    const objs = this._flip({ _direction: VERTICAL_FLIP })
    if (objs === undefined) { return }
    const { doc } = objs
    this.queueDocRender({ mode: 'layersUpdated' })
    doc.recordVersion({ name: 'Flip Canvas' })
  }
}
