import { observable, action } from 'mobx'
import Layer from './layer'
import Event from './event'
import { stateToHTML } from 'draft-js-export-html'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { registerGlobalFactory } from './factory-references'
import { loadText, getTextSize } from './text-layer-helpers'
import { isWithinBoundary } from './calc'
import hsh from '../lib/hsh'
import Pic from './pic'
import Tex from  './tex'
import EventList from './event-list'

export default class TextLayer extends Layer {
  @observable width = 0
  @observable height = 0
  @observable textWidth = 0
  @observable textBoundary = {
    offsetX: 0,
    offsetY: 0,
    width: 0,
    height: 0
  }
  @observable x = 0
  @observable y = 0
  @observable inTextEditMode = false
  @observable style = {
    color: '',
    fontSize: '',
    fontFamily: '',
    textAlignment: ''
  }

  constructor (options = {}) {
    super(options)
    const { width, height } = options
    this.pic = new Pic()
    this.initTex(options)
    this.resize({ width, height })

    this.editorState = EditorState.createEmpty()
    this.textChanged = new Event()
    this.events = new EventList()
    this.events.addEvent(this.pic.imageUpdated, () => {
      this.tex.loadImage({ image: this.pic.image, width: this.width, height: this.height })
    })
    this.events.addEvent(this.textChanged, () => {
      if (!this.wasRenamed) {
        const name = this.editorState.getCurrentContent().getPlainText().substring(0, 50)
        if (name.trim().length > 0) {
          this.setName(name, { autoRename: true })
        }
      }
    })
    this.events.addEvent(this.textChanged, () => this.wasUpdated.trigger())
    this.events.addEvent(this.wasUpdated, () => this.generatePic())
    this.events.addEvent(this.wasUpdated, () => {
      const html = this.getHtmlState()
      const size = getTextSize({ html, layer: this })
      this.updateTextBoundary(size)
    })
    this.textEditModeEntered = new Event()
    this.updateStyling(options.style)
  }

  getTex () { return this.tex }

  initTex ({ width, height }) {
    if (this.tex !== undefined) { return }
    this.tex = new Tex({ width, height })
  }

  @action setPosition ({ x, y }) {
    if (x !== undefined) { this.x = x }
    if (y !== undefined) { this.y = y }
  }

  @action updateStyling (options = {}) {
    if (options.color !== undefined) { this.style.color = options.color }
    if (options.fontSize !== undefined) { this.style.fontSize = options.fontSize }
    if (options.fontFamily !== undefined) { this.style.fontFamily = options.fontFamily }
    if (options.textAlignment !== undefined) { this.style.textAlignment = options.textAlignment }
  }

  @action updateTextBoundary (boundary = {}) {
    if (boundary.width) { this.textBoundary.width = boundary.width }
    if (boundary.height) { this.textBoundary.height = boundary.height }
  }

  @action enterTextEditMode (options = {}) {
    this.inTextEditMode = true
    this.textEditModeEntered.trigger(options)
  }

  @action exitTextEditMode () {
    this.inTextEditMode = false
  }

  generatePic = () => {
    const html = this.getHtmlState()
    loadText({ html, pic: this.pic, layer: this })
  }

  getHtmlState = () => {
    const contentState = this.editorState.getCurrentContent()
    return stateToHTML(contentState)
  }

  colorAtPoint = (p) => {
    const c = this.pic.colorAtPoint(p)
    c.setAlpha(c.a * this.opacity)
    return c
  }

  hasContentAtPoint = (p) => {
    const boundary = this.getBoundary()
    return isWithinBoundary(p, boundary)
  }

  getBoundary = () => {
    const { textBoundary, style, x, y, textWidth } = this
    const { textAlignment } = style
    const boundary = {
      width: textBoundary.width,
      height: textBoundary.height,
      y
    }
    if (textAlignment === 'left') { boundary.x = x }
    if (textAlignment === 'center') { boundary.x = x + (textWidth - boundary.width) / 2.0 }
    if (textAlignment === 'right') { boundary.x = x + textWidth - boundary.width }
    return boundary
  }

  setEditorState (editorState) {
    this.editorState = editorState
    this.textChanged.trigger()
  }

  initializableAttributes () {
    return super.initializableAttributes().concat(['x', 'y', 'textWidth'])
  }

  defaultAttributes () {
    return {
      x: 0,
      y: 0,
      color: 'black',
      fontSize: '25',
      fontFamily: 'Arial, Helvetica, sans-serif'
    }
  }

  @action resize ({ width, height }, options = {}) {
    if (width === undefined) { width = 0 }
    if (height === undefined) { height = 0 }
    if (options.resizeContent) {
      const ratio = {
        x: width / this.width,
        y: height / this.height
      }
      this.x *= ratio.x
      this.y *= ratio.y
      this.textWidth *= ratio.x
      this.style.fontSize *= ratio.x
    }
    this.width = width
    this.height = height
    this.touch()
  }

  serialize () {
    const info = super.serialize()
    return {
      data: {
        ...info.data,
        width: this.width,
        height: this.height,
        textWidth: this.textWidth,
        textBoundary: hsh(this.textBoundary).clone(),
        x: this.x,
        y: this.y,
        style: hsh(this.style).clone(),
        editorState: convertToRaw(this.editorState.getCurrentContent())
      },
      resources: undefined,
      childItems: undefined
    }
  }

  @action restore ({ data }) {
    super.restore({ data })
    this.width = data.width
    this.height = data.height
    this.textWidth = data.textWidth
    this.updateTextBoundary(data.textBoundary)
    this.x = data.x
    this.y = data.y
    this.updateStyling(data.style)
    this.setEditorState(EditorState.createWithContent(convertFromRaw(data.editorState)))
    this.touch()
  }

  applyPrintSrc (callback) {
    callback(this.pic.image)
  }

  type () { return 'text' }

  getFactoryKey () { return 'TextLayer' }

  dispose = () => {
    super.dispose()
    this.events.dispose()
  }

  beginTransform = () => {
    this.originalPosition = {
      x: this.x,
      y: this.y
    }
  }

  @action clone (options = {}) {
    const layerClone = new TextLayer({ ...this.size(), ...options })
    layerClone.setBlendMode(this.blendMode)
    layerClone.setOpacity(this.opacity)
    layerClone.setVisibility(this.visible)
    layerClone.textWidth = this.textWidth
    layerClone.updateStyling(this.style)
    layerClone.updateTextBoundary(this.boundary)
    layerClone.x = this.x
    layerClone.y = this.y
    return layerClone
  }

  deepClone () {
    const copy = this.clone()
    copy.setEditorState(this.editorState)
    return copy
  }

  @action transform = ({ translate }) => {
    this.x = this.originalPosition.x + translate.x
    this.y = this.originalPosition.y + translate.y
  }

  endTransform = () => {}
}

registerGlobalFactory('TextLayer', TextLayer)
