import React from 'react'
import { inject, observer } from 'mobx-react'
import BaseComponent from '../../core/base-component'

import './context-options-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  render () {
    const toolOptions = this.packageFor('tool-options')
    const layerOptions = this.packageFor('layer-options')
    return (
      <div className="context-options-view align-items-center">
        { toolOptions.toolOptionsView }
        { layerOptions.layerOptionsView }
      </div>
    )
  }
}
