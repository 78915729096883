import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import ToolView from './tool-view'

import './tool-view-list.scss'

@observer
export default class extends BaseComponent {
  render () {
    const { tools } = this.props
    return (
      <div className="tool-view-list">
        { tools.map((tool, info) =>
            <ToolView
              key={ tool.id }
              tool={ tool }
              tools={ tools }
              isActive={ info.isActive }
            />)
        }
      </div>
    )
  }
}
