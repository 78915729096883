import Item from './item'
import Dom from './dom'
import Event from './event'
import { svgToImageSrc } from './svg-helpers'
import Fabric from './fabric'

const COLOR_AT_POINT_PRECISION_ZOOM = 64

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.image = Dom.image()
    this.image.style.imageRendering = 'pixelated'
    this.isLoading = false
    this.imageUpdated = new Event()
    this.image.onload = () => {
      this.isLoading = false
      this.imageUpdated.trigger()
    }
  }

  loadSvg (svgRef) {
    this.isLoading = true
    this.image.src = svgToImageSrc(svgRef)
  }

  loadSrc (src) {
    this.isLoading = true
    this.image.src = src
  }

  asTexture () {
    return {
      src: this.image,
      id: this.id,
      updatedAt: this.updatedAt
    }
  }

  colorAtPoint (p) {
    const { image } = this
    const fabric = new Fabric({ width: 100, height: 100 })
    const zoom = COLOR_AT_POINT_PRECISION_ZOOM
    fabric.drawImage(image, {
      des: { x: -p.x * zoom, y: -p.y * zoom, width: image.width * zoom, height: image.height * zoom },
      contextOptions: { imageSmoothingEnabled: true }
    })
    const color = fabric.colorAtPoint({ x: 0, y: 0 })
    fabric.dispose()
    return color
  }

  dispose () {
    this.image.src = ''
    this.image.width = 0
    this.image.height = 0
  }
}
