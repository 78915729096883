import React from 'react'
import BasePackage from '../../core/base-package'
import DocCreatorView from './doc-creator-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'doc-creator'
  }

  activate = () => {
    this.initDocCreatorDialog()
  }

  initDocCreatorDialog = () => {
    const sharedOptions = {
      onConfirm: (options) => {
        this.hideDocCreatorDialog()
        const docsPackage = this.packageFor('docs')
        docsPackage.addDoc(options, (doc) => {
          docsPackage.setActiveDoc(doc.id)
        })
      },
      onCancel: this.hideDocCreatorDialog
    }
    const docCreator = React.createElement(DocCreatorView, sharedOptions)
    this.docCreatorDialog = this.createDialog(docCreator, {
      onCancel: sharedOptions.onCancel,
      name: 'New Document'
    })
  }

  showDocCreatorDialog = () => { this.docCreatorDialog.show() }

  hideDocCreatorDialog = () => { this.docCreatorDialog.hide() }
}
