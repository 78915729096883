import Item from './item'
import Dom from './dom'
import Color from './color'
import Collection from './collection'
import GlProgram from './gl-program'

export default class extends Item {
  constructor (options = {}) {
    super(options)
    this.canvas = options.canvas || Dom.canvas()
    this.gl = this.canvas.getContext('webgl2')
    if (!options.canvas) {
      const { width, height } = options
      this.resize({ width, height })
    }
    this.initGl()
    this.glPrograms = new Collection()
    this.hasData = false
  }

  initGl () {
    const { gl } = this
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true)
    gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1)
    this.syncViewport(this.getVertexArray())
  }

  size () {
    return { width: this.getWidth(), height: this.getHeight() }
  }

  resize ({ width, height }) {
    if (width === undefined) { width = 0 }
    if (height === undefined) { height = 0 }

    if (this.canvas.width === width && this.canvas.height === height) {
      return
    }
    this.canvas.width = width
    this.canvas.height = height
    this.syncViewport(this.getVertexArray())
  }

  getWidth () { return this.canvas.width }
  getHeight () { return this.canvas.height }

  syncViewport (vertexArray) {
    const { gl } = this
    gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight)
    const buffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
    gl.bufferData(gl.ARRAY_BUFFER, vertexArray, gl.STATIC_DRAW)
  }

  getVertexArray () {
    const top = 1, left = -1, right = 1, bottom = -1
    return new Float32Array([ left, top, left, bottom, right, top, right, bottom ])
  }

  applyFilter (filter, uniforms = {}, options = {}) {
    if (options.vertexArray !== undefined) { this.syncViewport(options.vertexArray) }
    const { gl } = this
    const glProgram = this.glPrograms.findOrAdd(filter.id, () => new GlProgram({ gl, filter }))
    if (this.glPrograms.activeId !== glProgram.id) {
      gl.useProgram(glProgram.program)
      this.glPrograms.setActiveId(glProgram.id)
    }
    uniforms.drawableWidth = gl.drawingBufferWidth
    uniforms.drawableHeight = gl.drawingBufferHeight
    const simpleUniforms = {}
    const textures = {}
    for (const key in uniforms) {
      const uniform = uniforms[key]
      if (uniform.src !== undefined) {
        textures[key] = uniform
      } else {
        simpleUniforms[key] = uniform
      }
    }
    glProgram.setTextures(textures)
    glProgram.setUniforms(simpleUniforms)
    this.render(options.render)
  }

  colorAtPoint (p) {
    const { gl } = this
    const data = new Uint8Array(4)
    gl.readPixels(p.x, p.y, 1, 1, gl.RGBA, gl.UNSIGNED_BYTE, data)
    return new Color({
      r: data[0],
      g: data[1],
      b: data[2],
      a: data[3] / 255.0
    })
  }

  render (options = {}) {
    const { gl } = this
    const primitive = options.primitive === undefined ? gl.TRIANGLE_STRIP : options.primitive
    const first = options.first === undefined ? 0 : options.first
    const count = options.count === undefined ? 4 : options.count
    gl.drawArrays(primitive, first, count)
    this.hasData = true
  }

  asTexture () {
    return {
      src: this.canvas
    }
  }

  dispose () {
    super.dispose()
    this.gl.getExtension('WEBGL_lose_context').loseContext()
  }

  clear () {
    const { gl } = this
    gl.clearColor(0, 0, 0, 0)
    gl.clear(gl.COLOR_BUFFER_BIT)
    this.hasData = false
  }
}
