import React from 'react'
import BaseMarquee from '../marquee/base-marquee'
import { observable, action } from 'mobx'

import Tool from '../../core/tool'
import MagicWandOptionsView from './magic-wand-options-view'

import { fillTex } from '../../core/bucket-fill-helpers'

const PERSISTABLE_STATE = {
  tolerancePercentage: 80,
  contiguous: true
}

export default class extends BaseMarquee {
  @observable options = { tolerancePercentage: 80, contiguous: true }

  constructor (options) {
    super(options)
    this.id = 'magic-wand'
    this.settingsId = this.id
    this.name = 'Magic Wand'
    this.icon = 'wi-magic-wand'
    this.info = 'Magic Wand (W)'
    this.validLayerTypes = { raster: true }
    this.optionsView = React.createElement(MagicWandOptionsView, {
      magicWandOptions: this.options,
      setTolerancePercentage: this.setTolerancePercentage,
      setContiguous: this.setContiguous
    })
  }

  @action activate () {
    const settings = this.packageFor('settings')
    const storedOptions = settings.retrieveState(this.settingsId, PERSISTABLE_STATE)
    this.options.tolerancePercentage = storedOptions.tolerancePercentage
    this.options.contiguous = storedOptions.contiguous
  }

  @action setTolerancePercentage = (tolerancePercentage) => {
    this.options.tolerancePercentage = tolerancePercentage
    const settings = this.packageFor('settings')
    settings.store(this.settingsId, { tolerancePercentage: tolerancePercentage })
  }

  @action setContiguous = (contiguous) => {
    this.options.contiguous = contiguous
    const settings = this.packageFor('settings')
    settings.store(this.settingsId, { contiguous: contiguous })
  }

  createSelectionOnBegin () { return true }

  clearable () { return true }

  createSelection ({ doc, p }) {
    const point = p.round()
    const layer = doc.activeLayer
    const tolerance = this.options.tolerancePercentage / 100.0
    const seedColor = layer.colorAtPoint(point)

    const refTex = layer.getTex()
    const baseTex = doc.selectionTex.deepClone()

    fillTex({
      refTex,
      baseTex,
      des: doc.selectionTex,
      seedColor,
      point,
      tolerance,
      contiguous: this.options.contiguous,
      compositeMode: this.getCompositeMode()
    })
    doc.selectionTex.setHasData(true)
  }

  zeroMovementSelectable () {
    return true
  }
}
