import Filter from '../../core/filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'alpha'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;

      void main() {
          vec4 color = texColorFor(_src);
          float brightness = getBrightnessOfColor(color);
          float alpha = 0.0;
          if (color.a > 0.0) {
            alpha = brightness;
          }
          outputColor = vec4(0.0, 0.0, 0.0, alpha);
      }
    `
  }
}
