import { observer } from 'mobx-react'
import React from 'react'
import classNames from 'classnames'
import Draggable from '../../core/draggable'
import BaseComponent from '../../core/base-component'

import './doc-tab-view.scss'

@observer
export default class extends BaseComponent {
  componentDidMount () {
    this.draggable = new Draggable({
      element: this.refs.root,
      ghostDrag: true,
      dragAxis: 'x',
      elementLimit: 'element-within-container',
      hideElementOnDrag: true,
      ignorableClasses: ['doc-tab-view__close-button'],
      animateGhostToElementDuration: 100,
      swapSiblingsAnimationDuration: 150,
      swapElementWithSiblings: true,
      siblingMatcher: (element) => element.classList.contains('doc-tab-view'),
      onGhostAnimationEnd: () => {
        const { docs, doc } = this.props
        const newIndex = this.draggable.elementIndex
        docs.reorder(doc.id, newIndex)
      },
      onMouseDown: this.onMouseDown
    })
  }
  componentWillUnmount () {
    this.draggable.dispose()
  }
  onCloseButtonClick = (e) => {
    this.props.closeDoc(this.props.doc)
  }
  onMouseDown = (e) => {
    const { doc, docs, onMouseDown } = this.props
    if (e.target !== this.refs.closeButton) {
      docs.setActiveId(doc.id)
      if (onMouseDown) { onMouseDown(e) }
    }
  }
  rootStyle () {
    return {
      width: this.props.width + 'px'
    }
  }
  render () {
    const { doc, isActive } = this.props
    return (
      <div className={ classNames('doc-tab-view activeable', { active: isActive }) }
        draggable="false"
        style={ { ...this.rootStyle() } }
        ref="root">
        <div className="doc-tab-view__border-cover"></div>
        <div>
          <div className="doc-tab-view__name">
            { doc.name }
          </div>
          <div className="doc-tab-view__close-button"
            ref="closeButton"
            onClick={ this.onCloseButtonClick }>&#10761;</div>
        </div>
      </div>
    )
  }
}
