import { action } from 'mobx'
import Layer from './layer'
import Cache from './cache'
import { pool } from './gl-canvas-pool'
import Transformable from './transformable'
import { registerGlobalFactory } from './factory-references'
import { wgl } from './wgl'
import Tex from './tex'
import RectFilter from './filters/rect-filter'

const rectFilter = new RectFilter()

export default class RasterLayer extends Layer {
  constructor (options = {}) {
    super(options)
    this.tex = options.tex
    this.transformable = new Transformable()
    this.initTex(options)
    const { width, height } = options
    this.resize({ width, height })
  }

  initTex ({ width, height }) {
    if (this.tex !== undefined) { return }
    this.tex = new Tex({ width, height })
  }

  getUpdatedAt () {
    return this.tex.updatedAt
  }

  forShader () {
    return this.tex
  }

  calcBoundary () {
    return this.tex.calcBoundary()
  }

  beginTransform = (mask, opts = {}) => {
    this.transformable.beginTransform(this.board, mask, opts)
  }

  transform (transformation) {
    this.transformable.applyTransformation(transformation)
  }

  endTransform (transformation = {}) {
    this.transformable.endTransform(transformation)
  }

  setBoardCanvas (canvas) {
    this.board.setCanvas(canvas)
    this.touch()
  }

  setBoardAlphaCanvas (canvas) {
    this.board.setAlphaCanvas(canvas)
    this.touch()
  }

  setBoardCanvases (canvases) {
    this.board.setCanvases(canvases)
    this.touch()
  }

  getBoardCanvas () { return this.board.getCanvas() }
  getBoardAlphaCanvas () { return this.board.getAlphaCanvas() }

  resize ({ width, height }, options = {}) {
    if (width === undefined) { width = 0 }
    if (height === undefined) { height = 0 }
    this.tex.resize({ width, height }, options)
  }

  getTex () {
    return this.tex
  }

  getWidth () { return this.tex.width }
  getHeight () { return this.tex.height }

  pasteBoard (board, opts) {
    this.board.pasteBoard(board, opts)
    this.touch()
  }

  pasteFabric (fabric, opts) {
    this.board.pasteFabric(fabric, opts)
    this.touch()
  }

  replaceBoard (board, opts = {}) {
    if (!opts.contextOptions) { opts.contextOptions = {} }
    opts.contextOptions.globalCompositeOperation = 'copy'
    this.pasteBoard(board, opts)
  }

  // board.fabric should not have any semi-transparent pixels
  // since the transparency values are already stored in board.alphaFabric
  @action loadImage ({ image, width, height }) {
    this.tex.loadImage({ image, width, height })
  }

  fill (options = {}) {
    this.tex.fill(options)
  }

  fillRect (options = {}) {
    this.tex.fillRect(options)
  }

  colorAtPoint (p) {
    const c = this.tex.colorAtPoint(p)
    c.setAlpha(c.a * this.opacity)
    return c
  }

  hasContentAtPoint (p) {
    return this.colorAtPoint(p).a > 0
  }

  printToCanvas ({ canvas, width, height }) {
    this.tex.printToCanvas({ canvas, width, height })
  }

  getPrintMethod () { return 'pasteFabric' }
  applyPrintSrc (callback) {
    const clone = this.board.deepClone()
    clone.applyAlpha()
    callback(clone.fabric)
    clone.dispose()
  }

  clone (options = {}) {
    const layerClone = new RasterLayer({ ...this.size(), ...options })
    layerClone.setBlendMode(this.blendMode)
    layerClone.setOpacity(this.opacity)
    layerClone.setVisibility(this.visible)
    return layerClone
  }

  deepClone () {
    const texClone = this.tex.deepClone()
    return this.clone({ tex: texClone })
  }

  type () { return 'raster' }

  getFactoryKey () { return 'RasterLayer' }

  serialize () {
    const info = super.serialize()
    return {
      data: info.data,
      childItems: {
        tex: this.tex,
        transformable: this.transformable
      }
    }
  }

  dispose = () => {
    super.dispose()
    this.transformable.dispose()
    this.tex.dispose()
  }
}

registerGlobalFactory('RasterLayer', RasterLayer)
