import React from 'react'
import { inject, observer } from 'mobx-react'
import BaseComponent from '../../core/base-component'

import './layer-options-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  editText = () => {
    this.app.executeCommand('tools:rotate-tool(text)')
    this.app.executeCommand('tools.text:enter-text-edit-mode')
  }

  render () {
    const { doc, layer } = this.props
    return (
      <div className="text-layer-options-view">
        <button className="text-button" onClick={ this.editText }>Edit Text</button>
      </div>
    )
  }
}
