import Filter from '../../core/filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'brightness-and-contrast'
  }

  getFragmentShader () {
    return `
      const float CONTRAST_FACTOR = 1.015686275;

      uniform sampler2D _src;
      uniform float brightness;
      uniform float contrast;

      float applyContrast (float colorComponent, float factor) {
        float value = factor * (colorComponent - 0.5) + 0.5;
        if (value < 0.0) { return 0.0; }
        if (value > 1.0) { return 1.0; }
        return value;
      }

      void main () {
        vec4 color = texColorFor(_src);
        float factor = (CONTRAST_FACTOR * (contrast + 1.0)) / (CONTRAST_FACTOR - contrast);
        color.r = applyContrast(color.r, factor);
        color.g = applyContrast(color.g, factor);
        color.b = applyContrast(color.b, factor);
        color.rgb = color.rgb + brightness;
        outputColor = color;
      }
    `
  }
}
