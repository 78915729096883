import Item from './item'

export default class extends Item {
  constructor (options) {
    super(options)
    this.setup()
  }

  initializableAttributes () {
    return ['element', 'event', 'listener', 'eventOptions']
  }

  setup () {
    setupDomEvent(this.element, this.event, this.listener, this.eventOptions)
  }

  dispose () {
    super.dispose()
    removeDomEvent(this.element, this.event, this.listener)
  }
}

export function setupDomEvent (element, event, listener, eventOptions = {}) {
  if (event === 'resize' && element !== window) {
    addResizeListener(element, listener)
    return
  }
  element.addEventListener(event, listener, eventOptions)
}

export function removeDomEvent (element, event, listener) {
  if (element === undefined) { return }
  if (event === 'resize' && element !== window) {
    removeResizeListener(element, listener)
    return
  }
  element.removeEventListener(event, listener)
}
