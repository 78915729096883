import BasePackage from '../../core/base-package'
import LocalStorageSettings from './local-storage-settings'
import hsh from '../../lib/hsh'

const SETTINGS_PREFIX = 'settings:'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'settings'
    this.storeable = new LocalStorageSettings()
  }

  store (key, value) {
    if (value === undefined || value === null || typeof value !== 'object') {
      throw new Error(`The settings for ${key} could not be stored because
        the value to be stored is not a hash`)
    }
    const settings = this.retrieve(key) || {}
    Object.assign(settings, value)
    this.storeable.store(SETTINGS_PREFIX + key, settings)
  }

  retrieve (key) {
    return this.storeable.retrieve(SETTINGS_PREFIX + key)
  }

  retrieveState (storeKey, persistableState = {}) {
    const retrievedState = this.retrieve(storeKey) || {}
    persistableState = hsh(persistableState).clone()
    // override the default values of persistableState
    for (const key in retrievedState) {
      persistableState[key] = retrievedState[key]
    }
    return persistableState
  }

  storeState (storeKey, state, persistableState) {
    this.store(storeKey, hsh(state).pick(Object.keys(persistableState)))
  }
}
