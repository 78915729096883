import { action, observable } from 'mobx'
import ary from '../lib/ary'
import hsh from '../lib/hsh'
import Collection from './collection'
import Event from './event'

export default class extends Collection {
  @observable root

  constructor (options = {}) {
    super(options)
    this.setRoot(options.root)
    this.parentsMap = {}
    this.childItemsMap = {}
    this.childItemsMap[this.root.id] = observable([])
    this.add(this.root)
    this.groupingWasUpdated = new Event()
    this.itemsLengthIncreased.subscribe((item, options) => {
      this.onItemsLengthIncreased(item, options)
    })
  }

  @action setRoot (root) {
    this.root = root
  }

  flattenGroup (container, item, options = {}) {
    container.push(item)
    if (options.visibleOnly && !item.isExpanded) { return }
    const childItems = this.childItemsFor(item)
    childItems.forEach((childItem) => this.flattenGroup(container, childItem, options))
  }

  @action clear () {
    for (const key in this.childItemsMap) {
      this.childItemsMap[key].clear()
      if (key !== this.root.id) {
        delete this.childItemsMap[key]
      }
    }
    this.parentsMap = {}
    super.clear()
  }

  @action onItemsLengthIncreased (item, options = {}) {
    const parent = options.parent || this.getActiveGroup()
    this.updateGrouping(parent, item)
    if (options.skipItemOrderingUpdate !== true) {
      this.updateItemOrderingToMatchGrouping()
    }
  }

  @action updateGrouping (parent, item) {
    const { childItemsMap, parentsMap } = this
    const prevParent = this.parentFor(item)
    parentsMap[item.id] = parent.id

    const childIds = this.childIdsFor(parent)
    const childObjects = childIds.concat([item.id]).map(id => {
      const index = this.ids.indexOf(id)
      return { index, id }
    })
    childObjects.sort((a, b) => a.index - b.index)
    if (childItemsMap[parent.id] === undefined) {
      childItemsMap[parent.id] = observable([])
    }
    const newChildIds = childObjects.map(child => child.id)
    childItemsMap[parent.id].replace(newChildIds)
    if (prevParent) {
      this.groupingWasUpdated.trigger(prevParent)
    }
    this.groupingWasUpdated.trigger(parent)
  }

  @action removeGrouping (parent, item) {
    const { childItemsMap, parentsMap } = this
    delete parentsMap[item.id]

    if (parent !== undefined && parent.id !== undefined) {
      const childIds = this.childIdsFor(parent)
      ary(childIds).remove(item.id)
      childItemsMap[parent.id] = childIds
      this.groupingWasUpdated.trigger(parent)
    }
  }

  @action updateItemOrderingToMatchGrouping () {
    const items = []
    this.flattenGroup(items, this.root)
    this.setIds(items.map(item => item.id))
  }

  @action extract (item, options = {}) {
    const parent = this.parentFor(item)
    const extractedItem = super.extract(item, options)
    this.removeGrouping(parent, item)
    if (options.skipItemOrderingUpdate !== true) {
      this.updateItemOrderingToMatchGrouping()
    }
    return extractedItem
  }

  @action removeItems (items) {
    super.removeItems(items, { skipItemOrderingUpdate: true })
    this.updateItemOrderingToMatchGrouping()
    this.removeOrphanedItems()
  }

  @action removeOrphanedItems () {
    const idMap = {}
    this.ids.forEach(id => idMap[id] = true)
    for (const id in this.entities) {
      if (idMap[id] === true) { continue }
      this.remove({ id }, { skipItemOrderingUpdate: true })
    }
  }

  @action disposeItems (items) {
    super.disposeItems(items, { skipItemOrderingUpdate: true })
    this.updateItemOrderingToMatchGrouping()
    this.disposeOrphanedItems()
  }

  @action disposeOrphanedItems () {
    const idMap = {}
    this.ids.forEach(id => idMap[id] = true)
    for (const id in this.entities) {
      if (idMap[id] === true) { continue }
      this.disposeItem({ id }, { skipItemOrderingUpdate: true })
    }
  }

  @action moveItem (item, { method, newParent, skipItemOrderingUpdate }) {
    this.remove(item, { skipItemOrderingUpdate: true })
    this.add(item, { method, parent: newParent, skipItemOrderingUpdate })
  }

  @action moveItems (items, options = {}) {
    options.skipItemOrderingUpdate = true
    for (const item of items) { this.moveItem(item, options) }
    this.updateItemOrderingToMatchGrouping()
  }

  getParentOfActiveGroup () {
    const group = this.getActiveGroup()
    return group.parent || group
  }

  getActiveGroup () {
    const activeItem = this.getActiveItem()
    if (activeItem === undefined) { return this.root }
    if (activeItem.isGroupType()) { return activeItem }
    const parent = this.parentFor(activeItem)
    if (parent) { return parent }
    return this.root
  }

  getActiveGroupParent () {
    const group = this.getActiveGroup()
    const parent = this.parentFor(group)
    return parent !== undefined ? parent : group
  }

  closestGroupFor ({ id }) {
    const item = this.find(id)
    return item.isGroupType() ? item : this.parentFor(item)
  }

  parentFor ({ id }) {
    const parentId = this.parentsMap[id]
    if (parentId === undefined) { return }
    return this.find(parentId)
  }

  parentsFor ({ id }) {
    const parents = []
    let parent = this.parentFor({ id })
    while (parent !== undefined) {
      parents.push(parent)
      parent = this.parentFor(parent)
    }
    return parents
  }

  hasSelectedParentFor ({ id }) {
    const parent = this.parentFor({ id })
    this.itemIsSelected(parent.id)
  }

  childIdsFor ({ id }) {
    const ids = this.childItemsMap[id]
    if (ids === undefined) { return [] }
    return ids
  }

  childItemsFor ({ id }) {
    return this.childIdsFor({ id }).map((id) => this.find(id))
  }

  getVisibleItems () {
    const items = []
    this.flattenGroup(items, this.root, { visibleOnly: true })
    return items.splice(1)
  }

  getUniqueSelectedItems () {
    const selectedItems = this.getSelectedItems()
    const uniqueItems = []
    for (const item of selectedItems) {
      if (this.hasSelectedParentFor(item)) { continue }
      uniqueItems.push(item)
    }
    return uniqueItems
  }

  serialize () {
    const info = super.serialize()
    return {
      data: {
        ...info.data,
        parentsMap: hsh(this.parentsMap).clone(),
        childItemsMap: hsh(this.childItemsMap).clone(),
        rootId: this.root.id
      },
      childItems: {
        ...info.childItems
      }
    }
  }

  restore ({ data, resources, version }) {
    super.restore({ data, resources, version })
    this.setRoot(this.find(data.rootId))
    this.parentsMap = hsh(data.parentsMap).clone()
    this.childItemsMap = {}
    for (const parentId in data.childItemsMap) {
      const childIds = data.childItemsMap[parentId]
      this.childItemsMap[parentId] = observable([])
      this.childItemsMap[parentId].replace(childIds)
    }
  }
}
