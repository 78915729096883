import tinycolor from 'tinycolor2'
import { observable, action, computed } from 'mobx'
import hsh from '../lib/hsh'
import { sanitizeRgb, sanitizeHsv, hsvToRgb, hexToRgb, rgbToHsv, rgbToHex } from './color-helpers'
import Item from './item'
import Event from './event'

export function parseForShader (value) {
  const color = tinycolor(value)
  const rgba = color.toRgb()
  return [rgba.r / 255.0, rgba.g / 255.0, rgba.b / 255.0, rgba.a]
}

export default class Color extends Item {
  @observable r = 0
  @observable g = 0
  @observable b = 0
  @observable a = 1
  @observable h = 0
  @observable s = 0
  @observable v = 0
  @observable hex = '000000'

  constructor (options = {}) {
    super(options)
    this.colorWasUpdated = new Event()
    this.setRgb(options)
    if (options.a !== undefined) { this.setAlpha(options.a) }
  }

  @computed get styleString () {
    const { r, g, b, a } = this
    return a === 1 ? `rgb(${r}, ${g}, ${b})` : `rgba(${r}, ${g}, ${b}, ${a})`
  }

  @action syncValues ({ hsv, rgb, hex }) {
    if (hsv) {
      const rgb = hsvToRgb(hsv)
      hsh(this).assignValues(rgb)
      const hex = rgbToHex(rgb)
      this.hex = hex
    }
    if (rgb) {
      const hsv = rgbToHsv(rgb)
      hsh(this).assignValues(hsv)
      const hex = rgbToHex(rgb)
      this.hex = hex
    }
    if (hex) {
      const rgb = hexToRgb(hex)
      hsh(this).assignValues(rgb)
      const hsv = rgbToHsv(rgb)
      hsh(this).assignValues(hsv)
    }
    this.colorWasUpdated.trigger()
  }

  @action setHsv (options = {}) {
    const hsv = sanitizeHsv(options)
    hsh(this).assignValues(hsv)
    this.syncValues({ hsv: this.hsv })
  }

  @action setHex (hex) {
    this.hex = hex
    this.syncValues({ hex })
  }

  @action setRgb (options = {}) {
    const rgb = sanitizeRgb(options)
    hsh(this).assignValues(rgb)
    this.syncValues({ rgb: this.rgb })
  }

  @computed get hsv () {
    const { h, s, v } = this
    return { h, s, v }
  }

  @computed get sv () {
    const { s, v } = this
    return { s, v }
  }

  @computed get rgb () {
    const { r, g, b } = this
    return { r, g, b }
  }

  @action setAlpha (alpha) {
    this.a = alpha
  }

  fractionalColor () {
    return {
      r: this.r / 255.0,
      g: this.g / 255.0,
      b: this.b / 255.0,
      a: this.a
    }
  }

  forShader () {
    const fractional = this.fractionalColor()
    return [fractional.r, fractional.g, fractional.b, fractional.a]
  }

  isDark () {
    return tinycolor(this.rgb).isDark()
  }

  multiplyBy (multiplier) {
    return new Color({
      r: this.r * multiplier,
      g: this.g * multiplier,
      b: this.b * multiplier
    })
  }

  divideBy (divisor) {
    divisor = parseFloat(divisor)
    return new Color({
      r: this.r / divisor,
      g: this.g / divisor,
      b: this.b / divisor
    })
  }

  add (color) {
    return new Color({
      r: this.r + color.r,
      g: this.g + color.g,
      b: this.b + color.b
    })
  }

  clone () {
    return new Color({ r: this.r, g: this.g, b: this.b })
  }

  toArray () {
    return [this.r, this.g, this.b, this.a]
  }

  toFractionalArray () {
    return [this.r / 255.0, this.g / 255.0, this.b / 255.0, this.a]
  }

  @action drawColorOnTop (color2) {
    const color1 = this
    const multiplier = color1.a * (1.0 - color2.a)
    const alpha = color2.a + multiplier;
    const color = color2.multiplyBy(color2.a).add(color1.multiplyBy(multiplier)).divideBy(alpha)
    color.setAlpha(alpha)
    return color
  }
}
