export function getStoreValueAsColorSnippet () {
  return `
    void storeValueAsColor (float index) {
      float r = floor(index / 65536.0);
      float remaining = index - r * 65536.0;
      float g = floor(remaining / 256.0);
      float b = remaining - g * 256.0;

      outputColor = vec4(r / 255.0, g / 255.0, b / 255.0, 1.0);
    }
  `
}
