const EASILY_DIVISIBLE_DENOMINATORS = [
  100, 200, 400, 500,
  1000, 2000, 4000, 5000, 8000,
  10000, 20000, 40000, 50000, 80000, 100000
]

export function getEasilyDivisibleDenominator (value) {
  const options = EASILY_DIVISIBLE_DENOMINATORS
  for (const option of options) {
    if (option >= value) {
      return option
    }
  }

  return value
}

export function getFittingDimensions (dimensions, maxDimensions) {
  if (dimensions.width === undefined || dimensions.width === 0 ||
    dimensions.height === undefined || dimensions.height === 0) {
    return { width: 0, height: 0 }
  }
  const maxWidth = maxDimensions.width
  const maxHeight = maxDimensions.height
  let width = maxWidth
  let height = width / dimensions.width * dimensions.height
  if (height > maxHeight) {
    height = maxHeight
    width = height / dimensions.height * dimensions.width
  }
  return { width, height }
}

export function getMinMaxOfPoints (points) {
  if (points.length === 0) {
    return {
      min: { x: 0, y: 0 },
      max: { x: 0, y: 0 }
    }
  }
  const min = { x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER }
  const max = { x: 0, y: 0 }
  for (const point of points) {
    if (point.x < min.x) { min.x = point.x }
    if (point.y < min.y) { min.y = point.y }
    if (point.x > max.x) { max.x = point.x }
    if (point.y > max.y) { max.y = point.y }
  }
  return { min, max }
}

export function getMinMaxOfArray (minMaxArray) {
  if (minMaxArray.length === 0) {
    return {
      min: { x: 0, y: 0 },
      max: { x: 0, y: 0 }
    }
  }
  const min = { x: Number.MAX_SAFE_INTEGER, y: Number.MAX_SAFE_INTEGER }
  const max = { x: 0, y: 0 }
  for (const minMax of minMaxArray) {
    if (minMax.min.x < min.x) { min.x = minMax.min.x }
    if (minMax.min.y < min.y) { min.y = minMax.min.y }
    if (minMax.max.x > max.x) { max.x = minMax.max.x }
    if (minMax.max.y > max.y) { max.y = minMax.max.y }
  }
  return { min, max }
}

export function ensureInt(value, fallback = 0) {
  const parsedValue = parseInt(value)
  return isNaN(parsedValue) ? fallback : parsedValue
}

export function ensureFloat(value, fallback = 0) {
  const parsedValue = parseFloat(value)
  return isNaN(parsedValue) ? fallback : parsedValue
}

export function isWithinBoundary (p, { x, y, width, height }) {
  return p.x >= x && (p.x < x + width) && p.y >= y && (p.y < y + height)
}
