import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import StatusBarItemView from './status-bar-item-view'

import './status-bar-view.scss'

@observer
export default class extends BaseComponent {
  render () {
    const { leftItems } = this.props
    return (
      <div className="status-bar-view">
        { leftItems.map((item) =>
          <StatusBarItemView item={ item } key={ item.id } />
        )}
      </div>
    )
  }
}
