import { inject, observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'

const SETTINGS_ID = 'move'
const PERSISTABLE_STATE = { selectionType: 'layers' }

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.initSettings({ settingsId: SETTINGS_ID, persistableState: PERSISTABLE_STATE })
    this.state = this.retrieveState(SETTINGS_ID, PERSISTABLE_STATE)
    props.updateSelectionType(this.state.selectionType)
  }

  updateSelectionType = (e) => {
    const selectionType = e.currentTarget.value
    this.setState({ selectionType })
    this.props.updateSelectionType(selectionType)
  }

  render () {
    const { selectionType } = this.state
    return (
      <div className="move-options-view">
        <label className="no-wrap mr-l">
          <input type="radio"
            className="mr-m"
            name="move-options-view__selection-type"
            checked={ selectionType === 'layers' }
            value="layers"
            onChange={ this.updateSelectionType } />
          Move Layers
        </label>
        <label className="no-wrap">
          <input type="radio"
            className="mr-m"
            name="move-options-view__selection-type"
            checked={ selectionType === 'objects' }
            value="objects"
            onChange={ this.updateSelectionType } />
          Move Objects
        </label>
      </div>
    )
  }
}
