import { action } from 'mobx'
import Vector from './vector'
import { getCorners } from '../rect-helpers'

export default class extends Vector {
  constructor (options = {}) {
    super(options)
  }

  @action setControlPoints = ({ topLeft, bottomRight }) => {
    const points = [
      topLeft,
      { x: bottomRight.x, y: topLeft.y },
      bottomRight,
      { x: topLeft.x, y: bottomRight.y }
    ]
    this.setCornerPoints(points)
  }

  @action setCorners = (point1, point2) => {
    this.setControlPoints(getCorners(point1, point2))
  }
}
