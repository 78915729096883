import { observable, computed, action } from 'mobx'
import Item from '../item'
import Point from '../point'
import hsh from '../../lib/hsh'

export default class VectorPoint extends Item {
  @observable x = 0
  @observable y = 0

  constructor (options = {}) {
    super(options)
    this.setPosition(options)
  }

  @action setPosition (options = {}) {
    hsh(this).assignValues(options, ['x', 'y'])
  }

  @computed get length () {
    const { x, y } = this
    return Math.hypot(x, y)
  }

  calc () {
    return new Point(this)
  }

  normalized () {
    const { x, y } = this
    return { x, y, c1x: x, c1y: y, c2x: x, c2y: y }
  }

  data () {
    return this.normalized()
  }

  clone () {
    const { x, y } = this
    return new VectorPoint({ x, y })
  }
}
