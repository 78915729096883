import { action } from 'mobx'
import React from 'react'
import BasePackage from '../../core/base-package'
import LayoutContainerViewList from './layout-container-view-list'
import LayoutContainer from './layout-container'
import Collection from '../../core/collection'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'layouts'
    this.layoutContainers = new Collection()
    this.hiddenItemsContainer = new LayoutContainer()
    this.freeformItemsContainer = new LayoutContainer()
    this.layoutContainerViewList = React.createElement(LayoutContainerViewList,
      { layoutContainers: this.layoutContainers,
        hiddenItemsContainer: this.hiddenItemsContainer,
        freeformItemsContainer: this.freeformItemsContainer
      })
    this.addDefaultLayoutContainers()
  }

  @action addHiddenItem = (options) => {
    this.hiddenItemsContainer.addLayoutItem(options)
  }

  @action addFreeformItem = (options) => {
    this.freeformItemsContainer.addLayoutItem(options)
  }

  @action findOrAddFreeformItem = (id, options) => {
    this.freeformItemsContainer.findOrAddLayoutItem(id, options)
  }

  @action removeFreeformItem = (id) => {
    this.freeformItemsContainer.removeLayoutItem(id)
  }

  @action clearFreeformItems = () => {
    this.freeformItemsContainer.layoutItems.clear()
  }

  @action addLayoutItem = (containerId, options) => {
    const layoutContainer = this.layoutContainers.find(containerId)
    return layoutContainer.addLayoutItem(options)
  }

  @action addLayoutContainers = (layoutContainerOptions) => {
    const layoutContainers = []
    for (const options of layoutContainerOptions) {
      layoutContainers.push(new LayoutContainer(options))
    }
    this.layoutContainers.concat(layoutContainers)
  }

  @action addDefaultLayoutContainers = () => {
    const layoutContainers = [
      {
        id: 'top-2',
        row: 10,
        col: 50,
        width: 'fill'
      },
      {
        id: 'top-1',
        row: 25,
        col: 50,
        width: 'fill'
      },
      {
        id: 'left-1',
        row: 50,
        col: 25
      },
      {
        id: 'center',
        row: 50,
        col: 50,
        width: 'fill',
        height: 'fill'
      },
      {
        id: 'right-1',
        row: 50,
        col: 75
      },
      {
        id: 'bottom-1',
        row: 75,
        col: 50,
        width: 'fill'
      }
    ]

    this.addLayoutContainers(layoutContainers)
  }
}
