import _ from 'lodash'

export default class {
  constructor (options = {}) {
    this.commandString = options.commandString
    this.commandObject = this.parseCommand(this.commandString)
  }

  parseCommand (commandString) {
    if (commandString === undefined) { return }
    const commandSegments = commandString.split(':')
    const packageId = commandSegments[0].split('.')[0]
    const subPackageId = commandSegments[0].split('.')[1]
    const functionCall = commandSegments[1]
    const functionSegments = functionCall.split('(')
    const functionName = _.camelCase(functionSegments[0])
    const functionParameterStr = functionSegments[1] ? functionSegments[1].split(')')[0] : undefined
    const functionParameters = functionParameterStr !== undefined ? functionParameterStr.split(',') : undefined
    return { packageId, subPackageId, functionName, functionParameters }
  }

  execute (app) {
    if (app === undefined) { return }
    const { packageId, subPackageId, functionName, functionParameters } = this.commandObject
    const program = app.packageFor(packageId)
    const subProgram = subPackageId === undefined ? program : program.subPackage(subPackageId)
    if (functionParameters !== undefined) {
      subProgram[functionName](...functionParameters)
    } else {
      subProgram[functionName]()
    }
  }
}
