import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import { isValidColor } from '../../core/color-helpers'
import EventList from '../../core/event-list'

@observer
export default class extends BaseComponent {
  componentWillMount () {
    const { newColor } = this.props
    this.events = new EventList()
    this.color = { hex: newColor.hex }
  }
  componentDidMount () {
    const { newColor } = this.props
    const { color } = this
    this.events.addObserver(newColor, 'hex', () => {
      if (color.hex === newColor.hex) { return }
      color.hex = newColor.hex
      this.setState({ color })
    })
  }
  onHexInputChange = (e) => {
    const { color } = this
    const inputValue = e.currentTarget.value
    color.hex = inputValue
    this.setState({ color })
    if (!isValidColor(inputValue)) { return }
    const { newColor } = this.props
    newColor.setHex(inputValue)
  }
  componentWillUnmount () {
    this.events.dispose()
  }
  render () {
    const { onHexInputChange, color } = this
    return (
      <tbody>
        <tr className="last-row-of-group">
          <td>#</td>
          <td>
            <input type="text"
              value={ color.hex }
              onChange={ (e) => onHexInputChange(e) }/>
          </td>
          <td></td>
        </tr>
      </tbody>)
  }
}
