import { action } from 'mobx'
import Vector from './vector'
import hsh from '../../lib/hsh'

export default class extends Vector {
  constructor (options = {}) {
    super(options)
    this.setControlPoints(options.topLeft, options.width, options.height)
  }

  @action setControlPoints = (topLeft, width, height) => {
    hsh(this).assignValues({ topLeft, width, height })
    if ([topLeft, width, height].includes(undefined)) { return }
    const topRight = { x: topLeft.x + width, y: topLeft.y }
    const points = [
      topRight,
      { x: topLeft.x, y: topRight.y + height / 2.0 },
      { x: topRight.x, y: topRight.y + height }
    ]
    this.setCornerPoints(points)
  }
}
