import { observer, inject } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import Dropzone from 'react-dropzone'

import './workspace-view.scss'

@inject('app') @observer
export default class extends BaseComponent {
  render () {
    const { onFileDrop, onFileDragEnter, onFileDragLeave } = this.props
    const { layoutContainerViewList } = this.packageFor('layouts')
    return (
      <Dropzone style={{ height: '100%' }}
        disableClick
        onDrop={ onFileDrop }
        onDragEnter={ onFileDragEnter }
        onDragLeave={ onFileDragLeave }>
        <div className="workspace-view fill-absolute">
          { layoutContainerViewList }
        </div>
      </Dropzone>
    )
  }
}
