import el from './el'
import Point from '../core/point'

export class Ev {
  constructor (event) {
    this.event = event
  }

  relativeCoordinates (element, options = {}) {
    const e = this.event
    const parentOffset = element === window ? { left: 0, top: 0 } : el(element).offset()
    let x = e.pageX - parentOffset.left
    let y = e.pageY - parentOffset.top
    if (options.includeScroll) {
      const hasScrollbars = el(element).hasScrollbars()
      if (hasScrollbars.horizontal) { x += element.scrollLeft }
      if (hasScrollbars.vertical) { y += element.scrollTop }
    }
    return new Point({ x, y })
  }

  point () {
    const e = this.event
    let x = e.pageX
    let y = e.pageY
    return new Point({ x, y })
  }
}

export default function ev (event) {
  return (event instanceof Ev) ? event : new Ev(event)
}
