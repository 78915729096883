import Filter from '../../core/filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'invert'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;

      void main() {
          vec4 color = texColorFor(_src);
          outputColor = vec4(1.0 - color.r, 1.0 - color.g, 1.0 - color.b, color.a);
      }
    `
  }
}
