import React from 'react'
import { inject, observer } from 'mobx-react'
import BaseComponent from '../../core/base-component'
import RgbInputs from './rgb-inputs'
import HsvInputs from './hsv-inputs'
import HexInputs from './hex-inputs'

import './color-picker-info.scss'

const SETTINGS_ID = 'color-picker'
const PERSISTABLE_STATE = { inputType: 'hsv' }

@inject('app') @observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.initSettings({ settingsId: SETTINGS_ID, persistableState: PERSISTABLE_STATE })
    this.state = this.retrieveState()
  }

  updateInputType = (e) => {
    const inputType = e.currentTarget.value
    this.setState({ inputType })
  }

  onSubmit = (e) => {
    const { onConfirm } = this.props
    e.preventDefault()
    onConfirm(e)
  }

  render () {
    const { currentColor, newColor, onCancel } = this.props
    const { onSubmit } = this
    const { inputType } = this.state
    const { ColorView } = this.packageFor('colors')

    return (
      <div className="color-picker-info">
        <form onSubmit={ this.onSubmit }>
          <div className="color-picker-info__comparor">
            <div className="color-picker-info__comparor-label">new</div>
            <ColorView color={ newColor } className="new-color" />
            <ColorView color={ currentColor } className="current-color" />
            <div className="color-picker-info__comparor-label">current</div>
          </div>
          <table>
            <tbody>
              <tr className="color-picker-info__hsv-rgb-selector">
                <td colSpan="3">
                  <label className="no-wrap mr-l">
                    <input type="radio"
                      className="mr-m"
                      name="color-picker-info__color-input-type"
                      value="hsv"
                      checked={ inputType === 'hsv' }
                      onChange={ this.updateInputType } />
                    HSV
                  </label>
                  <label className="no-wrap">
                    <input type="radio"
                      className="mr-m"
                      name="color-picker-info__color-input-type"
                      value="rgb"
                      checked={ inputType === 'rgb' }
                      onChange={ this.updateInputType } />
                    RGB
                  </label>
                </td>
              </tr>
            </tbody>
            {inputType === 'hsv' &&
              <HsvInputs newColor={ newColor } />
            }
            {inputType === 'rgb' &&
              <RgbInputs newColor={ newColor } />
            }
            <HexInputs newColor={ newColor } />
          </table>
          <div className="border-buttons">
            <button type="button"
              className="border-button mr-m"
              onClick={ onCancel }>
              Cancel
            </button>
            <button type="submit"
              className="border-button">
              Ok
            </button>
          </div>
        </form>
      </div>
    )
  }
}
