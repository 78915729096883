export function layersMenu () {
  const name = 'Layers'
  return [
    {
      path: [name, 'Duplicate Layer'],
      action: 'layers:duplicate-layer'
    },
    {
      path: [name, 'Rasterize Layer'],
      action: 'layers:rasterize-layer'
    },
    {
      path: [name, 'DIVIDER']
    },
    {
      path: [name, 'Merge Down'],
      action: 'layers:merge-down'
    },
    {
      path: [name, 'Merge Visible'],
      action: 'layers:merge-visible'
    },
    {
      path: [name, 'Flatten Image'],
      action: 'layers:flatten-image'
    }
  ]
}
