import React from 'react'
import BasePackage from '../../core/base-package'
import DocOpenerView from './doc-opener-view'

export default class extends BasePackage {
  constructor (options) {
    super(options)
    this.id = 'doc-opener'
  }

  activate = () => {
    this.initDocOpenerDiaload()
  }

  initDocOpenerDiaload = () => {
    const docOpener = React.createElement(DocOpenerView, {
      openFiles: (files) => {
        this.hideDocOpenerDialog()
        const docsPackage = this.packageFor('docs')
        docsPackage.openFiles(files)
      },
      openUrl: (url) => {
        this.hideDocOpenerDialog()
        const docsPackage = this.packageFor('docs')
        const doc = docsPackage.openImageFromSrc(url)
        const fileName = url.split('/').pop().split('?')[0]
        if (fileName !== undefined) {
          doc.rename(fileName)
        }
      },
      close: this.hideDocOpenerDialog
    })
    this.docOpenerDialog = this.createDialog(docOpener, {
      onCancel: this.hideDocOpenerDialog,
      name: 'Open Document'
    })
  }

  showDocOpenerDialog = () => { this.docOpenerDialog.show() }

  hideDocOpenerDialog = () => { this.docOpenerDialog.hide() }
}
