import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import classNames from 'classnames'

import './tool-view.scss'

@observer
export default class extends BaseComponent {
  render () {
    const { tool, isActive, tools } = this.props
    return (
      <div className={ classNames('tool-view activeable', { active: isActive }) }
        title={ tool.info }
        onClick={ () => tools.setActiveId(tool.id) }>
        { tool.icon &&
          <div className="tool-icon-container">
            <div className="tool-icon-sub-container">
              <div className={ classNames('tool-icon', tool.icon) }></div>
            </div>
          </div>
        }
        { tool.icon === undefined &&
          <div className='tool-name p-m'>{ tool.name }</div>
        }
      </div>
    )
  }
}
