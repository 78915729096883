import { observer } from 'mobx-react'
import React from 'react'
import BaseComponent from '../../core/base-component'
import { ensureValidCss } from '../../core/style-helpers'
import Pannable from '../../core/pannable'
import EventList from '../../core/event-list'

import './doc-viewport.scss'
const RECT_BORDER_WIDTH = 1

@observer
export default class extends BaseComponent {
  constructor (props) {
    super(props)
    this.doc = props.doc
  }

  componentDidMount () {
    this.initEvents(this.props, this.root)
  }

  rectStyle = (doc) => {
    const { zoomedWidth, zoomedHeight } = doc
    const style = {
      borderWidth: RECT_BORDER_WIDTH,
      width: zoomedWidth,
      height: zoomedHeight
    }
    return ensureValidCss(style)
  }

  updateRectContainerPadding = () => {
    if (this.pannable === undefined) { return }
    const { doc } = this.props
    const newRectSize = {
      width: doc.zoomedWidth + RECT_BORDER_WIDTH * 2,
      height: doc.zoomedHeight + RECT_BORDER_WIDTH * 2
    }
    this.pannable.updateContainerPadding(newRectSize)
  }

  rootResized = () => {
    this.updateRectContainerPadding()
    this.pannable.elementsResized()
  }

  initEvents = (props, scrollContainer) => {
    const { onViewportRectUpdate } = props
    const { rectContainer } = this.refs
    this.scrollContainer = scrollContainer
    this.events = new EventList()
    this.pannable = new Pannable({ element: this.rect,
      container: rectContainer,
      scrollContainer: scrollContainer })
    this.events.addDomEvent(scrollContainer, 'resize', this.rootResized)
    if (onViewportRectUpdate) {
      this.events.addEvent(this.pannable.onElementPositionUpdate, () => {
        onViewportRectUpdate()
      })
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { props } = this
    if (props.visible !== nextProps.visible) {
      if (props.visible) { // doc is going to become invisible
        this.pannable.storeScrollRatio()
        this.prevScrollRatio = {
          left: this.pannable.scrollRatio.left,
          top: this.pannable.scrollRatio.top
        }
      } else { // doc is going to become visible
        if (this.prevScrollRatio) {
          this.pannable.scrollRatio.left = this.prevScrollRatio.left
          this.pannable.scrollRatio.top = this.prevScrollRatio.top
          this.pannable.restoreScrollRatio()
        }
        const { onViewportRectUpdate } = props
        if (onViewportRectUpdate) {
          onViewportRectUpdate()
        }
      }
    }
  }

  componentWillUpdate () {
    this.updateRectContainerPadding()
  }

  componentDidUpdate () {
    if (this.pannable !== undefined) {
      this.pannable.elementsResized()
    }
  }

  componentWillUnmount () {
    this.events.dispose()
    this.pannable.dispose()
  }

  rectRefCallback = (rect) => {
    this.rect = rect
    const { rectRef } = this.props
    if (rectRef) { rectRef(rect) }
  }

  rootRefCallback = (root) => {
    this.root = root
    const { rootRef } = this.props
    if (rootRef) { rootRef(root) }
  }

  rootStyle = () => {
    const { doc } = this
    const disablePointerEvents = doc.inTextEditMode
    return {
      pointerEvents: disablePointerEvents ? 'none' : 'auto'
    }
  }

  render () {
    const { doc } = this.props
    const { rectStyle } = this
    return (
      <div className="doc-viewport fill-absolute"
        ref={ this.rootRefCallback }
        style={ { ...this.rootStyle() } }>
        <div className="doc-viewport__rect-container"
          ref="rectContainer">
          <div className="doc-viewport__rect"
            ref={ this.rectRefCallback }
            style={ { ...rectStyle(doc) } }>
          </div>
        </div>
      </div>
    )
  }
}
