import Filter from '../../core/filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'grayscale'
  }

  getFragmentShader () {
    return `
      uniform sampler2D _src;

      void main() {
          vec4 color = texColorFor(_src);
          float brightness = getBrightnessOfColor(color);
          outputColor = vec4(brightness, brightness, brightness, color.a);
      }
    `
  }
}
