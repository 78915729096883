import _ from 'lodash'

export class Ary {
  constructor (array) {
    this.array = array
  }

  last () {
    const { array } = this
    return array[array.length - 1]
  }

  clone () {
    return this.array.concat()
  }

  closestAvailableIndex (availableIndexes, index) {
    if (availableIndexes.length === 1) {
      return availableIndexes[0]
    }
    for (let i = 0; i < availableIndexes.length - 1; i++) {
      const availableIndex = availableIndexes[i]
      const nextIndex = availableIndexes[i + 1]
      if (availableIndex === index) {
        return availableIndex
      }
      if (availableIndex > index && nextIndex < index) {
        return availableIndex
      }
    }
    return availableIndexes[0]
  }

  closestIndex (index, availableIndexes) {
    const { array } = this
    if (index >= array.length) {
      return array.length - 1
    }
    if (index < 0) {
      return 0
    }
    return index
  }

  remove (item) {
    const { array } = this
    const index = array.indexOf(item)
    if (index === -1) { return }
    array.splice(index, 1)
  }

  closest (index) {
    const { array } = this
    return array[this.closestIndex(index)]
  }

  isEqualTo (comparison) {
    return _.isEqual(this.array.concat().sort(), comparison.concat().sort())
  }
}

export default function ary (array) {
  return (array instanceof Ary) ? array : new Ary(array)
}
