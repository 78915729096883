import Vector from './vector'

const TANGENT_MULTIPLIER = 0.552284749831

export default class extends Vector {
  constructor (options) {
    super(options)
    this.center = options.center || { x: 0, y: 0 }
    this.radius = options.radius || 0
  }

  setCenter (x, y) {
    this.center.x = x
    this.center.y = y
    this.syncPoints()
  }

  setRadius (r) {
    this.radius = r
    this.syncPoints()
  }

  syncPoints () {
    const c = this.center
    const r = this.radius
    const top = { x: c.x, y: c.y - r }
    const right = { x: c.x + r, y: c.y }
    const bottom = { x: c.x, y: c.y + r }
    const left = { x: c.x - r, y: c.y }
    const tangentLength = TANGENT_MULTIPLIER * r

    const topPoint = {
      x: top.x, y: top.y,
      c1x: top.x - tangentLength, c1y: top.y,
      c2x: top.x + tangentLength, c2y: top.y
    }
    const rightPoint = {
      x: right.x, y: right.y,
      c1x: right.x, c1y: right.y - tangentLength,
      c2x: right.x, c2y: right.y + tangentLength
    }
    const bottomPoint = {
      x: bottom.x, y: bottom.y,
      c1x: bottom.x + tangentLength, c1y: bottom.y,
      c2x: bottom.x - tangentLength, c2y: bottom.y
    }
    const leftPoint = {
      x: left.x, y: left.y,
      c1x: left.x, c1y: left.y + tangentLength,
      c2x: left.x, c2y: left.y - tangentLength
    }
    this.setCubicPoints([topPoint, rightPoint, bottomPoint, leftPoint])
  }
}
