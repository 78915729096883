import { wgl } from '../wgl'
import Tex from '../tex'

import HorizontalPresenceFilter from '../filters/presence-filter/horizontal-presence-filter'
import VerticalPresenceFilter from '../filters/presence-filter/vertical-presence-filter'
import CornerPresenceFilter from '../filters/presence-filter/corner-presence-filter'

const horizontalPresenceFilter = new HorizontalPresenceFilter()
const verticalPresenceFilter = new VerticalPresenceFilter()
const cornerPresenceFilter = new CornerPresenceFilter()

const BATCH_SIZE = 50.0

function textureHasDataReturn (tempTex, value) {
  tempTex.dispose()
  return value
}

export function texHasData (tex) {
  const { width, height } = tex
  const compressedWidth = Math.ceil(width / BATCH_SIZE)
  const compressedHeight = Math.ceil(height / BATCH_SIZE)

  const tempTex = new Tex()

  wgl.applyFilter({
    filter: horizontalPresenceFilter,
    des: {
      texture: 'temp',
      width: compressedWidth,
      height
    },
    data: {
      _src: tex
    }
  })

  tempTex.swapTexture(wgl.tempRenderedTex)
  if (tempTex.colorAtPoint({ x: 0, y: 0 }).a > 0) { return textureHasDataReturn(tempTex, true) }

  wgl.applyFilter({
    filter: verticalPresenceFilter,
    des: {
      texture: 'temp',
      width: compressedWidth,
      height: compressedHeight
    },
    data: {
      _src: tempTex
    }
  })

  tempTex.swapTexture(wgl.tempRenderedTex)
  if (tempTex.colorAtPoint({ x: 0, y: 0 }).a > 0) { return textureHasDataReturn(tempTex, true) }

  wgl.applyFilter({
    filter: cornerPresenceFilter,
    des: {
      texture: 'temp',
      width: 1,
      height: 1
    },
    data: {
      _src: tempTex
    }
  })

  tempTex.swapTexture(wgl.tempRenderedTex)
  const hasData = tempTex.colorAtPoint({ x: 0, y: 0 }).a > 0

  return textureHasDataReturn(tempTex, hasData)
}
