import Collection from './collection'
import GlCanvas from './gl-canvas'

const MAX_GL_CANVASES = 16
class GlCanvasPool {
  constructor () {
    this.pool = new Collection()
    this.usedIds = {}
  }

  getUnusedGlCanvas () {
    return this.pool.detect((item) => this.usedIds[item.id] !== true)
  }

  requestGlCanvas () {
    let glCanvas = this.getUnusedGlCanvas()
    if (glCanvas === undefined) {
      glCanvas = new GlCanvas()
      this.pool.add(glCanvas)
    }
    if (this.pool.count() > MAX_GL_CANVASES) {
      throw new Error(`The maximum number of GL Canvases allowed has been exceeded,
        GL Canvases must always be released after use`)
    }
    this.usedIds[glCanvas.id] = true
    return glCanvas
  }

  releaseGlCanvas (glCanvas) {
    glCanvas.clear()
    this.usedIds[glCanvas.id] = undefined
  }
}

export const pool = new GlCanvasPool()
