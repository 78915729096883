import Filter from '../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'flatten'
  }

  getFragmentShader () {
    return `
      uniform float textureCount;
      uniform float useViewport;

      uniform sampler2D src1;
      uniform sampler2D src2;
      uniform sampler2D src3;
      uniform sampler2D src4;

      uniform float alpha1;
      uniform float alpha2;
      uniform float alpha3;
      uniform float alpha4;

      uniform float blendMode1;
      uniform float blendMode2;
      uniform float blendMode3;
      uniform float blendMode4;

      uniform bool viewportClipped1;
      uniform bool viewportClipped2;
      uniform bool viewportClipped3;
      uniform bool viewportClipped4;

      vec4 conditionalColor (sampler2D tex, float alpha, bool viewportClipped) {
        vec4 color;
        if (useViewport == 1.0 && !viewportClipped) {
          color = viewportTexColorFor(tex);
        } else {
          color = texColorFor(tex);
        }
        color.a = color.a * alpha;
        return color;
      }

      void main () {
        float x = coordX();
        float y = coordY();
        vec4 color = vec4(0.0, 0.0, 0.0, 0.0);

        if (textureCount > 0.0) {
          color = conditionalColor(src1, alpha1, viewportClipped1);
          color = drawColorOnTop(color, vec4(0.0), 1.0, COMPOSITE_MODE_NORMAL, blendMode1);
        }
        if (textureCount > 1.0) {
          color = drawColorOnTop(conditionalColor(src2, alpha2, viewportClipped2), color, 1.0, COMPOSITE_MODE_NORMAL, blendMode2);
        }
        if (textureCount > 2.0) {
          color = drawColorOnTop(conditionalColor(src3, alpha3, viewportClipped3), color, 1.0, COMPOSITE_MODE_NORMAL, blendMode3);
        }
        if (textureCount > 3.0) {
          color = drawColorOnTop(conditionalColor(src4, alpha4, viewportClipped4), color, COMPOSITE_MODE_NORMAL, blendMode4);
        }

        outputColor = color;
      }
    `
  }
}
