export function viewMenu () {
  const name = 'View'
  return [
    {
      path: [name, 'Zoom In'],
      action: 'docs:zoom-in'
    },
    {
      path: [name, 'Zoom Out'],
      action: 'docs:zoom-out'
    },
    {
      path: [name, 'Fit to Screen'],
      action: 'docs:zoom-to-fit'
    },
    {
      path: [name, 'DIVIDER']
    },
    {
      path: [name, 'Increase UI Size'],
      action: 'ui:increase-font-size'
    },
    {
      path: [name, 'Decrease UI Size'],
      action: 'ui:decrease-font-size'
    },
    {
      path: [name, 'Reset UI Size'],
      action: 'ui:reset-font-size'
    }
  ]
}
