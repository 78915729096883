import { action } from 'mobx'
import Collection from '../collection'
import Version from './version'
import EventList from '../event-list'
import hsh from '../../lib/hsh'

export default class {
  constructor (options = {}) {
    this.doc = options.doc
    this.events = new EventList()
    this.versions = new Collection({ collectionLimit: 50 })
    // this.versions = new Collection({ collectionLimit: 2 })
    this.resourcePool = {}
    this.events.addEvent(this.versions.itemPendingDisposal, (version) => {
      this.clearResourceUsage({ version })
    })
  }

  record ({ name, combinable }) {
    const { doc, versions } = this
    this.removeVersionsAfterActiveVersion()
    const prevVersion = versions.last()
    const version = new Version({ name, combinable })
    version.processItem({ item: doc, prevVersion })
    this.recordResourceUsage({ version })
    if (prevVersion !== undefined) {
      const canCombine = version.combinable && prevVersion.combinable && version.name === prevVersion.name
      const timeBetweenVersions = version.createdAt - prevVersion.createdAt
      if (canCombine && timeBetweenVersions < 1 * 1000) {
        // version.createdAt = prevVersion.createdAt
        this.versions.disposeItem(prevVersion)
      }
    }
    versions.push(version)
    versions.setActiveId(version.id)
    return version
  }

  recordResourceUsage ({ version }) {
    version.forEachResource((resource) => {
      if (this.resourcePool[resource.id] === undefined) {
        this.resourcePool[resource.id] = 0
      }
      this.resourcePool[resource.id]++
    })
  }

  clearResourceUsage ({ version }) {
    version.forEachResource((resource) => {
      this.resourcePool[resource.id]--
      if (this.resourcePool[resource.id] === 0) {
        resource.dispose()
        delete this.resourcePool[resource.id]
      }
    })
  }

  removeVersionsAfterActiveVersion = () => {
    const { versions } = this
    if (!versions.empty()) {
      while (versions.last().id !== versions.getActiveItem().id) {
        versions.disposeLast()
      }
    }
  }

  @action restore (versionId) {
    const version = this.versions.find(versionId)
    const { doc } = this
    version.restoreItem({ item: doc, id: doc. id })
    this.versions.setActiveId(versionId)
  }

  goBack () {
    const version = this.versions.itemBeforeActiveItem()
    if (version === undefined) { return }
    this.restore(version.id)
  }

  goForward () {
    const version = this.versions.itemAfterActiveItem()
    if (version === undefined) { return }
    this.restore(version.id)
  }

  dispose () {
    this.versions.dispose()
    this.events.dispose()
  }
}
