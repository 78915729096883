import React from 'react'
import FilterPackage from '../../core/filter-package'
import GrayscaleFilter from './grayscale-filter'

export default class extends FilterPackage {
  constructor (options) {
    super(options)
    this.id = 'grayscale'
    this.filter = new GrayscaleFilter()
    this.filterOptions = {}
    this.resetFilterOptions()
  }

  getName () {
    return 'Grayscale'
  }
}
