import Filter from '../filter'

export default class extends Filter {
  constructor (options) {
    super(options)
    this.id = 'rotate'
  }

  getFragmentShader () {
    return `
      const float ROTATE_CLOCKWISE = 1.0;
      const float ROTATE_COUNTER_CLOCKWISE = 2.0;
      uniform sampler2D _src;
      uniform vec2 _srcSize;
      uniform float _direction;

      void main () {
        vec2 position = currPosition();
        vec2 rotatedPosition;
        if (_direction == ROTATE_CLOCKWISE) {
          rotatedPosition = vec2(position.y, _srcSize.y - position.x);
        }
        if (_direction == ROTATE_COUNTER_CLOCKWISE) {
          rotatedPosition = vec2(_srcSize.x - position.y, position.x);
        }
        outputColor = texColorAt(_src, _srcSize, rotatedPosition);
      }
    `
  }
}
